export default {
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'stretch',
  },
  item: {
    '&:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
  '@media print': {
    container: {
      display: 'none',
    },
  },
}
