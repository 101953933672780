import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavSideLink = (props) => {
  const {
    classes: classesProp,
    className,
    linkProps,
    text,
    // eslint-disable-next-line no-unused-vars
    variant,
    isActive,
    isSpaceColorOnHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (

    <A
      {...linkProps}
      className={cx(classes.container, className, isActive && 'is-active', isSpaceColorOnHover && 'is-spacecoloronhover')}
    >
      {text}
    </A>
  )
}

NavSideLink.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  linkProps: PropTypes.shape(A.propTypes),
  text: PropTypes.string,
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
  isActive: PropTypes.bool,
  isSpaceColorOnHover: PropTypes.bool,
}

NavSideLink.defaultProps = {
  className: null,
  classes: null,
  linkProps: null,
  text: '',
  isActive: false,
  isSpaceColorOnHover: false,
}

export default withMemo()(NavSideLink)
