import { colors, fonts, grids, tablet } from '../../theme'


export default {
  container: {
    '&:not(:first-child)': {
      marginTop: '4rem',
    },
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '6rem',
    },
    ...tablet({
      '&:not(:first-child)': {
        marginTop: '9rem',
      },
    }),
  },
  image: {
    textAlign: 'center',
    fontSize: '1.8rem',
    '& > *:not(:first-child)': {
      marginTop: '1.5rem',
    },
    ...tablet({
      fontSize: '1.5rem',
    }),
  },
  imageTitle: {
    display: 'block',
  },
  imageArrow: {
    display: 'block',
    width: '1.2rem',
    height: '1.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: colors.grey95,
  },
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
    ...tablet({
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      '&:not(:first-child)': {
        marginTop: '7.5rem',
      },
    }),
  },
  menu: {
    ...tablet({
      width: 'calc(25% - 3rem)',
    }),
  },
  content: {
    marginTop: '3rem',
    ...tablet({
      flex: '0 0 auto',
      width: '75%',
      marginTop: '0',
      marginLeft: 'auto',
    }),
  },
  title: {
    ...fonts.MaaxBold,
    position: 'relative',
    margin: 0,
    paddingTop: '2.5rem',
    fontSize: '2.5rem',
    textTransform: 'uppercase',
    lineHeight: 1.1,
    color: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseEnvironment
          : props.variant === 'listen' ? colors.orangeListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '0.5rem',
      width: '11.5rem',
      backgroundColor: 'currentColor',
    },
    ...tablet({
      fontSize: '3rem',
    }),
  },
  text: {
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
    ...tablet({
      '&:not(:first-child)': {
        marginTop: '6rem',
      },
    }),
  },
  files: {
    marginTop: '1rem',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
    '& a': {
      marginTop: '1rem',
    },
  },
  '@media print': {
    container: {

      '&:not(:first-child)': {
        marginTop: '1px',
      },
    },
    menu: {
      display: 'none',
    },
  },
}
