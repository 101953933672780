import { accessibleHiddenText, colors, fonts, mobile, tablet } from '../../theme'


export default {
  container: {
    marginTop: '4rem',
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '4rem',
    },
  },
  wrapper: {
    position: 'relative',
    maxWidth: '104rem', // diff from the rest of the site
    paddingLeft: '3rem',
    paddingRight: '3rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  // slider
  slider: {
    '& > .keen-slider': {
      visibility: 'hidden',
    },
  },
  slide: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  nav: {
    position: 'absolute',
    top: '50%',
    zIndex: 5,
    cursor: 'pointer',
    color: colors.blue,
    padding: '0.5rem',
    transform: 'translateY(-40%)',
    '&.is-disabled': {
      opacity: '50%',
      pointerEvents: 'none',
    },
    '& i': {
      ...accessibleHiddenText,
    },
    ...tablet({
      display: 'none',
      pointerEvents: 'none',
    }),
  },
  previous: {
    left: '0.5rem',
    ...mobile({
      left: '1rem',
    }),
  },
  next: {
    right: '0.5rem',
    ...mobile({
      right: '1rem',
    }),
  },
  navIcon: {
    width: '1.5rem',
    height: '2rem',
  },
  // content
  link: {
    ...fonts.MaaxBold,
    display: 'block',
    maxWidth: '10rem',
    margin: '0 auto',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '1rem',
    color: colors.pureBlack,
    opacity: '0.4',
    transform: 'translate3d(0,0,0) scale(1)',
    transition: 'all 200ms ease-out',
    '&.is-current': {
      opacity: 1,
      pointerEvents: 'none',
    },
    '&:hover': {
      opacity: '1',
      transform: 'translate3d(0,0,0) scale(1.1)',
    },
  },
  figure: {
    width: '7rem',
    height: '7rem',
    margin: '0 auto',
  },
  text: {
    display: 'block',
    marginTop: '1.5rem',
  },
  '@media print': {
    container: {
      display: 'none',
    },
  },
}
