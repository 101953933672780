import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import A from '../A'
import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'
import Image from '../Image'
import Icon from '../Icon'
import icons from '../Icon/assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockAnchor = (props) => {
  const {
    classes: classesProp,
    className,
    id,
    title,
    text,
    files,
    image,
    zoneMenu,
    TagTitle,
    // eslint-disable-next-line no-unused-vars
    variant,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <section
      className={cx(classes.container, className)}
    >
      {!zoneMenu && image && (
        <div className={classes.image}>
          <span
            className={classes.imageTitle}
            aria-hidden="true"
          >
            {title}
          </span>
          <Icon
            icon={icons.ArrowDown}
            className={classes.imageArrow}
          />
          <Image
            {...image}
            className={classes.imageImage}
          />
        </div>
      )}
      <div className={classes.wrapper}>
        {zoneMenu && <div className={classes.menu}>{zoneMenu}</div>}
        <div
          className={classes.content}
          id={id}
        >
          <TagTitle className={classes.title}>{title}</TagTitle>
          {text && (
          <MarkdownStyled
            className={classes.text}
            text={text}
            variant={variant}
            lexicon={lexicon}
            markHover={markHover}
          />
          )}
          {files && files.length > 0 && (
            <div className={classes.files}>
              {files.map((file, index) => (
                <A
                  key={`file_${index}`}
                  href={file.url}
                  target="_blank"
                >
                  {file.label}
                </A>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

BlockAnchor.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  image: PropTypes.shape(Image.propTypes),
  zoneMenu: PropTypes.node,
  TagTitle: PropTypes.string,
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

BlockAnchor.defaultProps = {
  className: null,
  classes: null,
  text: '',
  files: [],
  image: null,
  zoneMenu: null,
  TagTitle: 'h2',
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(BlockAnchor)
