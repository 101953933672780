import React from 'react'

import { generateAkamaiUrl } from '../../../helpers/Akamai'
import FontsResizer from '../../../components/FontsResizer'
import ShareLinks from '../../../components/ShareLinks'
import { shareLinksTransformer } from '../../../transformers'


export const praticesTransformer = (item) => item?.nodeQuery?.entities?.map((practice) => ({
    text: practice?.title ?? '',
    linkProps: {
        href: practice?.entityUrl?.path ?? '',
    },
    image: {
        src: generateAkamaiUrl(practice?.fieldIconeEspace?.url ?? ''),
        alt: practice?.fieldIconeEspace?.alt ?? '',
    },
}))

export const praticesViewTransformer = (item) => {
    const practices = item?.enPratiqueView?.results?.map((practice) => ({
        text: practice?.title ?? '',
        linkProps: {
            href: practice?.entityUrl?.path ?? '',
        },
        image: {
            src: generateAkamaiUrl(practice?.fieldIconeEspace?.url ?? ''),
            alt: practice?.fieldIconeEspace?.alt ?? '',
        },
    }))

    try {
        return {
            practices,
            meta: JSON.parse(item?.enPratiqueView?.meta),
        }
    } catch (e) {
        return { practices, meta: {} }
    }
}

export const navigationPracticeTransformer = (t, practices) => ({
    textPrevious: t('textPrevious'),
    textNext: t('textNext'),
    practices: practices ?? [],
})

export const blockToPracticeTransformer = (t, practices) => ({
    title: t('textEnPratiqueTitle'),
    text: `<p>${t('textEnPratiqueDescription')}</p>`,
    navigationProps: navigationPracticeTransformer(t, practices),
})

export const headerPracticeDetailTransformer = (item, t) => ({
    title: item?.title ?? '',
    variant: 'practicePage',
    text: item?.body.value ?? '',
    zoneTools: (
      <>
        <FontsResizer />
        <ShareLinks
          {...shareLinksTransformer(
                    {
                        title: item?.title ?? '',
                        url: item?.url ?? null,
                        mailToBody: t('sharePracticeBody'),
                    },
                    t
                )}
        />
      </>
    ),
})

export const practiceMapTransformer = (item) => ({
  referenceWidth: 600,
  backgroundImage: item?.fieldImage ? {
    src: generateAkamaiUrl(item?.fieldImage?.url ?? ''),
    alt: item?.fieldImage?.alt ?? '',
  } : null,
  mapSlides: item?.fieldOutilZones?.map(({ entity }, index) => ({
    groupId: `group${index + 1}`,
    positionX: entity?.fieldZoneOutilX ?? 0,
    title: entity?.fieldZoneTitle ?? '',
    positionY: entity?.fieldZoneOutilY ?? 0,
    text: entity?.fieldImageAncre?.alt ?? '',
    image: entity?.fieldImageAncre ? {
      src: generateAkamaiUrl(entity?.fieldImageAncre?.url ?? ''),
      alt: entity?.fieldImageAncre?.alt ?? '',
    } : null,
  })) ?? [],
})

export const practiceSliderTransformer = ({ entity }, t) => ({
    textPrevious: t('textPrevious'),
    textNext: t('textNext'),
    visibleSlides: 3,
    slides: entity?.fieldZoneOutilProduits.map(({ entity: zone }) => ({
        productId: `prod${zone.id}`,
        text: zone?.fieldProduitTitre ?? '',
        image: zone?.fieldProduitImage
            ? {
                src: generateAkamaiUrl(zone?.fieldProduitImage?.url ?? ''),
                alt: zone?.fieldProduitImage?.alt ?? '',
            }
            : null,
        tabs:
            zone?.fieldProduitOnglets?.map(({ entity: produit }) => ({
                imageTab: {
                    src: generateAkamaiUrl(produit?.fieldIcones?.entity?.fieldImage?.url) ?? '',
                    alt: produit?.fieldIcones?.entity?.fieldImage?.alt ?? '',
                },
                imageTabActive: {
                    src: generateAkamaiUrl(produit?.fieldIcones?.entity?.fieldImageActive?.url) ?? '',
                    alt: produit?.fieldIcones?.entity?.fieldImageActive?.alt ?? '',
                },
                imageTabBlack: {
                    src: generateAkamaiUrl(produit?.fieldIcones?.entity?.fieldImageLegend?.url) ?? '',
                    alt: produit?.fieldIcones?.entity?.fieldImageLegend?.alt ?? '',
                },
                text: produit?.fieldOngletsLegendeIcone ?? '',
                contents: produit?.fieldOngletsContenu?.map(({ value }) => value) ?? [],
            })) ?? [],
    })),
})
