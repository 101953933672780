import { accessibleHiddenText } from '../../theme'


export default {
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: (props) => (props.displaySize ? `${props.displaySize}px` : '16px'),
    background: 'transparent',
    padding: '0.5rem',
    '&:not(:disabled)': {
      cursor: 'pointer',
    },
    '& i': {
      ...accessibleHiddenText,
    },
  },
}
