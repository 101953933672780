import { colors, fonts, grids, mobile } from '../../theme'


export default {
  container: {
    padding: '3rem 0 5rem',
    background: colors.greyDE,
    '&:not(:first-child)': {
      marginTop: '4rem',
    },
  },
  wrapper: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    maxWidth: `calc(${(grids.desktop.boxingContent + 20) / 10}rem + 4rem)`, // exception here....
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    ...fonts.MaaxBold,
    margin: 0,
    fontSize: '2.5rem',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  list: {
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  item: {
    flex: '1 1 25%',
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  card: {},
  ...mobile({
    container: {
      padding: '5rem 0 10rem',
      '&:not(:first-child)': {
        marginTop: '9rem',
      },
    },
    list: {
      display: 'flex',
      justifyContent: 'center',
      '&:not(:first-child)': {
        marginTop: '5rem',
      },
    },
    item: {
      '&:not(:first-child)': {
        marginTop: '0',
        marginLeft: '2rem',
      },
    },
    card: {
      height: '100%',
    },
  }),
  '@media print': {
    container: {
      display: 'none',
    },
  },
}
