import { declareFont, defineFont, fontWeights } from '../utils/FontUtils'


export const declaration = {
  MaaxRegular: {
    basename: 'Maax-Regular',
    fontFamily: 'Maax',
    fontWeight: fontWeights.regular,
    fallback: 'sans-serif',
    cssVariable: '--MaaxRegular',
  },
  MaaxMedium: {
    basename: 'Maax-Medium',
    fontFamily: 'Maax',
    fontWeight: fontWeights.medium,
    fallback: 'sans-serif',
    cssVariable: '--MaaxMedium',
  },
  MaaxBold: {
    basename: 'Maax-Bold',
    fontFamily: 'Maax',
    fontWeight: fontWeights.bold,
    fallback: 'sans-serif',
    cssVariable: '--MaaxBold',
  },
  MaaxBlack: {
    basename: 'Maax-Black',
    fontFamily: 'Maax',
    fontWeight: fontWeights.black,
    fallback: 'sans-serif',
    cssVariable: '--MaaxBlack',
  },
}

export default {
  MaaxRegular: defineFont(declaration.MaaxRegular),
  MaaxMedium: defineFont(declaration.MaaxMedium),
  MaaxBold: defineFont(declaration.MaaxBold),
  MaaxBlack: defineFont(declaration.MaaxBlack),
}

export const fontsFaces = {
  '@font-face': Object.values(declaration).map(declareFont),
}
