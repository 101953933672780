import gql from 'graphql-tag'

import { VideoFragment } from '../Fragments/VideoFragment'


export const QuestionsByPeriode = gql`
    query questionsByPeriode($types: [String!]!, $periodes: [String]!, $languageId: LanguageId!) {
        nodeQuery(filter: {
            conditions: [
                { field: "type", value: $types, operator: IN, language: $languageId },
                { field: "field_periode", value: $periodes, operator: IN, language: $languageId }
            ] },
            sort: { field: "field_date", direction: DESC },
            limit: 1000,
            offset: 0
        ) {
            count
            entities {
                __typename
                ... on NodeQuestionMoment {
                    nid
                    fieldPeriode {
                        entity {
                            entityLabel
                        }
                    }
                    fieldDate {
                        value
                    }
                    fieldAuteurType
                    fieldAuthor {
                        processed
                    }
                    fieldQuestion
                    fieldAnswer {
                        processed
                    }
                    fieldPushHelp {
                        processed
                    }
                    fieldPushes {
                        entity {
                            ... on ParagraphVideoCustom {
                                __typename
                                fieldVideoItem {
                                    entity {
                                        __typename
                                        ... on NodeVideosPlaylist {
                                            title
                                            fieldVideos {
                                                entity {
                                                    __typename
                                                    ... on NodeVideo {
                                                        ...VideoFragment
                                                    }
                                                }
                                            }
                                        }
                                        ... on NodeVideo {
                                            ...VideoFragment
                                        }
                                    }
                                }
                            }
                            ... on ParagraphQuestionDuMomentVideo {
                                __typename
                                fieldImage {
                                    alt
                                    url
                                    width
                                    height
                                }
                                fieldUrlVideo
                            }
                            ... on ParagraphQuestionDuMomentEnPratique {
                                __typename
                                fieldBlocEnPratique {
                                    entity {
                                        ... on NodeOutil {
                                            title
                                            fieldIconeEspace {
                                                alt
                                                url
                                                width
                                                height
                                            }
                                        }
                                        entityUrl {
                                            path
                                        }
                                    }
                                }
                                fieldBlocPratiqueSousEspace
                            }
                            ... on ParagraphQuestionDuMomentIntArticle {
                                __typename
                                fieldArticle {
                                    entity {
                                        ... on NodeArticle {
                                            title
                                            body {
                                                processed
                                            }
                                            fieldImage {
                                                alt
                                                url
                                                width
                                                height
                                            }
                                            fieldImagePushArticles {
                                                url
                                            }
                                            fieldArticleSousRubrique {
                                                entity {
                                                    ... on TaxonomyTermRubrique {
                                                        fieldRubriqueClass
                                                    }
                                                }
                                            }
                                        }
                                        entityUrl {
                                            path
                                        }
                                    }
                                }
                            }
                            ... on ParagraphQuestionDuMomentExtArticle {
                                __typename
                                fieldTitle
                                fieldBody {
                                    processed
                                }
                                fieldImage {
                                    alt
                                    url
                                    width
                                    height
                                }
                                fieldLink {
                                    uri
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${VideoFragment.fragment}
`


export const NodesByTypes = gql`
    query NodesByTypes($types: [String!]!) {
        nodeQuery(filter: {
            conditions: [
                { field: "type", value: $types, operator: IN }
            ] }
        ) {
            entities {
                __typename
            }
        }
    }
    # Fragments
`


export const ArticlePrevNext = gql`
    query nodesByTypes($types: [String!]!, $languageId: LanguageId!, $rubrique:[String]!, $order: [String!]!, $nid: [String!]) {
        previous: nodeQuery(filter: {
            conditions: [
                { field: "type", value: $types, operator: IN, language: $languageId },
                { field: "field_article_sous_rubrique", operator: EQUAL, value: $rubrique, language: $languageId },
                { field: "nid", operator:NOT_EQUAL, value:$nid, language: $languageId },
                { field: "field_article_ordre", operator: SMALLER_THAN, value: $order, language: $languageId }
            ] },
            sort: { field: "field_article_ordre", direction: DESC },
            limit: 20,
            offset: 0
        ) {
            entities {
                __typename
                ... on NodeArticle{
                    title
                    entityUrl{
                        path
                    }
                }
            }
        }
        next: nodeQuery(filter: {
            conditions: [
                { field: "type", value: $types, operator: IN, language: $languageId },
                { field: "field_article_sous_rubrique", operator: EQUAL, value: $rubrique, language: $languageId },
                { field: "nid", operator:NOT_EQUAL, value:$nid, language: $languageId },
                { field: "field_article_ordre", operator: GREATER_THAN, value: $order, language: $languageId }
            ] },
            sort: { field: "field_article_ordre", direction: ASC },
            limit: 20,
            offset: 0
        ) {
            entities {
                __typename
                ... on NodeArticle{
                    title
                    entityUrl{
                        path
                    }
                }
            }
        }
        first: nodeQuery(filter: {
            conditions: [
                { field: "type", value: $types, operator: IN, language: $languageId },
                { field: "nid", operator:NOT_IN, value:$nid, language: $languageId },
                { field: "field_article_sous_rubrique", operator: EQUAL, value: $rubrique, language: $languageId }
            ] },
            sort: { field: "field_article_ordre", direction: DESC },
            limit: 20,
            offset: 0
        ) {
            entities {
                __typename
                ... on NodeArticle{
                    title
                    entityUrl{
                        path
                    }
                }
            }
        }
        last: nodeQuery(filter: {
            conditions: [
                { field: "type", value: $types, operator: IN, language: $languageId },
                { field: "nid", operator:NOT_IN, value:$nid, language: $languageId },
                { field: "field_article_sous_rubrique", operator: EQUAL, value: $rubrique, language: $languageId }
            ] },
            sort: { field: "field_article_ordre", direction: ASC },
            limit: 20,
            offset: 0
        ) {
            entities {
                __typename
                ... on NodeArticle{
                    title
                    entityUrl{
                        path
                    }
                }
            }
        }
    }
    # Fragments
`


export const ToolsSmall = gql`
    query nodesByTypes($types: [String!]!, $languageId: LanguageId!) {
        nodeQuery(filter: {
            conditions: [
                { field: "type", value: $types, operator: IN, language: $languageId },
            ] },
            sort: { field: "field_article_ordre", direction: ASC },
            limit: 1000,
            offset: 0
        ) {
            entities {
                __typename
                ... on NodeOutil {
                    title
                    entityUrl {
                        path
                    }
                    fieldIconeEspace {
                        url
                        alt
                    }
                    fieldImage {
                        url
                        alt
                    }
                }
            }
        }
    }
    # Fragments
`
