import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import ScrollContainer from 'react-indiana-drag-scroll'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import NavQuestionsLink from '../NavQuestionsLink'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavQuestions = (props) => {
  const {
    classes: classesProp,
    className,
    links,
    id,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const [scrolled, setScrolled] = useState(-1)
  const scrollerRef = useRef()

  useEffect(() => {
    if (scrollerRef.current !== undefined && scrolled > -1) {
      scrollerRef.current.container.current.scrollTo({
        left: scrolled,
        behavior: 'smooth',
      })
    }
  }, [scrolled, scrollerRef])

  return (
    <nav
      id={id}
      className={cx(classes.container, className)}
    >
      <ScrollContainer ref={scrollerRef}>
        <div className={classes.wrapper}>
          {links && (
            <ul className={classes.list}>
              {links.map((link, index) => (
                <NavQuestionsLink
                  key={`navquestions-${index}`}
                  {...link}
                  scrollCurrentHandler={setScrolled}
                />
              ))}
            </ul>
          )}
        </div>
      </ScrollContainer>
    </nav>
  )
}

NavQuestions.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      linkProps: PropTypes.shape(A.propTypes),
      text: PropTypes.string,
      isCurrent: PropTypes.bool,
      changeCurrentHandler: PropTypes.func,
    })
  ),
  id: PropTypes.string,
}

NavQuestions.defaultProps = {
  className: null,
  classes: null,
  links: null,
  id: null,
}

export default withMemo()(NavQuestions)
