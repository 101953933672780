import gql from 'graphql-tag'


export const LinkFragment = {
  fragment: gql`
      fragment LinkFragment on MenuLink {
          label
          summary
          color
          links {
              label
              url {
                  path
                  routed
              }
              links{
                  label
                  url {
                      path
                      routed
                  }
              }
          }
          url {
              path
              routed
          }
      }
    `,
}
