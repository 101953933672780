import { accessibleHiddenText, colors, desktop, mobile, smallest, tablet } from '../../theme'


export default {
    container: {
        position: 'relative',
        overflow: 'hidden',
    },
    // slider
    slider: {
        position: 'relative',
        left: '50%',
        width: 'calc(80vw * 3)',
        // height: '100%',
        transform: 'translateX(-50%)',
        visibility: 'hidden',
    },
    slide: {},
    sliderTitle: {
        flex: '0 0 auto',
        margin: 0,
        padding: '1.5rem 1rem 1rem',
        textAlign: 'center',
        fontSize: '1.7rem',
        fontWeight: 900,
        lineHeight: 1.15,
        textTransform: 'uppercase',
        background: colors.greyF6,
        ...smallest({
            display: 'none',
        }),
    },
    nav: {
        position: 'absolute',
        top: '62%',
        zIndex: 5,
        cursor: 'pointer',
        color: colors.pureBlack,
        padding: '0.5rem',
        '&.is-disabled': {
            opacity: '50%',
            pointerEvents: 'none',
        },
        '& i': {
            ...accessibleHiddenText,
        },
    },
    previous: {
        left: 'calc(50% - 40vw + 1rem)',
    },
    next: {
        right: 'calc(50% - 40vw + 1rem)',
    },
    navIcon: {
        width: '1rem',
        height: '1.8rem',
        stroke: colors.pureBlack,
        strokeWidth: '1px',
    },
    ...smallest({
        nav: {
            top: '60%',
        },
    }),
    ...mobile({
        container: {
            position: 'relative',
            maxWidth: '50.5rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '4rem',
            boxShadow: '0px 0px 50px 0px rgba(0, 0, 0, 0.2)',
        },
        slider: {
            left: 'auto',
            width: '100%',
            transform: 'translateX(0)',
        },
        sliderTitle: {
            display: 'block',
        },
        nav: {
            top: '67.4%',
        },
        previous: {
            left: '2.5rem',
        },
        next: {
            right: '2.5rem',
        },
    }),
    ...tablet({
        navIcon: {
            width: '1.2rem',
            height: '2.2rem',
        },
    }),
    ...desktop({
        container: {
            height: 'min-content',
            '&:first-of-type': {
                marginLeft: '30px',
                marginRight: '0',
            },
            '&:last-of-type': {
                marginRight: '30px',
                marginLeft: '0',
            },
        },
        nav: {
            top: '64.4%',
        },
    }),
    '@media print': {
        nav: {
            display: 'none',
        },
        slider: {
            width: '300%',
        },
        previous: {
            display: 'none',
        },
        next: {
            display: 'none',
        },
    },
}
