import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import debounce from 'lodash/debounce'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockQuestionsIntroduction = (props) => {
  const {
    classes: classesProp,
    className,
    text,
    textOther,
    // eslint-disable-next-line no-unused-vars
    isHidden,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const refContainer = useRef()
  const [maxHeight, setMaxHeight] = useState(0)

  const handleMaxHeight = useCallback(
    debounce(() => {
      if (refContainer.current && isHidden !== true) {
        setMaxHeight(refContainer.current.offsetHeight)
      } else {
        setMaxHeight(0)
      }
    }, 50),
    []
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleMaxHeight()
      window.addEventListener('resize', handleMaxHeight)
    }
    return () => {
      try {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleMaxHeight)
        }
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [handleMaxHeight])

  return (text || textOther) && (
    <div
      className={cx(classes.container, className)}
      style={{ maxHeight: `${maxHeight / 10}rem` }}
    >
      <div
        className={classes.wrapper}
        ref={refContainer}
      >
        {text && (
          <MarkdownStyled
            className={classes.text}
            text={text}
            lexicon={lexicon}
            markHover={markHover}
          />
        )}
        {textOther && (
          <MarkdownStyled
            className={classes.textOther}
            text={textOther}
            lexicon={lexicon}
            markHover={markHover}
          />
        )}
      </div>
    </div>
  )
}

BlockQuestionsIntroduction.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
  textOther: PropTypes.string,
  isHidden: PropTypes.bool,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

BlockQuestionsIntroduction.defaultProps = {
  className: null,
  classes: null,
  text: '',
  textOther: '',
  isHidden: false,
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(BlockQuestionsIntroduction)
