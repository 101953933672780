/*
 * Width values are in px, to use them on styles convert them in rem by / 10
 */

export default {
  mobile: {
  },
  tablet: {
  },
  desktop: {
    boxingVideo: 560,
    boxingQuestion: 840,
    boxingContent: 1170,
    boxingMedium: 1450,
    boxingMax: 1600,
  },
}
