import { colors, desktop, fonts } from '../../theme'


export default {
  container: {
    position: 'fixed',
    bottom: '0',
    zIndex: '45',
    width: '100%',
    margin: '1px 0 0',
    transform: 'translate3D(0, calc(100% - 4rem / 2), 0)',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      height: 'calc(4rem / 2)',
      background: colors.pureWhite,
      opacity: 0,
    },
    '&.is-inviewport': {
      zIndex: 'auto',
      position: 'relative',
      bottom: 'auto',
      transform: 'translate3D(0, 0%, 0)',
    },
    '&.isfixed': {
      transition: 'transform 200ms ease-in-out 0ms',
    },
    // open
    '&.is-open::after': {
      opacity: 1,
    },
    '&.is-open': {
      boxShadow: '0 -1rem 5rem rgba(0, 0, 0, 0.05)',
      zIndex: '55',
    },
    '&.is-open.is-fixed': {
      transform: 'translate3D(0, 0%, 0)',
      transition: 'transform 200ms ease-in-out 0ms',
    },
  },
  button: {
    ...fonts.MaaxBold,
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '4rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    textAlign: 'center',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    color: colors.pureWhite,
    backgroundColor: colors.blue,
    transform: 'translate(-50%, 50%)',
    cursor: 'pointer',
  },
  buttonIcon: {
    width: '1.4rem',
    height: '1.4rem',
    marginLeft: '1.5rem',
    opacity: 0.5,
    transform: 'rotate(45deg)',
    transition: 'transform 150ms ease-in-out',
    '$container.is-open &': {
      transform: 'rotate(0deg)',
    },
  },
  wrapper: {
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
    background: colors.pureWhite,
    opacity: 0,
    '$container.is-open &': {
      opacity: 1,
    },
    '$container.is-inviewport &': {},
    '$container.is-fixed &': {
      transition: 'max-height 200ms ease-in-out 0ms',
      maxHeight: '0 !important',
    },
    '$container.is-open.is-fixed &': {
      maxHeight: 'calc(var(--vh, 1vh) * 100 - 4rem) !important',
    },
  },
  content: {
    maxWidth: '70rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingTop: '4rem',
    paddingBottom: '4rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  navigation: {
    fontSize: '1.2rem',
    marginTop: '3rem',
    '&:last-child': {
      marginBottom: '0',
    },
    '& > div': {
      padding: 0,
    },
  },
  text: {
    fontSize: '1.4rem',
    lineHeight: 1.4,
  },
  ...desktop({
    wrapper: {

    },
    button: {
      fontSize: '1.4rem',
    },
    content: {
      paddingTop: '4rem',
    },
    navigation: {
      marginTop: '4rem',
    },
    text: {
      fontSize: '1.6rem',
    },
  }),
  '@media print': {
    container: {
      display: 'none',
    },
  },
}
