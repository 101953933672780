import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'search'

//
// Initial state
//
const initialState = {
  terms: null,
  tools: null,
  all: null,
  page: 1,
  perPage: 10,
}

//
// Actions
//
export const actions = {
  search: createAction('Tool: search', (payload) => (payload)),
  setTerms: createAction('Tool: setTerms', (terms) => ({ terms })),
  setTools: createAction('Tool: setTools', (tools) => ({ tools })),
  setAll: createAction('Tool: setAll', (all) => ({ all })),
  setPage: createAction('Tool: setPage', (page) => ({ page })),
  setPerPage: createAction('Tool: setPerPage', (perPage) => ({ perPage })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setTerms]: (state, { terms }) => Immutable.static.merge(state, { terms }),
    [actions.setTools]: (state, { tools }) => Immutable.static.merge(state, { tools }),
    [actions.setAll]: (state, { all }) => Immutable.static.merge(state, { all }),
    [actions.setPage]: (state, { page }) => Immutable.static.merge(state, { page }),
    [actions.setPerPage]: (state, { perPage }) => Immutable.static.merge(state, { perPage }),
  },
  initialState
)

//
// Selectors
//

const root = (state) => state[key]
const tools = (state) => root(state).tools
const terms = (state) => root(state).terms
const all = (state) => root(state).all
const page = (state) => root(state).page
const perPage = (state) => root(state).perPage

export const selectors = {
  tools,
  terms,
  all,
  page,
  perPage,
}
