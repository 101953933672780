import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import HeaderLanding from '../../../components/HeaderLanding'
import withMemo from '../../../decorators/withMemo'
import { BlocksParser } from '../../../helpers/BlocksHelpers'
import { selectors as AppSelectors } from '../../../redux/app/redux'
import { selectors as I18nSelectors } from '../../../redux/i18n/redux'
import { useLexiconHandler } from '../../../utils/hooks/useLexicon'
import { actions as PageActions, selectors as PageSelectors } from '../../Pages/Page/redux'

import { pageLandingTransformer } from './transformer'


function PageLandingWrapper(props) {
  const { t } = useTranslation()
  const slug = useSelector(PageSelectors.slug)
  const route = useSelector(AppSelectors.route)
  const locale = useSelector(I18nSelectors.locale)
  const dispatch = useDispatch()
  const [lexicals, handleLexicon] = useLexiconHandler()
  const breadcrumb = useSelector(PageSelectors.breadcrumb)

  useEffect(() => {
    if (breadcrumb.length === 1) {
      const pageLandingBreadcrumb = breadcrumb.concat({
        linkProps: {
          href: `/${locale}/${slug}`,
        },
        slug,
        text: t('siteTitle'),
      })

      dispatch(PageActions.setBreadcrumb(pageLandingBreadcrumb))
    }
  }, [breadcrumb, dispatch, locale, slug, t])

  const {
    headerProps,
    pageBlocks,
  } = useMemo(() => pageLandingTransformer(props, route, t), [props, route, t])

  return (
    <>
      <HeaderLanding
        {...headerProps}
        lexicon={lexicals}
        markHover={handleLexicon}
      />
      <BlocksParser blocks={pageBlocks} />
    </>
  )
}

export default withMemo()(PageLandingWrapper)
