import gql from 'graphql-tag'


export const TaxonomyTermsByVocabulary = gql`
    query taxonomyTermsByVocabulary($vocabulary: String!) {
        taxonomyTermQuery(filter: { conditions: [{ field: "vid", value: [$vocabulary], operator: IN }] }, limit: 10000) {
            entities {
                __typename
                ... on TaxonomyTermGlossaire {
                    name
                    fieldGlossaireVariations
                    description {
                        value
                    }
                }
            }
        }
    }
`
