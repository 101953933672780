import { colors, tablet } from '../../theme'


export default {
  container: {
    '&:not(:first-child)': {
      marginTop: '6rem',
    },
  },
  letter: {
    margin: 0,
    lineHeight: 1.5,
    fontSize: '4rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: colors.blue,
    paddingBottom: '2rem',
  },
  terms: {
    margin: 0,
    '& > *:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  list: {
    margin: 0,
  },
  ...tablet({
    letter: {
      fontSize: '6rem',
    },
    terms: {
      display: 'grid',
      gridTemplateAreas: '"left right"',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '10%',
      '& > *': {
        gridArea: 'left',
      },
      '& > * + *': {
        gridArea: 'right',
      },
      '& > *:not(:first-child)': {
        marginTop: '0',
      },
    },
  }),
}
