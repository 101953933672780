import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'

import styles from './styles'


const useStyles = createUseStyles(styles)

const MagicTitle = (props) => {
  const {
    classes: classesProp,
    className,
    text,
    TagTitle,
    // eslint-disable-next-line no-unused-vars
    sizeDecoration,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const strippedTagsTitle = useMemo(() => text.replace(/(<([^>]+)>)/gi, ''), [text])
  const renderTitleWords = useMemo(() => (strippedTagsTitle.split(' ').map((word, index) => (
    <React.Fragment key={`magictitle-word-${index}`}>
      <span>
        {word}
      </span>
      {' '}
    </React.Fragment>
  ))), [strippedTagsTitle])

  return (
    <TagTitle className={cx(classes.container, className)}>
      {renderTitleWords}
    </TagTitle>
  )
}

MagicTitle.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string.isRequired,
  TagTitle: PropTypes.string,
  sizeDecoration: PropTypes.number,
}

MagicTitle.defaultProps = {
  className: null,
  classes: null,
  TagTitle: 'h2',
  sizeDecoration: 5,
}

export default withMemo()(MagicTitle)
