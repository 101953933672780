import '../utils/polyfills'
import React, { useEffect, useMemo } from 'react'
import withRedux from 'next-redux-wrapper'
import { JssProvider, ThemeProvider } from 'react-jss'
import { Provider } from 'react-redux'
import Head from 'next/head'
import Router from 'next/router'

import AppGate from '../containers/AppGate'
import I18nProvider from '../i18n/Provider'
import createStore from '../redux'
import AppSagas from '../redux/app/sagas'
import getPageContext from '../theme/context'
import bugsnagClient from '../helpers/BugsnagHelper'
import { GTMPageView } from '../utils/gtm'


const MyApp = (props) => {
  // eslint-disable-next-line react/prop-types
  const { store, ...pageProps } = props

  const pageContext = useMemo(getPageContext, [])

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')

    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = (url) => {
      GTMPageView(url)
      // eslint-disable-next-line no-undef
      tC.container.reload({ events: { pageview: [{}, {}] } })
    }

    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>
      <JssProvider
        registry={pageContext.sheetsRegistry}
        generateClassName={pageContext.generateClassName}
        jss={pageContext.jss}
        generateId={pageContext.generateId}
      >
        <ThemeProvider theme={pageContext.theme}>
          <Provider store={store}>
            <I18nProvider store={store}>
              <AppGate
                pageContext={pageContext}
                {...pageProps}
              />
            </I18nProvider>
          </Provider>
        </ThemeProvider>
      </JssProvider>
    </>
  )
}

MyApp.getInitialProps = async({ Component, ctx }) => {
  try {
    const props = await ctx.store.saga
      .run(AppSagas.init, ctx, Component?.getInitialProps)
      .toPromise()

    return props
  } catch (e) {
    if (bugsnagClient) {
      bugsnagClient.notify(e)
    }
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

export default withRedux(createStore)(MyApp)
