import gql from 'graphql-tag'


export const ParagraphFaqBlocFragment = {
  fragment: gql`
        fragment ParagraphFaqBlocFragment on ParagraphFaqBloc {
            uuid
            fieldTitreAncre
            fieldImageAncre{
                alt
                title
                url
            }
            fieldFaqBlocRubriqueColor{
                entity{
                    ... on TaxonomyTermRubrique{
                        fieldRubriqueClass
                    }
                }
            }
            fieldFaqBlocQuestionReponse{
                entity{
                    ... on NodeQuestionReponse{
                        fieldQuestion
                        body{
                            value
                        }
                    }
                }
            }
        }
    `,
}
