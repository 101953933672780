/* eslint-disable max-lines */
import { all, call, put, select } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import ApiSagas from '../../../redux/api/sagas'
import Service from '../../../graphql/query/View'
import { actions } from '../../../redux/lexicon/redux'
import { selectors as AppSelectors } from '../../../redux/app/redux'

import { termsTransformer } from './transformers'


export default class LexiqueSagas {

  static* init() {
    yield all([
      put(showLoading()),
    ])
    const route = yield select(AppSelectors.route)

    const view = yield call(ApiSagas.persistQuery, Service.lexiqueView, {}, termsTransformer)

    yield all([
      put(actions.setTerm(route?.query?.term ?? null)),
      put(actions.setView(view)),
      put(hideLoading()),
    ])
  }

  static* loop() {
  }

}
