export const trimOnSpace = (string, length, suffix = '…') => {
  const trimmedString = string.substr(0, length)

  return `${trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  )}${suffix}`
}

export const removeLineBreaks = (string = '') => {
  // replace line break with space
  const newString = string.replace(/(\r\n|\n|\r)/gm, ' ')

  // replace all double white spaces with single spaces
  newString.replace(/\s+/g, ' ')

  return newString
}

export const stripHtml = (string = '') => string.replace(/(<([^>]+)>)/gi, '')

export const interpretHtml = (string = '') => {
  if (typeof window !== 'undefined') {
    const div = document.createElement('div')

    div.innerHTML = string
    return div.textContent.replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ')
  }
  return string
}
