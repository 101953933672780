import { tablet } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    color: 'inherit',
    '&.is-desktop-only': {
      display: 'none',
    },
    '& > *:not(:first-child)': {
      marginLeft: '1rem',
    },
  },
  ...tablet({
    container: {
      '&.is-mobile-only': {
        display: 'none',
      },
      '&.is-desktop-only': {
        display: 'flex',
      },
      '& > *:not(:first-child)': {
        marginLeft: '1.5rem',
      },
    },
  }),
  '@media print': {
    container: {
      display: 'none',
    },
  },
}
