import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import ShareLink from '../ShareLink'

import styles from './styles'


const useStyles = createUseStyles(styles)

const ShareLinks = (props) => {
  const {
    classes: classesProp,
    className,
    links,
    isMobileOnly,
    isDesktopOnly,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <div
      className={cx(classes.container,
        className,
        isMobileOnly && 'is-mobile-only',
        isDesktopOnly && 'is-desktop-only')}
    >
      {links.map((link, index) => (
        <ShareLink
          {...link}
          key={`sharelinks-link-${index}`}
        />
      ))}
    </div>
  )
}

ShareLinks.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.shape(ShareLink.propTypes)),
  isMobileOnly: PropTypes.bool,
  isDesktopOnly: PropTypes.bool,
}

ShareLinks.defaultProps = {
  className: null,
  classes: null,
  links: null,
  isMobileOnly: false,
  isDesktopOnly: false,
}

export default withMemo()(ShareLinks)
