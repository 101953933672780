import gql from 'graphql-tag'

import { ParagraphArticleEncartContenuFragment } from './ParagraphArticleEncartContenuFragment'
import { ParagraphArticleBlocFocusBienEtreFragment } from './ParagraphArticleBlocFocusBienEtreFragment'
import { ParagraphVideoSpfFragment } from './ParagraphVideoSpfFragment'
import { VideoFragment } from './VideoFragment'


export const ArticleFragment = {
  fragment: gql`
      fragment ArticleFragment on NodeArticle {
          nid
          title
          entityCreated(format:"Y-m-d")
          fieldArticleOrdre
          fieldImageReseauxSociaux {
              url
          }
          fieldArticleDateModification {
              date
          }
          fieldArticleTempsLecture
          fieldArticleSousRubrique {
              targetId
          }
          fieldImage {
              alt
              fieldImageArticle: derivative(style:IMAGEARTICLE) {
                  url
              }
          }
          body {
              value
          }
          fieldArticleBlocsSuggestions {
              entity {
                  ... on NodeArticle {
                      title
                      entityUrl {
                          path
                      }
                      fieldImage {
                          alt
                          fieldImageArticle: derivative(style:IMAGESUBRUBHOME) {
                              url
                          }
                      }
                      body{
                          value
                      }
                      fieldArticleSousRubrique {
                          entity{
                              ... on TaxonomyTermRubrique {
                                  fieldRubriqueClass
                              }
                          }
                      }
                  }
              }
          }
          fieldBlocsArticle {
              entity {
                  ... on ParagraphArticleEncartContenu {
                      ...ParagraphArticleEncartContenuFragment
                  }
                  ... on ParagraphArticleBlocFocusBienEtre {
                      ...ParagraphArticleBlocFocusBienEtreFragment
                  }
                  ... on ParagraphVideoSpf {
                      ...ParagraphVideoSpfFragment
                  }
                  ... on ParagraphVideoCustom {
                      __typename
                      fieldVideoItem {
                          entity {
                              __typename
                              ... on NodeVideosPlaylist {
                                  title
                                  fieldVideos {
                                      entity {
                                          __typename
                                          ... on NodeVideo {
                                              ...VideoFragment
                                          }
                                      }
                                  }
                              }
                              ... on NodeVideo {
                                  ...VideoFragment
                              }
                          }
                      }
                  }
              }
          }
      }
      ${ParagraphArticleEncartContenuFragment.fragment}
      ${ParagraphArticleBlocFocusBienEtreFragment.fragment}
      ${ParagraphVideoSpfFragment.fragment}
      ${VideoFragment.fragment}
  `,
}
