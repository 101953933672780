import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import * as components from '../Wrappers/Paragraphs'
import * as transformers from '../transformers'


export const BlocksParser = ({
  blocks,
  options,
  className,
  firstBlockClassname,
  withTransitionIn,
}) => {
  if (!blocks) {
    return null
  }

  return blocks.map((blockItem, key) => {
    /* Here each block is already transformed by the saga */
    const block = { ...blockItem.entity }
    const Block = components[block.__typename]

    if (!Block) {
      // eslint-disable-next-line no-console
      console.warn(block.__typename)
      return null
    }

    return (
      <Block
        blockIndex={key}
        {...options}
        {...block}
        withTransitionIn={withTransitionIn}
        key={key}
        className={cx(className, key === 0 && firstBlockClassname)}
      />
    )
  })
}
BlocksParser.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
  withTransitionIn: PropTypes.bool,
}
BlocksParser.defaultProps = {
  blocks: null,
  options: {},
  withTransitionIn: false,
}

export const types = {
  ARTICLE: 'ARTICLE',
}

export const blocksTransformer = (blocks, utils) => blocks
  .filter((props) => transformers[props.__typename])
  .map((props) => transformers[props.__typename](props, utils))

export const getFirstParagraphInBlocks = (blocks) => blocks.reduce((res, element) => {
  let r = res

  if (r !== '') {
    return r
  }
  if (element.paragraph && element.paragraph !== '') {
    r = element.paragraph
  } else if (element.contents && element.contents.length >= 1) {
    const paragraphs = element.contents.filter((contentEl) => contentEl.paragraph)

    if (paragraphs.length >= 1) {
      r = paragraphs[0].paragraph
    }
  }
  return r
}, '')

export const addPageTitleInFirstParagraphInBlocks = (blocks, pageTitle) => {
  if (!blocks) {
    return null
  }

  // Check if already h1 in structure blocks
  const blocksWithH1InsideYet = ['ManagementCompanyListBlock']
  const found = blocks.some((r) => blocksWithH1InsideYet.indexOf(r.__typename) >= 0)

  if (found) {
    return blocks
  }

  const textToReplace = getFirstParagraphInBlocks(blocks)
  const newBlocks = []

  const regexHeadings = new RegExp('(#+)(.*)', 'g')

  let matches

  // eslint-disable-next-line array-callback-return
  blocks.map((element) => {
    if (element.contents && element.contents.length >= 1) {
      const contents = []

      // eslint-disable-next-line array-callback-return
      element.contents.map((contentEl) => {
        if (
          contentEl.paragraph
          && contentEl.paragraph.length >= 1
          && contentEl.paragraph === textToReplace
        ) {
          let count = 0

          // eslint-disable-next-line no-cond-assign
          while ((matches = regexHeadings.exec(contentEl.paragraph)) !== null) {
            if (matches[1] && matches[1] === '#') {
              count++
            }
          }
          if (count === 0) {
            contents.push({
              ...contentEl,
              paragraph: `# ${pageTitle} \n ${contentEl.paragraph}`,
            })
          } else {
            contents.push(contentEl)
          }
        } else {
          contents.push(contentEl)
        }
      })
      newBlocks.push({
        ...element,
        contents,
      })
    } else if (
      element.paragraph
      && element.paragraph !== ''
      && element.paragraph === textToReplace
    ) {
      let count = 0

      // eslint-disable-next-line no-cond-assign
      while ((matches = regexHeadings.exec(element.paragraph)) !== null) {
        if (matches[1] && matches[1] === '#') {
          count++
        }
      }
      if (count === 0) {
        newBlocks.push({
          ...element,
          paragraph: `# ${pageTitle} \n ${element.paragraph}`,
        })
      } else {
        newBlocks.push(element)
      }
    } else {
      newBlocks.push(element)
    }
  })

  return newBlocks
}

export const hasBlock = (blocks, blockName) => {
  const result = blocks.find((b) => b.__typename === blockName)

  return !!result
}

export const checkPaginator = (data, page) => {
  if (page > 1 && data && data.blocks) {
    const keywords = ['articles', 'managementCompanies', 'scpisByFilters']
    const block = data.blocks.find((b) => keywords.find((key) => b[key] && b[key].paginatorInfo))

    if (block) {
      const paginatorInfo = block[keywords.find((key) => block[key] && block[key].paginatorInfo)].paginatorInfo

      if (paginatorInfo && parseInt(paginatorInfo.lastPage, 10) < parseInt(page, 10)) {
        return true
      }
    }
  }
  return false
}
