import gql from 'graphql-tag'


export const OutilFragment = {
  fragment: gql`
    fragment OutilFragment on NodeOutil {
        title
        body {
            value
        }
        fieldImageReseauxSociaux {
            url
        }
        fieldImage {
            url
            alt
        }
        fieldOutilZones {
            entity {
                ... on ParagraphZoneOutil {
                    fieldZoneOutilX
                    fieldZoneOutilY
                    fieldZoneTitle
                    fieldImageAncre {
                        url
                        alt
                    }
                    fieldZoneOutilProduits {
                        entity {
                            ... on ParagraphProduit {
                                id
                                fieldProduitTitre
                                fieldProduitImage {
                                    url
                                    alt
                                }
                                fieldProduitOnglets {
                                    entity {
                                        ... on ParagraphOnglets {
                                            fieldOngletsLegendeIcone
                                            fieldIcones {
                                                entity {
                                                    ... on TaxonomyTermIconesProduitOnglet {
                                                        fieldImage {
                                                            url
                                                            alt
                                                        }
                                                        fieldImageActive {
                                                            url
                                                            alt
                                                        }
                                                        fieldImageLegend {
                                                            url
                                                            alt
                                                        }
                                                    }
                                                }
                                            }
                                            fieldOngletsContenu {
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  `,
}
