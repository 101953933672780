import { grids } from '../../theme'


export default {
  container: {
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '55vh',
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',

  },
  text: {
    textAlign: 'center',
    fontSize: '2.2rem',
    letterSpacing: '2px',
  },
}
