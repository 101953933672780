import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import ReactTooltip from 'react-tooltip'
// import { useRouter } from 'next/router'

import { mergeStyles } from '../../utils/StylesUtils'
import withMemo from '../../decorators/withMemo'
import A from '../A'
import Icon from '../Icon'
import icons from '../Icon/assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const LexiconPop = (props) => {
  const {
    classes: classesProp,
    className,
    text,
    linkProps,
    page,
  } = props
  // commented because might come back (if word is in blue -> lexiconPop is in blue also)
  // const [isBlue, setIsBlue] = useState(false)
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const [visible, setVisible] = useState(true)
  const [toLexique, setToLexique] = useState(false)

  // commented because might come back (if word is in blue -> lexiconPop is in blue also)
  // const handleColor = useCallback((event) => {
  //   if (typeof window !== 'undefined') {
  //     const color = Color(window.getComputedStyle(event.target).getPropertyValue('color')).hex()

  //     // console.log(color, Color(colors.blue).hex())

  //     if (color === Color(colors.blue).hex()) {
  //       setIsBlue(true)
  //     } else {
  //       setIsBlue(false)
  //     }
  //   }
  // }, [])

  const checkToLexique = useCallback((node) => {
    const element = node.querySelector(`.${classes.text}`)

    if (element.offsetHeight < element.scrollHeight) {
      setToLexique(true)
    } else {
      setToLexique(false)
    }
  }, [classes.text])

  const handlePosition = useCallback((position, event, target, node) => {
    // console.log('test', position, event, target, node)

    // commented because might come back (if word is in blue -> lexiconPop is in blue also)
    // handleColor(event)

    const d = document.documentElement


    // new lateral position
    const minLeft = Math.min(d.clientWidth - node.clientWidth, position.left)

    let maxLeft = Math.max(0, minLeft)

    // new vertical position
    const minTop = Math.min(d.clientHeight - node.clientHeight, position.top)

    let maxTop = Math.max(0, minTop) <= 0 ? target.getBoundingClientRect().top + 40 : minTop

      // si c'est du coté gauche
      if (target.getBoundingClientRect().left < (d.clientWidth / 2)) {
        if (target.getBoundingClientRect().height > 24 && d.clientWidth >= 500) {
          maxLeft = target.getBoundingClientRect().right - node.clientWidth
          maxTop = target.getBoundingClientRect().top - (node.clientHeight - 8)
        } else if ((target.getBoundingClientRect().left - (node.clientWidth / 2)) > 0) { // si y'a la place pour centrer
            maxLeft = target.getBoundingClientRect().left - ((node.clientWidth - target.getBoundingClientRect().width) / 2)
            maxTop = target.getBoundingClientRect().top - (node.clientHeight - 8)
          } else if ((target.getBoundingClientRect().left + node.clientWidth) < d.clientWidth) { // si y'a la place
          maxLeft = target.getBoundingClientRect().left
          maxTop = target.getBoundingClientRect().top - node.clientHeight - 2
        }
      }

    checkToLexique(node)
    return { top: maxTop, left: maxLeft }
  }, [checkToLexique])

  const handleRefresh = useCallback(() => {
    setVisible(true)
    if (ReactTooltip) {
      ReactTooltip.hide()
      ReactTooltip.rebuild()
    }
  }, [])

  const handleClose = useCallback(() => {
    setVisible(false)
  }, [])

  useEffect(() => {
    handleRefresh()
    // redirectToLexique()
  }, [page, text, handleRefresh, checkToLexique])

  return (
    <div className={cx(classes.container, className, visible !== true && 'is-hidden')}>
      {typeof window !== 'undefined' && (
        <ReactTooltip
          className={classes.tooltip}
          place="top"
          delayHide={50}
          delayShow={50}
          delayUpdate={50}
          effect="solid"
          event="click"
          globalEventOff="click"
          overridePosition={({ left, top }, currentEvent, currentTarget, node) => handlePosition({ left, top }, currentEvent, currentTarget, node)}
          getContent={() => (
            <A
              {...linkProps}
              className={`${classes.link} ${!toLexique && 'noRedirect'}`}
              disabled={!toLexique}
              onClick={handleClose}
              onMouseLeave={handleClose}
            >
              {text && (
              <div
                className={classes.text}
                dangerouslySetInnerHTML={{ __html: text }}
              />
              )}
              {(linkProps && toLexique) && (
                <Icon
                  icon={icons.ArrowRight}
                  className={classes.icon}
                />
              )}
            </A>
          )}
        />
      )}
    </div>
  )
}

LexiconPop.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
  linkProps: PropTypes.shape(A.propTypes),
  page: PropTypes.string,
}

LexiconPop.defaultProps = {
  className: null,
  classes: {},
  text: '',
  linkProps: null,
  page: '',
}

export default withMemo()(LexiconPop)
