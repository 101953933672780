import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import { safeCall } from '../../helpers/React'

import styles from './styles'


const useStyles = createUseStyles(styles)

const SearchResults = (props) => {
  const {
    classes: classesProp,
    className,
    zoneInPractice,
    zoneResults,
    moreResultsHandler,
    hasMoreResults,
    textMoreResults,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])


  const handleMoreResults = useCallback(() => {
    safeCall(moreResultsHandler)
  }, [moreResultsHandler])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {zoneInPractice && (
          <div className={classes.zoneInPractice}>{zoneInPractice}</div>
        )}
        {zoneResults && (
          <>
            <div className={classes.zoneResults}>{zoneResults}</div>
            {hasMoreResults && (
              <div className={classes.buttons}>
                <button
                  type="button"
                  onClick={handleMoreResults}
                  className={classes.button}
                >
                  {textMoreResults}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

SearchResults.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  zoneInPractice: PropTypes.node,
  zoneResults: PropTypes.node,
  moreResultsHandler: PropTypes.func,
  hasMoreResults: PropTypes.bool,
  textMoreResults: PropTypes.string,
}

SearchResults.defaultProps = {
  className: null,
  classes: null,
  zoneInPractice: null,
  zoneResults: null,
  moreResultsHandler: () => null,
  hasMoreResults: true,
  textMoreResults: '',
}

export default withMemo()(SearchResults)
