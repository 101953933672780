import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'home'

//
// Initial state
//
const initialState = {
  rubriques: null,
  questions: null,
}

//
// Actions
//
export const actions = {
  setRubriques: createAction('Home: setRubriques', (rubriques) => ({ rubriques })),
  setQuestions: createAction('Home: setQuestions', (questions) => ({ questions })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setRubriques]: (state, { rubriques }) => Immutable.static.merge(state, { rubriques }),
    [actions.setQuestions]: (state, { questions }) => Immutable.static.merge(state, { questions }),
  },
  initialState
)

//
// Selectors
//

const root = (state) => state[key]
const rubriques = (state) => root(state).rubriques
const questions = (state) => root(state).questions

export const selectors = {
  rubriques,
  questions,
}
