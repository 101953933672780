import gql from 'graphql-tag'


export const PageLandingFragment = {
  fragment: gql`
      fragment PageLandingFragment on NodePageLanding {
          nid
          entityCreated(format: "Y-m-d")
          fieldArticleDateModification {
              date
          }
          title
          fieldImage {
              entity {
                  ... on File {
                      url
                  }
              }
          }
          fieldImageMobile {
              entity {
                  ... on File {
                      url
                  }
              }
          }
          fieldContent {
              processed
          }
          fieldLandingPageBlocks {
              entity {
                  __typename
                  ... on ParagraphPageLandingRichText {
                      fieldTitleLong
                      fieldBody {
                          processed
                      }
                  }
                  ... on ParagraphPageLandingCtas {
                      fieldTitleLong
                      fieldImage {
                          entity {
                              ... on File {
                                  url
                              }
                          }
                      }
                      fieldBody {
                          processed
                      }
                      fieldLinks {
                          title
                          url {
                              path
                          }
                      }
                  }
                  ... on ParagraphPageLandingSliders {
                      fieldSliders {
                          entity {
                              __typename
                              ... on ParagraphPageLandingSlider {
                                  fieldSliderItems {
                                      entity {
                                          __typename
                                          ... on ParagraphPageLandingSliderItem {
                                              fieldTitle
                                              fieldSubtitle
                                              fieldImage {
                                                  entity {
                                                      ... on File {
                                                          url
                                                      }
                                                  }
                                              }
                                              fieldBody {
                                                  processed
                                              }
                                              fieldLink {
                                                  title
                                                  url {
                                                      path
                                                  }
                                              }
                                              fieldFile {
                                                  entity {
                                                      ... on File {
                                                          url
                                                      }
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `,
}
