import { isDefined, isEmptyString, strContains } from '../../../helpers/TypeHelpers'

import { CategoryTypenamesEnum, linkTypeEnum, tcEventEnum } from './consts'
import { getRubriqueQuestionDuMomentPeriodNameWithRoute } from './matchers'
import { updateTcVarsInWindow } from './useTagCommander'

/** **
 * Maintains list of actions that should not get executed over a loop.
 * A Loop is the action of looping through |eventHandlers| array.
 */
const preventEventHandler = {
    listPreventTcEvent: [],
    addToListTcEventToPrevent(tcEventNameList = []) {
        this.listPreventTcEvent = [...new Set(this.listPreventTcEvent.concat(tcEventNameList))]
    },
    removeFromListTcEventToPrevent(tcEventNameList = []) {
        this.listPreventTcEvent = this.listPreventTcEvent.filter((tcEventName) => !tcEventNameList.includes(tcEventName))
    },
    clearListPreventTcEvent() {
        this.listPreventTcEvent = []
    },
    isPreventedEvent(eventName) {
        return this.listPreventTcEvent.includes(eventName)
    },
}

export const eventHandlers = [
    /*
    *   CTA click events
     */
    {
        testFn: function isCtaClickEvent(eventName, eventData) {
            const { data } = eventData
            const isCta = isDefined(data['data-cta-name'])
            return eventName === tcEventEnum.LINK_CLICK && isCta
        },
        action: function handleCtaClickEvent(eventName, eventData) {
            try {
                const { data } = eventData
                const ctaName = data?.['data-cta-name'] ?? null
                if (isDefined(ctaName) && !isEmptyString(ctaName)) {
                    const payload = { ctaName }
                    console.info('# TagCommander: [event] |clickOnCta|', payload)
                    preventEventHandler.addToListTcEventToPrevent(['clickOnLink'])
                    // eslint-disable-next-line
                    tC.event.clickOnCta(<div></div>, payload)
                }
            } catch (e) {
                console.warn('Error while trying to use tC.event.clickOnCta', e)
            }
        },
    },
    /*
    * Share article click event
     */
    {
        testFn: function isShareArticleEvent(eventName, eventData) {
            const { pageData, data } = eventData
            const isArticlePage = CategoryTypenamesEnum.ARTICLE === pageData?.__typename
            if (!isArticlePage) {
                return false
            }
            const isClickLinkEvent = eventName === tcEventEnum.LINK_CLICK
            const isShareLink = !isEmptyString(data['data-label'] ?? '')
            return isClickLinkEvent && isShareLink
        },
        action: function handleTcShareArticleEvent(eventName, eventData) {
            const { data } = eventData
            const payload = { shareMedium: data?.['data-label'] ?? '' }
            try {
                console.info('# TagCommander: [event] |articleShare|', payload)
                preventEventHandler.addToListTcEventToPrevent(['clickOnLink'])
                // eslint-disable-next-line
                tC.event.articleShare(<div></div>, payload);
            } catch (e) {
                console.warn('tC seems not defined', e)
            }
        },
    },
    /*
    * Font resize click event
     */
    {
        testFn: function isFontResizerEvent(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_FONT_RESIZE
        },
        action: function handleFontResizeEvent(eventName, eventData) {
            const { data } = eventData
            const zoomLevel = {
                'Taille de police plus petite': 'small',
                'Taille de police moyenne': 'medium',
                'Taille de police plus grande': 'large',
            }[data?.textResize]

            if (!isDefined(zoomLevel)) {
                return
            }
            try {
                const payload = { zoomLevel }
                console.info('# TagCommander: [event] |clickZoom|', payload)
                // eslint-disable-next-line
                tC.event.clickZoom(<div></div>, payload);
            } catch (e) {
                console.warn('tC seems not defined', e)
            }
        },
    },
    /*
    * Question du moment
     */
    {
        testFn: function isClickRubriqueQuestionDuMoment(eventName, eventData) {
            const { data } = eventData
            return strContains(data?.route, '/questions_du_moment/')
        },
        action: function handleClickRubriqueQuestionDuMoment(eventName, eventData) {
            const { data } = eventData
            const dataRoute = { query: { slug: data.href } }
            const arrayPeriodNameAndDetails = getRubriqueQuestionDuMomentPeriodNameWithRoute(dataRoute)
            try {
                const [periodName] = arrayPeriodNameAndDetails
                const payload = { periodName }
                if (eventName === tcEventEnum.LINK_CLICK) {
                    preventEventHandler.addToListTcEventToPrevent(['clickOnLink'])
                }
                console.info('# TagCommander: [event] |openPeriod|', payload)
                // eslint-disable-next-line
                tC.event.openPeriod(<div></div>, payload);
            } catch (e) {
                console.warn('tC seems not defined', e)
            }
        },
    },
    /*
    *   Click on Article Card Question Du Moment
     */
    {
        testFn: function isClickCardQuestionDuMoment(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_CLICK_CARD_QUESTION_DU_MOMENT
        },
        action: function handleClickCardQuestionDuMoment(eventName, eventData) {
            try {
                const { data } = eventData
                const payload = {
                    cardName: data?.title,
                    cardRedirection: data.href,
                }
                console.info('# TagCommander: [event] |clickCard|', payload)
                // eslint-disable-next-line
                tC.event.clickCard(<div></div>, payload)
            } catch (e) {
                console.warn('While processing cardQuestionDuMoment click event for tag commander:: tC seems not defined', e)
            }
        },
    },
    /*
    * Use of search input
     */
    {
        testFn: function isSearchInputAction(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_SEARCH
        },
        action: function handleSearchInputAction(eventName, eventData) {
            try {
                const { data } = eventData
                const payload = { searchTerm: data.searchTerm }
                console.info('# TagCommander: [event] |search|', payload)
                /* eslint-disable */
                updateTcVarsInWindow(payload);
                tC.event.search(<div/>, payload)
                /* eslint-enabled */
            } catch (e) {
                console.warn('While processing search input term for tag commander', e)
            }
        },
    },
    /*
    * Video play
     */
    {
        testFn: function isVideoPlayAction(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_VIDEO_PLAY
        },
        action: function handleVideoPlayAction(eventName, eventData) {
            try {
                const {data} = eventData
                const payload = {
                    videoName: data.videoName,
                }
                console.info('# TagCommander: [event] |videoPlay|', payload)
                // eslint-disable-next-line
                tC.event.videoPlay(<div></div>, payload) // function |video[pP]lay| naming ?
            } catch (e) {
                console.warn('While processing video play for tag commander', e)
            }
        },
    },
    /*
    * Video pause
     */
    {
        testFn: function isVideoPauseAction(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_VIDEO_PAUSE
        },
        action: function handleVideoPauseAction(eventName, eventData) {
            try {
                const {data} = eventData
                const payload = {
                    videoName: data.videoName,
                }
                console.info('# TagCommander: [event] |videoPayse|', payload)
                // eslint-disable-next-line
                tC.event.videoPause(<div></div>, payload)
            } catch (e) {
                console.warn('While processing video pause for tag commander', e)
            }
        },
    },
    /*
    * Video speed change
     */
    {
        testFn: function isVideoSpeedChangeAction(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_VIDEO_SPEED_CHANGE
        },
        action: function handleVideoSpeedChangeAction(eventName, eventData) {
            try {
                const {data} = eventData
                const payload = {
                    videoSpeedLevel: data?.videoSpeedLevel,
                    videoName: data?.videoName,
                }
                console.info('# TagCommander: [event] |videoSpeed|', payload)
                // eslint-disable-next-line
                tC.event.videoSpeed(<div></div>, payload)
            } catch (e) {
                console.warn('While processing video speed change for tag commander', e)
            }
        },
    },
    /*
    * Video share
     */
    {
        testFn: function isVideoShareAction(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_VIDEO_SHARE
        },
        action: function handleVideoShareAction(eventName, eventData) {
            try {
                const {data} = eventData
                const payload = {
                    shareMedium: data.shareMedium,
                    videoName: data.videoName,
                }
                console.info('# TagCommander: [event] |videoShare|', payload)
                // eslint-disable-next-line
                tC.event.videoShare(<div></div>, payload)
            } catch (e) {
                console.warn('While processing video share for tag commander', e)
            }
        },
    },
    /*
    * En pratique: location clicked action
     */
    {
        testFn: function isClickLocationRubriqueEnPratique(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_CLICK_LOCATION_RUBRIQUE_EN_PRATIQUE
        },
        action: function handleClickLocationRubriqueEnPratique(eventName, eventData) {
            try {
                const {data} = eventData
                const payload = {
                    placeName: data.placeName,
                }
                console.info('# >>>>> TagCommander: [event] |openPlace|', payload)
                preventEventHandler.addToListTcEventToPrevent(['clickOnLink'])
                // eslint-disable-next-line
                tC.event.openPlace(<div></div>, payload)
            } catch (e) {
                console.warn('While processing click location rubrique en pratique for tag commander', e)
            }
        },
    },
    /*
    * En pratique: object clicked action
     */
    {
        testFn: function isClickObjectEnPratique(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_CLICK_OBJECT_RUBRIQUE_EN_PRATIQUE
        },
        action: function handleClickObjectEnPratique(eventName, eventData) {
            try {
                const {data} = eventData
                const payload = {
                    objectName: data.objectName,
                }
                console.info('# TagCommander: [event] |openObject|', payload)
                // eslint-disable-next-line
                tC.event.openObject(<div></div>, payload)
            } catch (e) {
                console.warn('While processing click object rubrique en pratique for tag commander', e)
            }
        },
    },
    /*
    * En pratique: category click action
     */
    {
        testFn: function isClickCategoryEnPratique(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_CLICK_CATEGORY_RUBRIQUE_EN_PRATIQUE
        },
        action: function handleClickCategoryEnPratique(eventName, eventData) {
            try {
                const {data} = eventData
                const payload = {
                    categoryName: data.categoryName,
                }
                console.info('# TagCommander: [event] |openCategory|', payload)
                // eslint-disable-next-line
                tC.event.openCategory(<div></div>, payload)
            } catch (e) {
                console.warn('While processing click category rubrique en pratique for tag commander', e)
            }
        },
    },
    /*
    * En pratique: section click action
     */
    {
        testFn: function isClickSectionEnPratique(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_CLICK_SECTION_RUBRIQUE_EN_PRATIQUE
        },
        action: function handleClickSectionEnPratique(eventName, eventData) {
            try {
                const {data} = eventData
                const payload = {
                    sectionName: data.sectionName,
                }
                console.info('# TagCommander: [event] |openSection|', payload)
                // eslint-disable-next-line
                tC.event.openSection(<div></div>, payload)
            } catch (e) {
                console.warn('While processing click section rubrique en pratique for tag commander', e)
            }
        },
    },

    /* Click on Search Result Item */
    {
        testFn: function isSearchResultItemClickAction(eventName, eventData) {
            return eventName === tcEventEnum.ACTION_CLICK_ON_SEARCH_RESULT_ITEM
        },
        action: function handleSearchResultItemClickAction(eventName, eventData) {
            try {
                const {data} = eventData
                const payload = {
                    searchTerm: data.searchTerm,
                    searchRank: data.searchRank,
                    searchTitle: data.searchTitle,
                }
                console.info('# TagCommander: [event] |searchResultClick|', payload)
                preventEventHandler.addToListTcEventToPrevent(['clickOnLink'])
                // eslint-disable-next-line
                tC.event.searchResultClick(<div></div>, payload)
            } catch (e) {
                console.warn('While processing click section rubrique en pratique for tag commander', e)
            }
        },
    },
    {
        /*
        *   click events: must remain last of current array
         */
        testFn: function isLinkClickEvent(eventName, eventData) {
            const {data} = eventData
            return eventName === tcEventEnum.LINK_CLICK && isDefined(data?.href)
        },
        action: function handleTcLinkClickEvent(eventName, eventData) {
            try {
                const listFnPreventIfTrue = [
                    function isNavigationToRubriqueEnPratique(evtName, evtData) {
                        const {data} = evtData
                        return data.href.endsWith('/en_pratique')
                    },
                    function isNavigationToRubriqueQuestionDuMoment(evtName, evtData) {
                        const {data} = evtData
                        return data.href.endsWith('/questions_du_moment')
                    },
                ]

                if (
                    preventEventHandler.isPreventedEvent('clickOnLink')
                    || listFnPreventIfTrue.some((shouldPrevent) => shouldPrevent(eventName, eventData))
                ) {
                    preventEventHandler.removeFromListTcEventToPrevent('clickOnLink')
                    return
                }
                const {data} = eventData
                const payload = {
                    linkType: data?.href?.startsWith('/') ? linkTypeEnum.internal : linkTypeEnum.external,
                    linkURL: data?.href,
                }
                console.info('# TagCommander: [event] |clickOnLink|', payload)
                // eslint-disable-next-line
                tC.event.clickOnLink(<div></div>, payload)
            } catch (e) {
                console.warn('Error while trying to use tC.event.clickOnLink', e)
            }
        },
    },
]


