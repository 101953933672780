import { colors } from '../../theme'


export default {
  container: {
    color: (props) => (
      props.variant === 'care' || props.variant === 'discover' ? colors.pureWhite
        : colors.pureBlack),
  },
  figure: {
    height: '8.5rem',
    margin: 0,
  },
  content: {
    padding: '2rem 1rem 1rem',
    '&:not(:first-child)': {
      paddingTop: '1rem',
    },
    '& > *:not(:first-child)': {
      marginTop: '1rem',
    },
  },
  title: {
    margin: 0,
    fontSize: '1.6rem',
    color: (props) => (
      props.variant === 'care' ? colors.yellowCare
        : props.variant === 'environment' ? colors.blueEnvironment
          : props.variant === 'listen' ? colors.blueListen
            : props.variant === 'discover' ? colors.yellowDiscover
              : colors.blueQuestions),
  },
  text: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '6',
    overflow: 'hidden',
    fontSize: '1.4rem',
    'figure + $content &': {
      WebkitLineClamp: '2',
    },
    '& p:not(:first-of-type)': {
      display: 'none',
    },
  },
}
