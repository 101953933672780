import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import withMemo from '../../../decorators/withMemo'
import { selectors as ToolSelectors } from '../OutilWrapper/redux'
import { blockToPracticeTransformer } from '../OutilWrapper/transformers'
import BlockHomeQuestions from '../../../components/BlockHomeQuestions'
import BlockHomeTopic from '../../../components/BlockHomeTopic'
import { useLexiconHandler } from '../../../utils/hooks/useLexicon'
import BlockHomePractice from '../../../components/BlockHomePractice'
import HeaderHomeSpecial from '../../../components/HeaderHomeSpecial'

import { selectors as HomeSelectors } from './redux'
import { blockHomeQuestionTransformer, blockHomeTopicTransformer, headerHomeTransformer } from './transformers'



function HomeWrapper() {
  const { t } = useTranslation()
  const tools = useSelector(ToolSelectors.tools)
  const rubriques = useSelector(HomeSelectors.rubriques)
  const questions = useSelector(HomeSelectors.questions)
  const [lexicals, handleLexicon] = useLexiconHandler()
  const blockToPracticeProps = useMemo(() => blockToPracticeTransformer(t, tools), [t, tools])
  const headerHomeProps = useMemo(() => headerHomeTransformer(t), [t])
  const blockHomeTopicProps = useMemo(() => blockHomeTopicTransformer(rubriques, {
    lexicon: lexicals,
    markHover: handleLexicon,
  }), [handleLexicon, lexicals, rubriques])
  const blockHomeQuestionsProps = blockHomeQuestionTransformer(questions, {
    lexicon: lexicals,
    markHover: handleLexicon,
  }, t)

  return (
    <>
      <HeaderHomeSpecial
        {...headerHomeProps}
      />
      <BlockHomePractice
        {...blockToPracticeProps}
      />
      <BlockHomeQuestions
        {...blockHomeQuestionsProps}
        lexicon={lexicals}
        markHover={handleLexicon}
      />
      <BlockHomeTopic {...blockHomeTopicProps} />
    </>
  )
}

HomeWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
}

HomeWrapper.defaultProps = {
}

export default withMemo()(HomeWrapper)
