import { accessibleHiddenText, colors, desktop, grids, tablet } from '../../theme'


export default {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 50,
    minHeight: '14.5rem',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    backgroundColor: colors.pureWhite,
    boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
    transition: 'box-shadow 200ms ease-out',
    '& > * + *': {
      paddingTop: '1.5rem',
      marginTop: '1.5rem',
      borderTop: `1px solid ${colors.greyE7}`,
    },
    '&.is-fixed': {
      position: 'fixed',
      top: '-8.1rem',
      boxShadow: '0 0 5rem rgba(0, 0, 0, 0.1)',
    },
    ...desktop({
      minHeight: '17.5rem',
      '& > * + *': {
        borderTop: '0 none',
      },
    }),
  },
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 3rem)`,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  wrapperTop: {
  },
  wrapperBottom: {
    justifyContent: 'space-between',
    '&, & > *': {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
  },
  left: {
    ...desktop({
      '& > * + *': {
        marginLeft: '10rem',
      },
    }),
  },
  right: {
    '& > *': {
      marginLeft: '1rem',
    },
    ...tablet({
      '& > *': {
        marginLeft: '2rem',
      },
    }),
  },
  logos: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    '& > *': {
      outline: 'flex: 0 0 auto',
    },
    '& > *:not(:first-child)': {
      marginLeft: '1.5rem',
    },
    ...desktop({
      '& > *:not(:first-child)': {
        marginLeft: '4rem',
      },
    }),
  },
  logoRF: {
    width: '4.8rem',
    ...desktop({
      width: '6rem',
    }),
  },
  logoSPF: {
    width: '6.5rem',
    ...desktop({
      width: '8.1rem',
    }),
  },
  logoMain: {
    display: 'none',
    margin: 0,
    '& > *': {
      display: 'block',
      height: '100%',
    },
    '& img': {
      width: '7.2rem',
    },
    '$wrapperTop &': {
      display: 'block',
      paddingLeft: '1.5rem',
      borderLeft: `1px solid ${colors.greyE7}`,
      '& img': {
        width: '4.7rem',
      },
    },
    ...desktop({
      display: 'block',
      '$wrapperTop &': {
        display: 'none',
      },
    }),
  },
  menuTrigger: {
    display: 'inline-flex',
    width: '2.5rem',
    color: colors.blue,
    cursor: 'pointer',
    '& > i': {
      ...accessibleHiddenText,
    },
    ...desktop({
      display: 'none',
    }),
  },
  spacer: {
    height: '15rem',
    ...desktop({
      height: '17.5rem',
    }),
  },
  '@media print': {
    container: {
      position: 'relative',
      minHeight: 0,
      borderBottom: `1pt solid ${colors.greyDE}`,
      boxShadow: 'none',
      '&.is-fixed': {
        position: 'relative',
        top: 'auto',
        boxShadow: 'none',
      },
    },
    wrapperBottom: {
      display: 'none',
    },
    spacer: {
      display: 'none',
    },
  },
}
