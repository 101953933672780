export default {
  container: {
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    zIndex: 49, // under header, hover blocktopractice
    background: 'linear-gradient(180deg, #fAFAFA 0%, #F9F9F9 100%)',
    pointerEvents: 'none',
    opacity: 0,
    transition: 'opacity 200ms ease-in-out',
    '&.is-visible': {
      opacity: 1,
    },
  },
  '@media print': {
    container: {
      display: 'none',
    },
  },
}
