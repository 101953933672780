import { mobile, desktop } from '../../theme'


export default {
  container: {
    '&:not(:first-child)': {
      marginTop: '7rem',
    },
    // '@media print': {
    //   display: 'none',
    // },
  },
  wrapper: {
    position: 'relative',
    maxWidth: '144rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '3rem',
  },
  content: {
    position: 'relative',
    zIndex: 2,
    maxWidth: '123rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > *:not(:first-child)': {
      marginTop: '6rem',
    },
  },
  bubbleTop: {
    position: 'absolute',
    right: '-10rem',
    top: '1rem',
    zIndex: 1,
    width: '52.7rem',
    height: '76rem',
    pointerEvents: 'none',
  },
  bubbleBottom: {
    position: 'absolute',
    left: '-1rem',
    bottom: 0,
    zIndex: 1,
    display: 'none',
    width: '76.5rem',
    height: '58rem',
    pointerEvents: 'none',
  },
  bubbleBottomMobile: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 1,
    width: '37.5rem',
    height: '48rem',
    pointerEvents: 'none',
  },
  ...mobile({
    container: {
      '&:not(:first-child)': {
        marginTop: '18rem',
      },
    },
    wrapper: {
      paddingBottom: '8rem',
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem',
    },
    content: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      '& > *': {
        maxWidth: 'calc(50% - 2rem)',
        margin: 0,
      },
      '& > *:not(:first-child)': {
        marginTop: '0',
      },
    },
    bubbleTop: {
      width: '39rem',
      height: '56.2rem',
      top: '-10rem',
    },
    bubbleBottom: {
      display: 'block',
    },
    bubbleBottomMobile: {
      display: 'none',
    },
  }),
  ...mobile({
    content: {
      '& > *': {
        flex: '0 0 auto',
        maxWidth: 'min(calc(50% - 2rem), 55.5rem)',
      },

    },
    bubbleTop: {
      top: '-30rem',
      right: 0,
    },
  }),
  ...desktop({
    bubbleTop: {
      right: 0,
    },
    content: {
      display: 'flex',
      justifyContent: 'space-evenly',
      '& > *:not(:first-child)': {
        marginTop: '0rem',
      },
    },
  }),
  '@media print': {
    container: {},
    bubbleTop: {
      display: 'none',
    },
    bubbleBottom: {
      display: 'none',
    },
    bubbleBottomMobile: {
      display: 'none',
    },
  },
}
