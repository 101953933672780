import gql from 'graphql-tag'

import { ParagraphSatelliteAncreFragment } from './ParagraphSatelliteAncreFragment'


export const SatelliteFragment = {
  fragment: gql`
    fragment SatelliteFragment on NodeSatellite {
      title
      body{value}
      entityUrl {
        path
      }
      fieldImageReseauxSociaux{
          url
      }
      entityMetatags{
        key
        value
      }
      title
      fieldSatelliteAncres{
        entity{
          __typename
          ... on ParagraphSatelliteAncre{
              ...ParagraphSatelliteAncreFragment
          }
        }
      }
    }
    ${ParagraphSatelliteAncreFragment.fragment}
  `,
}
