import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'i18n'

//
// Initial state
//
const initialState = {
  lang: null,
  locale: null,
  store: {},
  locales: null,
  configuration: {},
  configurations: {},
  socials: {},
}

//
// Actions
//
export const actions = {
  setLang: createAction('I18n: setLang', (lang) => ({ lang })),
  setLocale: createAction('I18n: setLocale', (locale) => ({ locale })),
  setLocales: createAction('I18n: setLocales', (locales) => ({ locales })),
  setStore: createAction('I18n: setStore', (store) => ({ store })),
  setConfiguration: createAction('I18n: setConfiguration', (configuration) => ({ configuration })),
  setConfigurations: createAction('I18n: setConfigurations', (configurations) => ({ configurations })),
  saveLang: createAction('I18n: saveLang', (lang, store) => ({
    lang,
    store,
  })),
  setSocials: createAction('I18n: setSocials', (socials) => ({ socials })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.saveLang]: (state, { lang, store }) => Immutable.static.merge(state, {
    lang,
    store,
  }),
  [actions.setLocale]: (state, { locale }) => Immutable.static.merge(state, { locale }),
  [actions.setStore]: (state, { store }) => Immutable.static.merge(state, { store }),
  [actions.setLocales]: (state, { locales }) => Immutable.static.merge(state, { locales }),
  [actions.setConfiguration]: (state, { configuration }) => Immutable.static.merge(state, { configuration }),
  [actions.setConfigurations]: (state, { configurations }) => Immutable.static.merge(state, { configurations }),
  [actions.setSocials]: (state, { socials }) => Immutable.static.merge(state, { socials }),
}, initialState)

//
// Selectors
//
const root = (state) => state[key]
const lang = (state) => root(state).lang
const store = (state) => root(state).store
const locale = (state) => root(state).locale
const locales = (state) => root(state).locales
const configuration = (state) => root(state).configuration
const configurations = (state) => root(state).configurations
const socials = (state) => root(state).socials

export const selectors = {
  lang,
  store,
  locale,
  locales,
  configuration,
  configurations,
  socials,
}
