import { all, call, put, select } from 'redux-saga/effects'

import { selectors as AppSelectors } from '../app/redux'
import client, { persistedClient } from '../../graphql/Client'

import { actions, selectors } from './redux'


export default class ApiSagas {

  static* initHeaders() {
    const preview = yield select(AppSelectors.preview)

    yield put(
      actions.setHeaders({
        preview: preview ? 1 : 0,
      })
    )
  }

  static* getHeaders() {
    const headers = yield select(selectors.headers)

    return {
      ...headers,
    }
  }

  static* query(service, variables = null, transformer = null) {
    const headers = yield call(ApiSagas.getHeaders)

    const result = yield call(client.query, service.apply(null, [variables, headers]))


    if (transformer && result && !result.errors) {
      return yield call(transformer, result.data)
    }

    return result
  }

  static* persistQuery(service, variables = null, transformer = null) {
   const headers = yield call(ApiSagas.getHeaders)

    const result = yield call(persistedClient.query, service.apply(null, [variables, headers]))

    if (transformer && result && !result.errors) {
      return yield call(transformer, result.data)
    }

    return result
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
