import { useEffect } from 'react'


export function useOutsideClick(ref, callback) {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      try {
        document.removeEventListener('click', handleClick)
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    }
  })
}
