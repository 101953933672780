/* eslint-disable max-lines */
import { all, call, put, select } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import AcceptLanguageParser from 'accept-language-parser'

import ApiSagas from '../../../redux/api/sagas'
import Service from '../../../graphql/query/Page/index'
import { parseUriToLanguageID, redirect } from '../../../helpers/UrlHelpers'
import * as TemplatesSagas from '../../Templates/sagas'
import { actions as AppActions } from '../../../redux/app/redux'
import { api as ApiConfig, app as AppConfig } from '../../../configuration'
import {
  actions as MenuActions,
  selectors as MenuSelectors
} from '../../../redux/menu/redux'
import { selectors as TranslationsSelectors } from '../../../redux/i18n/redux'
import routes, { Router } from '../../../routes'
import { generateAkamaiUrl } from '../../../helpers/Akamai'
import { actions as SearchActions } from '../../Templates/RechercheWrapper/redux'
import { selectors as ToolSelectors } from '../../Templates/OutilWrapper/redux'

import { actions as PageActions, selectors as PageSelectors } from './redux'
import {
  breadcrumbTransformer,
  pageAlternateTransformer,
  pageByFullSlugTransformer,
  variantMapperTransformer
} from './transformers'


export default class PageSagas {

  static* init(ctx) {
    const { query } = ctx
    const { locale } = query

    yield all([
      put(AppActions.setIsMobileMenuOpen(false)),
      put(AppActions.setIsCurrentSearch(false)),
      put(SearchActions.setTerms(query?.search_api_fulltext ?? null)),
    ])

    if (locale) {
      return yield call(PageSagas.loadPage, ctx)
    }
    return yield call(PageSagas.redirect, ctx)
  }

  static* redirect(ctx) {
    const locales = yield select(TranslationsSelectors.locales)
    const { req, res } = ctx
    const { headers } = req
    const languages = AcceptLanguageParser.parse(headers['accept-language'])

    let link = null

    languages.forEach((locale) => {
      if (!link) {
        let key = locale.code.toLowerCase()

        if (locale.region) {
          key += `-${locale.region.toLowerCase()}`

          if (locales[key]) {
            link = Router.getRouteUrl(routes.page, { locale: key })
          }
        } else {
          Object.keys(locales).forEach((item) => {
            const subKey = `${key}-${locales[item].country.toLowerCase()}`

            if (locales[subKey]) {
              link = Router.getRouteUrl(routes.page, { locale: subKey })
            }
          })
        }
      }
    })

    if (!link) {
      link = Router.getRouteUrl(routes.page, { locale: 'fr' })
    }

    return yield call(redirect, res, link, 301)
  }

  static* loadWithCache({ slug }) {
    const pages = yield select(PageSelectors.pages)

    if (!pages[slug]) {
      const result = yield call(ApiSagas.persistQuery, Service.byFullSlug, {
        slug,
      })

      if (result && !result.errors && result.data && result.data.route && result.data.route.nodeContext) {
        const tmp = {}

        tmp[slug] = result

        yield put(PageActions.setPages(Object.assign(tmp, pages)))
      }

      return result
    }

    return pages[slug] ?? null
  }

  static* purgeCache() {
    yield put(PageActions.setPages({}))
  }

  static* calculateSpace({ breadcrumb }) {
    yield all([
      put(PageActions.setBreadcrumb([])),
      put(PageActions.setVariant('default')),
    ])

    const breadcrumbTransformed = yield call(breadcrumbTransformer, breadcrumb)

    if (breadcrumbTransformed && breadcrumbTransformed.length > 1) {
      if (breadcrumbTransformed[1].linkProps.href && breadcrumbTransformed[1].linkProps.href !== '') {
        const result = yield call(PageSagas.loadWithCache, {
          slug: breadcrumbTransformed[1].linkProps.href,
        })

        if (result && !result.errors && result.data && result.data.route && result.data.route.nodeContext) {
          const variant = yield call(variantMapperTransformer, result.data.route.nodeContext)

          yield put(PageActions.setVariant(variant))
        }
      }
    }

    yield all([
      put(PageActions.setBreadcrumb(breadcrumbTransformed)),
    ])
  }

  static seo(metas) {
    const tMetas = metas?.map((k) => {
      if (k.key === 'canonical' || k.key === 'shortlink' || k.key === 'og:url' || k.key === 'twitter:url') {
        const protocol = ApiConfig.BACKEND_URL.startsWith('http://') ? {
          search: 'http',
          replace: 'https',
        } : { search: 'https', replace: 'http' }
        const search = [
          ApiConfig.BACKEND_URL,
          ApiConfig.BACKEND_URL.replace(protocol.search, protocol.replace),
        ]

        let value = k.value

        search.forEach((url) => {
          value = value.replace(url, AppConfig.APP_URL)
        })

        return {
          ...k,
          value,
        }
      }
      if (k.key === 'title' || k.key === 'og:title' || k.key === 'twitter:title') {
        let cleanValue = k.value

        if (cleanValue.indexOf('- ') === 0) {
          cleanValue = cleanValue.substr(2)
        }
        return {
          ...k,
          value: cleanValue,
        }
      }
      if (k.key === 'image_src' || k.key === 'og:image' || k.key === 'og:image:url' || k.key === 'twitter:image') {
        return {
          ...k,
          value: generateAkamaiUrl(k.value)?.trimEnd('/'),
        }
      }

      return k
    }) ?? []

    // Remove canonical? (seems buggy)
    const noCanonicalMetas = []

    for (let i = 0; i < tMetas.length; i++) {
      if (tMetas[i].key !== 'canonical') {
        noCanonicalMetas.push(tMetas[i])
      }
    }

    return noCanonicalMetas
  }

  static* loadPage(ctx) {
    const { query } = ctx
    const { locale = 'fr', slug = '' } = query
    const languageId = parseUriToLanguageID(locale)
    const header = yield select(MenuSelectors.header)
    const practices = yield select(ToolSelectors.view)

    yield put(showLoading())
    yield put(PageActions.setIsLoading(true))

    let filteredSlug = slug

    practices.forEach((practice) => {
      const url = (practice?.linkProps?.href?.trim('/')?.split('/')?.filter((i) => !!i) ?? [])[1]

      if (slug?.startsWith(url)) {
        filteredSlug = url
      }
    })

    const result = yield call(PageSagas.loadWithCache, {
      slug: `/${locale}/${(filteredSlug === '' ? 'node' : filteredSlug)}`,
    })

    if (result
        && !result.errors
        && result.data
        && result.data.route
        && (
            result.data.route.nodeContext
            || result.data.route.__typename === 'DefaultInternalUrl'
            || result.data.route.__typename === 'RedirectUrl'
        )) {
      const page = yield call(pageByFullSlugTransformer, result.data)
      const alternates = yield call(pageAlternateTransformer, result.data)
      const seo = yield call(PageSagas.seo, (page?.entityMetatags ?? (result.data.route?.metatags ?? [])))

      if (!header || header.length > 0) {
        yield put(MenuActions.setHeader([]))
      }

      yield call(PageSagas.calculateSpace, result.data.route)

      yield all([
        put(AppActions.setSeo({
          metas: seo,
          image: generateAkamaiUrl(page?.fieldImageReseauxSociaux?.url ?? ''),
        })),
        put(PageActions.setData(page)),
        put(PageActions.setAlternates(alternates)),
      ])

      let template = null

      let node = null

      if (result.data.route.__typename !== 'DefaultInternalUrl') {
        template = page && page.fieldTemplate && page.fieldTemplate.entity ? page.fieldTemplate.entity.__typename.toCamelCase() : null
        node = page.__typename.replace('Node', '').toCamelCase() ?? null
      } else {
        template = result.data.route?.pathInternal.replaceAll('/', '').replaceAll('_', ' ').replaceAll('-', ' ').toCamelCase() ?? null
      }

      if (template?.startsWith('QuestionsDuMoment')) {
        template = 'QuestionsDuMoment'
      }

      if (template && TemplatesSagas[`${template}Sagas`]) {
        yield call(TemplatesSagas[`${template}Sagas`].init, {
          ctx,
          data: page,
          languageId,
        })
      } else if (node && TemplatesSagas[`${node}Sagas`]) {
        yield call(TemplatesSagas[`${node}Sagas`].init, {
          ctx,
          data: page,
          languageId,
        })
      }
    } else {
      yield all([
        put(hideLoading()),
      ])
      return { error: 404 }
    }

    yield all([
      put(PageActions.setSlug(filteredSlug)),
      put(PageActions.setIsLoading(false)),
      put(PageActions.setLoaded(true)),
      put(hideLoading()),
    ])
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
