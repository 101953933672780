// eslint-disable-next-line
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cookiesTool } from 'react-gdpr'

import { actions as TrackingActions } from '../../redux/tracking/redux'


function Tracker() {
  const dispatch = useDispatch()

  useEffect(() => {
    if (cookiesTool.isActive('analytics')) {
      dispatch(TrackingActions.enableTracking())
    }
  })

  return null
}

export default Tracker
