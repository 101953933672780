/* eslint-disable camelcase */

import React from 'react'

import { variantTransformer } from '../../../redux/menu/transformers'
import FontsResizer from '../../../components/FontsResizer'
import ShareLinks from '../../../components/ShareLinks'
import { shareLinksTransformer } from '../../../transformers'
import { interpretHtml, stripHtml } from '../../../helpers/StringHelpers'
import { isEmptyString } from '../../../helpers/TypeHelpers'


export const headerSearchResultsTransformer = (route, tools, all, terms, onSearch, t, perPage) => ({
    textSearchedTerm: terms ?? '',
    textCountResults: `${tools ? tools?.result_count + all?.result_count : 0} ${t('resultSearch')} `,
    textSearch: t('textSearch'),
    textSubmit: t('textSubmit'),
    textSuggestionsTitle: t('textSuggestionsTitle'),
    textSearchPlaceholder: t('textSearchPlaceholder'),
    onSearch,
    zoneTools: (
      <>
        <FontsResizer />
        <ShareLinks
          {...shareLinksTransformer(
                    {
                        printJs: false,
                        downloadPdf: false,
                        url: route?.asPath ?? null,
                    },
                    t
                )}
        />
      </>
    ),
})

const getContentFromEntity = (entity) => {
    switch (entity.type) {
        case 'article':
            return [
                entity.field_body,
                entity.field_paragraphe_contenu?.join(' '),
                entity.field_description_ancre?.join(' '),
                entity.field_paragraphe_contenu_resume?.join(' '),
            ]?.join(' ') ?? ''
        case 'rubrique':
            return [entity.field_body]?.join(' ') ?? ''
        default:
            return [entity.field_zones_body?.join(' '), entity.summary]?.join(' ') ?? ''
    }
}

const highlightRegexInContent = (REGX, content) => {
    const applyHighlight = (text) => `<span class='search-result-hightlight'>${text}</span>`
    return content.replace(
        REGX,
        (matchString, matchIndex) => applyHighlight(matchString)
    )
}

const createRegexWordBoundaryWithTerm = (term) => new RegExp(`(^|[ '",.!?:;])${term}([ '",.!?:;]|$)`, 'gmi')

const stripContent = (content) => stripHtml(content)
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .replace(/&nbsp;/g, ' ')
    .replace(/\s+/g, ' ')


const getStripedContentFromEntity = (entity) => stripContent(getContentFromEntity(entity) ?? '')

const getSummaryWithSearchTermHighlighted = (entity, searchTerm) => {
    let content = getStripedContentFromEntity(entity)
    if (isEmptyString(searchTerm)) {
        return content
    }

    const REGX = createRegexWordBoundaryWithTerm(searchTerm)
    if (content) {
        content = highlightRegexInContent(REGX, content)
    }

    const sentences = content
        .match(/((?:[A-Z][a-z]\.|\w\.\w.|.)*?(?:[.!?]|$))(?:\s+|$)/g)
        .map((sentence) => sentence.trim())
        .filter((sentence) => sentence.length)


    if (!sentences || !sentences.length) {
        return content
    }

    const indexOfFirstMatchedOccurence = sentences.findIndex((sentence) => (sentence.toLowerCase().search(REGX) > -1))

    let actualSentence

    let afterSentence

    if (indexOfFirstMatchedOccurence >= 1) {
        actualSentence = sentences[indexOfFirstMatchedOccurence] || null
        afterSentence = sentences[indexOfFirstMatchedOccurence + 1] || null
    }

    if (indexOfFirstMatchedOccurence === 0) {
        actualSentence = sentences[indexOfFirstMatchedOccurence + 1] || null
        afterSentence = sentences[indexOfFirstMatchedOccurence + 2] || null
    }

    if (!afterSentence && !actualSentence) {
        return content
    }

    return [actualSentence, afterSentence].filter((e) => !!e).join(' ')
}

export const searchResultInpracticeProps = (item, t, search) => {
    const summary = getSummaryWithSearchTermHighlighted(item, search)

    const interpretedHTML = interpretHtml(summary)
    const subSummary = interpretedHTML.substring(0, interpretedHTML.indexOf('.') + 1)

    return {
        textButton: t('textButton'),
        variant: 'inpractice',
        topic: {
            text: t('textEnPratiqueTitle'),
            linkProps: {
                href: t('uriEnPratique'),
                target: '_self',
            },
        },
        section: {
            text: item?.title ?? '',
            linkProps: {
                href: `${item?.url ?? ''}?search_fulltext=${search}&field_desc=${subSummary}`,
                target: '_self',
            },
        },
        linkProps: {
            href: `${item?.url ?? ''}?search_fulltext=${search}&field_desc=${subSummary}`,
            target: '_self',
        },
        title: '',
        summary,
        image: item?.field_icone_espace_url
            ? {
                src: item?.field_icone_espace_url,
                alt: item?.field_icone_espace_alt,
            }
            : null,
    }
}

export const searchResultProps = (item, t, terms) => ({
    textButton: t('textButton'),
    variant: variantTransformer(item?.field_rubrique_class ?? ''),
    topic: item?.field_master_rubrique_name
        ? {
            text: item?.field_master_rubrique_name?.replace('|', '<br />') ?? '',
        }
        : null,
    section: item?.field_rubrique_name
        ? {
            text: item?.field_rubrique_name?.replace('|', '<br />') ?? '',
        }
        : null,
    linkProps: {
        href: item?.field_url ?? '',
        target: '_self',
    },
    title: item?.field_title ?? '',
    summary: getSummaryWithSearchTermHighlighted(item, terms),
    image: item?.field_image_url
        ? {
            src: item?.field_image_url,
            alt: item?.field_image_alt,
        }
        : null,
})
