import { colors, tablet } from '../../theme'


export default {
  container: {
    '&:not(:last-child), &.is-open:last-child': {
      borderBottom: `1px solid ${colors.greyDD}`,
    },
  },
  trigger: {
    position: 'relative',
    paddingRight: '4rem',
    cursor: 'pointer',
  },
  icon: {
    position: 'absolute',
    top: '3rem',
    right: 0,
    width: '2rem',
    height: '1.2rem',
    color: colors.grey95,
    transition: 'transform 200ms',
    '$container.is-open &': {
      transform: 'rotate(180deg)',
    },
  },
  collapser: {
    position: 'relative',
    overflow: 'hidden',
    transition: 'height 200ms ease-out',
    marginLeft: 0,
  },
  collapsed: {
    position: 'absolute',
    padding: '2rem 0',
    '$container.is-open &': {
      position: 'relative',
    },
  },
  question: {
    fontWeight: 'bold',
    margin: 0,
    padding: '2rem 0',
    fontSize: '2rem',
    transition: 'color 200ms ease-out',
    '$trigger:hover &': {
      textDecoration: 'underline',
      color: colors.blueSteel,
    },
  },
  answer: {

  },
  ...tablet({
    icon: {
      width: '1.2rem',
      height: '0.8rem',
    },
    question: {
      fontSize: '1.5rem',
    },
  }),
  '@media print': {
    collapser: {
      height: 'auto !important',
      maxHeight: 'auto !important',
    },
    collapsed: {
      position: 'relative',
    },
    icon: {
      display: 'none',
    },
  },
}
