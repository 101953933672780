import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

import withMemo from '../../decorators/withMemo'
import PageTemplate from '../../templates/Page'
import TemplatesParser from '../../components/TemplatesParser'
import { selectors as PageSelectors } from '../../Wrappers/Pages/Page/redux'
import { actions as AppActions, selectors as AppSelectors } from '../../redux/app/redux'
import { selectors as TranslationSelector } from '../../redux/i18n/redux'
import PageSagas from '../../Wrappers/Pages/Page/sagas'
import NavMain from '../../components/NavMain'
import NavSecondary from '../../components/NavSecondary'
import HeaderSearch from '../../components/HeaderSearch'
import { actions as MenuActions, selectors as MenuSelectors } from '../../redux/menu/redux'
import { selectors as LexiconSelector } from '../../redux/lexicon/redux'
import routes, { Router } from '../../routes'
import { selectors as SearchSelectors } from '../../Wrappers/Templates/RechercheWrapper/redux'
import { selectors as ToolsSelectors } from '../../Wrappers/Templates/OutilWrapper/redux'
import LexiconPop from '../../components/LexiconPop'
import LoadingScreen from '../../components/LoadingScreen'
import { useTagCommander } from '../../utils/hooks'
import { isDefined } from '../../helpers/TypeHelpers'


const Page = () => {
    const { handlePageChangeTC } = useTagCommander()
    const { t } = useTranslation()
    const router = useRouter()
    // page selectors
    const slug = useSelector(PageSelectors.slug)
    const data = useSelector(PageSelectors.data)
    const breadcrumb = useSelector(PageSelectors.breadcrumb)
    const isLoaded = useSelector(PageSelectors.isLoaded)
    const isMobileMenuOpen = useSelector(AppSelectors.isMobileMenuOpen)
    const isCurrentSearch = useSelector(AppSelectors.isCurrentSearch)
    const locale = useSelector(TranslationSelector.locale)
    const terms = useSelector(SearchSelectors.terms)
    const route = useSelector(AppSelectors.route)

    // STATE
    const menu = useSelector(MenuSelectors.menu)
    const headerRight = useSelector(MenuSelectors.headerRight)
    const footerTop = useSelector(MenuSelectors.footerTop)
    const footer = useSelector(MenuSelectors.footer)
    const search = useSelector(MenuSelectors.search)
    const searchIsOpen = useSelector(MenuSelectors.searchIsOpen)
    const lexicon = useSelector(LexiconSelector.lexicon)
    const tools = useSelector(ToolsSelectors.tools)

    // local state
    const [anchorFooter, setAnchorFooter] = useState('')
    const [openMenu, setOpenMenu] = useState(-1)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const toolsSlugs = useMemo(() => tools?.map((tool) => tool.linkProps.href.replace(`/${locale}/`, '') ?? []), [tools, locale])

    const setMobileMenuOpen = useCallback((value) => {
        dispatch(AppActions.setIsMobileMenuOpen(value))
    }, [dispatch])

    const menuProps = useMemo(() => (menu?.map((link) => ({
        ...link,
        isActive: !!(breadcrumb && breadcrumb.find((item) => (item?.slug.indexOf(link.slug) >= 0))),
    })) ?? []), [breadcrumb, menu])

    const navSecondaryProps = useMemo(() => (headerRight?.map((link) => ({
        ...link,
        isActive: !!(slug && slug !== '' && (link.slug.indexOf(slug) >= 0 || link.slug.indexOf(slug.replaceAll('_', '-')) >= 0) || (link.linkProps.href === t('uriEnPratique') && toolsSlugs?.indexOf(slug) >= 0)),
    })) ?? []), [headerRight, slug, t, toolsSlugs])

    const navMain = useMemo(() => (
      <NavMain
        links={menuProps?.map((menuItem, index) => ({
                ...menuItem,
                sub: {
                    ...menuItem?.sub,
                    isOpen: index === openMenu,
                    onMenuLeaveHandler: () => {
                        setOpenMenu(-1)
                    },
                },
                mouseEnterHandler: () => {
                    if (menuItem?.sub.links?.length > 0) {
                        setOpenMenu(index)
                    } else {
                        setOpenMenu(-1)
                    }
                },
            }))}
        onMenuLeaveHandler={() => {
                setOpenMenu(-1)
            }}
      />
    ), [menuProps, openMenu])

    const searchProps = useMemo(() => ({
        links: search,
        textSearch: t('textSearch'),
        textSubmit: t('textSubmit'),
        textSuggestionsTitle: t('textSuggestionsTitle'),
        textSearchPlaceholder: t('textSearchPlaceholder'),
        isOpen: searchIsOpen,
        textInitialValue: terms ?? '',
        isCurrent: isCurrentSearch,
        onSearch: (values) => {
            dispatch(MenuActions.setSearchIsOpen(false))
            return Router.pushRoute(Router.getRouteUrl(routes.page, {
                slug: 'recherche',
                locale,
                search_api_fulltext: values,
            }))
        },
    }), [search, t, searchIsOpen, terms, isCurrentSearch, dispatch, locale])

    const headerProps = useMemo(() => ({
        urlHome: `/${locale}`,
        logoMain: {
            src: '/images/Common/logo-1000-jours.png',
            alt: t('logoMain'),
        },
        logoSPF: {
            src: '/images/Common/logo-spf.svg',
            alt: t('logoSPF'),
        },
        logoRF: {
            src: '/images/Common/logo-rf.svg',
            alt: t('logoRF'),
        },
        urlSPF: 'https://www.santepubliquefrance.fr/',
        urlRF: 'https://solidarites-sante.gouv.fr/',
        textOpenMenu: t('textOpenMenu'),
        navMain,
        navSecondary: <NavSecondary links={navSecondaryProps} />,
        search: <HeaderSearch {...searchProps} />,
    }), [locale, t, navMain, navSecondaryProps, searchProps])

    const footerTopProps = useMemo(() => footerTop?.map((footerItem) => {
        const thisUrl = footerItem?.linkProps?.href
        const currentUrl = `${route?.asPath.split('#')[0]}${anchorFooter}`

        return {
            ...footerItem,
            isCurrent: thisUrl === currentUrl,
            isOpen: !!footerItem?.sub?.find((link) => link?.linkProps?.href === currentUrl),
            sub: footerItem?.sub?.map((link) => ({
                ...link,
                isCurrent: link?.linkProps?.href === currentUrl,
            })) ?? [],
        }
    }), [anchorFooter, footerTop, route.asPath])

    const footerMenuProps = useMemo(() => ({
        urlHome: `/${locale}`,
        logo: {
            src: '/images/Common/logo-1000-jours.png',
            alt: t('logoMain'),
            width: 72,
            height: 65,
        },
        textOpenMenu: t('textOpenMenu'),
        links: footerTopProps,
    }), [footerTopProps, t, locale])

    const footerUnderComputedProps = useMemo(() => footer?.map((footerItem) => ({
        ...footerItem,
        isCurrent: footerItem?.linkProps?.href === `${route?.asPath}${anchorFooter}`,
    })), [anchorFooter, footer, route.asPath])

    const navMobileProps = useMemo(() => ({
        linksMain: menuProps.map((item) => ({
            ...item.sub,
            mainLinkProps: item.linkProps,
        })),
        linksSecondary: headerRight,
        textClose: t('textClose'),
        textBack: t('textBack'),
    }), [menuProps, headerRight, t])

    // - close the menu on page change
    const handleRouteChangeStart = useCallback(() => {
        setOpenMenu(-1)
        if (window) {
            window.scroll({
                top: 0,
                left: 0,
            })
        }
        setLoading(true)
    }, [])

    const handleRouteChangeComplete = useCallback((targetUrl) => {
        if (isDefined(targetUrl)) {
            handlePageChangeTC()
        }
        setAnchorFooter(window?.location?.hash ?? '')
        setLoading(false)
    }, [handlePageChangeTC])

    // - deal with anchors of page change
    const handleHashChangeStart = useCallback(() => {
        setOpenMenu(-1)
    }, [])

    const handleHashChangeComplete = useCallback(() => {
        setAnchorFooter(window?.location?.hash ?? '')
    }, [])

    useEffect(() => {
        handleRouteChangeComplete()
        // events
        router.events.on('routeChangeStart', handleRouteChangeStart)
        router.events.on('routeChangeComplete', handleRouteChangeComplete)
        router.events.on('hashChangeStart', handleHashChangeStart)
        router.events.on('hashChangeComplete', handleHashChangeComplete)
        if (window) {
            window.addEventListener('hashchange', handleHashChangeComplete)
        }

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart)
            router.events.off('routeChangeComplete', handleRouteChangeComplete)
            router.events.off('hashChangeStart', handleHashChangeStart)
            router.events.on('hashChangeComplete', handleHashChangeComplete)
            if (window) {
                window.removeEventListener('hashchange', handleHashChangeComplete)
            }
        }
    }, [handleHashChangeComplete, handleHashChangeStart, handleRouteChangeComplete, handleRouteChangeStart, router.events])

    if (data === null || isLoaded === false) {
        return null
    }

    return (
      <PageTemplate pageContainerProps={{
            headerProps,
            breadcrumbProps: { links: breadcrumb },
            footerMenuProps,
            footerUnderProps: { links: footerUnderComputedProps },
            navMobileProps,
            isMobileMenuOpen,
            setMobileMenuOpen,
            tools: (
              <>
                {lexicon && (
                <LexiconPop
                  {...lexicon}
                  page={Router.router.asPath}
                />
                    )}
              </>
            ),
        }}
      >
        {data && data.fieldTemplate && data.fieldTemplate.entity && (
        <TemplatesParser {...data} />
            )}
        {data && data.__typename === 'DefaultInternalUrl' && !data.fieldTemplate && (
        <TemplatesParser
          fieldNode={slug === '' ? 'home' : slug.replaceAll('/', '').replaceAll('_', ' ').replaceAll('-', ' ').toCamelCase()}
          {...data}
        />
            )}
        {data && data.__typename !== 'DefaultInternalUrl' && !data.fieldTemplate && (
        <TemplatesParser
          fieldNode={data.__typename}
          {...data}
        />
            )}
        <LoadingScreen isActive={loading} />
      </PageTemplate>
    )
}

Page.getInitialProps = async(ctx) => ctx.store.saga.run(PageSagas.init, ctx)
    .toPromise()

Page.propTypes = {}

Page.defaultProps = {}

export default withMemo()(Page)
