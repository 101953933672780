import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import withMemo from '../../../decorators/withMemo'
import { selectors as PageSelectors } from '../../Pages/Page/redux'
import BlockArticle from '../../../components/BlockArticle'

import { articleEncartContenuTransformer } from './transformers'


const ArticleEncartContenu = (props) => {
  const {
    lexicon,
    markHover,
    transformLexicon,
  } = props
  const variant = useSelector(PageSelectors.variant)
  const blockArticleProps = useMemo(() => articleEncartContenuTransformer(props, variant), [props, variant])

  return (
    <BlockArticle
      {...blockArticleProps}
      variant={variant}
      lexicon={lexicon}
      markHover={markHover}
      transformLexicon={transformLexicon}
    />
  )
}

ArticleEncartContenu.propTypes = {
  lexicon: PropTypes.array,
  markHover: PropTypes.func,
  transformLexicon: PropTypes.bool,
}

ArticleEncartContenu.defaultProps = {
  lexicon: null,
  markHover: () => null,
  transformLexicon: true,
}

export default withMemo()(ArticleEncartContenu)
