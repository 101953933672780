import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'menu'

//
// Initial state
//
const initialState = {
  header: [],
  links: null,
  menu: null,
  search: null,
  headerRight: null,
  menusCache: {},
  footerTop: null,
  footer: null,
  searchIsOpen: false,
}

//
// Actions
//
export const actions = {
  setMenusCache: createAction('Menu: setMenusCache', (menusCache) => ({ menusCache })),
  setLinks: createAction('Menu: setLinks', (links) => ({ links })),
  setHeader: createAction('Menu: setHeader', (header) => ({ header })),
  setHeaderRight: createAction('Menu: setHeaderRight', (headerRight) => ({ headerRight })),
  setSearch: createAction('Menu: setSearch', (search) => ({ search })),
  setMenu: createAction('Menu: setMenu', (menu) => ({ menu })),
  setFooterTop: createAction('Menu: setFooterTop', (footerTop) => ({ footerTop })),
  setFooter: createAction('Menu: setFooter', (footer) => ({ footer })),
  setSearchIsOpen: createAction('Menu: setSearchIsOpen', (searchIsOpen) => ({ searchIsOpen })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setMenusCache]: (state, { menusCache }) => Immutable.merge(state, { menusCache }),
    [actions.setLinks]: (state, { links }) => Immutable.merge(state, { links }),
    [actions.setHeader]: (state, { header }) => Immutable.merge(state, { header }),
    [actions.setHeaderRight]: (state, { headerRight }) => Immutable.merge(state, { headerRight }),
    [actions.setSearch]: (state, { search }) => Immutable.merge(state, { search }),
    [actions.setMenu]: (state, { menu }) => Immutable.merge(state, { menu }),
    [actions.setFooterTop]: (state, { footerTop }) => Immutable.merge(state, { footerTop }),
    [actions.setFooter]: (state, { footer }) => Immutable.merge(state, { footer }),
    [actions.setSearchIsOpen]: (state, { searchIsOpen }) => Immutable.merge(state, { searchIsOpen }),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state[key]
const menusCache = (state) => root(state).menusCache
const links = (state) => root(state).links
const header = (state) => root(state).header
const headerRight = (state) => root(state).headerRight
const search = (state) => root(state).search
const menu = (state) => root(state).menu
const footerTop = (state) => root(state).footerTop
const footer = (state) => root(state).footer
const searchIsOpen = (state) => root(state).searchIsOpen

export const selectors = {
  menusCache,
  links,
  header,
  headerRight,
  search,
  menu,
  footerTop,
  footer,
  searchIsOpen,
}
