import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BackToTop = (props) => {
  const {
    classes: classesProp,
    className,
    isActive,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const [isVisible, setVisible] = useState(false)

  // effects
  // - change state on prop change
  useEffect(() => {
    setVisible(isActive)
  }, [isActive])

  return (
    <div
      className={cx(classes.container, className, isVisible === true && 'is-visible')}
      aria-hidden
    />
  )
}

BackToTop.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  isActive: PropTypes.bool,
}

BackToTop.defaultProps = {
  className: null,
  classes: null,
  isActive: false,
}

export default withMemo()(BackToTop)
