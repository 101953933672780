import { colors } from '../../theme'


export default {
  container: {
    position: 'relative',
    marginTop: '4rem',
    background: colors.greyE7,
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '7rem',
    },
    // penultimate child has a margin bottom because last is lexicon container
    '& ~ &:nth-last-child(2)': {
      marginTop: '7rem',
      marginBottom: '0',
    },
  },
  wrapper: {
    display: 'flex',
  },
  list: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexFlow: 'row nowrap',
    paddingTop: '7rem',
    paddingBottom: '9rem',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '6rem',
      zIndex: 1,
      height: '0.5rem',
      background: colors.bluePale,
    },
  },
  '@media print': {
    container: {
      margin: 0,
      '& ~ &': {
        display: 'none',
      },
    },
    list: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      padding: '0',
      '&::after': {
        display: 'none',
      },
    },
  },
}
