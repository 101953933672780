import { colors, tablet } from '../../theme'


export default {
  container: {
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  term: {
    position: 'relative',
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '0.5rem 2rem 1.5rem 0.5rem',
    textAlign: 'left',
    outline: '0 none',
    borderBottom: `1px solid ${colors.grey95}`,
    cursor: 'pointer',
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: '0.8rem',
    width: '1.2rem',
    height: '0.8rem',
    transition: 'transform 100ms',
    color: colors.grey95,
    '$container.is-open &': {
      transform: 'rotate(180deg)',
    },
  },
  definition: {
    position: 'absolute',
    height: 0,
    padding: '1rem 0.5rem 0',
    margin: 0,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0ms ease-out',
    '$container.is-open &': {
      position: 'static',
      height: 'auto',
      opacity: 1,
      pointerEvents: 'all',
      transition: 'opacity 200ms ease-out',
    },
  },
  text: {
    fontSize: '1.4rem',
  },
  ...tablet({
    term: {
      fontSize: '1.8rem',
    },
    text: {
      fontSize: '1.6rem',
    },
  }),
  '@media print': {
    container: {
      breakInside: 'avoid',
      pageBreakInside: 'avoid',
    },
    definition: {
      position: 'static',
      height: 'auto',
      opacity: 1,
      breakInside: 'avoid',
      pageBreakInside: 'avoid',
    },
    icon: {
      display: 'none',
    },
  },
}
