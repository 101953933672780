import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import objectFitImages from 'object-fit-images'
import { createUseStyles } from 'react-jss'

import { imageTypes } from '../CloudinaryImage/types'
import withMemo from '../../decorators/withMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Image = React.forwardRef((props, ref) => {
  const {
    lazyLoad,
    children,
    className,
    covered,
    type,
    src,
    srcSet,
    sizes,
    alt,
    lazySrc,
    width,
    height,
  } = props
  const classes = useStyles(props)
  const lazyRef = ref || useRef()
  const [visible, setVisible] = useState(!lazyLoad)
  const [isObjectFitSupported, setObjectFitSupported] = useState(true)

  const shouldUseIntersectionManager = useCallback(
    () => lazyLoad
      && 'IntersectionObserver' in window
      && !(typeof IntersectionObserver === 'undefined'),
    [lazyLoad]
  )

  const objectFitPolyfill = useCallback(() => {
    if (!isObjectFitSupported && lazyRef) {
      objectFitImages(lazyRef.current.querySelector('img'))
    }
  }, [isObjectFitSupported, lazyRef])

  useEffect(() => {
    const testImg = window.document.createElement('img')

    setObjectFitSupported('object-fit' in testImg.style)

    if (shouldUseIntersectionManager()) {
      const io = new IntersectionObserver((entries) => {
        const entry = entries[0]

        if (entry && entry.isIntersecting && lazyRef.current) {
          io.unobserve(lazyRef.current)
          io.disconnect()
          setVisible(true)
        }
      })

      io.observe(lazyRef.current)

      return () => io.disconnect()
    }
    setVisible(true)
  }, [lazyRef, shouldUseIntersectionManager])

  useEffect(() => {
    if (visible) {
      objectFitPolyfill()
    }
  }, [objectFitPolyfill, visible])

  // src.split('/').pop().split('-').join(' ').split('.')[0]

  const computedAlt = useMemo(() => (alt !== null ? alt : ''), [alt])

  const renderImage = useMemo(
    () => (
      <img
        src={src}
        {...(srcSet ? { srcSet } : {})}
        {...(sizes ? { sizes } : {})}
        alt={computedAlt}
        className={classes.img}
        width={width}
        height={height}
      />
    ),
    [classes.img, computedAlt, height, sizes, src, srcSet, width]
  )

  const renderLazyImage = useMemo(
    () => lazySrc && (
    <img
      src={lazySrc}
      {...(srcSet ? { srcSet } : {})}
      {...(sizes ? { sizes } : {})}
      width={width}
      height={height}
      alt={computedAlt}
      className={classes.img}
    />
    ),
    [classes.img, computedAlt, height, lazySrc, sizes, srcSet, width]
  )

  const contentNode = useMemo(
    () => (
      <>
        {type === imageTypes.Background ? children : visible ? renderImage : renderLazyImage}
        {type !== imageTypes.Background && lazyLoad && (
          <noscript>
            <div className={classes.noScriptImage}>{renderImage}</div>
          </noscript>
        )}
      </>
    ),
    [children, classes.noScriptImage, lazyLoad, renderImage, renderLazyImage, type, visible]
  )

  const divStyle = useMemo(
    () => ({
      ...(visible
        && type === imageTypes.Background && {
        backgroundImage: `url('${src}')`,
        ...(covered && { backgroundSize: 'cover' }),
      }),
    }),
    [covered, src, type, visible]
  )

  return (
    <div
      ref={lazyRef}
      className={classNames(className, classes.image, { covered })}
      style={divStyle}
    >
      {contentNode}
    </div>
  )
})

Image.propTypes = {
  src: PropTypes.string.isRequired,
  lazySrc: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
  children: PropTypes.node,
  alt: PropTypes.string,
  className: PropTypes.string,
  lazyLoad: PropTypes.bool,
  covered: PropTypes.bool,
  type: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Image.defaultProps = {
  covered: false,
  className: null,
  lazySrc: '',
  srcSet: '',
  sizes: '',
  children: null,
  lazyLoad: false,
  alt: null,
  type: imageTypes ? imageTypes.Image : 'image',
  width: '100%',
  height: '100%',
}

export default withMemo()(Image)
