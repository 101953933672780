import { Router } from '../routes'


export function getGoogleMapApiUrl(lat, long) {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${long}`
}

export function getYoutubeThumbnailUrl(videoUrl) {
  let videoId = false

  if (/^[a-zA-Z0-9]+$/.test(videoUrl)) {
    videoId = videoUrl
  } else {
    let matches = videoUrl.match(/https:\/\/youtu\.be\/([a-zA-Z0-9]+)/)

    if (!matches) {
      matches = videoUrl.match(/https:\/\/www.youtube.com\/watch\?v=([a-zA-Z0-9]+)/)
    }
    if (matches) {
      videoId = matches[1]
    }
  }

  return videoId ? `//img.youtube.com/vi/${videoId}/sddefault.jpg` : null
}

export function redirect(res, route, statusCode = 301) {
  if (res) {
    res.redirect?.(statusCode, `${process.env.APP_URL}${route}`)
  } else {
    Router.replaceRoute(route)
  }
}

export function routeParamSlug(fullSlug) {
  if (!fullSlug || Array.isArray(fullSlug)) {
    return fullSlug
  }

  const slug = fullSlug.replace(/^\//, '')

  return slug.indexOf('/') !== -1 ? slug.split('/') : slug
}

export function parseUriToLanguageID(fullSlug) {
  if (!fullSlug && typeof fullSlug !== 'string') {
    return null
  }

  const tableSlug = fullSlug.split('/')

  return tableSlug.length > 0 ? tableSlug[0].replace('-', '_').toUpperCase() : 'FR'
}

export function parseLanguageToUri(languageId) {
  return languageId ? languageId.replace('_', '-').toLowerCase() : 'fr'
}

export function parseLocaleToCountryFromID(locale) {
  if (!locale && typeof locale !== 'string') {
    return null
  }

  const tableSlug = locale.split('_')

  return tableSlug.length > 0 ? tableSlug[1] : null
}

export function parseLocaleToCountryFromLocale(locale) {
  if (!locale && typeof locale !== 'string') {
    return null
  }

  const tableSlug = locale.split('-')

  return tableSlug.length > 0 ? tableSlug[1].toUpperCase() : null
}

export function parseLocaleToIsoFromLocale(locale) {
  if (!locale && typeof locale !== 'string') {
    return null
  }

  const tableSlug = locale.split('-')

  return tableSlug.length > 0 ? tableSlug[0].toUpperCase() : null
}
