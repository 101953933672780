import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'
import Icon from '../Icon'
import icons from '../Icon/assets'
import BlockFaqItem from '../BlockFaqItem'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockFaq = (props) => {
  const {
    classes: classesProp,
    className,
    id,
    title,
    questions,
    image,
    zoneMenu,
    TagTitle,
    // eslint-disable-next-line no-unused-vars
    variant,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const [openAnswer, setOpenAnswer] = useState(0)

  const handleToggle = useCallback((index) => {
    if (index === openAnswer) {
      setOpenAnswer(-1)
    } else {
      setOpenAnswer(index)
    }
  }, [openAnswer])

  const renderQuestions = useMemo(() => questions && (
    <dl className={classes.list}>
      {questions.map((question, index) => (
        <BlockFaqItem
          key={`blockfaq-${index}`}
          question={question}
          handleOnToggle={() => handleToggle(index)}
          isOpen={index === openAnswer}
        />
      ))}
    </dl>
  ), [classes.list, handleToggle, openAnswer, questions])

  return (
    <section
      className={cx(classes.container, className)}
    >
      {!zoneMenu && image && (
        <div className={classes.image}>
          <span
            className={classes.imageTitle}
            aria-hidden="true"
          >
            {title}
          </span>
          <Icon
            icon={icons.ArrowDown}
            className={classes.imageArrow}
          />
          <Image
            {...image}
            className={classes.imageImage}
          />
        </div>
      )}
      <div className={classes.wrapper}>
        {zoneMenu && <div className={classes.menu}>{zoneMenu}</div>}
        <div
          className={classes.content}
          id={id}
        >
          <TagTitle className={classes.title}>{title}</TagTitle>
          {renderQuestions}
        </div>
      </div>
    </section>
  )
}

BlockFaq.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
  image: PropTypes.shape(Image.propTypes),
  zoneMenu: PropTypes.node,
  TagTitle: PropTypes.string,
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
}

BlockFaq.defaultProps = {
  className: null,
  classes: null,
  questions: null,
  image: null,
  zoneMenu: null,
  TagTitle: 'h3',
}

export default withMemo()(BlockFaq)
