import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'
import Image from '../Image'
import { useTagCommander } from '../../utils/hooks'
import { tcEventEnum } from '../../utils/hooks/useTagCommander'
import { isDefined } from '../../helpers/TypeHelpers'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PracticeTabs = (props) => {
    const {
        classes: classesProp,
        className,
        id,
        tabs,
        lexicon,
        currentTab,
        currentContent,
        handleClickTab,
        callbackClickContent,
        markHover,
    } = props
    const { handleEventTC } = useTagCommander()
    const classesComp = useStyles(props)
    const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

    const handleClickContent = useCallback((e, index) => {
        const isOpenningDropdown = e.currentTarget.classList.contains('is-active') === false
        if (isOpenningDropdown) {
            handleEventTC(
                tcEventEnum.ACTION_CLICK_SECTION_RUBRIQUE_EN_PRATIQUE,
                {
                    data: {
                        sectionName: index + 1,
                    },
                }
            )
        }
        if (e.target.closest('blockquote:not(:only-child)')) {
            if (index === currentContent) {
                callbackClickContent(-1)
            } else {
                callbackClickContent(index)
            }
        }
    }, [callbackClickContent, currentContent, handleEventTC])

    const newCurrentTab = useMemo(() => {
        if (tabs && currentTab > tabs.length - 1) {
            return 0
        }
        return currentTab
    }, [currentTab, tabs])

    const onTabClick = useCallback((tab, index = null) => {
        if (!tab) {
            return
        }
        handleEventTC(
            tcEventEnum.ACTION_CLICK_CATEGORY_RUBRIQUE_EN_PRATIQUE,
            {
                data: {
                    categoryName: tab?.text,
                },
            }
        )
        if (isDefined(index)) {
            handleClickTab(index)
        }
    }, [handleClickTab, handleEventTC])

    useEffect(() => {
        const firstTabIndex = 0
        handleClickTab(firstTabIndex)
        onTabClick(tabs[firstTabIndex], firstTabIndex)
        // eslint-disable-next-line
    }, []);

    return tabs ? (
      <div
        className={cx(classes.container, className)}
        id={id}
      >
        <ul
          role="navigation"
          className={classes.tabs}
        >
          {tabs.map((tab, index) => (
            <li
              key={`practicetabs-tab-${index}`}
              className={classes.item}
            >
              <button
                type="button"
                className={cx(classes.button, newCurrentTab === index && 'is-active')}
                onClick={() => onTabClick(tab, index)}
              >
                {tab.imageTab && (
                <Image
                  {...tab.imageTab}
                  className={classes.imageTab}
                />
                            )}
                {tab.imageTab && tab.imageTabActive && (
                <Image
                  {...tab.imageTabActive}
                  className={cx(classes.imageTab, 'is-active')}
                />
                            )}
                {tab.imageTab ? <i>{tab.text}</i> : <span>{tab.text}</span>}
              </button>
            </li>
                ))}
        </ul>
        <div className={classes.contents}>
          {tabs[newCurrentTab]?.contents.map((content, index) => (
            <MarkdownStyled
              key={`practicetabs-content-${index}`}
              className={cx(classes.content, currentContent === index && 'is-active')}
              text={content}
              onClick={(e) => handleClickContent(e, index)}
              lexicon={lexicon}
              markHover={markHover}
            />
                ))}
        </div>
        <div className={classes.legend}>
          {tabs[newCurrentTab] && (
            <h3 className={classes.textLegend}>
              <Image
                {...tabs[newCurrentTab].imageTabBlack}
                className={classes.imageLegend}
              />
              {tabs[newCurrentTab].text}
            </h3>
                )}
        </div>
      </div>

    ) : null
}

PracticeTabs.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.string),
    id: PropTypes.string,
    currentTab: PropTypes.number,
    currentContent: PropTypes.number,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            imageTab: PropTypes.shape(Image.propTypes),
            imageTabActive: PropTypes.shape(Image.propTypes),
            imageTabBlack: PropTypes.shape(Image.propTypes),
            text: PropTypes.string,
            contents: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    lexicon: PropTypes.arrayOf(PropTypes.object),
    handleClickTab: PropTypes.func,
    callbackClickContent: PropTypes.func,
    markHover: PropTypes.func,
}

PracticeTabs.defaultProps = {
    className: null,
    classes: null,
    id: null,
    tabs: null,
    lexicon: null,
    currentTab: 0,
    currentContent: 0,
    handleClickTab: () => null,
    callbackClickContent: () => null,
    markHover: () => null,
}

export default withMemo()(PracticeTabs)

