import { colors } from '../../theme'


export default {
  container: {
    position: 'relative',
    display: 'block',
    width: '24.4rem',
    height: '20rem',
    borderRadius: '1rem',
    overflow: 'hidden',
    color: colors.pureBlack,
    backgroundColor: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseEnvironment
          : props.variant === 'listen' ? colors.orangeListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.greyF6),
  },
  icon: {
    position: 'absolute',
    bottom: '1rem',
    right: '1.5rem',
    width: '2.3rem',
    height: '0.7rem',
    color: (props) => (
      props.variant === 'default' ? colors.turquoiseDark
        : 'currentColor'),
    transition: 'transform 200ms ease-out',
    '$container:hover &': {
      transform: 'translateX(0.25rem)',
    },
  },
  '@media print': {
    container: {
      width: 'calc(100vw / 3 - 3rem)',
    },
    icon: {
      display: 'none',
    },
  },
}
