import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import debounce from 'lodash/debounce'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import { safeCall } from '../../helpers/React'
import A from '../A'
import Image from '../Image'
import Icon from '../Icon'
import icons from '../Icon/assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Header = (props) => {
  const { classes: classesProp,
    className,
    urlHome,
    logoMain,
    logoSPF,
    logoRF,
    urlSPF,
    urlRF,
    navMain,
    navSecondary,
    search,
    onOpenMenu,
    textOpenMenu,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  // state

  const [isFixed, setFixed] = useState(false)

  // handlers
  const handleOpenMobileMenu = useCallback(() => {
    safeCall(onOpenMenu)
  }, [onOpenMenu])

  const handleMenuThreshold = useCallback(
    debounce(() => {
      if (window.pageYOffset > 60) {
        setFixed(true)
      } else {
        setFixed(false)
      }
    }, 15),
    []
  )

  // effects
  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleMenuThreshold()
      window.addEventListener('resize', handleMenuThreshold)
      window.addEventListener('scroll', handleMenuThreshold)
    }
    return () => {
      try {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleMenuThreshold)
          window.removeEventListener('scroll', handleMenuThreshold)
        }
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [handleMenuThreshold])

  // renderers
  const renderLogo = useMemo(() => logoMain && (
    <div className={classes.logoMain}>
      <A
        href={urlHome}
      >
        <Image
          src={logoMain.src}
          alt={logoMain.alt}
        />
      </A>
    </div>
  ), [classes, logoMain, urlHome])

  return (
    <>
      <header
        className={cx(classes.container, className, isFixed && 'is-fixed')}
        role="heading"
        aria-level="1"
      >
        <div className={cx(classes.wrapper, classes.wrapperTop)}>
          <div className={classes.logos}>
            {logoRF && (
              <A
                href={urlRF}
                target="_blank"
              >
                <Image
                  className={classes.logoRF}
                  src={logoRF.src}
                  alt={logoRF.alt}
                />
              </A>
            )}
            {logoSPF && (
              <A
                href={urlSPF}
                target="_blank"
              >
                <Image
                  className={classes.logoSPF}
                  src={logoSPF.src}
                  alt={logoSPF.alt}
                />
              </A>
            )}
            {renderLogo}
          </div>
        </div>
        <div className={cx(classes.wrapper, classes.wrapperBottom)}>
          <div className={classes.left}>
            <button
              type="button"
              className={classes.menuTrigger}
              onClick={handleOpenMobileMenu}
            >
              <Icon icon={icons.Burger} />
              <i>{textOpenMenu}</i>
            </button>
            {renderLogo}
            {navMain}
          </div>
          <div className={classes.right}>
            {search}
            {navSecondary}
          </div>
        </div>
      </header>
      <div
        className={classes.spacer}
        aria-hidden="true"
      />
    </>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  urlHome: PropTypes.string,
  urlSPF: PropTypes.string,
  urlRF: PropTypes.string,
  logoMain: PropTypes.shape(Image.propTypes),
  logoSPF: PropTypes.shape(Image.propTypes),
  logoRF: PropTypes.shape(Image.propTypes),
  search: PropTypes.node,
  navMain: PropTypes.node,
  navSecondary: PropTypes.node,
  onOpenMenu: PropTypes.func,
  textOpenMenu: PropTypes.string,
}

Header.defaultProps = {
  className: null,
  classes: null,
  urlHome: null,
  urlSPF: null,
  urlRF: null,
  logoMain: null,
  logoSPF: null,
  logoRF: null,
  search: null,
  navMain: null,
  navSecondary: null,
  onOpenMenu: () => null,
  textOpenMenu: null,
}

export default withMemo()(Header)
