import { colors, desktop, smallest, mobile } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    // height: '100%',
    background: colors.greyF6,
    borderRadius: '0.8rem',
  },
  sliderTitle: {
    flex: '0 0 auto',
    margin: 0,
    padding: '1.5rem 1rem 1rem',
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 900,
    lineHeight: 1.15,
    textTransform: 'uppercase',
  },
  figure: {
    flex: '0 0 auto',
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    padding: '2.5rem',
  },
  title: {
    margin: 0,
    fontSize: '1.7rem',
    lineHeight: 1.15,
    textAlign: 'center',
    textTransform: 'uppercase',
    '& h4': {
      fontSize: '1.6rem !important',
    },
  },
  text: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    position: 'relative',
    width: '100%',
    minHeight: '100px',
    fontSize: '1.7rem',
    lineHeight: 1.6,
    textAlign: 'center',
    '&, & > *': {
      lineHeight: '1.6 !important',
    },
    '&:not(:first-child)': {
      marginTop: '1.5rem',
    },
    '& p': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  button: {
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  number: {
    textAlign: 'center',
    fontSize: '1.8rem',
    lineHeight: 1.15,
    '&:not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  ...smallest({
    figure: {
      height: '145px',

      '& img': {
        height: '100%',
        width: 'auto',
      },
    },
    title: {
      minHeight: '50px',
    },
    text: {
      maxWidth: '38rem',
      marginLeft: 'auto',
      marginRight: 'auto',
      '& p': {
        fontSize: '1.5rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      },
    },
    button: {
      alignSelf: 'center',
      minWidth: '25rem',
    },
  }),
  ...mobile({
    figure: {
      maxHeight: '240px',
      height: 'auto',
    },
    container: {
      height: '100%',
    },
    sliderTitle: {
      display: 'none',
    },
    content: {
      height: '100%',
      justifyContent: 'space-evenly',
    },
  }),
  ...desktop({
    container: {
      borderRadiusBottomLeft: '40px',
      borderRadiusBottomRight: '40px',
      boxShadow: 'none',
      height: '100%',
    },
    figure: {
      maxHeight: '240px',
      height: 'auto',
    },
    sliderTitle: {
      fontSize: '2.4rem',
      padding: '2.5rem 2rem 2rem',
    },
    content: {
      padding: '3rem 8rem',
      justifyContent: 'space-evenly',
    },
    title: {
      fontSize: '1.6rem',
      minHeight: '40px',
    },
    text: {
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: '1.25rem',
      marginTop: '0!important',
      // '&:not(:first-child)': {
      //     marginTop: '3rem',
      // },
      '& p': {
        fontSize: '1.5rem',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
      },
    },
    button: {
      '&:not(:first-child)': {
        marginTop: '1rem',
      },
      fontSize: '1.5rem!important',
      minHeight: '3rem!important',
    },
    number: {
      fontSize: '1.2rem',
      '&:not(:first-child)': {
        marginTop: '3rem',
        marginBottom: '3rem',
      },
    },
  }),
  '@media print': {
    container: {
      display: 'block',
      maxWidth: 'calc(100% /4)',
      marginLeft: 'auto',
      marginRight: 'auto',
      breakInside: 'avoid',
      pageBreakInside: 'avoid',
    },
    figure: {
      height: 'auto',
      // all images hidden, code above was from before and kept in case
      display: 'none',
    },
    button: {
      '&:not(:first-child)': {
        marginTop: '0.5rem',
      },
    },
  },
  '@media print and (min--moz-device-pixel-ratio:0)': {
    container: {
      maxWidth: '80%',
    },
  },
}
