import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import Image from '../Image'
import Icon from '../Icon'
import icons from '../Icon/assets'
import FooterMenuSub from '../FooterMenuSub'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FooterMenu = (props) => {
  const {
    classes: classesProp,
    className,
    links,
    logo,
    urlHome,
    textOpenMenu,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const [openMenus, setOpenMenus] = useState([])

  const handleToggleSub = useCallback((index) => {
    const newOpenMenus = openMenus.includes(index) ? openMenus.filter((item) => item !== index) : [...openMenus, index]

    setOpenMenus(newOpenMenus)
  }, [openMenus])

  // set open menu
  useEffect(() => {
    const openLevelOnes = links
    ?.reduce((newArray, levelOne, index) => {
      if (levelOne?.isOpen === true) {
        newArray.push(index)
      }
      return newArray
    }, [])

    setOpenMenus(openLevelOnes)
  }, [links])

  const renderLinks = useMemo(() => links && (
    <ul className={classes.list}>
      {links.map((levelOne, index) => {
        const count = levelOne?.sub?.length

        return (
          <li
            className={cx(classes.levelOne, count && count >= 7 && 'is-large', !count && 'is-small', openMenus.includes(index) && 'is-open')}
            key={`footermenu-links-${index}`}
          >
            <div className={classes.levelOneTitle}>
              <A
                {...levelOne?.linkProps}
                className={cx(classes.levelOneLink, levelOne.isCurrent === true && 'is-current')}
                dangerouslySetInnerHTML={{ __html: levelOne?.text }}
              />
              {levelOne.sub?.length > 0 && (
                <button
                  className={classes.levelOneOpen}
                  type="button"
                  onClick={() => handleToggleSub(index)}
                >
                  <Icon
                    icon={icons.ChevronSquareDown}
                    className={classes.levelOneOpenIcon}
                  />
                  <i>{textOpenMenu}</i>
                </button>
              )}
            </div>
            {levelOne.sub?.length > 0 && (
              <FooterMenuSub
                links={levelOne.sub}
                isOpen={openMenus.includes(index)}
                isLarge={Boolean(count && count >= 7)}
                rowsNumber={Math.ceil(count / 2)}
              />
            )}
          </li>
        )
      })}
    </ul>
  // eslint-disable-next-line max-len
  ), [classes.levelOne, classes.levelOneLink, classes.levelOneOpen, classes.levelOneOpenIcon, classes.levelOneTitle, classes.list, handleToggleSub, links, openMenus, textOpenMenu])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.logo}>
          <A
            href={urlHome}
          >
            <Image {...logo} />
          </A>
        </div>
        {renderLinks}
      </div>
    </div>
  )
}

FooterMenu.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    linkProps: PropTypes.shape(A.propTypes),
    isCurrent: PropTypes.bool,
    isOpen: PropTypes.bool,
    sub: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      linkProps: PropTypes.shape(A.propTypes),
      isCurrent: PropTypes.bool,
    })),
  })),
  urlHome: PropTypes.string,
  logo: PropTypes.shape(Image.propTypes),
  textOpenMenu: PropTypes.string.isRequired,
}

FooterMenu.defaultProps = {
  className: null,
  classes: null,
  links: null,
  urlHome: null,
  logo: null,
}

export default withMemo()(FooterMenu)
