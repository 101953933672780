import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import withMemo from '../../../decorators/withMemo'
import BlockLandingSliders from '../../../components/BlockLandingSliders'
import LandingSlider from '../../../components/LandingSlider'

import { pageLandingSliderTransformer } from './transformer'


const PageLandingSlider = (props) => {
  const { t } = useTranslation()
  const sliders = props?.fieldSliders ?? []

  return (
    <>
      {sliders.length && (
        <BlockLandingSliders>
          {sliders.map((slider, index) => (
            <LandingSlider
              key={`slider_${index}`}
              {...pageLandingSliderTransformer(slider, t)}
            />
          ))}
        </BlockLandingSliders>
      )}
    </>
  )
}

PageLandingSlider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fieldSliders: PropTypes.array,
}

PageLandingSlider.defaultProps = {
  fieldSliders: [],
}

export default withMemo()(PageLandingSlider)
