import { generateAkamaiUrl } from '../../../helpers/Akamai'


export const pageLandingSliderItemsTransformer = (items, t) => items.map((item) => {
        const slide = item?.entity

        let button = null
        const fieldFile = slide?.fieldFile ?? null
        const fieldLink = slide?.fieldLink ?? null

        if (fieldFile) {
            button = {
                linkProps: {
                    href: generateAkamaiUrl(fieldFile?.entity?.url ?? '#'),
                    target: '_blank',
                },
                text: t('downloadDocument'),
            }
        } else if (fieldLink) {
            button = {
                linkProps: {
                    href: fieldLink?.url?.path ?? '#',
                    target: '_blank',
                },
                text: fieldLink?.title,
            }
        }

        return {
            sliderTitle: slide?.fieldSubtitle ?? '',
            title: slide?.fieldTitle ?? '',
            image: {
                src: generateAkamaiUrl(slide?.fieldImage?.entity?.url ?? ''),
            },
            text: slide?.fieldBody?.processed ?? '',
            button,
        }
    })

export const pageLandingSliderTransformer = (slider, t) => {
    const sliderItems = slider?.entity?.fieldSliderItems ?? []

    return {
        textPrevious: t('textPrevious'),
        textNext: t('textNext'),
        slides: pageLandingSliderItemsTransformer(sliderItems, t),
    }
}

