const API_URL = process.env.API_URL
const API_URL_CLIENT = process.env.API_URL_CLIENT || API_URL
const GRAPH_API_URL = process.env.GRAPH_API_URL || `${API_URL}/graphql`
const GRAPH_API_URL_CLIENT = process.env.GRAPH_API_URL_CLIENT || `${API_URL_CLIENT}/graphql`
const BACKEND_URL = process.env.BACKEND_URL
const API_BASIC_AUTH = process.env.API_BASIC_AUTH

export default {
  API_URL: process.browser ? API_URL_CLIENT : API_URL,
  GRAPH_API_URL: process.browser ? GRAPH_API_URL_CLIENT : GRAPH_API_URL,
  BACKEND_URL,
  API_BASIC_AUTH,
}
