import gql from 'graphql-tag'

// Fragments
import { LinkFragment } from '../Fragments/LinkFragment'


export const MenuByName = gql`    
    query MenuByName(
        $footer: String!
        $menu: String!
        $headerRight: String!
        $footerTop: String!
        $search: String!
    ) {
        search: menuByName(name: $search) {
            links{
                ... on MenuLink{
                    ...LinkFragment
                }
            }
        }
        menu: menuByName(name: $menu) {
            links{
                ... on MenuLink{
                    ...LinkFragment
                }
            }
        }
        headerRight: menuByName(name: $headerRight) {
            links{
                ... on MenuLink{
                    ...LinkFragment
                }
            }
        }
        footerTop: menuByName(name: $footerTop) {
            links{
                ... on MenuLink{
                    ...LinkFragment
                }
            }
        }
        footer: menuByName(name: $footer) {
            links{
                ... on MenuLink{
                    ...LinkFragment
                }
            }
        }
    }

    # Fragments
    ${LinkFragment.fragment}
`
