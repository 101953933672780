import slugify from 'slugify'


const config = require('../../configuration')


export const subMenuTransformer = (item, index = 0, parent = null) => ({
  text: (item?.label ?? '').replace('|', '<br />'),
  slug: slugify(item?.label.replace('|', '').toLowerCase() ?? ''),
  linkProps: {
    href: (item?.url?.path && item?.url?.path !== '') ? item.url.path : (parent ? `${parent}#menu${index}` : null),
    // preventDefaultOnClick: false,
  },
  sub: item?.links?.map((link, subIndex) => subMenuTransformer(link, subIndex, parent)) ?? [],
})

export const footerTransformer = (data) => data && data?.map((item) => ({
  text: (item?.label ?? '').replace('|', '<br />'),
  sub: item?.links?.map((link, index) => subMenuTransformer(link, index)) ?? [],
  linkProps: { href: item?.url?.path ?? null, scroll: true },
}) ?? [])

export const variantTransformer = (item) => {
  let variant

  switch (item) {
    case 'item1':
      variant = 'care'
      break
    case 'item2':
      variant = 'environment'
      break
    case 'item3':
      variant = 'listen'
      break
    case 'item4':
      variant = 'discover'
      break
    case 'item5':
      variant = 'questions'
      break
    default:
      variant = 'default'
  }

  return variant
}

export const menuTransformer = (data) => {
  if (!data && typeof data !== 'object') {
    return []
  }

  return data.map((item) => {
    const slug = slugify(item?.label.replace('|', '').toLowerCase() ?? '')
    const variant = config?.variants?.slugs[slug] ?? null

    return {
      text: (item?.label ?? '').replace('|', '<br />'),
      slug,
      variant: variant || variantTransformer(item?.color ?? ''),
      linkProps: { href: item?.url?.path ?? null },
      sub: {
        title: (item?.label ?? '').replace('|', '<br />'),
        text: item?.summary ?? '',
        variant: variantTransformer(item?.color ?? ''),
        links: item?.links?.map((link, index) => subMenuTransformer(link, index, item?.url?.path ?? null)) ?? [],
      },
    }
  })
}


export const linksTransformer = ({ nodeQuery }) => nodeQuery && nodeQuery?.entities?.map((item) => ({
  url: item?.entityUrl?.path ?? null,
  type: `${item?.fieldTemplate?.entity?.__typename ?? ''}`.replace('ParagraphTemplate', ''),
}))
