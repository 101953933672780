

export const satelliteAncreTransformer = (item) => ({
  id: item?.uuid ?? '',
  title: item?.fieldTitreAncre ?? '',
  text: item?.fieldDescriptionAncre?.value ?? '',
  variant: 'default',
  files: item?.fieldFiles?.map((file) => {
    const filename = file?.entity?.filename ?? ''
    const description = file?.description ?? ''

    return {
      label: (description !== '') ? description : filename,
      url: file?.entity?.url ?? null,
    }
  }).filter((file) => file.url !== null) ?? [],
})
