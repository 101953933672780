import { colors, grids } from '../../theme'


export default {
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1000',
  },
  popin: {
    position: 'relative',
    paddingBlock: '20px',
    width: '474px',
    backgroundColor: 'white',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    paddinInline: 'auto',
    '@media (max-width: 840px)': {
      width: '55%',
    },
    '@media (max-width: 560px)': {
      width: '80%',
    },
  },
  btnPopinLink: {
    padding: '1.3rem 2rem',
    display: 'inline-block',
    backgroundColor: colors.blue,
    color: 'white',
    fontSize: '20px',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
  popinHeader: {
    alignItems: 'center',
    paddingInline: '20px',
  },
  popinHeaderTitle: {
    width: '80%',
    marginBlock: '0',
    marginInline: 'auto',
    textAlign: 'center',
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#3333ff',
    '@media (max-width: 420px)': {
      textAlign: 'left',
    },
  },
  btnClose: {
    position: 'absolute',
    top: '-1px',
    right: '0px',
    width: '45px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    '& > span': {
      width: '100%',
      transform: 'scale(0.7)',
    },
  },
  iconClose: {
    cursor: 'pointer',
    width: '80%',
  },
  imgContainer: {
    width: '50%',
    marginInline: 'auto',
    '& img': {
      width: '100%',
    },
  },
  popinText: {
    display: 'block',
    paddingBlock: '.3rem',
    color: colors.blue,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  popinFooter: {
    marginTop: '1rem',
    position: 'relative',
    height: '2rem',
    paddingInline: '20px',
  },
  popinBody: {
    paddingInline: '20px',
    '& > * ': {
      marginBottom: '2rem',
    },
    '& .img-container': {
      width: '50%',
      marginInline: 'auto',
      '& img': {
        width: '100%',
      },
    },
    "& > *:not('.img-container')": {
      paddingInline: '20px',
    },
  },
  btnDoNotAskAgain: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    position: 'absolute',
    left: '20px',
    fontSize: '12px',
    color: '#666666',
  },
}
