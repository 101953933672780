import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'share'

//
// Initial state
//
const initialState = {
  video: null,
}

//
// Actions
//
export const actions = {
  setVideo: createAction('ShareVideo: setVideo', (video) => ({ video })),

}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setVideo]: (state, { video }) => Immutable.static.merge(state, { video }),
  },
  initialState
)

//
// Selectors
//

const root = (state) => state[key]
const video = (state) => root(state).video


export const selectors = {
  video,
}
