import { grids, smallest, tablet } from '../../theme'


export default {
  container: {
    position: 'relative',
    marginTop: '6rem',
    marginBottom: '10rem',
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    ...smallest({
      flexFlow: 'column',
    }),
    ...tablet({
      flexFlow: 'row nowrap',
    }),
  },
  navigation: {
    flex: '0 0 auto',
    width: '2rem',
  },
  content: {
    flex: '0 0 auto',
    width: 'calc(100% - 2rem - 2rem)',
  },
  ...smallest({
    navigation: {
      width: '100%',
    },
  }),
  ...tablet({
    wrapper: {},
    navigation: {
      position: 'sticky',
      top: '10rem',
      width: 'calc(100% / 12 * 3)',
      paddingRight: '6rem',
    },
    content: {
      width: 'calc(100% / 12 * 9)',
    },
  }),
  '@media print': {
    container: {
      marginTop: '2rem',
    },
    content: {
      width: '100%',
    },
  },
}
