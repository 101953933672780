import { colors, tablet, smallest } from '../../theme'


export default {
  container: {},
  list: {},
  item: {
    ...tablet({
    '&:not(:first-child)': {
      marginTop: '4.2rem',
    },
    }),
  },
  letter: {
    display: 'block',
    padding: '0.5rem',
    lineHeight: 1,
    fontWeight: 'bold',
    fontSize: '2.4rem',
    color: colors.pureBlack,
    cursor: 'pointer',
    transition: 'color 200ms ease-out',
    '&:not(.is-notlink):hover': {
      color: colors.blueSteel,
      textDecoration: 'underline',
    },
    '&.is-current': {
      color: colors.blue,
    },
    '&.is-notlink': {
      color: colors.grey95,
      cursor: 'default',
    },
  },
  ...smallest({
    list: {
      width: '100%',
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',

      '&::after': {
        content: '""',
        flex: 'auto',
      },
    },
    letter: {
      padding: '0.8rem 1rem',
    },
    container: {
      marginBottom: '3rem',
    },
  }),
  ...tablet({
    list: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'flex-start',
    },
    item: {
      '&, &:not(:first-child)': {
        marginTop: '1rem',
        width: 'calc(100% / 6)',
      },
    },
  }),
  '@media print': {
    container: {
      display: 'none',
    },
  },
}
