import slugify from 'slugify'
import React from 'react'

import { variantTransformer } from '../../../redux/menu/transformers'
import FontsResizer from '../../../components/FontsResizer'
import ShareLinks from '../../../components/ShareLinks'
import { shareLinksTransformer } from '../../../transformers'


export const lexiqueViewTransformer = (terms, current, currentLetter) => ({
    currentTerm: current,
    currentLetter,
    terms,
  })

export const termsTransformer = (data) => (data?.lexiqueView?.results?.map((term) => ({
  term: term?.name ?? '',
  id: slugify(term?.name ?? ''),
  text: term?.description?.value ?? '',
  isOpen: false,
  writable: true,
})))


export const headerSimpleLexiqueTransformer = (params, t) => ({
  title: t('lexiqueTitle'),
  variant: variantTransformer(''),
  zoneTools:
  <>
    <FontsResizer />
    <ShareLinks {...shareLinksTransformer({ title: t('lexiqueTitle'), url: params?.url ?? null }, t)} />
  </>,
})
