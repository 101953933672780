import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/withMemo'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const MarkdownStyled = (props) => {
  const classes = useStyles(props)
  const {
    className,
    ...markDownProps
  } = props

  return (
    <MarkdownText
      {...markDownProps}
      className={cx(classes.container, className)}
    />
  )
}

MarkdownStyled.propTypes = {
  className: PropTypes.string,
}

MarkdownStyled.defaultProps = {
  className: null,
}

export default withMemo()(MarkdownStyled)
