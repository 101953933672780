const entities = [
  // Externals
  {
    key: 'loadingBar',
    reducer: require('react-redux-loading-bar').loadingBarReducer,
  },

  // Pages
  require('../Wrappers/Pages/Page/redux'),
  // Templates
  require('../Wrappers/Templates/ArticleWrapper/redux'),
  require('../Wrappers/Templates/OutilWrapper/redux'),
  require('../Wrappers/Templates/RubriqueWrapper/redux'),
  require('../Wrappers/Templates/HomeWrapper/redux'),
  require('../Wrappers/Templates/RechercheWrapper/redux'),
  require('../Wrappers/Templates/QuestionsDuMomentWrapper/redux'),
  require('../Wrappers/Templates/ShareVideoWrapper/redux'),

  // Global
  require('./i18n/redux'),
  require('./menu/redux'),
  require('./api/redux'),
  require('./app/redux'),
  require('./lexicon/redux'),
  require('./tracking/redux'),
]


















// Make sure all entities have a defined key
const reducers = entities.reduce((acc, entity) => {
  acc[entity.key] = entity.reducer
  return acc
}, {})

export default reducers
