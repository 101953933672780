import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FocusSmall = (props) => {
  const {
    classes: classesProp,
    className,
    text,
    // eslint-disable-next-line no-unused-vars
    variant,
    lexicon,
    transformLexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <MarkdownStyled
      className={cx(classes.container, className)}
      text={text}
      variant={variant}
      tag="aside"
      lexicon={lexicon}
      markHover={markHover}
      transformLexicon={transformLexicon}
    />
  )
}

FocusSmall.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  transformLexicon: PropTypes.bool,
  markHover: PropTypes.func,
}

FocusSmall.defaultProps = {
  className: null,
  classes: null,
  text: '',
  lexicon: null,
  transformLexicon: true,
  markHover: () => null,
}

export default withMemo()(FocusSmall)
