import gql from 'graphql-tag'


export const Configuration = gql`
    query configuration {
        nodeQuery(filter: {conditions: [{field: "type", value: ["configuration"], operator: IN}]}) {
            entities {
                ... on NodeConfiguration {
                    fieldTwitterAccount
                    fieldFacebookAccount
                    fieldInstagramAccount
                    fieldTranslations
                }
            }
        }
    }
`
