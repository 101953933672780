import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import withMemo from '../../../decorators/withMemo'
import HeaderSearchResults from '../../../components/HeaderSearchResults'
import SearchResults from '../../../components/SearchResults'
import SearchResult from '../../../components/SearchResult'
import routes, { Router } from '../../../routes'
import { actions as PageActions, selectors as PageSelectors } from '../../Pages/Page/redux'
import { selectors as I18nSelectors } from '../../../redux/i18n/redux'
import { selectors as AppSelectors } from '../../../redux/app/redux'
import { tcEventEnum, useTagCommander } from '../../../utils/hooks/useTagCommander'

import { actions as SearchActions, selectors as SearchSelectors } from './redux'
import { headerSearchResultsTransformer, searchResultInpracticeProps, searchResultProps } from './transformers'


function RechercheWrapper() {
    const { handleEventTC } = useTagCommander()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const slug = useSelector(PageSelectors.slug)
    const breadcrumb = useSelector(PageSelectors.breadcrumb)
    const locale = useSelector(I18nSelectors.locale)
    const terms = useSelector(SearchSelectors.terms)
    const tools = useSelector(SearchSelectors.tools)
    const route = useSelector(AppSelectors.route)
    const all = useSelector(SearchSelectors.all)
    const page = useSelector(SearchSelectors.page)
    const perPage = useSelector(SearchSelectors.perPage)

    useEffect(() => {
        if (breadcrumb.length === 1) {
            const searchBreadcrumb = breadcrumb.concat({
                linkProps: {
                    href: `/${locale}/${slug}`,
                },
                slug,
                text: t('textSearch'),
            })

            dispatch(PageActions.setBreadcrumb(searchBreadcrumb))
        }
    }, [breadcrumb, dispatch, locale, slug, t])

    const onSearch = useCallback((values) => {
        handleEventTC(
            tcEventEnum.ACTION_SEARCH,
            {
                data: {
                    searchTerm: values,
                },
            }
        )
        Router.pushRoute(Router.getRouteUrl(routes.page, { slug, locale, search_api_fulltext: values }))
    }, [handleEventTC, locale, slug])

    const onPaginate = useCallback(() => {
        dispatch(SearchActions.search({ q: terms, page: (page + 1) }))
    }, [dispatch, page, terms])

    // eslint-disable-next-line max-len
    const headerSearchResultsProps = useMemo(() => headerSearchResultsTransformer(route, tools, all, terms, onSearch, t, perPage), [route, all, onSearch, perPage, t, terms, tools])
    const zoneInPractice = useMemo(() => tools?.documents?.map((tool) => searchResultInpracticeProps(tool, t, terms)), [tools, t, terms])
    const zoneResults = useMemo(() => all?.documents?.map((item) => searchResultProps(item, t, terms)), [all.documents, t, terms])
    const hasMoreResult = useMemo(() => all && all.documents.length < all.result_count, [all])

    return (
      <>
        <HeaderSearchResults {...headerSearchResultsProps} />
        <SearchResults
          textMoreResults={t('textMoreResults')}
          hasMoreResults={hasMoreResult}
          moreResultsHandler={onPaginate}
          zoneInPractice={zoneInPractice && zoneInPractice.map((tool, index) => (
            <SearchResult
              position={index + 1}
              key={`res-${index}`}
              {...tool}
            />
                ))}
          zoneResults={zoneResults && zoneResults.map((item, index) => (
            <SearchResult
              position={zoneInPractice.length + index + 1}
              key={`res-zone-${index}`}
              {...item}
            />
                ))}
        />
      </>
    )
}

RechercheWrapper.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
}

RechercheWrapper.defaultProps = {}

export default withMemo()(RechercheWrapper)
