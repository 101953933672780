import { colors, fonts, tablet } from '../../theme'


export default {
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(1.1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  container: {
    overflow: 'hidden',
    marginTop: '2rem',
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '2rem',
    },
    ...tablet({
      marginTop: '3rem',
      // penultimate child has a margin bottom because last is lexicon container
      '&:nth-last-child(2)': {
        marginBottom: '3rem',
      },
    }),
  },
  wrapper: {
    maxWidth: '120rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    ...tablet({
      display: 'flex',
      flexFlow: 'row nowrap',
      maxHeight: '60rem',
      background: colors.greyF6,
      '& > *': {
        flex: '0 0 auto',
        width: '50%',
      },
    }),
  },
  mapWrapper: {
    '& > .animate-enter': {
      opacity: 0,
    },
    '& > .animate-enter-active': {
      opacity: 1,
      transition: 'opacity 300ms ease-out 300ms',
    },
    '& > .animate-exit': {
      opacity: 1,
      position: 'absolute',
    },
    '& > .animate-exit-active': {
      opacity: 0,
      transition: 'opacity 0ms ease-out',
    },
  },
  contentWrapper: {
    ...tablet({
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    }),
  },
  content: {
    height: '100%',
    '&.animate-enter': {
      opacity: 0,
    },
    '&.animate-enter-active': {
      opacity: 1,
      transition: 'opacity 300ms ease-out 300ms',
    },
    '&.animate-exit': {
      opacity: 1,
      position: 'absolute',
    },
    '&.animate-exit-active': {
      opacity: 0,
      transition: 'opacity 0ms ease-out',
    },
    ...tablet({
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    }),
  },
  noContent: {
    ...fonts.MaaxBold,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '20rem',
    padding: '2rem 4rem',
    textTransform: 'uppercase',
    fontSize: '1.7rem',
    textAlign: 'center',
    background: colors.greyF6,
    // html code should be inherited from the original wording
    '& span': {
      margin: '0 !important',
    },
    '& i.point': {
      display: 'inline-block',
      width: '18px',
      height: '18px',
      background: colors.redInfo,
      border: `4px solid ${colors.pureWhite}`,
      borderRadius: '50%',
      boxShadow: `0 0 0 4px ${colors.redInfo}`,
      margin: '0 0.4em',
      animation: '$pulsate 0.6s ease-out infinite alternate both',
    },
    ...tablet({
      flex: 1,
    }),
  },
}
