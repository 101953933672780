import { colors, desktop, fonts, grids, tablet } from '../../theme'
import { smallest } from '../../theme/mixins'


export default {
  container: {
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '6rem',
    },
  },
  // title and infos
  main: {
    position: 'relative',
  },
  mainWrapper: {
    display: 'flex',
    flexFlow: 'column-reverse nowrap',
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  titleZone: {
    position: 'relative',
    zIndex: 2,
    flex: '1 1 auto',
    padding: '0 2rem',
    marginTop: '-9rem',
  },
  titleWrapper: {
    position: 'relative',
    maxWidth: '45rem',
    width: 'min-content',
    margin: '0 auto',
    padding: '3.5rem',
    color: (props) => (
      props.variant === 'care' ? colors.yellowCare
        : props.variant === 'environment' ? colors.blueDarkEnvironment
          : props.variant === 'listen' ? colors.blueListen
            : props.variant === 'discover' ? colors.yellowDiscover
              : colors.pureWhite),
    backgroundColor: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseEnvironment
          : props.variant === 'listen' ? colors.orangeListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
  },
  title: {
    ...fonts.MaaxBold,
    position: 'relative',
    padding: '2.5rem 0',
    margin: 0,
    lineHeight: '1.1',
    fontSize: '2rem',
    color: 'inherit',
    textTransform: 'uppercase',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      height: '0.5rem',
      width: '6rem',
      backgroundColor: 'currentColor',
    },
    '&::before': {
      top: 0,
      left: 0,
    },
    '&::after': {
      bottom: 0,
      left: 0,
    },
  },
  toolsZone: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  // image
  figure: {
    position: 'relative',
    zIndex: 1,
    margin: 0,
    width: '100%',
  },
  image: {
    height: 0,
    paddingTop: '48.8027366020525%',
    '& > *': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  // summary and sharing
  summary: {
    marginTop: '4rem',
  },
  summaryWrapper: {
    display: 'flex',
    flexFlow: 'column-reverse nowrap',
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  sharingZone: {
    display: 'none',
  },
  text: {
    fontSize: '1.9rem',
    '& > :not(:first-child)': {
      marginTop: '1rem',
    },
  },
  ...smallest({
    title: {
      fontSize: '2.5rem',
    },
  }),
  ...tablet({
    mainWrapper: {
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    titleZone: {
      flex: '0 0 auto',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      width: 'calc(100% / 12 * 3)',
      padding: '0',
      marginTop: '0',
    },
    titleWrapper: {
      width: '45rem',
      paddingTop: '4rem',
      paddingBottom: '4rem',
    },
    title: {
      width: 'auto',
      fontSize: '3.4rem',
      marginLeft: 0,
      '&::before, &::after': {
        width: '11.5rem',
      },
    },
    toolsZone: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      right: 0,
      padding: '2rem',
      '& > *:not(:first-child)': {
        display: 'none',
      },
    },
    figure: {
      flex: '0 0 auto',
      width: 'calc(100% / 12 * 9)',
    },
    summary: {
      marginTop: '6rem',
    },
    summaryWrapper: {
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
    },
    sharingZone: {
      flex: '0 0 auto',
      display: 'block',
      width: 'calc(100% / 12 * 3 )',
      padding: '0 2rem',
    },
    text: {
      flex: '0 0 auto',
      width: 'calc(100% / 12 * 9)',
      fontSize: '1.6rem',
      padding: '0 2rem',
      '&:first-child': {
        marginLeft: 'auto',
      },
    },
  }),
  ...desktop({
    titleWrapper: {
      paddingTop: '6rem',
      paddingBottom: '6rem',
      paddingLeft: '5rem',
    },
  }),
  '@media print': {
    sharingZone: {
      display: 'none',
    },
    toolsZone: {
      display: 'none',
    },
    figure: {
      display: 'none',
    },
    mainWrapper: {
      display: 'block',
    },
    titleZone: {
      maxWidth: 'none',
      width: 'auto',
      marginTop: 0,
    },
    titleWrapper: {
      margin: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      maxWidth: 'none',
      width: 'auto',
    },
    title: {
      paddingBottom: 0,
      '&::before, &::after': {
        display: 'none',
      },
    },
  },
}
