import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import { safeCall } from '../../helpers/React'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavMainLink = (props) => {
  const {
    classes: classesProp,
    className,
    linkProps,
    text,
    isActive,
    isOpen,
    mouseEnterHandler,
    mouseLeaveHandler,
    // eslint-disable-next-line no-unused-vars
    variant,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const handleMouseEnter = useCallback(() => {
    safeCall(mouseEnterHandler)
  }, [mouseEnterHandler])

  const handleMouseLeave = useCallback(() => {
    safeCall(mouseLeaveHandler)
  }, [mouseLeaveHandler])

  return (
    <A
      {...linkProps}
      className={cx(className,
        classes.container,
        isActive === true && 'is-active',
        isOpen === true && 'is-open')}
      dangerouslySetInnerHTML={{ __html: text }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  )
}

NavMainLink.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  linkProps: PropTypes.shape(A.propTypes),
  text: PropTypes.string,
  isActive: PropTypes.bool,
  isOpen: PropTypes.bool,
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']),
  mouseEnterHandler: PropTypes.func,
  mouseLeaveHandler: PropTypes.func,
}

NavMainLink.defaultProps = {
  className: null,
  classes: null,
  linkProps: null,
  text: null,
  isActive: false,
  isOpen: false,
  variant: 'care',
  mouseEnterHandler: () => null,
  mouseLeaveHandler: () => null,
}

export default withMemo()(NavMainLink)
