import { accessibleHiddenText, colors, fonts, tablet } from '../../theme'


export default {
  container: {
    // no position relative or abso or fixed or the dialog will be positionned relative to it
    ...fonts.MaaxBold,
    color: colors.pureBlack,
  },
  trigger: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '2.8rem',
    height: '3.5rem',
    cursor: 'pointer',
    color: colors.blue,
    '& > i': {
      ...accessibleHiddenText,
    },
    '&::after': {
      content: '""',
      top: '100%',
      left: '0px',
      right: '0px',
      height: '0.3rem',
      position: 'absolute',
      marginTop: '0.5rem',
      transition: 'opacity 100ms ease-out 0s',
      pointerEvents: 'none',
      background: colors.turquoiseQuestions,
      opacity: 0,
    },
    '&:hover::after, &.is-current::after': {
      opacity: 1,
    },
  },
  //
  dialog: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    padding: '4.5rem 2.5rem',
    textAlign: 'center',
    background: colors.turquoise,
    // closed
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 200ms ease-out 100ms',
    // open
    '&.is-open': {
      opacity: 1,
      pointerEvents: 'all',
      transition: 'opacity 200ms ease-out 0ms',
    },
    ...tablet({
      paddingBottom: '3.5rem',
    }),
  },
  form: {
    '& > *:not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  title: {
    display: 'block',
    fontSize: '3rem',
    textTransform: 'uppercase',
    color: colors.blue,
  },
  field: {
    position: 'relative',
    maxWidth: '45rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  input: {
    ...fonts.MaaxRegular,
    display: 'block',
    width: '100%',
    height: '5rem',
    padding: '0 6.5rem',
    border: `1px solid ${colors.blue}`,
    fontSize: '1.8rem',
    color: colors.pureBlack,
    transition: 'all 200ms ease-out',
    '&:focus': {
      boxShadow: '0 0 1rem rgba(0, 0, 0, 0.2)',
    },
    '&::placeholder': {
      color: colors.grey67,
    },
  },
  fieldDecoration: {
    position: 'absolute',
    top: '50%',
    left: '1.5rem',
    display: 'block',
    width: '3.7rem',
    height: '2.8rem',
    color: colors.blue,
    transform: 'translateY(-50%)',
  },
  submit: {
    position: 'absolute',
    top: '50%',
    right: '2rem',
    display: 'flex',
    width: '2.8rem',
    height: '1rem',
    color: colors.blue,
    transform: 'translateY(-50%)',
    '& > i': {
      ...accessibleHiddenText,
    },
  },
  //
  suggestions: {
    '&:not(:first-child)': {
      marginTop: '3.5rem',
    },
    textAlign: 'left',
    ...tablet({
      textAlign: 'center',
    }),
  },
  suggestionsTitle: {
    fontSize: '1.8rem',
  },
  suggestionsList: {
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
    ...tablet({
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      '&:not(:first-child)': {
        marginTop: '1.5rem',
      },
    }),
  },
  suggestionsItem: {
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
    ...tablet({
      '&, &:not(:first-child)': {
        margin: '1rem 2rem',
      },
    }),
  },
  suggestionsLink: {
    textTransform: 'uppercase',
    fontSize: '1.6rem',
    color: colors.blue,
    '&:hover': {
      color: colors.blueTropaz,
    },
  },
}
