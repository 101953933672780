// Important KEEP IN VANILLA JAVASCRIPT
/* eslint-disable no-console */

const bugsnag = require('@bugsnag/js')

const config = require('../configuration')


const browserIgnoreErrors = [
  /^No error$/,
  /__show__deepen/,
  /Access is denied/,
  /anonymous function: captureException/,
  /Blocked a frame with origin/,
  /console is not defined/,
  /DataCloneError/,
  /event is not defined/,
  /feedConf/,
  /MyIPhoneApp/,
  /snapchat.com/,
  /vid_mate_check is not defined/,
  /win\.document\.body/,
  /window\._sharedData\.entry_data/,
  /ztePageScrollModule/,
  // React specific
  /Cannot read property 'className' of undefined/,
  /Permission denied to access property "apply"/,
  /Failed to register a ServiceWorker/,
]

const productionDisableCallback = (callback) => {
  if (config.app.APP_ENV !== 'production') {
    callback()
  }
}

const bugsnagLoggerPrefix = `[bugsnag:${config.app.APP_ENV}]: `

const bugsnagLogger = {
  debug: (e) => productionDisableCallback(() => console.debug(`${bugsnagLoggerPrefix}${e}`)),
  info: (e) => productionDisableCallback(() => console.info(`${bugsnagLoggerPrefix}${e}`)),
  warn: (e) => productionDisableCallback(() => console.warn(`${bugsnagLoggerPrefix}${e}`)),
  error: (e) => productionDisableCallback(() => console.error(`${bugsnagLoggerPrefix}${e}`)),
}

if (config.bugsnag.BUGSNAG_API_KEY) {
  module.exports = bugsnag({
    apiKey: config.bugsnag.BUGSNAG_API_KEY,
    appType: process.browser ? 'browser' : 'node',
    logger: bugsnagLogger,
    releaseStage: config.app.APP_ENV,
    beforeSend: (report) => {
      // Disable reporting of errors with a given error message
      if (browserIgnoreErrors.some((ignoreError) => ignoreError.test(report.errorMessage))) {
        report.ignore()
      }
    },
  })
} else {
  module.exports = null
}
