import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { isDefined, isEmptyObject } from '../../../helpers/TypeHelpers'
import { selectors as PageSelectors } from '../../../Wrappers/Pages/Page/redux'
import { selectors as AppSelector } from '../../../redux/app/redux'

import { matchers } from './matchers'
import { eventHandlers } from './eventHandlers'


const getTcPayloadWithPageDataAndPageInfosAndRoute = (eventData) => {
    const { breadcrumb, pageData, pageInfos, route } = eventData
    return matchers.reduce((resultMapPayload, matcherItem) => {
        const { testFn: hasTcAction, prepareTcPayload } = matcherItem
        if (hasTcAction(pageData, pageInfos, route, breadcrumb)) {
            return ({
                ...resultMapPayload,
                ...prepareTcPayload(pageData, pageInfos, route, breadcrumb),
            })
        }
        return resultMapPayload
    }, {})
}

export const updateTcVarsInWindow = (data) => {
    if (!isDefined(window?.tc_vars) || !isDefined(data) || isEmptyObject(data)) {
        console.warn('updateTcVarsInWindow: cannot update tc_vars in windows')
        return
    }
    console.info('# TagCommander: [data_layer]', data)
    Object.keys(data).forEach((key) => {
        (window?.tc_vars ?? {})[key] = data[key]
    })
    console.info('# TagCommander: [TC_VARS]', window?.tc_vars)
}


export function useTagCommander() {
    const route = useSelector(AppSelector.route)
    const breadcrumb = useSelector(PageSelectors.breadcrumb)
    const pageData = useSelector(PageSelectors.data)
    const pageInfos = useSelector(PageSelectors.pages)

    const eventData = useMemo(() => ({
        pageData,
        pageInfos,
        breadcrumb,
        route,
    }), [pageData, pageInfos, breadcrumb, route])

    const handlePageChangeTC = useCallback(() => {
        const payload = getTcPayloadWithPageDataAndPageInfosAndRoute(eventData)
        updateTcVarsInWindow(payload)
    }, [eventData])

    const handleEventTC = useCallback((enumEventName, { data }) => {
        return eventHandlers.forEach((eventHandler) => {
            const { testFn, action } = eventHandler
            const options = { ...eventData, data }
            if (testFn(enumEventName, options)) {
                action(enumEventName, options)
            }
        })
    }, [eventData])

    return {
        handlePageChangeTC,
        handleEventTC,
    }
}
