import gql from 'graphql-tag'


export const query = (q, variables, headers = {}) => ({
  query: gql`${q}`,
  variables,
  fetchPolicy: 'no-cache',
  errorPolicy: 'all',
  context: {
    headers,
  },
})

