import { colors, fonts, grids, tablet } from '../../theme'


export default {
  container: {
    '&:not(:first-child)': {
      marginTop: '4rem',
    },
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '6rem',
    },
  },
  image: {
    textAlign: 'center',
    fontSize: '1.8rem',
    '& > *:not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  imageTitle: {
    display: 'block',
  },
  imageArrow: {
    display: 'block',
    width: '1.2rem',
    height: '1.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: colors.grey95,
  },
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  menu: {
  },
  content: {
    marginTop: '3rem',
  },
  title: {
    ...fonts.MaaxBold,
    position: 'relative',
    maxWidth: 'max-content',
    margin: 0,
    paddingTop: '2.5rem',
    fontSize: '3.5rem',
    textTransform: 'uppercase',
    lineHeight: 1.1,
    color: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
          : props.variant === 'listen' ? colors.orangeListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      height: '0.5rem',
      width: '11.5rem',
      backgroundColor: 'currentColor',
    },
    '&::before': {
      top: 0,
      left: 0,
    },
    '&::after': {
      display: 'none',
    },
  },
  list: {
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  item: {},
  ...tablet({
    container: {
      '&:not(:first-child)': {
        marginTop: '9rem',
      },
    },
    image: {
      fontSize: '1.5rem',
    },
    wrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      '&:not(:first-child)': {
        marginTop: '7.5rem',
      },
    },
    menu: {
      width: 'calc(25% - 3rem)',
    },
    content: {
      flex: '0 0 auto',
      width: '75%',
      marginTop: '0',
      marginLeft: 'auto',
    },
    title: {
      paddingBottom: '2.5rem',
      fontSize: '3rem',
      '&::after': {
        display: 'block',
        bottom: 0,
        right: 0,
      },
    },
    list: {
      '&:not(:first-child)': {
        marginTop: '6rem',
      },
    },
  }),
  '@media print': {
    container: {
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
        marginBottom: '0',
      },
    },
    menu: {
      display: 'none',
    },
    image: {
      display: 'none',
    },
    content: {
      marginTop: 0,
    },
    title: {
      '&::before, &::after': {
        display: 'none',
      },
    },
  },
}
