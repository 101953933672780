import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useRouter } from 'next/router'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'

import styles from './styles'


const useStyles = createUseStyles(styles)

const HeaderSimple = (props) => {
  const {
    classes: classesProp,
    className,
    variant,
    title,
    text,
    zoneTools,
    zoneSharing,
    TagTitle,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])
  const router = useRouter()

  const specialRoutes = ['questions_du_moment']

  return (
    <header
      className={cx(classes.container, className, `is-${variant}`)}
      role="heading"
      aria-level="2"
    >
      <div className={classes.wrapper}>
        <div className={classes.titleZone}>
          <TagTitle className={cx(classes.title, title.length < 12 && 'is-small', specialRoutes.includes(router?.query?.slug) && 'is_special')}>
            {title}
          </TagTitle>
          {text && (
            <MarkdownStyled
              className={classes.text}
              text={text}
              variant={variant}
              lexicon={lexicon}
              markHover={markHover}
            />
          )}
        </div>
        {(zoneTools || zoneSharing) && (
          <div className={classes.toolsZone}>
            {zoneTools}
            {zoneSharing}
          </div>
        )}
      </div>
    </header>
  )
}

HeaderSimple.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  zoneTools: PropTypes.node,
  zoneSharing: PropTypes.node,
  TagTitle: PropTypes.string,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

HeaderSimple.defaultProps = {
  className: null,
  classes: null,
  text: '',
  zoneTools: null,
  zoneSharing: null,
  TagTitle: 'h1',
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(HeaderSimple)
