import React from 'react'

import { generateAkamaiUrl } from '../../../helpers/Akamai'
import FontsResizer from '../../../components/FontsResizer'
import { shareLinksTransformer } from '../../../transformers'
import ShareLinks from '../../../components/ShareLinks'


export const textDateTransformer = (item, t) => {
  const published = new Date(item?.entityCreated ?? '')
  const updated = new Date((item?.fieldArticleDateModification?.date.split(' '))[0] ?? '')

  let text = `${t('textPublish')} ${published.getDate().toString().padStart(2, '0')} ${t(`month_${published.getMonth()}`)} ${published.getUTCFullYear()}`

  if (updated.getDate() !== published.getDate() || updated.getMonth() !== published.getMonth() || updated.getFullYear() !== published.getFullYear()) {
    text += ` - ${t('textUpdated')} ${updated.getDate().toString().padStart(2, '0')} ${t(`month_${updated.getMonth()}`)} ${updated.getUTCFullYear()}`
  }
  return text
}


export const textDateToObjectTransformer = (item, t) => {
  const published = new Date(item?.entityCreated ?? '')
  const updated = new Date((item?.fieldArticleDateModification?.date.split(' '))[0] ?? '')

  const textPublished = `${t('textPublish')} ${published.getDate().toString().padStart(2, '0')} ${t(`month_${published.getMonth()}`)} ${published.getUTCFullYear()}`

  let textUpdated = ''

  if (updated.getDate() !== published.getDate() || updated.getMonth() !== published.getMonth() || updated.getFullYear() !== published.getFullYear()) {
    textUpdated = `${t('textUpdated')} ${updated.getDate().toString().padStart(2, '0')} ${t(`month_${updated.getMonth()}`)} ${updated.getUTCFullYear()}`
  }

  return {
    textPublished,
    textUpdated,
  }
}

export const headerArticleTransformer = (item, t, variant = '') => ({
    title: item?.title ?? '',
    variant,
    textTiming: `${t('textTiming')} ${item?.fieldArticleTempsLecture ?? ''}`,
    textDate: textDateTransformer(item, t),
    zoneTools:
  <>
    <FontsResizer />
    {/* <ShareLinks {...shareLinksTransformer({ sharing: false }, t)} /> */}
  </>,
    zoneSharing:
  <>
    <ShareLinks {...shareLinksTransformer({ title: item?.title ?? null, url: item?.url ?? null, mailToBody: t('shareArticleBody') }, t)} />
  </>,
    image: item?.fieldImage?.fieldImageArticle?.url ? {
      src: generateAkamaiUrl(item?.fieldImage?.fieldImageArticle?.url ?? ''),
      alt: item?.fieldImage?.alt ?? '',
    } : null,
  })

