import { accessibleHiddenText, colors, fonts, tablet } from '../../theme'


export default {
  container: {
    position: 'relative',
    background: colors.greyD9,
  },
  tabs: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row nowrap',
    background: colors.greyE7,
    // gap: '0.2rem', // if this ends up being needed
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      width: '0',
      height: '0',
      borderLeft: '2.5rem solid transparent',
      borderRight: '2.5rem solid transparent',
      borderBottom: `2rem solid ${colors.greyD9}`,
      marginLeft: '-2.5rem',
    },
  },
  item: {
    flex: '1 1 auto',
    maxWidth: 'calc(100% / 3)',
  },
  button: {
    position: 'relative',
    display: 'block',
    height: '6rem',
    width: '100%',
    background: colors.greyE7,
    cursor: 'pointer',
    outline: '0 none',
    '&.is-active': {
      background: colors.greyD9,
    },
    '& i': {
      ...accessibleHiddenText,
    },
  },
  imageTab: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '4rem',
    width: '4rem',
    transform: 'translate(-50%, -50%)',
    '&.is-active': {
      opacity: 0,
    },
    '$button.is-active &': {
      opacity: 0,
    },
    '$button.is-active &.is-active': {
      opacity: 1,
    },
  },
  contents: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  content: {
    padding: '2rem 5.5rem 2rem 1.5rem',
    fontSize: '1.5rem',
    overflow: 'hidden',
    '&.is-active': {
      overflow: 'visible',
    },
    '&:not(:first-child)': {
      borderTop: `1px solid ${colors.pureWhite}`,
    },
    '& > * + *': {
      marginTop: '1rem',
    },
    '& blockquote + *': {
      marginTop: '1.5rem',
    },
    '& > *:not(blockquote)': {
      position: 'absolute',
      opacity: 0,
      pointerEvents: 'none',
      transform: 'scale(0)',
      transition: 'opacity 0ms ease-out',
    },
    '&.is-active > *:not(blockquote)': {
      position: 'relative',
      opacity: 1,
      pointerEvents: 'all',
      transform: 'none',
      transition: 'opacity 200ms ease-out 50ms',
    },
    '& blockquote': {
      ...fonts.MaaxBold,
      position: 'relative',
      padding: 0,
      border: '0 none',
      lineHeight: 1.1,
      fontSize: '1em',
      color: colors.blue,
      '& p': {
        lineHeight: 1.2,
      },
      '& h4': {
        fontSize: '1.5rem !important',
        lineHeight: '1.2 !important',
      },
    },
    '& blockquote:not(:only-child)': {
      cursor: 'pointer',
    },
    '& blockquote:not(:only-child)::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '100%',
      width: '12px',
      height: '8px',
      marginLeft: '2rem',
      marginTop: '-0.4rem',
      background: colors.grey95,
      mask: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMC4zMTgzNiAxMy4yNzkyOTciPgogIDxwYXRoIGZpbGw9ImdyZXlEOSIgZD0iTSAyMC4zMTgzNTksMi44MjgxMjUgMTcuNDkwMjM0LDAgOS45NDE0MDUsNy41NDg4MjggMi45MDIzNDQsMC4xMjg5MDYyIDAsMi44ODA4NTkzIDkuODY3MTg4LDEzLjI3OTI5NyBaIi8+Cjwvc3ZnPgo=") center center no-repeat',
      transition: 'transform 100ms',
    },
    '&.is-active blockquote:not(:only-child)::after': {
      transform: 'rotate(180deg)',
    },
  },
  legend: {
    padding: '1.5rem 1rem',
    lineHeight: 1,
    fontSize: '1.1rem',
    textAlign: 'right',
    '& span': {
      position: 'relative',
    },
  },
  textLegend: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: '0',
    fontWeight: 400,
  },
  imageLegend: {
    marginRight: '1rem',
    height: '20px',
    width: '20px',
  },
  ...tablet({
    container: {
      flex: 1,
      paddingBottom: '4rem',
    },
    contents: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: '6rem',
      bottom: '4rem',
    },
    legend: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
  }),
  '@media print': {
    content: {
      overflow: 'visible',
      '& > *:not(blockquote)': {
        position: 'relative',
        opacity: 1,
        pointerEvents: 'all',
        transform: 'none',
        transition: 'opacity 200ms ease-out 50ms',
      },
      '& blockquote:not(:only-child)::after': {
        display: 'none',
      },
    },
    button: {
      display: 'none',
      '&.is-active': {
        display: 'block',
      },
    },
    tabs: {
      '&::before': {
        display: 'none',
      },
    },
  },
}
