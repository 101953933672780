import { colors, fonts, grids, tablet } from '../../theme'


export default {
  container: {
    marginTop: '5rem',
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '5rem',
    },
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  link: {
    ...fonts.MaaxRegular,
    position: 'relative',
    flex: '0 0 50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    lineHeight: 1.4,
    color: colors.grey67,
  },
  prevLink: {
    paddingLeft: '4rem',
    paddingRight: '2rem',
    marginRight: 'auto',
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  nextLink: {
    paddingRight: '4rem',
    paddingLeft: '2rem',
    marginLeft: 'auto',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    width: '1.4rem',
    height: '2.1rem',
    marginTop: '-1rem',
    transform: 'translate3D(0, 0, 0)',
    transition: 'transform 500ms ease-out',
    '$prevLink &': {
      left: 0,
    },
    '$nextLink &': {
      right: 0,
    },
    '$prevLink:hover &': {
      transform: 'translate3D(-0.5rem, 0, 0)',
    },
    '$nextLink:hover &': {
      transform: 'translate3D(0.5rem, 0, 0)',
    },
    color: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
          : props.variant === 'listen' ? colors.orangeDarkestListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),

  },
  ...tablet({
    container: {
      marginTop: '14.5rem',
      // penultimate child has a margin bottom because last is lexicon container
      '&:nth-last-child(2)': {
        marginBottom: '8.5rem',
      },
    },
    wrapper: {
      flexFlow: 'row nowrap',
    },
    link: {
      fontSize: '1.8rem',
    },
  }),
  '@media print': {
    container: {
      display: 'none',
    },
  },
}
