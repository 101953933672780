import React from 'react'

import { stripHtml } from '../../../helpers/StringHelpers'
import { generateAkamaiUrl } from '../../../helpers/Akamai'
import FontsResizer from '../../../components/FontsResizer'
import ShareLinks from '../../../components/ShareLinks'
import { shareLinksTransformer } from '../../../transformers'


export const headerTopicTransformer = (item, variant, t, route) => ({
  title: item?.title.replace('|', '<br />') ?? '',
  variant,
  text: item?.body?.value ?? null,
  zoneTools: (
    <>
      <FontsResizer />
      {/* <ShareLinks {...shareLinksTransformer({ sharing: false }, t)} /> */}
    </>
  ),
  zoneSharing: (
    <>
      <ShareLinks
        {...shareLinksTransformer(
          { title: item?.title.replace('|', '') ?? '', url: route?.asPath ?? null },
          t
        )}
      />
    </>
  ),
  image: item?.fieldImage?.fieldImageRub
    ? {
        src: generateAkamaiUrl(item?.fieldImage?.fieldImageRub?.url ?? ''),
        alt: item?.fieldImage?.alt ?? '',
      }
    : null,
  imageFaq: item?.fieldRubriqueImageOutil?.derivative
    ? {
        src: generateAkamaiUrl(item?.fieldRubriqueImageOutil?.derivative?.url ?? ''),
        alt: item?.fieldRubriqueImageOutil?.alt ?? '',
      }
    : null,
})

export const blockTopicTransformer = (item, variant) => {
  const categories = item?.fieldSousRubrique
    ?.filter(
      ({ entity: sousRubrique }) => sousRubrique?.reverseFieldArticleSousRubriqueNode.count > 0
    )
    .map(({ entity: sousRubrique }) => ({
      text: sousRubrique?.entityLabel ?? '',
      weight: sousRubrique?.weight,
      links: []
        .concat(
          sousRubrique?.reverseFieldArticleSousRubriqueNode?.entities
            ?.filter((article) => article !== null)
            ?.map((article) => ({
              title: article?.title ?? '',
              order: article?.fieldArticleOrdre ?? 0,
              text: article?.body?.value ? stripHtml(article?.body?.value ?? '') : null,
              linkProps: {
                href: article?.entityUrl?.path ?? '',
              },
              image: article?.fieldImage
                ? {
                    src: generateAkamaiUrl(article?.fieldImage?.url ?? ''),
                    alt: article?.fieldImage?.alt ?? '',
                  }
                : null,
            }))
        )
        .filter((e) => e.title !== '')
        .sort((a, b) => a.order - b.order),
    }))

  const sortedCategories = Array.from(categories).sort(
    (category1, category2) => category1.weight - category2.weight
  )

  return {
    variant,
    categories: sortedCategories,
  }
}

export const blockTopicFaqTransformer = (item, t) => ({
  title: t('titleQuestions'),
  questions: item?.fieldRubriqueQuestionReponse.map(({ entity: faq }) => ({
    title: faq?.fieldQuestion ?? '',
    text: `${stripHtml(faq?.body?.value ?? '').substr(0, 200)}...`,
    linkProps: {
      href: faq?.entityUrl?.path ?? '',
    },
  })),
})
