import { colors, desktop, mobile, tablet, biggestDesktop } from '../../theme'


export default {
  container: {
    '&:not(:first-child)': {
      marginTop: '0.3rem',
    },
  },
  link: {
    position: 'relative',
    display: 'block',
    height: '100%',
    color: (props) => (
      props.variant === 'care' ? colors.yellowCare
        : props.variant === 'environment' ? colors.blueDarkEnvironment
          : props.variant === 'listen' ? colors.blueListen
            : props.variant === 'discover' ? colors.yellowDiscover
              : colors.pureWhite),
    backgroundColor: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseEnvironment
          : props.variant === 'listen' ? colors.orangeListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
    cursor: 'pointer',
  },
  hook: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  titleZone: {
    alignSelf: 'center',
    padding: '0.5rem 4rem 0.5rem 1.5rem',
  },
  title: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    fontSize: '1.7rem',
    textTransform: 'uppercase',
    overflow: 'hidden',
    '& span:first-child::before': {
      top: '-1.5rem',
      width: '3.5rem',
    },
    '& span:last-child::after': {
      bottom: '-1.5rem',
    },
  },
  figure: {
    flex: '0 0 auto',
    width: 'calc(100% / 3)',
    margin: 0,
  },
  image: {},
  text: {
    display: 'none',
    fontSize: '1.2rem',
    color: (props) => (
      props.variant === 'care' ? colors.pureWhite
        : props.variant === 'environment' ? colors.blueDarkEnvironment
          : props.variant === 'listen' ? colors.blueListen
            : props.variant === 'discover' ? colors.pureWhite
              : colors.pureWhite),
    backgroundColor: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseEnvironment
          : props.variant === 'listen' ? colors.orangeListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
  },
  ...mobile({
    container: {
      '&:not(:first-child)': {
        marginTop: 0,
      },
    },
    link: {
      paddingTop: '1rem',
    },
    hook: {
      display: 'flex',
      flexFlow: 'column nowrap',
      height: '100%',
    },
    titleZone: {
      flex: '1 0 auto',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      width: '100%',
      padding: '3rem',
    },
    figure: {
      width: '100%',
    },
    image: {
      position: 'relative',
      height: 0,
      paddingTop: '89%',
      '& > *': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  }),
  ...tablet({
    titleZone: {
      padding: '4rem',
    },
    title: {
      fontSize: '2.4rem',
      lineHeight: 1.4,
    },
    text: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      padding: '2.5rem',
      lineHeight: 1.3,
      opacity: 0,
      transition: 'opacity 100ms ease-out',
      '$link:hover &': {
        opacity: 1,
      },
    },
  }),
  ...desktop({
    titleZone: {
      padding: '6rem 3rem 6rem 6rem',
    },
    text: {
      fontSize: '1.5rem',
      lineHeight: 1.4,
    },
  }),
  ...biggestDesktop({
    titleZone: {
      padding: '10rem 3rem 10rem 6rem',
    },
    title: {
      fontSize: '3.1rem',
    },
  }),
  '@media (hover: none)': {
    text: {
      '$link:hover &': {
        opacity: 0,
      },
    },
  },
}
