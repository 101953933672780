import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import NavMainLink from '../NavMainLink'
import SubMenu from '../SubMenu'
import { safeCall } from '../../helpers/React'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavMain = (props) => {
  const { classes: classesProp, className, links, onMenuLeaveHandler } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const handleLeaveMenu = useCallback(() => {
    safeCall(onMenuLeaveHandler)
  }, [onMenuLeaveHandler])

  return links ? (
    <nav
      className={cx(classes.container, className)}
      onMouseLeave={handleLeaveMenu}
    >
      <ul className={classes.list}>
        {links.map((link, index) => (
          <li
            key={`nav-${index}`}
            className={cx(classes.item, link.isActive === true && 'is-active')}
          >
            <NavMainLink
              className={classes.navmainlink}
              {...link}
            />
            {link.sub && (
              <SubMenu
                {...link.sub}
              />
            )}
          </li>
        ))}
      </ul>
    </nav>
  ) : null
}

NavMain.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      ...NavMainLink.propTypes,
      sub: PropTypes.shape(SubMenu.propTypes),
    })
  ),
  isClosed: PropTypes.bool,
  onMenuLeaveHandler: PropTypes.func,
}

NavMain.defaultProps = {
  className: null,
  classes: null,
  links: null,
  isClosed: false,
  onMenuLeaveHandler: () => null,
}

export default withMemo()(NavMain)
