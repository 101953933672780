import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useKeenSlider } from 'keen-slider/react'

import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import MagicTitle from '../MagicTitle'
import Icon from '../Icon'
import icons from '../Icon/assets'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockHomeQuestions = (props) => {
  const {
    classes: classesProp,
    className,
    title,
    questions,
    linkMore,
    textNext,
    textPrevious,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  // states
  const [currentSlide, setCurrentSlide] = useState(0)
  const [currentLastSlide, setCurrentLastSlide] = useState(0)

  // slider settings
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 1,
    spacing: 100,
    mounted() {
      if (sliderRef.current) {
        sliderRef.current.style.visibility = 'visible'
      }
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
      setCurrentLastSlide(s.details().relativeSlide)
    },
  })

  // renderers
  const renderSlider = useMemo(() => questions && (
    <div
      ref={sliderRef}
      className={cx(classes.keenSlider, 'keen-slider')}
    >
      {questions.map((question, index) => (
        <div
          key={`blockhomequestions-slide-${index}`}
          className={cx(classes.column, 'keen-slider__slide')}
        >
          <div className={classes.questionTitle}>
            <Icon
              icon={icons.Calendar}
              className={classes.questionIcon}
            />
            <span className={classes.questionPeriod}>{question.period}</span>
          </div>
          <div className={classes.questionMetas}>
            <MarkdownText
              text={question.date}
              lexicon={lexicon}
              markHover={markHover}
              className={classes.questionDate}
            />
            {' '}
            <MarkdownText
              text={question.author}
              className={classes.questionAuthor}
            />
          </div>
          <blockquote className={classes.questionText}>
            {question.text}
          </blockquote>
          <div className={classes.questionLinks}>
            <A
              {...question.link.linkProps}
              scroll={false}
              className={classes.questionLink}
            >
              {question.link.text}
            </A>
          </div>
        </div>
      ))}
    </div>
  ),
  // eslint-disable-next-line max-len
  [classes.column, classes.keenSlider, classes.questionAuthor, classes.questionDate, classes.questionIcon, classes.questionLink, classes.questionLinks, classes.questionMetas, classes.questionPeriod, classes.questionText, classes.questionTitle, lexicon, markHover, questions, sliderRef])

  const renderPagination = useMemo(() => slider && (
  <div className={classes.pagination}>
    {[...Array(slider.details().size).keys()].map((index) => (
      <button
        key={`blockhomequestions-page-${index}`}
        type="button"
        onClick={() => {
          slider.moveToSlideRelative(index)
        }}
        className={cx(classes.dot, currentSlide === index && 'is-active')}
      >
        <i>{index}</i>
      </button>
    ))}
  </div>
  ), [classes.dot, classes.pagination, currentSlide, slider])

  // handlers
  const handlePrev = useCallback(() => {
    if (slider) {
      slider.prev()
    }
  }, [slider])

  const handleNext = useCallback(() => {
    if (slider) {
      slider.next()
    }
  }, [slider])

  const handleResize = useCallback(() => {
    if (slider) {
      slider.resize()
    }
  }, [slider])

  // effects
  // - slider resize on change fonts size
  // - slider resize on window resize
  useEffect(() => {
    const o = ((typeof window !== 'undefined')) ? new MutationObserver(() => {
      handleResize()
    }) : null

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      if (o !== null) {
        o.observe(document.querySelector('html'), { attributes: true, attributeFilter: ['style'] })
      }
    }
    return () => {
      try {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleResize)
          if (o !== null) {
            o.disconnect()
          }
        }
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [handleResize, slider])

  return (
    <section className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <MagicTitle
          text={title}
          className={classes.title}
        />
        <div className={classes.slider}>
          {renderSlider}
          {slider && (
            <>
              <button
                type="button"
                className={cx(classes.previous, classes.nav, currentSlide <= 0 && 'is-disabled')}
                onClick={handlePrev}
              >
                <Icon
                  icon={icons.ChevronSliderLeft}
                  className={classes.navIcon}
                />
                <i>{textPrevious}</i>
              </button>
              <button
                type="button"
                className={cx(classes.next, classes.nav, (currentLastSlide >= questions.length - 1) && 'is-disabled')}
                onClick={handleNext}
              >
                <Icon
                  icon={icons.ChevronSliderRight}
                  className={classes.navIcon}
                />
                <i>{textNext}</i>
              </button>
            </>
          )}
        </div>
        {renderPagination}
        {linkMore && (
          <div className={classes.links}>
            <A
              {...linkMore.linkProps}
              className={classes.linkMore}
            >
              {linkMore.text}
            </A>
          </div>
        )}
      </div>
    </section>
  )
}

BlockHomeQuestions.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      period: PropTypes.string,
      date: PropTypes.string,
      author: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.shape({
        text: PropTypes.string,
        linkProps: PropTypes.shape(A.propTypes),
      }),
    })
  ),
  linkMore: PropTypes.shape({
    text: PropTypes.string,
    linkProps: PropTypes.shape(A.propTypes),
  }),
  textNext: PropTypes.string,
  textPrevious: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  lexicon: PropTypes.array,
  markHover: PropTypes.func,
}

BlockHomeQuestions.defaultProps = {
  className: null,
  classes: null,
  title: '',
  questions: null,
  linkMore: null,
  textNext: '',
  textPrevious: '',
  lexicon: null,
  markHover: () => null,
}

export default BlockHomeQuestions
