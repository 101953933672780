import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import NavSecondaryLink from '../NavSecondaryLink'
import Icon from '../Icon'
import icons from '../Icon/assets'
import { safeCall } from '../../helpers/React'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavMobile = (props) => {
  const {
    classes: classesProp,
    className,
    linksMain,
    linksSecondary,
    isOpen,
    textClose,
    textBack,
    onCloseMenu,
    openSubMenu,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const [stateOpenSubMenu, setOpenSubMenu] = useState(openSubMenu)

  const handleCloseMenu = useCallback(() => {
    safeCall(onCloseMenu)
    setOpenSubMenu(false)
  }, [onCloseMenu])

  const handleOpenSubMenu = useCallback((index) => {
    setOpenSubMenu(index)
  }, [])

  const handleCloseSubMenu = useCallback(() => {
    setOpenSubMenu(-1)
  }, [])

  useEffect(() => {
    if (window) {
      const targets = window.document.querySelectorAll('.is-scrollable')

      if (isOpen === true && targets.length > 0) {
        targets.forEach((target) => {
          disableBodyScroll(target)
        })
      } else {
        clearAllBodyScrollLocks()
      }
    }
  }, [isOpen])

  //
  const renderlevelOne = useMemo(() => (linksMain ? (
    <ul className={classes.levelOne}>
      {linksMain.map((item, index) => (
        <li
          key={`navmobile-one-${index}`}
          className={cx(classes.levelOneItem)}
        >
          {item.links.length > 0 ? (
            <button
              type="button"
              onClick={() => handleOpenSubMenu(index)}
              className={cx(classes.levelOneButton, `is-${item.variant}`)}
            >
              <span
                className={classes.levelOneText}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <Icon
                icon={icons.ChevronSquareRight}
                className={classes.levelOneIcon}
              />
            </button>
          ) : (
            <A
              {...item.mainLinkProps}
              scroll={false}
              className={cx(classes.levelOneButton, classes.levelOneLink, `is-${item.variant}`)}
              preventDefaultOnClick={false}
            >
              <span
                className={classes.levelOneText}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <Icon
                icon={icons.ChevronSquareRight}
                className={classes.levelOneIcon}
              />
            </A>
          )}
        </li>
      ))}
    </ul>
  // eslint-disable-next-line max-len
  ) : null), [classes.levelOne, classes.levelOneButton, classes.levelOneIcon, classes.levelOneItem, classes.levelOneText, classes.levelOneLink, handleOpenSubMenu, linksMain])
  //
  const renderlevelOneSecondary = useMemo(() => (linksSecondary ? (
    <ul className={classes.secondary}>
      {linksSecondary.map((link, index) => (
        <li
          className={cx(classes.secondaryItem, `is-${link.variant}`)}
          key={`navmobile-secondary-${index}`}
        >
          <A
            {...link.linkProps}
            scroll={false}
            className={cx(classes.secondaryLink, `is-${link.variant}`)}
            dangerouslySetInnerHTML={{ __html: link.text }}
            preventDefaultOnClick={false}
          />
        </li>
      ))}
    </ul>
  ) : null), [classes.secondary, classes.secondaryItem, classes.secondaryLink, linksSecondary])
  //
  const renderlevelTwo = useMemo(() => (linksMain ? (
    <ul>
      {linksMain.map((item, index) => (
        <li
          className={cx(classes.levelTwo, index === stateOpenSubMenu && 'is-active', `is-${item.variant}`, 'is-scrollable')}
          key={`navmobile-two-${index}`}
        >
          <button
            type="button"
            className={classes.backButton}
            onClick={handleCloseSubMenu}
          >
            <span className={classes.accessiblehiddentext}>{textBack}</span>
            <Icon icon={icons.ChevronSquareLeft} />
          </button>
          <div className={classes.levelTwoHeader}>
            <span
              className={classes.levelTwoTitle}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          </div>
          <div className={classes.levelTwoContent}>
            {item.links && (
              <ul className={classes.levelTwoList}>
                {item.links.map((link, idx) => (
                  <li
                    key={`navmobile-two-${index}-${idx}`}
                    className={classes.levelTwoItem}
                  >
                    <A
                      {...link.linkProps}
                      scroll={false}
                      onClick={handleCloseMenu}
                      className={classes.levelTwoLink}
                      preventDefaultOnClick={false}
                    >
                      {link.text}
                    </A>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </li>
      ))}
    </ul>
  // eslint-disable-next-line max-len
  ) : null), [classes.accessiblehiddentext, classes.backButton, classes.levelTwo, classes.levelTwoContent, classes.levelTwoHeader, classes.levelTwoItem, classes.levelTwoLink, classes.levelTwoList, classes.levelTwoTitle, handleCloseMenu, handleCloseSubMenu, linksMain, stateOpenSubMenu, textBack])
  //

  return (
    <nav className={cx(classes.container, className, isOpen && 'is-open')}>
      <div className={cx(classes.start, 'is-scrollable')}>
        <button
          type="button"
          className={classes.close}
          onClick={handleCloseMenu}
        >
          <span className={classes.accessiblehiddentext}>{textClose}</span>
          <Icon icon={icons.Close} />
        </button>
        <span className={classes.menuTitle}>Menu</span>
        {renderlevelOne}
        {renderlevelOneSecondary}
      </div>
      <div className={classes.sub}>
        {renderlevelTwo}
      </div>
    </nav>
  )
}

NavMobile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  isOpen: PropTypes.bool,
  linksMain: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']),
      links: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        linkProps: PropTypes.shape(A.propTypes),
      })),
    })
  ),
  linksSecondary: PropTypes.arrayOf(
    PropTypes.shape(NavSecondaryLink.propTypes)
  ),
  textClose: PropTypes.string.isRequired,
  textBack: PropTypes.string.isRequired,
  onCloseMenu: PropTypes.func,
  openSubMenu: PropTypes.number,
}

NavMobile.defaultProps = {
  className: null,
  classes: null,
  isOpen: true,
  linksMain: null,
  linksSecondary: null,
  onCloseMenu: () => null,
  openSubMenu: -1,
}

export default withMemo()(NavMobile)
