/* eslint-disable max-lines */
import { all, call, put, select } from 'redux-saga/effects'

import ApiSagas from '../../../redux/api/sagas'
import Service from '../../../graphql/query/View/index'
import { actions as ToolActions } from '../OutilWrapper/redux'
import { praticesViewTransformer } from '../OutilWrapper/transformers'
import {
  actions as AppActions,
  selectors as AppSelectors
} from '../../../redux/app/redux'


export default class EnPratiqueSagas {

  static* init() {
    const { practices, meta } = yield call(
        ApiSagas.persistQuery,
        Service.enPratiqueView,
        {},
        praticesViewTransformer
    )

    const { query } = yield select(AppSelectors.route)

    const slug = query?.slug


    if (meta.length && slug === 'en_pratique') {
      const seo = yield select(AppSelectors.seo)

      const originalMetas = seo?.metas ?? []

      const transformedMetas = meta?.map((item) => ({
        ...item,
        key: item.key.replace('og_', 'og:'),
      })) ?? []

      const metaTags = transformedMetas.concat(originalMetas.filter((item) => !transformedMetas.find((item2) => item2.key === item.key)))

      yield all([
        put(
            AppActions.setSeo({
              metas: metaTags,
            })
        ),
      ])
    }

    yield all([
      put(ToolActions.setView(practices ?? null)),
    ])
  }

  static* loop() {
    yield all([])
  }

}
