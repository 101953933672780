import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FooterUnder = (props) => {
  const { classes: classesProp, className, links } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {links && (
          <ul className={classes.list}>
            {links.map((link, index) => (
              <li
                key={`footermenusub-${index}`}
                className={classes.item}
              >
                <A
                  className={cx(classes.link, link?.isCurrent === true && 'is-current')}
                  {...link?.linkProps}
                  dangerouslySetInnerHTML={{ __html: link?.text }}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

FooterUnder.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    linkProps: PropTypes.shape(A.propTypes),
    isCurrent: PropTypes.bool,
  })),
}

FooterUnder.defaultProps = {
  className: null,
  classes: null,
  links: null,
}

export default withMemo()(FooterUnder)
