export const mergeStyles = (source = {}, add = {}) => {
  const result = { ...(add || {}) }
  const addKeys = Object.keys(add || {})

  Object.keys(source || {}).forEach((key) => {
    if (addKeys.indexOf(key) !== -1) {
      result[key] = `${source[key]} ${add[key]}`
    } else {
      result[key] = source[key]
    }
  })
  return result
}
