import { generateAkamaiUrl } from '../../../helpers/Akamai'


export const videoBySlugTransformer = (data) => {
  if (data) {
    return {
      title: data?.fieldTranscription?.entity?.title ?? '',
      path: data?.entityUrl?.path ?? '',
      logo: {
        src: data?.fieldLogo?.url ?? '',
        width: data?.fieldLogo?.width ?? 0,
        height: data?.fieldLogo?.height ?? 0,
      },
      coverShareSEO: {
        src: data?.fieldCoverShare?.url ?? '',
        width: data?.fieldCoverShare?.width ?? 0,
        height: data?.fieldCoverShare?.height ?? 0,
      },
      speeds: [
        {
          text: '0.5',
          value: 0.5,
        },
        {
          text: 'Normale',
          isDefault: true,
          value: 1,
        },
        {
          text: '1.5',
          value: 1.5,
        },
      ],
      videoSource: {
        type: 'video',
        poster: data?.fieldImage?.url ?? '',
        sources: [
          {
            src: data?.fieldUrlVideo ?? '',
            type: 'video/mp4',
          },
        ],
      },
      linkTranscription: {
        href: generateAkamaiUrl(data?.fieldTranscription?.entity?.entityUrl?.path ?? ''),
        target: '_blank',
      },
      textPlay: 'Lancer la vidéo',
      textTranscription: 'Transcription',
      textOpen: 'Ouvrir',
      textSubtitles: 'Sous-titres',
      textSpeed: 'Vitesse de lecture',
    }
  }

  return null
}
