import { cookiesTool } from 'react-gdpr'

import { actions } from './redux'


const isGAIDExist = typeof process.env.GA_ID === 'string' && process.env.GA_ID.length > 0

const trackPage = () => {
  const event = new Event('analytics.pageview')

  if (typeof window !== 'undefined') {
    window.dispatchEvent(event)
  }
}

const trackEvent = (store, payload) => {
  const codeEmbeded = store.getState().tracking.codeEmbeded

  if (!codeEmbeded || !cookiesTool.isActive('analytics') || !isGAIDExist) {
    return
  }
  const { category: eventCategory, action, label: eventLabel, value } = payload

  // Send event
  window.gtag('event', action, {
    event_category: eventCategory,
    event_label: eventLabel,
    value,
  })
}

const enableTracking = (store, payload) => {
  const codeEmbeded = store.getState().tracking.codeEmbeded

  if (codeEmbeded) {
    return
  }

  store.dispatch(actions.setCodeEmbeded())
}

const TrackingMiddleware = (store) => (next) => (action) => {
  const actionMap = {
    [actions.trackPage]: trackPage,
    [actions.trackEvent]: trackEvent,
    [actions.enableTracking]: enableTracking,
  }

  if (actionMap[action.type] !== undefined) {
    actionMap[action.type](store, action.payload)
  }

  next(action)
}

export default TrackingMiddleware
