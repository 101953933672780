import gql from 'graphql-tag'


export const VideoFragment = {
  fragment: gql`
      fragment VideoFragment on NodeVideo {
          title
          fieldTheme
          entityUrl {
            path
        }
          fieldUrlVideo
          fieldDuration
          fieldImage {
              entity {
                  __typename
                  ... on File {
                      url
                  }
              }
          }
          fieldImageSquare {
              entity {
                  __typename
                  ... on File {
                      url
                  }
              }
          }
          fieldLogo {
              entity {
                  __typename
                  ... on File {
                      url
                  }
              }
          }
          fieldSubtitles {
              entity {
                  __typename
                  ... on File {
                      url
                  }
              }
          }
          fieldTranscription {
              entity {
                  __typename
                  ... on NodeVideoTranscription {
                      entityUrl {
                          path
                      }
                  }
              }
          }
      }
  `,
}
