import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectors as LexiconSelector, actions as LexiconAction } from '../../redux/lexicon/redux'
import { safeCall } from '../../helpers/React'



export const replaceAndReturnMatch = (thisText, replacements, skip = []) => {
  if (!replacements) {
    return [thisText, skip]
  }

  if (!replacements.length) {
    return [thisText, skip]
  }

  let transformedText = thisText
  const moreSkip = []

  replacements.forEach((value) => {
    const terms = value.terms.join('|').replace(' ', '\\s').replace(' ', '\\s')

    if (skip.indexOf(terms) < 0) {
      // eslint-disable-next-line max-len
      const regexLexicon = new RegExp(`(?:^|\\s|\\:|\\n|\\,|\\?|\\!|\\)|\\(|\\[|\\]|\\’|\\'|\\"\\>)(${terms})+(?:$|\\.|\\:|\\s|\\,|\\n|\\?|\\!|\\)|\\(|\\[|\\]|\\’|\\'|\\"\\<)`, 'i')

      if (regexLexicon.test(transformedText)) {
        moreSkip.push(terms)
      }
      transformedText = regexLexicon.test(transformedText)
        ? transformedText.replace(regexLexicon, (thisFragment) => `<mark data-tip>${thisFragment}</mark>`)
        : transformedText
    }
  })

  return [transformedText, skip ? skip.concat(moreSkip) : moreSkip]
}


export const replaceAndReturnText = (thisText, replacements, skip = []) => {
  const [transformedText] = replaceAndReturnMatch(thisText, replacements, skip)

  return transformedText
}


export const useLexicon = ({
  markHover,
}) => {
  const attachLexicon = replaceAndReturnText
  const handleMarkHover = useCallback((e) => {
    for (let target = e.target; target && target !== this; target = target.parentNode) {
      if (target.tagName && target.tagName.toLowerCase() === 'mark') {
        if (markHover) {
          const regexLexiconReplace = new RegExp('(\\.|\\:|\\n|\\,|\\?|\\!|\\)|\\(|\\[|\\]|\\’|\\\'|\\")', 'gi')
          const str = target.innerText.replaceAll(regexLexiconReplace, '')

          safeCall(markHover, str)
        }
        break
      }
    }
  }, [markHover])

  return [attachLexicon, handleMarkHover]
}

export const useLexiconHandler = () => {
  const dispatch = useDispatch()

  const lexicals = useSelector(LexiconSelector.lexicals)
  const handleLexicon = useCallback((lexicon) => {
    const result = lexicals.find(
      (lex) => lex.terms.findIndex(
        (item) => item.toLocaleLowerCase().trim().replace(/[“”‘’'"«»]/g, '') === lexicon.toLocaleLowerCase().trim()
      ) >= 0
    )

    safeCall(dispatch, LexiconAction.setLexicon(result))
  }, [dispatch, lexicals])

  return [lexicals, handleLexicon]
}
