import gql from 'graphql-tag'


export const ParagraphArticleBlocFocusBienEtreFragment = {
  fragment: gql`
        fragment ParagraphArticleBlocFocusBienEtreFragment on ParagraphArticleBlocFocusBienEtre {
            fieldTitle
            fieldImageAncre{
                alt
                fieldImageArticle:derivative(style:IMAGEARTICLE){
                    url
                }
            }
            fieldParagrapheContenuResume{ value }
            fieldLink{
                url{
                    path
                }
            }
        }
    `,
}
