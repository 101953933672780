import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import withMemo from '../../../decorators/withMemo'
import HeaderPractice from '../../../components/HeaderPractice'
import NavPractice from '../../../components/NavPractice'
import { selectors as ToolSelectors } from '../OutilWrapper/redux'
import { selectors as PageSlectors } from '../../Pages/Page/redux'
import { navigationPracticeTransformer } from '../OutilWrapper/transformers'
import { headerPracticeTransformer } from '../../Paragraphs/HeaderPractice/transformers'
import { selectors as AppSelectors } from '../../../redux/app/redux'


function EnPratiqueWrapper() {
  const { t } = useTranslation()
  const view = useSelector(ToolSelectors.view)
  const route = useSelector(AppSelectors.route)
  const breadcrumb = useSelector(PageSlectors.breadcrumb)
  const navPracticeProps = useMemo(() => navigationPracticeTransformer(t, view), [t, view])
  const headerPracticeHomeProps = useMemo(() => headerPracticeTransformer({
    url: route?.asPath,
    title: breadcrumb[breadcrumb.length - 1]?.text ?? undefined,
  }, t), [route.asPath, breadcrumb, t])

  return (
    <>
      <HeaderPractice
        {...headerPracticeHomeProps}
        variantType="practiceHome"
      />
      <NavPractice {...navPracticeProps} />
    </>
  )
}

EnPratiqueWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
}

EnPratiqueWrapper.defaultProps = {
}

export default withMemo()(EnPratiqueWrapper)
