import { colors, tablet } from '../../theme'


export default {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: (props) => (props.variantType === 'question' ? 'row nowrap' : 'row-reverse nowrap'),
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  avatar: {
    flex: '0 0 auto',
    width: '5rem',
    marginLeft: (props) => (props.variantType === 'question' ? '0' : '2rem'),
    marginRight: (props) => (props.variantType === 'question' ? '2rem' : '0'),
    overflow: 'hidden',
  },
  content: {
    position: 'relative',
    flex: '1',
  },
  icon: {
    position: 'absolute',
    left: (props) => (props.variantType === 'question' ? 'auto' : '100%'),
    right: (props) => (props.variantType === 'question' ? '100%' : 'auto'),
    top: '5.5rem',
    width: '1.5rem',
    height: '1.5rem',
    marginLeft: (props) => (props.variantType === 'question' ? '0' : '-2px'),
    marginRight: (props) => (props.variantType === 'question' ? '-2px' : '0'),
    color: (props) => (props.variantType === 'question' ? colors.lavenderQuestionsE2 : colors.greyF6),
    pointerEvents: 'none',
  },
  text: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    minHeight: '8.5rem',
    padding: '2rem 1rem',
    borderRadius: '1rem',
    fontSize: '1.5rem',
    fontWeight: (props) => (props.variantType === 'question' && 'bold'),
    backgroundColor: (props) => (props.variantType === 'question' ? colors.lavenderQuestionsE2 : colors.greyF6),
    '& p:not(:first-child)': {
      marginTop: '1.5rem',
    },
    '& h3': {
      fontSize: '1.6rem !important',
      margin: 0,
    },
  },
  ...tablet({
    text: {
      padding: '2rem 3rem',
      fontSize: '1.6rem',
    },
  }),
  '@media print': {
    icon: {
      display: 'none',
    },
  },
}
