export default {
  container: {
    margin: 0,
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    '& > span': {
      position: 'relative',
    },
    '& > span:first-child::before, & > span:last-child::after': {
      content: '""',
      position: 'absolute',
      width: '11.5rem',
      background: 'currentColor',
      height: (props) => `${(props.sizeDecoration / 10)}rem`,
    },
    '& > span:first-child::before': {
      top: '-2.5rem',
      left: 0,
    },
    '& > span:last-child::after': {
      bottom: '-2.5rem',
      right: 0,
    },
  },
}
