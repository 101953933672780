import { query } from '../../../helpers/GraphqlHelpers'

import { NodesByTypes, ArticlePrevNext, ToolsSmall, QuestionsByPeriode } from './NodesByTypes'


export default {
  questionsByPeriode: (variables, headers = {}) => query(QuestionsByPeriode, variables, headers),
  toolsSmall: (variables, headers = {}) => query(ToolsSmall, variables, headers),
  prevNext: (variables, headers = {}) => query(ArticlePrevNext, variables, headers),
  byTypes: (variables, headers = {}) => query(NodesByTypes, variables, headers),
}
