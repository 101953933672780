import { useEffect, useRef } from 'react'


export function useDelayedCallback(delay = 100) {
  const ref = useRef()

  useEffect(() => {
    if (ref.current) {
      clearTimeout(ref.current)
    }
  }, [])
  return (callback) => {
    if (ref.current) {
      clearTimeout(ref.current)
    }
    ref.current = setTimeout(callback, delay)
  }
}
