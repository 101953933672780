import { useMemo, useState } from 'react'


export function useMenuAnchor(blocks) {
  const [current, setCurrent] = useState(0)


  const links = useMemo(() => blocks.map((block, index) => ({
    linkProps: {
      href: `#${block.id}`,
      onClick: () => {
        setCurrent(index)
        if (window) {
          const y = document.getElementById(block.id).getBoundingClientRect().top + window.scrollY - 100

          window.scroll({
            top: y,
            behavior: 'smooth',
          })
        }
      },
    },
    isActive: index === current,
    text: block.title,
    variant: block.variant,
  })), [blocks, current])

  return {
    current,
    setCurrent,
    links,
  }
}
