import { colors, tablet } from '../../theme'


export default {
  container: {},
  item: {
    padding: '2rem 4.5rem',
    background: colors.greyF6,
    '&:not(:first-child)': {
      marginTop: '1.5rem',
    },
    ...tablet({
      padding: 0,
      background: 'transparent',
      '&:not(:first-child)': {
        marginTop: '2rem',
      },
    }),
  },
}
