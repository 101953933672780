import gql from 'graphql-tag'


export const ParagraphArticleEncartContenuFragment = {
  fragment: gql`
        fragment ParagraphArticleEncartContenuFragment on ParagraphArticleEncartContenu {
            fieldParagrapheContenu{value}
            fieldDescriptionAncre{value}
        }
    `,
}
