import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'

import styles from './styles'



const useStyles = createUseStyles(styles)

const Footer = (props) => {
  const { classes: classesProp, className, children } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <footer className={cx(classes.container, className)}>
      {children}
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
}

Footer.defaultProps = {
  className: null,
  classes: null,
  children: null,
}

export default withMemo()(Footer)
