import React, { useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

import NavSide from '../../../components/NavSide'
import HeaderSimple from '../../../components/HeaderSimple'
import withMemo from '../../../decorators/withMemo'
import { selectors as PageSelectors } from '../../Pages/Page/redux'
import { useMenuAnchor } from '../../../utils/hooks/useMenuAnchor'
import { headerSimpleTransformer } from '../../Paragraphs/HeaderSimple/transformers'
import { BlocksParser } from '../../../helpers/BlocksHelpers'
import { useLexiconHandler } from '../../../utils/hooks/useLexicon'
import { selectors as AppSelectors } from '../../../redux/app/redux'

import { satelliteAncreTransformer } from './transformers'


function SatelliteWrapper(props) {
  const { t } = useTranslation()
  const router = useRouter()
  const route = useSelector(AppSelectors.route)
  const { fieldSatelliteAncres } = props
  const variant = useSelector(PageSelectors.variant)
  const [lexicals, handleLexicon] = useLexiconHandler()
  //
  const blocks = useMemo(() => fieldSatelliteAncres.map((block) => satelliteAncreTransformer(block.entity)), [fieldSatelliteAncres])
  const { setCurrent, links } = useMenuAnchor(blocks)
  const headerSimpleProps = useMemo(() => headerSimpleTransformer(props, route, t), [props, route, t])

  const handleRouteChange = useCallback(() => {
    setCurrent(0)
  }, [setCurrent])

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [handleRouteChange, router.events])

  const renderMenu = useMemo(() => (
    <NavSide
      variant={variant}
      links={links}
    />
  ), [variant, links])

  return (
    <>
      <HeaderSimple
        {...headerSimpleProps}
        variant={variant}
        lexicon={lexicals}
        markHover={handleLexicon}
      />
      {fieldSatelliteAncres && (
        <BlocksParser
          options={{
            renderMenu,
            lexicon: lexicals,
            markHover: handleLexicon,
          }}
          blocks={fieldSatelliteAncres}
        />
      )}
    </>
  )
}

SatelliteWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fieldSatelliteAncres: PropTypes.array,
}

SatelliteWrapper.defaultProps = {
  fieldSatelliteAncres: null,
}

export default withMemo()(SatelliteWrapper)
