import React, { useRef, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import ClassNames from 'classnames'


import { safeCall } from '../../helpers/React'

import icons from './assets'
import useStyles from './styles'


function Icon(props) {
  const {
    className,
    classname,
    style,
    icon,
    color,
    accessibilityAlt,
    iconProps,
    hasColorTransition,
    onRef,
  } = props

  const classes = useStyles(props)

  const $ref = useRef(null)

  useEffect(() => {
    safeCall(onRef, $ref.current)
  }, [onRef])

  const IconComponent = typeof icon === 'string' ? icons[icon] : icon

  const renderDefault = (
    <span
      className={ClassNames(classes.icon, className, classname, color, { 'color-transition': hasColorTransition })}
      style={style}
      role="presentation"
      ref={$ref}
    >
      {IconComponent && <IconComponent {...iconProps} />}
      {accessibilityAlt && <span className={ClassNames(classes.alt)}>{accessibilityAlt}</span>}
    </span>
  )

  return renderDefault
}

Icon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf(Object.keys(icons))]).isRequired,
  color: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  classname: PropTypes.string,
  className: PropTypes.string,
  iconProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  accessibilityAlt: PropTypes.string,
  onRef: PropTypes.func,
  hasColorTransition: PropTypes.bool,
}

Icon.defaultProps = {
  classes: null,
  style: null,
  color: null,
  classname: null,
  className: null,
  accessibilityAlt: null,
  iconProps: {
    className: null,
  },
  onRef: null,
  hasColorTransition: true,
}

export default Icon
