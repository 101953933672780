import React from 'react'
import { useTranslation } from 'react-i18next'

import withMemo from '../../../decorators/withMemo'
import ArticlePushVideo from '../../../components/ArticlePushVideo'

import { videoSpfTransformer } from './transformer'


const VideoSpf = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <div style={{ margin: '8rem auto 0' }}>
        <ArticlePushVideo {...videoSpfTransformer(props, t)} />
      </div>
    </>
  )
}

VideoSpf.propTypes = {}

VideoSpf.defaultProps = {}

export default withMemo()(VideoSpf)
