import { query } from '../../../helpers/GraphqlHelpers'

import { EnPratiqueView, HomepageView, LexiqueView, QuestionsDuMomentView } from './ViewByType'


export default {
  enPratiqueView: (variables, headers = {}) => query(EnPratiqueView, variables, headers),
  questionsDuMomentView: (variables, headers = {}) => query(QuestionsDuMomentView, variables, headers),
  homepageView: (variables, headers = {}) => query(HomepageView, variables, headers),
  lexiqueView: (variables, headers = {}) => query(LexiqueView, variables, headers),
}
