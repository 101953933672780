import { colors, desktop } from '../../theme'


export default {
  // this animation exists only to prevent the current state to be visible before the route/hash change
  '@keyframes isCurrent': {
    '0%': {
      color: colors.pureBlack,
      fontWeight: 'normal',
    },
    '100%': {
      color: colors.blue,
      fontWeight: 'bold',
    },
  },
  container: {
    position: 'absolute',
    opacity: 0,
    pointerEvents: 'none',
    paddingBottom: '1rem',
    transition: 'opacity 0ms ease-out',
    '&.is-open': {
      position: 'static',
      opacity: 1,
      pointerEvents: 'all',
      transition: 'opacity 200ms ease-out',
    },
    // taken out of desktop to avoid JSS bug when nesting functions
    gridTemplateRows: ({ rowsNumber }) => `repeat(${rowsNumber}, auto)`,
    ...desktop({
      position: 'static',
      opacity: 1,
      pointerEvents: 'all',
      '&.is-large': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridAutoFlow: 'column',
      },
    }),
  },
  levelTwoItem: {
    lineHeight: 1.1,
    marginTop: '1rem',
    ...desktop({
      paddingRight: '4rem',
    }),
  },
  levelTwoLink: {
    fontSize: '1.4rem',
    color: colors.pureBlack,
    transition: 'color 200ms ease-out',
    '&:hover': {
      color: colors.blue,
    },
    '&.is-current': {
      animation: '$isCurrent 200ms 1 forwards ease-out 1000ms',
    },
  },
}
