import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware from 'redux-saga'

import reducers from './reducers'
import rootSaga from './sagas'
import TrackingMiddleware from './tracking/middleware'


const getStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware()
  const enhancer = composeWithDevTools(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(TrackingMiddleware)
  )
  const combinedReducers = combineReducers(reducers)
  const store = createStore(combinedReducers, initialState, enhancer)

  store.saga = sagaMiddleware
  store.sagaTask = sagaMiddleware.run(rootSaga)
  return store
}

export default getStore
