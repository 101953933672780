import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'

import withMemo from '../../../decorators/withMemo'
import { selectors as ToolSelectors } from '../OutilWrapper/redux'
import { blockToPracticeTransformer } from '../OutilWrapper/transformers'
import HeaderTopic from '../../../components/HeaderTopic'
import BlockTopic from '../../../components/BlockTopic'
import BlockImage from '../../../components/BlockImage'
import BlockToPractice from '../../../components/BlockToPractice'
import { selectors as PageSelectors } from '../../Pages/Page/redux'
import { useLexiconHandler } from '../../../utils/hooks/useLexicon'
import { actions as AppActions, selectors as AppSelectors } from '../../../redux/app/redux'

import { selectors as RubriqueSelectors, actions as RubriqueActions } from './redux'
import { blockTopicTransformer, headerTopicTransformer } from './transformers'


function RubriqueWrapper(props) {
  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useDispatch()
  const variant = useSelector(PageSelectors.variant)
  const route = useSelector(AppSelectors.route)
  const tools = useSelector(ToolSelectors.tools)
  const currentCategory = useSelector(RubriqueSelectors.currentCategory)
  const [lexicals, handleLexicon] = useLexiconHandler()
  const headerTopicProps = useMemo(() => headerTopicTransformer(props, variant, t, route), [props, variant, t, route])
  const blockToPracticeProps = useMemo(() => blockToPracticeTransformer(t, tools), [t, tools])
  const blockTopicProps = useMemo(() => blockTopicTransformer(props, variant), [props, variant])

  const callSetCurrentCategory = useCallback(() => {
    let menuIndex = null
    const asPath = route?.asPath ?? null
    const locationHash = window?.location?.hash ?? null

    if (locationHash.indexOf('#menu') >= 0) {
      const hashSplit = locationHash.split('#menu')

      menuIndex = parseInt(hashSplit[hashSplit.length - 1], 10)
    } else if (asPath.indexOf('#menu') >= 0) {
      const hashSplit = asPath.split('#menu')

      menuIndex = parseInt(hashSplit[hashSplit.length - 1], 10)
    }

    const calculatedIndex = typeof menuIndex !== 'number' ? 0 : menuIndex

    setTimeout(() => {
      dispatch(RubriqueActions.setCurrentCategory(calculatedIndex))
      dispatch(AppActions.setIsMobileMenuOpen(false))
    }, 500)
  }, [dispatch, route.asPath])

  const onMenuClick = useCallback((index) => {
    window.location.hash = `menu${index}`

    dispatch(RubriqueActions.setCurrentCategory(index))
    dispatch(AppActions.setIsMobileMenuOpen(false))
  }, [dispatch])

  useEffect(() => {
    callSetCurrentCategory()
  }, [callSetCurrentCategory])

  useEffect(() => {
    const handleSlideToCurrentTopic = (url) => {
      callSetCurrentCategory()
      const locationHash = window?.location?.hash ?? null

      if (locationHash.indexOf('#menu') >= 0) {
        const blockTopicTitle = document?.querySelector('#block_topic_title') ?? null

      //  console.log(blockTopicTitle)

        if (blockTopicTitle) {
          const target = blockTopicTitle.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop) - 100

          window.scrollTo({
            top: target,
            behavior: 'smooth',
          })
        }
      }
    }

    router.events.on('routeChangeComplete', handleSlideToCurrentTopic)
    router.events.on('hashChangeComplete', handleSlideToCurrentTopic)

    return () => {
      router.events.off('routeChangeComplete', handleSlideToCurrentTopic)
      router.events.off('hashChangeComplete', handleSlideToCurrentTopic)
    }
  }, [router, callSetCurrentCategory])

  return (
    <>
      <HeaderTopic
        {...headerTopicProps}
        lexicon={lexicals}
        markHover={handleLexicon}
      />
      <BlockTopic
        {...blockTopicProps}
        currentCategory={currentCategory}
        onMenuClick={onMenuClick}
      />
      <BlockImage image={headerTopicProps.imageFaq} />
      <BlockToPractice {...blockToPracticeProps} />
    </>
  )
}

RubriqueWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
}

RubriqueWrapper.defaultProps = {
}

export default withMemo()(RubriqueWrapper)
