import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'

import styles from './styles'


const useStyles = createUseStyles(styles)

const HeaderPractice = (props) => {
  const {
    classes: classesProp,
    className,
    title,
    text,
    zoneTools,
    TagTitle,
    // eslint-disable-next-line no-unused-vars
    variantType,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <header
      className={cx(classes.container, className, variantType === 'practiceHome' && 'is-practicehome')}
      role="heading"
      aria-level="2"
    >
      <div className={classes.wrapper}>
        <div className={classes.titleZone}>
          <TagTitle
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
        <div className={classes.contentZone}>
          <MarkdownStyled
            text={text}
            className={classes.text}
            lexicon={lexicon}
            markHover={markHover}
          />
          <div className={classes.toolsZone}>
            {zoneTools}
          </div>
        </div>
      </div>
    </header>
  )
}

HeaderPractice.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  zoneTools: PropTypes.node,
  TagTitle: PropTypes.string,
  variantType: PropTypes.oneOf(['practicePage', 'practiceHome']),
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

HeaderPractice.defaultProps = {
  className: null,
  classes: null,
  text: '',
  zoneTools: null,
  TagTitle: 'h1',
  variantType: 'practicePage',
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(HeaderPractice)
