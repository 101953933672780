/* eslint-disable max-lines */
import { all } from 'redux-saga/effects'


export default class PageSagas {

  static* init({ languageId, data }) {

  }

  static* loop() {
    yield all([
    ])
  }

}
