import React from 'react'

import FontsResizer from '../../../components/FontsResizer'
import ShareLinks from '../../../components/ShareLinks'
import { generateAkamaiUrl } from '../../../helpers/Akamai'
import { shareLinksTransformer } from '../../../transformers'
import { textDateToObjectTransformer } from '../../Paragraphs/HeaderArticle/transformers'


export const pageLandingTransformer = (page, route, t) => {
  const shareLinks = shareLinksTransformer({
    title: page?.title ?? '',
    url: route?.asPath ?? '',
    mailToBody: t('shareMailBodyLandingPage'),
  }, t)

  shareLinks.title = t('siteShortTitle')

  const dates = page ? textDateToObjectTransformer(page, t) : {}

  const headerProps = {
    title: page?.title ?? '',
    text: page?.fieldContent?.processed ?? '',
    ...dates,
    image: {
      src: generateAkamaiUrl(page?.fieldImage?.entity?.url ?? ''),
    },
    imageMobile: {
      src: generateAkamaiUrl(page?.fieldImageMobile?.entity?.url ?? ''),
    },
    zoneTools:
  <>
    <FontsResizer />
    <ShareLinks
      {...shareLinks}
      isMobileOnly
    />
  </>,
    zoneSharing:
  <>
    <ShareLinks
      {...shareLinks}
      isDesktopOnly
    />
  </>,
  }

  return {
    headerProps,
    pageBlocks: page?.fieldLandingPageBlocks ?? [],
  }
}
