import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import Immutable from 'seamless-immutable'

import i18next from '../../i18n'
import ApiSagas from '../api/sagas'
import Translations from '../../graphql/query/Translations'

import {
  actions as TranslationActions,
  selectors as TranslationsSelectors
} from './redux'


export default class TranslationSagas {

  static* init({ lang, locale }) {
    const initLang = yield select(TranslationsSelectors.lang)

    if (!process.browser) {
      i18next.reset()
      yield put(TranslationActions.saveLang(lang, {}))
    }

    yield call(TranslationSagas.setLang, { lang: lang || initLang, locale })
  }

  static* getLocales() {
    const result = yield call(ApiSagas.persistQuery, Translations.locales)

    if (!result.errors) {
      const objLocales = {}

      result.data.availableLanguages.forEach((item) => {
        objLocales[item.id] = item
        objLocales[item.id].country = item.id.toLowerCase()
      })

      yield put(TranslationActions.setLocales(objLocales))

      return result
    }
    // eslint-disable-next-line no-console
    console.error('Locales not loaded successfully')
    return null
  }

  static* setLang({ lang, locale }) {
    yield call(TranslationSagas.loadLang, lang)

    i18next.changeLanguage(lang)

    yield put(TranslationActions.setLocale(locale))
    yield put(TranslationActions.saveLang(lang, i18next.store.data))
  }

  static* loadLang(lang) {
    const configurations = Immutable.asMutable(yield select(TranslationsSelectors.configurations))
    const store = Immutable.asMutable(yield select(TranslationsSelectors.store))

    let socials = {}

    if (!store[lang]) {
      const result = yield call(ApiSagas.persistQuery, Translations.configuration, { languageId: lang })

      if (!result.errors) {
        const objResult = result.data.nodeQuery?.entities[0]?.fieldTranslations ?? {}
        const apiTranslations = objResult ? JSON.parse(objResult) : {}
        const localeConfiguration = objResult

        store[lang] = {
          translation: {
            ...i18next.locales[lang],
            ...apiTranslations,
          },
        }

        configurations[lang] = localeConfiguration

        socials = {
          twitterAccount: result.data.nodeQuery?.entities[0]?.fieldTwitterAccount,
          facebookAccount: result.data.nodeQuery?.entities[0]?.fieldFacebookAccount,
          instagramAccount: result.data.nodeQuery?.entities[0]?.fieldInstagramAccount,
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Translations not loaded successfully')
      }
    }

    if (store[lang] && store[lang].translation) {
      i18next.addResourceBundle(lang, 'translation', store[lang].translation)
    }

    yield all([
      put(TranslationActions.setStore(store)),
      put(TranslationActions.setConfigurations(configurations)),
      put(TranslationActions.setConfiguration(configurations[lang])),
      put(TranslationActions.setSocials(socials)),
    ])
  }

  static* loop() {
    yield all([
      takeLatest(TranslationActions.setLang.getType(), TranslationSagas.setLang),
    ])
  }

}
