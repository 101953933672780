import { colors, tablet } from '../../theme'


export default {
  container: {
    flex: '0 0 auto',
    width: '17rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    transition: (props) => `width ease-out ${(props.animationTime - 100) ?? 600}ms`,
    '&:first-child': {
      marginLeft: 'auto',
    },
    '&:last-child': {
      marginRight: 'auto',
    },
    '&.is-current': {
      width: '24rem',
    },
  },
  link: {
    position: 'relative',
    zIndex: 2,
    display: 'block',
    height: '100%',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    background: colors.greyF6,
    borderRadius: '1rem',
    transition: (props) => `all ease-out ${(props.animationTime - 100) ?? 600}ms`,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '&::before': {
      bottom: '-3.3rem',
      zIndex: 2,
      width: '1.2rem',
      height: '1.2rem',
      borderRadius: '50%',
      background: colors.bluePale,
      border: `0.3rem solid ${colors.pureWhite}`,
      transition: (props) => `all ease-out ${(props.animationTime - 100) ?? 600}ms`,
    },
    '&::after': {
      bottom: '-2.7rem',
      zIndex: 1,
      width: '0.1rem',
      height: '2rem',
      background: colors.bluePale,
      transition: (props) => `all ease-out ${(props.animationTime - 100) ?? 600}ms`,
    },
    '$container.is-current &': {
      background: colors.purpleQuestions,
    },
    '$container.is-current &::before': {
      background: colors.purpleQuestions,
    },
    '$container.is-current &::after': {
      background: colors.turquoiseDark,
    },
  },
  textBox: {
    margin: 0,
  },
  text: {
    textAlign: 'center',
    fontSize: '1.4rem',
    color: colors.purpleQuestions,
    fontWeight: '400',
    transition: (props) => `all ease-out ${(props.animationTime - 100) ?? 600}ms`,
    '& strong': {
      fontSize: '1.7rem',
      display: 'block',
      color: colors.turquoiseDark,
    },
    '$container.is-current &': {
      color: colors.pureWhite,
    },
    '$container.is-current & strong': {
      color: colors.turquoiseDark,
    },
  },
  eyelet: {
    position: 'absolute',
    top: '-0.5rem',
    left: '50%',
    width: '1rem',
    height: '1.7rem',
    marginLeft: '-3rem',
    transform: 'translateX(-50%)',
    '& + &': {
      left: 'auto',
      right: '50%',
      transform: 'translateX(50%)',
      marginLeft: 0,
      marginRight: '-3rem',
    },
  },
  ...tablet({
    container: {
      '&.is-current': {
        width: '42rem',
      },
    },
  }),
  '@media print': {
    container: {
      padding: '1rem',
      '&, &:first-child, &:last-child': {
        margin: '1rem',
      },
      '&, &.is-current': {
        width: 'auto',
        border: '1px solid transparent',
      },
      '&.is-current': {
        borderColor: colors.greyD9,
      },
    },
    eyelet: {
      display: 'none',
    },
    link: {
      padding: 0,
      '&::before, &::after': {
        display: 'none',
      },
    },
  },
}
