import React from 'react'
import PropTypes from 'prop-types'
import { NextSeo } from 'next-seo'
import { useSelector } from 'react-redux'

import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import JsonLd from '../JsonLd'
import withMemo from '../../decorators/withMemo'


const Seo = (props) => {
  const {
    metas,
    type,
    twitterAccount,
    twitterCard,
    jsonld,
    base,
    alternates,
    image,
  } = props

  // a handy callback to get a meta value by key name
  const metaByKey = (name) => metas?.find((item) => item.key === name)?.value

  // get socials from store
  const socials = useSelector(TranslationSelectors.socials)

  // twitterAccount is never set, so why use it ? leaving it for now
  const twitter = twitterAccount ?? socials?.twitterAccount

  const additionalLinkTags = metas
      ?.filter((item) => item.__typename === 'MetaLink') // take only meta links
      ?.map((item) => ({ // map them into compatible format
        rel: item.key,
        href: item.value,
      }))

  const excludedFromDuplications = [
    'title',
    'description',
    'og:title',
    'og:site_name',
    'og:url',
    'og:image',
    'og:description',
  ]

  const additionalMetaTags = [
    {
      name: 'keywords',
      content: metaByKey('keywords'),
    },
    ...metas
        // filter only meta values
        ?.filter((item) => item.__typename === 'MetaValue')
        // avoid duplicates
        ?.filter((item) => !excludedFromDuplications.includes(item.key))
        // map them into compatible format
        ?.map((item) => ({
          name: item.key,
          content: item.value,
        })),
    ...metas
        // filter only meta properties
        ?.filter((item) => item.__typename === 'MetaProperty')
        // avoid duplicates
        ?.filter((item) => !excludedFromDuplications.includes(item.key))
        // map them into compatible format
        ?.map((item) => ({
          property: item.key,
          content: item.value,
        })),
  ]

  return (
    <>
      <NextSeo
        title={metaByKey('title') ?? metaByKey('og:site_name')}
        description={metaByKey('description')}
        canonical={metaByKey('og:url')}
        openGraph={{
              url: metaByKey('og:url'),
              images: [
                {
                  url: image?.length ? image : metaByKey('og:image'),
                },
              ],
              site_name: metaByKey('og:site_name'),
              type,
              description: metaByKey('og:description') ?? metaByKey('description'),
            }}
        twitter={{
              handle: twitter,
              site: twitter,
              cardType: twitterCard,
            }}
        additionalMetaTags={additionalMetaTags}
        additionalLinkTags={additionalLinkTags}
        languageAlternates={alternates && Object.keys(alternates).map((item, i) => ({
              hrefLang: item,
              href: alternates[item],
            }))}
      />
      {jsonld && <JsonLd data={jsonld} />}
      {base && <base />}
    </>
  )
}

Seo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  jsonld: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  alternates: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  metas: PropTypes.array,
  type: PropTypes.string,
  twitterCard: PropTypes.string,
  twitterAccount: PropTypes.string,
  base: PropTypes.string,
  image: PropTypes.string,
}

Seo.defaultProps = {
  base: '',
  alternates: null,
  twitterAccount: null,
  type: 'website',
  twitterCard: 'summary_large_image',
  metas: [],
  jsonld: null,
  image: null,
}

export default withMemo()(Seo)
