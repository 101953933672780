import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    borderTop: '0.4rem solid',
    borderBottom: '0.4rem solid',
    padding: '3rem 1.5rem',
    borderColor: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
          : props.variant === 'listen' ? colors.orangeDarkestListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
    // defaults overrides
    '& h3, & h4, & h5, & h6': {
      textTransform: 'uppercase',
      color: (props) => (
        props.variant === 'care' ? colors.redCare
          : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
            : props.variant === 'listen' ? colors.orangeDarkestListen
              : props.variant === 'discover' ? colors.blueDiscover
                : colors.blue),
    },
    // vertical spacing override
    '& h3, & h4 + *, & h5 + *, & h6 + *': {
      marginTop: '1rem',
    },
    '& h3, & h4:not(:first-child), & h5:not(:first-child), & h6:not(:first-child)': {
      marginTop: '1.5rem',
    },
    '& strong, & b': {
      fontWeight: 'bold',
    },
    '& h3': {
      margin: 0,
    },
  },
  ...tablet({
    container: {
      padding: '2rem 0.5rem',
      // defaults overrides
      '& h3, & h4': {
        fontSize: '1.8rem',
      },
      '& h3, & h4, & h5, & h6': {
        ...fonts.MaaxRegular,
        color: 'inherit !important', // jss is adding specificity to the rule above by putting it after this one
      },
      '& p': {
        ...fonts.MaaxMedium, // strange to override this only in this mq, stay aware
      },
      '& strong, & b': {
        fontWeight: 'bold',
      },
    },
  }),
  '@media print': {
    container: {
      breakInside: 'avoid',
      pageBreakInside: 'avoid',
      borderColor: `${colors.greyE7} !important`,
    },
  },
}
