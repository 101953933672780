import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useSelector } from 'react-redux'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import Icon from '../Icon'
import icons from '../Icon/assets'
import { selectors as QuestionsDuMomentSelectors } from '../../Wrappers/Templates/QuestionsDuMomentWrapper/redux'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Breadcrumb = (props) => {
  const { classes: classesProp, className, links } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const currentPeriode = useSelector(QuestionsDuMomentSelectors.currentPeriode)


  const renderDetails = () => {
    if (typeof window !== 'undefined') {
      if (links[links.length - 1].slug === 'questions-du-moment' && window.location.hash !== '') {
        if (currentPeriode?.text) {
        const html = currentPeriode.text

        const div = document.createElement('div')

        div.innerHTML = html
        const text = div.textContent || div.innerText || ''

        return (<li className={classes.item}><A className={classes.link}>{text}</A></li>)
        }
      }
    }
  }

  return links && links.length > 0 ? (
    <nav className={cx(classes.container, className)}>
      <ul className={classes.list}>
        {links.map((link, index) => (
          <li
            key={`nav-${index}`}
            className={classes.item}
          >
            <A
              className={classes.link}
              {...link.linkProps}
            >
              {index === 0 && (
                <Icon
                  icon={icons.Home}
                  className={classes.homeIcon}
                />
              )}
              {link.text}
            </A>
          </li>
        ))}
        {renderDetails()}
      </ul>
    </nav>
  ) : null
}

Breadcrumb.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
    text: PropTypes.string,
    linkProps: PropTypes.shape(A.propTypes),
  })),
}

Breadcrumb.defaultProps = {
  className: null,
  classes: null,
  links: null,
}

export default withMemo()(Breadcrumb)
