import gql from 'graphql-tag'


export const TaxonomyTermRubriqueFragment = {
  fragment: gql`
        fragment TaxonomyTermRubriqueFragment on TaxonomyTermRubrique {
            weight
            fieldRubriqueClass
            entityLabel
            reverseFieldArticleSousRubriqueNode(limit:100){
                count
                entities{
                    ... on NodeArticle{
                        title
                        fieldArticleOrdre
                        body{value}
                        fieldImage{
                            url
                            alt
                        }
                        entityUrl{
                            path
                        }
                    }
                }
            }
        }
    `,
}
