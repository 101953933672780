import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'
import { createUseStyles, ThemeProvider } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import { actions as TrackingActions } from '../../redux/tracking/redux'
import { selectors as AppSelectors } from '../../redux/app/redux'
import { selectors as PageSelectors } from '../../Wrappers/Pages/Page/redux'
import { themeNames } from '../../theme'
import { Router } from '../../routes'
import ErrorView from '../../pages/Error'
import Tracker from '../Tracker'
import Seo from '../../components/Seo'
import app from '../../configuration/app'
import withMemo from '../../decorators/withMemo'
import i18next from '../../i18n'
import LoadingBar from '../../components/LoadingBar'
import { tcPagenameTransformer } from '../../transformers'
import AdvertPopin from '../../components/AdvertPopin'

import styles from './styles'


const useStyles = createUseStyles(styles)

let prevPagename = ''

const AppGate = (props) => {
    // eslint-disable-next-line react/prop-types
    const { Component, pageContext, ...pageProps } = props

    const alternates = useSelector(PageSelectors.alternates)
    const preview = useSelector(AppSelectors.preview)
    const seo = useSelector(AppSelectors.seo)
    const jsonld = useSelector(AppSelectors.jsonld)

    const isError = pageProps.error
    const isErrorComp = pageProps.errorComponent

    const classes = useStyles(props)
    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const variant = useSelector(PageSelectors.variant)

    useEffect(() => {
        // on load
        document.querySelector('html').setAttribute('lang', i18n?.language?.substr(0, 2)?.toLowerCase())
        // on language change
        i18next.on('languageChanged', () => {
            document.querySelector('html').setAttribute('lang', i18n?.language?.substr(0, 2)?.toLowerCase())
        })
        // cleanup
        return () => {
            i18next.off('languageChanged')
        }
    }, [i18n])

    const changeTcVars = useCallback(async() => {
        if (window?.tc_vars && seo) {
            const metas = seo?.metas ?? []
            const pagename = tcPagenameTransformer(metas)
            const env_device = window?.navigator?.userAgentData?.mobile ? 'mobile' : 'desktop'
            const env_language = window?.navigator?.language
            // eslint-disable-next-line no-undef
            const cookie_consent = (typeof tC !== 'undefined' && tC?.privacy?.getListCategoryConsent() !== 'optout' && tC?.privacy?.getListCategoryConsent() !== 'no_consent') ? 'true' : 'false'

            try {
                const url = 'https://ipwho.is/'
                const response = await axios.get(url)
                window.tc_vars.env_country = response?.data?.country ?? ''
                console.log('######', window.tc_vars.env_country)
            } catch (error) {
                console.error('Error fetching geolocation:', error)
            }

            if (pagename) {
                window.tc_vars.pagename = pagename
                // https://community.commandersact.com/tagcommander/tips-and-tricks/best-practices/common-trigger-strategies#page-view-trigger
                // eslint-disable-next-line no-undef
                if (typeof tC !== 'undefined') {
                    if (prevPagename !== pagename) {
                        prevPagename = pagename
                        // eslint-disable-next-line no-undef
                        // tC.container.reload({ events: { pageview: [{}, {}] } })
                    }
                }
            }

            if (env_device) {
                window.tc_vars.env_device = env_device
            }

            if (env_device) {
                window.tc_vars.env_language = env_language
            }

            if (typeof cookie_consent !== 'undefined') {
                window.tc_vars.cookie_consent = cookie_consent
            }

            window.tc_vars.page_url = window.location.href
        }
    }, [seo])

    const bodyRef = useRef()

    useEffect(() => {
        changeTcVars()
        Router.onRouteChangeComplete = () => {
            dispatch(TrackingActions.trackPage())
            changeTcVars()
        }
    }, [changeTcVars, dispatch])

    const getScrollPercentage = useCallback(() => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement

        return Math.floor(((scrollTop + clientHeight) / scrollHeight) * 1000)
    }, [])

    const handleOnScroll = useCallback(() => {
        // eslint-disable-next-line no-undef
        if (!!tC?.event && !!bodyRef.current) {
            const scrollPercentage = getScrollPercentage()

            if (scrollPercentage === 1000) {
                console.log({ scrollLevel: 100 })
                // eslint-disable-next-line no-undef
                return tC?.event?.scroll(bodyRef.current, { scrollLevel: 100 })
            }
            if (scrollPercentage === 750) {
                console.log({ scrollLevel: 75 })
                // eslint-disable-next-line no-undef
                return tC?.event?.scroll(bodyRef.current, { scrollLevel: 75 })
            }
            if (scrollPercentage === 500) {
                console.log({ scrollLevel: 50 })
                // eslint-disable-next-line no-undef
                return tC?.event?.scroll(bodyRef.current, { scrollLevel: 50 })
            }
            if (scrollPercentage === 250) {
                console.log({ scrollLevel: 25 })
                // eslint-disable-next-line no-undef
                return tC?.event?.scroll(bodyRef.current, { scrollLevel: 25 })
            }
        }
    }, [getScrollPercentage])

    useEffect(() => {
        window.addEventListener('scroll', handleOnScroll)
        return () => {
            try {
                if (typeof window !== 'undefined') {
                    window.removeEventListener('scroll', handleOnScroll)
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
            }
        }
    }, [])

    return (
      <div
        className={classes.wrapper}
        ref={bodyRef}
      >
        {seo && seo.metas && (
        <Seo
          {...seo}
          alternates={alternates}
          base={preview === true ? app.APP_PREVIEW_URL : app.APP_URL}
          jsonld={jsonld || {}}
        />
            )}
        <AdvertPopin />
        <LoadingBar variant={variant} />

        <Tracker />

        {isError && !isErrorComp && (
        <ErrorView
          pageContext={pageContext}
          {...pageProps}
        />
            )}

        {Component && (
        <>
          <ThemeProvider theme={{ APP_THEME: themeNames.DEFAULT }}>
            <Component
              pageContext={pageContext}
              {...pageProps}
            />
          </ThemeProvider>
        </>
            )}
      </div>
    )
}

AppGate.propTypes = {
    pageProps: PropTypes.shape({
        error: PropTypes.number,
        errorComponent: PropTypes.any,
    }),
}

AppGate.defaultProps = {
    pageProps: {},
}

export default withMemo()(AppGate)
