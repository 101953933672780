import gql from 'graphql-tag'

import { ParagraphFaqBlocFragment } from './ParagraphFaqBlocFragment'


export const FaqFragment = {
  fragment: gql`
    fragment FaqFragment on NodeFaq {
      title
      body{value}
      entityUrl {
        path
      }
      fieldImageReseauxSociaux{
          url
      }
      entityMetatags{
        key
        value
      }
      fieldFaqBlocsQuestionReponse{
        entity{
          __typename
          ... on ParagraphFaqBloc{
              ...ParagraphFaqBlocFragment
          }
        }
      }
    }
    ${ParagraphFaqBlocFragment.fragment}
  `,
}
