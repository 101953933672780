import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import debounce from 'lodash/debounce'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Icon from '../Icon'
import icons from '../Icon/assets'
import MarkdownStyled from '../MarkdownStyled'
import { safeCall } from '../../helpers/React'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockFaq = (props) => {
  const {
    classes: classesProp,
    className,
    question,
    handleOnToggle,
    isOpen,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const refAnswer = useRef()
  const [maxHeight, setMaxHeight] = useState(0)

  const handleToggle = useCallback(() => {
    safeCall(handleOnToggle)
  }, [handleOnToggle])

  const handleMaxHeight = useCallback(
    debounce(() => {
      if (refAnswer.current && isOpen) {
        setMaxHeight(refAnswer.current.offsetHeight)
      } else {
        setMaxHeight(0)
      }
    }, 50),
    [isOpen]
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleMaxHeight()
      window.addEventListener('resize', handleMaxHeight)
    }
    return () => {
      try {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleMaxHeight)
        }
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [handleMaxHeight])

  return (
    <div className={cx(classes.container, className, isOpen && 'is-open')}>
      <dt
        className={classes.trigger}
        onClick={handleToggle}
      >
        <MarkdownStyled
          text={question.question}
          tag="h4"
          className={classes.question}
          lexicon={lexicon}
          markHover={markHover}
        />
        <Icon
          icon={icons.ChevronSquareDown}
          className={classes.icon}
        />
      </dt>
      <dd
        className={classes.collapser}
        style={{ height: `${maxHeight / 10}rem` }}
      >
        <div
          ref={refAnswer}
          className={classes.collapsed}
        >
          <MarkdownStyled
            text={question.answer}
            className={classes.answer}
            lexicon={lexicon}
            markHover={markHover}
          />
        </div>
      </dd>
    </div>
  )
}

BlockFaq.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  question: PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string,
  }),
  handleOnToggle: PropTypes.func,
  isOpen: PropTypes.bool,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

BlockFaq.defaultProps = {
  className: null,
  classes: null,
  question: null,
  handleOnToggle: () => null,
  isOpen: false,
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(BlockFaq)
