import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import * as components from '../../Wrappers/Templates'
import withMemo from '../../decorators/withMemo'


const TemplatesParser = (props) => {
  const { fieldTemplate, className, fieldNode } = props

  let node = fieldNode

  node = fieldNode.startsWith('QuestionsDuMoment') ? 'QuestionsDuMoment' : node

  let templateName = null

  let TemplateComponent = null

  if (fieldTemplate && fieldTemplate.entity) {
    templateName = `${fieldTemplate.entity.__typename.toCamelCase()}Wrapper`
    TemplateComponent = components[templateName] ? components[templateName] : null
  } else if (node) {
    templateName = `${node.replace('Node', '').toCamelCase()}Wrapper`
    TemplateComponent = components[templateName] ? components[templateName] : null
  }

  if (!TemplateComponent) {
    return null
  }

  return (
    <>
      <TemplateComponent
        {...props}
        className={cx(className)}
      />
    </>
  )
}

TemplatesParser.propTypes = {
  fieldNode: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  fieldTemplate: PropTypes.object,
  className: PropTypes.string,
}
TemplatesParser.defaultProps = {
  fieldTemplate: null,
  fieldNode: null,
  className: null,
}

export default withMemo()(TemplatesParser)
