import { colors, grids, tablet } from '../../theme'


export default {
  container: {
    marginTop: '3rem',
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '3rem',
    },
  },
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > *:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  buttons: {
    textAlign: 'center',
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  button: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '4rem',
    border: `1px solid ${colors.blue}`,
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: '1.25',
    textTransform: 'uppercase',
    color: colors.pureWhite,
    backgroundColor: colors.blue,
    transition: 'all 200ms ease-out',
    cursor: 'pointer',
    '&:hover': {
      color: colors.blue,
      backgroundColor: colors.pureWhite,
      borderColor: colors.blue,
    },
  },
  ...tablet({
    container: {
      marginTop: '6rem',
      // penultimate child has a margin bottom because last is lexicon container
      '&:nth-last-child(2)': {
        marginBottom: '6rem',
      },
    },
    wrapper: {
      '& > *:not(:first-child)': {
        marginTop: '6rem',
      },
    },
    buttons: {
      '&:not(:first-child)': {
        marginTop: '2.5rem',
      },
    },
  }),
  '@media print': {
    buttons: {
      display: 'none',
    },
  },
}
