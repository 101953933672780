import { colors } from '../../theme'


export default {
  container: {
    position: 'fixed',
    width: '100%',
    margin: '0 auto',
    height: '0.3rem',
    zIndex: '999',
    top: '0',
    overflow: 'hidden',
  },
  loader: {
    position: 'absolute',
    height: '0.3rem',
    width: '100%',
    backgroundColor: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseEnvironment
          : props.variant === 'listen' ? colors.orangeListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
  },
}
