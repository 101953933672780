const colors = {
  pureBlack: '#333333',
  pureWhite: '#FFF',
  error: '#EB5757',
  redInfo: '#ff5640',
  primary: '#FF7326',
  //
  blue: '#3333ff',
  blueTropaz: '#2a54a8',
  blueSteel: '#336699',
  bluePale: '#B8B8FF',
  turquoise: '#00ffdf',
  turquoiseDark: '#12CFB8',
  // care / soins
  redCare: '#ff5542',
  redDarkestCare: '#e34c3d',
  yellowCare: '#fcff99',
  // environment
  turquoiseEnvironment: '#75e1d3',
  turquoiseDarkEnvironment: '#2db1b1',
  turquoiseDarkestEnvironment: '#0fa290',
  blueEnvironment: '#323fb3',
  blueDarkEnvironment: '#242a84',
  // listen
  orangeListen: '#ffab1a',
  orangeDarkListen: '#ff9f2e',
  orangeDarkestListen: '#ff6600',
  blueListen: '#0c36c0',
  blueDarkListen: '#00187a',
  // discover
  blueDiscover: '#6666ff',
  blueDarkDiscover: '#5e4fef',
  yellowDiscover: '#f0ff00',
  // questions
  lavenderQuestionsE2: '#E2E2FF',
  turquoiseQuestions: '#74E1D3',
  blueQuestions: '#3039B2',
  purpleQuestions: '#1903AE',
  //
  grey67: '#676767',
  grey95: '#959da3',
  greya8: '#a8a8a8',
  greyDD: '#DDDDDD',
  greyD9: '#D9DEE2',
  greyDE: '#dee3e7',
  greyE7: '#E7EDF1',
  greyEE: '#EEEEEE',
  greyEF: '#EFEFEF',
  greyF6: '#F6F7F9',
  //
  currentColor: 'currentColor',
}

export default colors
