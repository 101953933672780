import { accessibleHiddenText, colors, fonts, grids, mobile } from '../../theme'


export default {
  container: {
    marginTop: '3rem',
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '3rem',
    },
  },
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > *:not(:first-child)': {
      marginTop: '4rem',
    },
  },
  main: {
    '& > *:not(:first-child)': {
      marginTop: '5rem',
    },
  },
  form: {},
  field: {
    position: 'relative',
  },
  input: {
    ...fonts.MaaxRegular,
    display: 'block',
    width: '100%',
    height: '5rem',
    padding: '0 6.5rem',
    border: `1px solid ${colors.blue}`,
    fontSize: '2.1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: colors.blue,
    transition: 'all 200ms ease-out',
    '&:focus': {
      boxShadow: '0 0 1rem rgba(0, 0, 0, 0.2)',
    },
    '&::placeholder': {
      color: colors.grey67,
    },
  },
  fieldDecoration: {
    position: 'absolute',
    top: '50%',
    left: '1.5rem',
    display: 'block',
    width: '3.7rem',
    height: '2.8rem',
    color: colors.blue,
    transform: 'translateY(-50%)',
  },
  submit: {
    position: 'absolute',
    top: '50%',
    right: '2rem',
    display: 'flex',
    width: '2.8rem',
    height: '1rem',
    color: colors.blue,
    transform: 'translateY(-50%)',
    '& > i': {
      ...accessibleHiddenText,
    },
  },
  count: {
    '& > *:not(:first-child)': {
      marginTop: '0.5rem',
    },
  },
  countResults: {
    fontWeight: 'bold',
    fontSize: '2.2rem',
    margin: 0,
  },
  countPages: {
    fontSize: '1.7rem',
  },
  toolsZone: {
    '& > *:not(:first-child)': {
      display: 'none',
    },
    '& > * + *': {
      marginTop: '1.5rem',
    },
  },
  ...mobile({
    container: {
      marginTop: '7rem',
      // penultimate child has a margin bottom because last is lexicon container
      '&:nth-last-child(2)': {
        marginBottom: '7rem',
      },
    },
    wrapper: {
      display: 'flex',
      flexFlow: 'row-reverse nowrap',
      justifyContent: 'flex-start',
      '& > *:not(:first-child)': {
        marginTop: '0',
      },
    },
    main: {
      flex: '0 0 auto',
      width: 'calc(100% / 12 * 9)',
      paddingLeft: '6.5rem',
    },
    toolsZone: {
      width: 'calc(100% / 12 * 3)',
      '& > *:not(:first-child)': {
        display: 'block',
      },
    },
    input: {
      fontSize: '2.4rem',
    },
  }),
  '@media print': {
    toolsZone: {
      display: 'none',
    },
    input: {
      borderColor: colors.greyD9,
    },
  },
}
