import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import Icon from '../Icon'
import icons from '../Icon/assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const ShareLink = (props) => {
  const {
    classes: classesProp,
    className,
    linkProps,
    icon,
    text,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <A
      className={cx(classes.container, className)}
      {...linkProps}
    >
      {icon && (
        <Icon
          className={classes.icon}
          icon={icons[icon]}
        />
      )}
      <i>{text}</i>
    </A>
  )
}

ShareLink.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  linkProps: PropTypes.shape(A.propTypes),
  icon: PropTypes.string,
  text: PropTypes.string,
}

ShareLink.defaultProps = {
  className: null,
  classes: null,
  linkProps: null,
  icon: '',
  text: '',
}

export default withMemo()(ShareLink)
