import gql from 'graphql-tag'


export const VideoBySlug = gql`
    query nodeByPath($path: String!) {
        route: route(path: $path) {
            ... on DefaultInternalUrl {
                metatags {
                    key
                    value
                }
                breadcrumb {
                    text
                    url {
                        path
                    }
                }
            }
            ... on EntityCanonicalUrl {
                breadcrumb {
                    text
                    url {
                        path
                    }
                }
                nodeContext {
                    entityMetatags {
                        key
                        value
                    }
                }
                entity {
                    ... on NodeVideo {
                        nid
                        fieldUrlVideo
                        fieldDuration
                        entityUrl {
                            path
                        }
                        fieldImage {
                            url
                            width
                            height
                        }
                        fieldCoverShare {
                            url
                            width
                            height
                        }
                        fieldDescription
                        fieldLogo {
                            url
                            width
                            height
                        }
                        fieldTranscription {
                            entity {
                                title
                                entityUrl {
                                    path
                                }
                                ... on NodeVideoTranscription {
                                    title
                                    fieldContent {
                                        processed
                                    }
                                }
                            }
                        }
                        fieldSubtitles {
                            description
                        }
                    }
                }
            }
        }
    }
`
