import { accessibleHiddenText, colors, fonts, grids, mobile, tablet } from '../../theme'


export default {
  container: {
    // fonts are declared here so that it's easy to override in other contexts
    ...fonts.MaaxBold,
    textTransform: 'uppercase',
    color: colors.pureBlack,
    fontSize: '1.1rem',
    marginTop: '6rem',
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '6rem',
    },
  },
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'inherit',
  },
  // slider stuff
  slider: {
    position: 'relative',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    '& > .keen-slider': {
      visibility: 'hidden',
    },
  },
  nav: {
    position: 'absolute',
    top: '50%',
    zIndex: 5,
    cursor: 'pointer',
    color: colors.blue,
    padding: '1rem',
    transform: 'translateY(-70%)',
    '&.is-disabled': {
      opacity: '50%',
      pointerEvents: 'none',
    },
    '& i': {
      ...accessibleHiddenText,
    },
  },
  previous: {
    left: '-1rem',
  },
  next: {
    right: '-1rem',
  },
  navIcon: {
    width: '1.5rem',
    height: '3rem',
  },

  // content
  item: {
    textAlign: 'center',
    '&:not(:first-child)': {
      marginTop: '6rem',
    },
    '$container.is-smaller--mobile &:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  link: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '12rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'inherit',
  },
  text: {
    marginTop: '2rem',
    fontSize: '1.4rem',
    marginBottom: 0,
  },
  figure: {
    margin: 0,
    width: '11rem',
    height: '11rem',
    '$container.is-smaller--mobile &': {
      width: '6rem',
      height: '6rem',
    },
  },
  image: {
  },
  ...mobile({
    nav: {
      display: 'none',
    },
    slider: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    item: {
      '$container.is-smaller--mobile &:not(:first-child)': {
        marginTop: '6rem',
      },
    },
    figure: {
      width: '12rem',
      height: '12rem',
      '$container.is-smaller--mobile &': {
        width: '8rem',
        height: '8rem',
      },
    },
  }),
  ...tablet({
    link: {
      maxWidth: '16rem',
    },
    container: {
      fontSize: '1.4rem',
    },
    figure: {
      width: '14rem',
      height: '14rem',
      '$container.is-smaller--mobile &': {
        width: '10rem',
        height: '10rem',
      },
    },
  }),
}
