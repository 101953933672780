import React from 'react'

import FontsResizer from '../../../components/FontsResizer'
import ShareLinks from '../../../components/ShareLinks'
import { shareLinksTransformer } from '../../../transformers'


export const headerPracticeTransformer = (params, t) => ({
  title: params?.title?.replace('|', '<br />') ?? t('headerPracticeTitle'),
  variant: 'practicePage',
  text: `<p>${t('headerPracticeText')}</p>`,
  zoneTools:
  <>
    <FontsResizer />
    <ShareLinks {...shareLinksTransformer({ printJs: false, downloadPdf: false, title: params?.title ?? t('headerPracticeTitle'), mailToBody: t('sharePracticeBody'), url: params?.url ?? null }, t)} />
  </>,
})
