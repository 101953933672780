export const lexicalsTransformer = (data) => data && data?.map((lexicon) => ({
  term: lexicon.name,
  terms: (lexicon?.fieldGlossaireVariations ?? []).concat([lexicon.name]),
  text: lexicon?.description?.value ?? null,
  linkProps: { href: `/fr/lexique?term=${lexicon.name}` },
}) ?? []).sort((a, b) => {
  if (a.term < b.term) {
    return -1
  }
  if (a.term > b.term) {
    return 1
  }
  return 0
})
