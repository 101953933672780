const PORT = process.env.PORT !== '80' && process.env.PORT

export default {
  APP_ENV: process.env.APP_ENV || 'production',
  APP_VERSION: process.env.APP_VERSION || '',
  BANNER: process.env.BANNER || false,
  PORT,
  APP_URL: process.env.APP_URL,
  APP_PREVIEW_URL: process.env.APP_PREVIEW_URL,
  SECURE_COOKIE: process.env.SECURE_COOKIE === 'true',
  COOKIE_NAME: process.env.COOKIE_NAME || 'auth_token',
  IS_PREVIEW: process.env.IS_PREVIEW === 'true',
  APP_URL_ASSETS: process.env.APP_URL_ASSETS ?? '',
  APP_URL_ASSETS_REPLACE: process.env.APP_URL_ASSETS_REPLACE ?? '',
  APP_URL_ASSETS_SEARCH: process.env.APP_URL_ASSETS_SEARCH ?? '',
  IGNORE_API_SSL_ERRORS: process.env.API_IGNORE_SSL_ERRORS ? (process.env.API_IGNORE_SSL_ERRORS === 1 || process.env.API_IGNORE_SSL_ERRORS === '1') : false,
  CANVAS_CORS: process.env.CANVAS_CORS ? (process.env.CANVAS_CORS === 1 || process.env.CANVAS_CORS === '1') : false,
  BACKEND_URL: process.env.BACKEND_URL ?? '',
}
