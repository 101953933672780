import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import LexiconEntry from '../LexiconEntry'

import styles from './styles'


const useStyles = createUseStyles(styles)

const LexiconLetter = (props) => {
  const {
    classes: classesProp,
    className,
    letter,
    terms,
    TagTitle,
  } = props
  const rowsNumber = useMemo(() => (terms ? Math.ceil(terms.length / 2) : 0), [terms])
  const classesComp = useStyles({ ...props, rowsNumber })
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const renderTerms = useMemo(() => terms && (
    <div className={classes.terms}>
      <dl className={classes.list}>
        {terms.slice(0, rowsNumber).map((term, index) => (
          <LexiconEntry
            classname={classes.entry}
            key={`lexiconletter-col1-${index}`}
            {...term}
            isDefaultOpen={index === 0}
          />
        ))}
      </dl>
      {terms.length > 1 && (
        <dl className={classes.list}>
          {terms.slice(rowsNumber).map((term, index) => (
            <LexiconEntry
              classname={classes.entry}
              key={`lexiconletter-col1-${index}`}
              {...term}
            />
          ))}
        </dl>
      )}
    </div>
  ), [classes.entry, classes.list, classes.terms, rowsNumber, terms])

  return (
    <div
      className={cx(classes.container, className)}
      id={`lexiconletter-${letter}`}
    >
      <TagTitle className={classes.letter}>{letter}</TagTitle>
      {renderTerms}
    </div>
  )
}

LexiconLetter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  letter: PropTypes.string,
  terms: PropTypes.arrayOf(PropTypes.shape(LexiconEntry.propTypes)),
  TagTitle: PropTypes.string,
}

LexiconLetter.defaultProps = {
  className: null,
  classes: null,
  letter: '',
  terms: null,
  TagTitle: 'h2',
}

export default withMemo()(LexiconLetter)
