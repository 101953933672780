import { accessibleHiddenText } from '../../theme'


export default {
  container: {
    display: 'inline-flex',
    height: '1.8rem',
    width: '2rem',
    color: 'inherit',
    cursor: 'pointer',
    '& i': {
      ...accessibleHiddenText,
    },
  },
}
