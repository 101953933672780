import React from 'react'
import PropTypes from 'prop-types'

import withMemo from '../../../decorators/withMemo'
import { BlocksParser } from '../../../helpers/BlocksHelpers'
import { useLexiconHandler } from '../../../utils/hooks/useLexicon'


function PageWrapper(props) {
  const { fieldParagraphs } = props.fieldTemplate.entity
  const [lexicals, handleLexicon] = useLexiconHandler()

  return (
    <>
      {fieldParagraphs && (
        <BlocksParser
          blocks={fieldParagraphs}
          options={{
            lexicon: lexicals,
            markHover: handleLexicon,
          }}
        />
      )}
    </>
  )
}

PageWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fieldTemplate: PropTypes.object,
}

PageWrapper.defaultProps = {
  fieldTemplate: null,
}

export default withMemo()(PageWrapper)
