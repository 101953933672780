import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import FontsResizerButton from '../FontsResizerButton'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FontsResizer = (props) => {
  const {
    classes: classesProp,
    className,
    sizes,
  } = props

  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const handleResize = useCallback((targetSize) => {
    if (document.querySelector('html')) {
      document.querySelector('html').style.fontSize = `${targetSize / 16 * 100}%`
    }
  }, [])

  return sizes && (
    <ul className={cx(classes.container, className)}>
      {sizes.map((size, index) => (
        <li
          className={classes.item}
          key={`fontsresizer-${index}`}
        >
          <FontsResizerButton
            {...size}
            onResizeHandler={handleResize}
          />
        </li>
      ))}
    </ul>
  )
}

FontsResizer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  sizes: PropTypes.arrayOf(PropTypes.shape(FontsResizerButton.propTypes)),
}

FontsResizer.defaultProps = {
  className: null,
  classes: null,
  sizes: [
    {
      textResize: 'Taille de police plus petite',
      textLetter: 'a',
      displaySize: 10,
      targetSize: 8.5625,
    },
    {
      textResize: 'Taille de police moyenne',
      textLetter: 'a',
      displaySize: 16,
      targetSize: 10,
    },
    {
      textResize: 'Taille de police plus grande',
      textLetter: 'a',
      displaySize: 20,
      targetSize: 11.25,
    },
  ],
}

export default withMemo()(FontsResizer)
