import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import NavSideLink from '../NavSideLink'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavSide = (props) => {
  const {
    classes: classesProp,
    className,
    links,
    // eslint-disable-next-line no-unused-vars
    variant,
    isMultiSpace,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const renderLinks = useMemo(() => links && links.map((link, index) => (
    <li
      className={classes.item}
      key={`navside-link-${index}`}
    >
      <NavSideLink
        {...link}
        className={classes.link}
        isSpaceColorOnHover={isMultiSpace}
      />
    </li>
  )), [classes.item, classes.link, isMultiSpace, links])

  return links && (
    <nav className={cx(classes.container, className)}>
      <ul className={classes.list}>
        {renderLinks}
      </ul>
    </nav>
  )
}

NavSide.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(
    PropTypes.shape(NavSideLink.propTypes)
  ),
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
  isMultiSpace: PropTypes.bool,
}

NavSide.defaultProps = {
  className: null,
  classes: null,
  links: null,
  isMultiSpace: false,
}

export default withMemo()(NavSide)
