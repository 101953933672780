import React from 'react'
import PropTypes from 'prop-types'

import BlockArticle from '../../../components/BlockArticle'
import VideoPlayerWithPlaylist from '../../../components/VideoPlayerWithPlaylist'
import withMemo from '../../../decorators/withMemo'
import { videoCustomPlaylistTransformer } from '../../../transformers'


const VideoCustom = (props) => {
  const playlistProps = videoCustomPlaylistTransformer(props?.fieldVideoItem ?? {})

  return (
    <BlockArticle variant="care">
      <VideoPlayerWithPlaylist playlistProps={playlistProps} />
    </BlockArticle>
  )
}

VideoCustom.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fieldVideoItem: PropTypes.any,
}

VideoCustom.defaultProps = {
  fieldVideoItem: null,
}

export default withMemo()(VideoCustom)
