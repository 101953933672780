import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import Image from '../Image'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RelatedCard = (props) => {
  const {
    classes: classesProp,
    className,
    linkProps,
    text,
    summary,
    image,
    // eslint-disable-next-line no-unused-vars
    variant,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const truncatedText = summary ? `${summary.substr(0, 200) }\u2026` : ''

  return (
    <A
      {...linkProps}
      className={cx(classes.container, className, !image && summary && 'is-text')}
    >
      {image && (
        <figure className={classes.figure}>
          <Image
            {...image}
            className={classes.image}
          />
        </figure>
      )}
      <div className={classes.text}>
        <p className={classes.title}>
          {text}
        </p>
        {!image && summary && (
          <MarkdownText
            className={classes.summary}
            text={truncatedText}
          />
        )}
      </div>
    </A>
  )
}

RelatedCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  linkProps: PropTypes.shape(A.propTypes).isRequired,
  text: PropTypes.string.isRequired,
  summary: PropTypes.string,
  image: PropTypes.shape(Image.propTypes),
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
}

RelatedCard.defaultProps = {
  className: null,
  classes: null,
  image: null,
  summary: '',
}

export default withMemo()(RelatedCard)
