import { colors, tablet, mobile } from '../../theme'


export default {
  container: {
    '&:not(:first-child)': {
      marginTop: '5rem',
    },
    '&:nth-last-child(2)': {
      marginBottom: '7rem',
    },
  },
  wrapper: {
    position: 'relative',
    maxWidth: '144rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
  content: {
    position: 'relative',
    zIndex: 2,
    maxWidth: '107.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '3rem 2.5rem',
    border: '2px solid #C5E9CF',
    borderRadius: '0.8rem',
    background: colors.greyF6,
    boxShadow: '0px 2px 26px -7px rgba(0, 0, 0, 0.205857)',
  },
  title: {
    margin: 0,
    fontSize: '2.6rem',
    lineHeight: '1.3',
    textTransform: 'uppercase',
    fontWeight: 900,
  },
  text: {
    fontSize: '1.7rem',
    '&:not(:first-child)': {
      marginTop: '2.5rem',
    },
    '&, & > *': {
      lineHeight: '1.6 !important',
    },
    '& > *:not(:first-child)': {
      marginTop: '2.5rem',
    },
  },
  bubble: {
    position: 'absolute',
    right: '-5rem',
    top: '-30rem',
    width: '19rem',
    height: '60rem',
    zIndex: 1,
    pointerEvents: 'none',
  },
  ...mobile({
    container: {
      '&:not(:first-child)': {
        marginTop: '10rem',
      },
      '&:nth-last-child(2)': {
        marginBottom: '10rem',
      },
    },
  }),
  ...tablet({
    content: {
      padding: '5.5rem 6rem',
      borderWidth: '0.3rem',
      borderRadius: '4rem',
      boxShadow: '0px 0px 50px 0px rgba(0, 0, 0, 0.2)',
    },
    title: {
      fontSize: '3rem',
    },
    text: {
      fontSize: '1.5rem',
    },
    bubble: {
      right: '0',
      top: '-10rem',
      width: '19rem',
    },
  }),
  '@media print': {
    container: {},
    content: {
      border: '0 none',
      boxShadow: 'none',
      padding: 0,
    },
    bubble: {
      display: 'none',
    },
  },
}
