/* eslint-disable prefer-template */
const routes = {
  pagePaginated: {
    component: '/Page',
    path: '/:locale([a-zA-Z]{0,3})/:slug+/p:page([0-9]+)',
    name: 'pagePaginated',
  },
  shareVideo: {
    component: '/ShareVideo',
    path: '/:locale([a-zA-Z]{0,3})/partage/:slug',
    name: 'shareVideo',
  },
  // Page Wildcard
  pageHash: {
    component: '/Page',
    path: '/:locale([a-zA-Z]{0,3})/:slug+#:hash',
    name: 'pageHash',
  },
  page: {
    component: '/Page',
    path: '/:locale([a-zA-Z]{0,3})/:slug*',
    name: 'page',
  },
  redirect: {
    component: '/Page',
    path: '/',
    name: 'redirect',
  },
  error: {
    component: '/Error',
    path: '/:slug*',
    name: '404',
  },
}

module.exports = routes
