import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'article'

//
// Initial state
//
const initialState = {
  nextPrev: null,
}

//
// Actions
//
export const actions = {
  setNextPrev: createAction('Article: setNextPrev', (nextPrev) => ({ nextPrev })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setNextPrev]: (state, { nextPrev }) => Immutable.static.merge(state, { nextPrev }),
  },
  initialState
)

//
// Selectors
//

const root = (state) => state[key]
const nextPrev = (state) => root(state).nextPrev

export const selectors = {
  nextPrev,
}
