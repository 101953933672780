import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import Icon from '../Icon'
import icons from '../Icon/assets'
import MarkdownText from '../MarkdownText'
import { safeCall } from '../../helpers/React'

import styles from './styles'


const useStyles = createUseStyles(styles)

const SubMenu = (props) => {
  const {
    classes: classesProp,
    className,
    // eslint-disable-next-line no-unused-vars
    variant,
    title,
    text,
    links,
    onMenuLeaveHandler,
    lexicon,
    markHover,
    isOpen,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const [currentLevelTwo, setCurrentLevelTwo] = useState(-1)

  const handleOpenLevelTwo = useCallback((index) => {
    setCurrentLevelTwo(index)
  }, [])

  const handleCloseLevelTwo = useCallback(() => {
    setCurrentLevelTwo(-1)
  }, [])

  const handleLeaveMenu = useCallback(() => {
    safeCall(onMenuLeaveHandler)
  }, [onMenuLeaveHandler])

  const renderLevelTwo = useMemo(() => (links ? (
    <ul className={classes.twoList}>
      {links?.map((item, index) => (
        <li
          key={`submenu-lvl2-${index}`}
          className={classes.twoItem}
          onMouseEnter={() => {
            handleOpenLevelTwo(index)
          }}
        >
          <em
            {...item?.linkProps}
            className={cx(classes.twoTrigger, index === currentLevelTwo && 'is-active')}
          >
            {item?.text && <span>{item.text}</span>}
          </em>
        </li>
      ))}
    </ul>
  ) : null), [classes.twoItem, classes.twoList, classes.twoTrigger, currentLevelTwo, handleOpenLevelTwo, links])

  const renderLevelThree = useMemo(() => links?.map((link, index) => link?.sub && (
    <ul
      key={`submenu-lvl3-${index}`}
      className={cx(classes.threeList, currentLevelTwo === index && 'is-active')}
    >
      {link?.sub?.map((item, idx) => (
        <li
          key={`submenu-lvl3-${index}-${idx}`}
          className={classes.threeItem}
        >
          <A
            {...item?.linkProps}
            className={classes.threeLink}
          >
            <Icon
              className={classes.threeIcon}
              icon={icons.ChevronSquareRight}
            />
            {item?.text && <span>{item.text}</span>}
          </A>
        </li>
      ))}
    </ul>
  )), [links, currentLevelTwo, classes.threeList, classes.threeItem, classes.threeLink, classes.threeIcon])

  return (
    <div
      className={cx(classes.container, className, isOpen === true && 'is-open')}
      onMouseLeave={handleLeaveMenu}
    >
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {title && (
            <MarkdownText
              tag="p"
              className={classes.title}
              text={title}
            />
          )}
          {text && (
            <MarkdownText
              tag="div"
              className={classes.text}
              text={text}
              lexicon={lexicon}
              markHover={markHover}
            />
          )}
        </div>
        <nav
          className={classes.navigation}
          onMouseLeave={handleCloseLevelTwo}
        >
          <div className={classes.level2}>{renderLevelTwo}</div>
          <div className={classes.level3}>
            {renderLevelThree}
          </div>
        </nav>
      </div>
    </div>
  )
}

SubMenu.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']),
  title: PropTypes.string,
  text: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    sub: PropTypes.arrayOf(PropTypes.shape({
      linkProps: PropTypes.shape(A.propTypes),
      text: PropTypes.string,
    })),
  })),
  onMenuLeaveHandler: PropTypes.func,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
  isOpen: PropTypes.bool,
}

SubMenu.defaultProps = {
  className: null,
  classes: null,
  variant: 'care',
  title: null,
  text: null,
  links: null,
  onMenuLeaveHandler: () => null,
  lexicon: null,
  markHover: () => null,
  isOpen: false,
}

export default withMemo()(SubMenu)
