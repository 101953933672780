import { createUseStyles } from 'react-jss'

import { transition, accessibleHiddenText } from '../../theme'


export default createUseStyles({
  icon: {
    display: 'inline-flex',
    position: 'relative',
    '& *[fill]:not([fill="none"]):not(.no-fill)': {
      fill: (props) => props.color,
    },
    '& *[stroke]:not([stroke="none"]):not(.no-stroke)': {
      stroke: (props) => props.color,
    },
    '&.color-transition': {
      '& *[fill]:not([fill="none"]):not(.no-fill), & *[stroke]:not([stroke="none"]):not(.no-stroke)': {
        ...transition(['fill', 'stroke']),
      },
    },
    '& a': {
      display: 'flex',
    },
  },
  alt: {
    ...accessibleHiddenText,
  },
})
