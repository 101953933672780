import { merge } from 'lodash'

import { colors, fonts } from '.'
import { fontsFaces } from './fonts'
import overlayScrollbars from './overlayScrollbars'
import reset from './reset'


export default {
  '@global': merge(
    {
      ...reset,
      ...fontsFaces,
      ...overlayScrollbars,
    },
    {
      html: {
        ...fonts.MaaxRegular,
        height: '100%',
        scrollPaddingTop: '10rem',
        scrollBehavior: 'smooth',
      },
      body: {
        height: '100%',
      },
      '#__next': {
        height: '100%',
      },
      ':root': {
        '--vh': '1vh',
      },
      // keen slider instead of importing the css
      '.keen-slider': {
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        userSelect: 'none',
        WebkitTouchCallout: 'none',
        KhtmlUserSelect: 'none',
        MsTouchAction: 'pan-y',
        touchAction: 'pan-y',
        WebkitTapHighlightColor: 'transparent',
      },
      '.keen-slider__slide': {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        minHeight: '100%',
      },
      '.keen-slider[data-keen-slider-v]': {
        flexWrap: 'wrap',
      },
      '.keen-slider[data-keen-slider-v] .keen-slider__slide': {
        width: '100%',
      },
      '.keen-slider[data-keen-slider-moves] *': {
        pointerEvents: 'none',
      },
      '@media print': {
        html: {
          fontFamily: 'sans-serif !important',
        },
        '*': {
          fontFamily: 'sans-serif !important',
          color: `${colors.pureBlack} !important`,
          background: 'none transparent !important',
        },
      },
      // firefox only
      '@media screen and (min--moz-device-pixel-ratio:0)': {
        html: {
          scrollBehavior: 'auto',
        },
      },
    }
  ),
}
