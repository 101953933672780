import React from 'react'

import withMemo from '../../../decorators/withMemo'
import BlockLandingApps from '../../../components/BlockLandingApps'
import { useLexiconHandler } from '../../../utils/hooks/useLexicon'

import { pageLandingCtasTransformer } from './transformer'


const PageLandingCtas = (props) => {
  const blockProps = pageLandingCtasTransformer(props)
  const [lexicals, handleLexicon] = useLexiconHandler()

  return (
    <BlockLandingApps
      {...blockProps}
      lexicon={lexicals}
      markHover={handleLexicon}
    />
  )
}

PageLandingCtas.propTypes = {}

PageLandingCtas.defaultProps = {}

export default withMemo()(PageLandingCtas)
