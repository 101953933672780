import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    '&:not(:first-child)': {
      marginTop: '4rem',
    },
    color: (props) => (
      props.variant === 'care' ? colors.pureWhite
        : props.variant === 'environment' ? colors.blueDarkEnvironment
          : props.variant === 'listen' ? colors.blueDarkListen
            : props.variant === 'discover' ? colors.pureWhite
              : colors.pureWhite),
    backgroundColor: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseEnvironment
          : props.variant === 'listen' ? colors.orangeListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
    ...tablet({
      '&:not(:first-child)': {
        marginTop: '9rem',
      },
    }),
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    maxWidth: `calc(${1100 / 10}rem)`, // exception here ? from maxWidth: `calc(${grids.desktop.boxingContent / 10}rem)`,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'inherit',
  },
  content: {
    position: 'relative',
    paddingTop: '1.5rem',
    paddingBottom: '3rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    ...tablet({
      flex: '1 1 auto',
      paddingTop: '5rem',
      paddingBottom: '5rem',
      paddingLeft: '6rem',
      paddingRight: '8rem',
    }),
  },
  title: {
    ...fonts.MaaxBold,
    margin: 0,
    fontSize: '2rem',
    textTransform: 'uppercase',
    '& span, & u': {
      textDecoration: 'underline',
    },
    color: (props) => (
      props.variant === 'care' ? colors.yellowCare
        : props.variant === 'environment' ? colors.blueEnvironment
          : props.variant === 'listen' ? colors.blueListen
            : props.variant === 'discover' ? colors.yellowDiscover
              : colors.purewhite),
    ...tablet({
      fontSize: '2.5rem',
    }),
  },
  text: {
    fontSize: '1.4rem',
    '&:not(:first-child)': {
      marginTop: '1rem',
    },
    '& mark, & mark:hover': {
      fontWeight: 'inherit',
      color: 'inherit',
      cursor: 'inherit',
      textDecoration: 'none',
    },
    ...tablet({
      fontSize: '1.5rem',
      '&:not(:first-child)': {
        marginTop: '2rem',
      },
    }),
  },
  figure: {
    flex: '0 0 auto',
    width: '40%',
    margin: 0,
    overflow: 'hidden',
    ...tablet({
      maxHeight: '294px',
      maxWidth: '42rem',
    }),
  },
  image: {
    '& img': {
      width: '100%',
    },
  },
  linkIcon: {
    position: 'absolute',
    bottom: '1rem',
    right: '2rem',
    display: 'block',
    width: '2.8rem',
    height: '1rem',
    transform: 'translate3D(0, 0, 0)',
    transition: 'transform 500ms ease-out',
    color: (props) => (
      props.variant === 'care' ? 'inherit'
        : props.variant === 'environment' ? colors.pureWhite
          : props.variant === 'listen' ? colors.pureWhite
            : props.variant === 'discover' ? 'inherit'
              : 'inherit'),
    'a[href]:hover &': {
      transform: 'translate3D(1rem, 0, 0)',
    },
    ...tablet({
      bottom: '3rem',
    }),
  },
  '@media print': {
    container: {
      breakInside: 'avoid',
      pageBreakInside: 'avoid',
    },
    linkIcon: {
      display: 'none',
    },
  },
}
