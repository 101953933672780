import { colors, grids, tablet } from '../../theme'


export default {
  container: {
    marginTop: '5rem',
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '6rem',
    },
  },
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  content: {},
  titleZone: {},
  title: {
    maxWidth: 'max-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontWeight: 'bold',
    fontSize: '3.4rem',
    textTransform: 'uppercase',
    color: colors.blue,
  },
  text: {
    display: 'none',
  },
  navigation: {
    margin: 0,
    marginTop: '5rem',
    '& > *': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  ...tablet({
    container: {
      marginTop: '8rem',
      // penultimate child has a margin bottom because last is lexicon container
      '&:nth-last-child(2)': {
        marginBottom: '8rem',
      },
    },
    content: {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
    titleZone: {
      marginLeft: '2.5rem',
    },
    title: {
      maxWidth: 'none',
      marginLeft: 0,
      marginRight: 0,
      whiteSpace: 'nowrap',
    },
    text: {
      display: 'block',
      marginLeft: '18rem',
    },
    navigation: {
      marginTop: '10rem',
    },
  }),
}
