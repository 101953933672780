import gql from 'graphql-tag'


export const Locales = gql`    
    query locales {
        availableLanguages {
            id
            name
            direction
            isDefault
        }
    }
`
