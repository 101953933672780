import React from 'react'
import PropTypes from 'prop-types'


function JsonLd(props) {
  const { data } = props

  if (!data) {
    return null
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  )
}

JsonLd.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
}

JsonLd.defaultProps = {
  data: null,
}

export default JsonLd
