import Color from 'color'

import { colors, fonts, small, tablet, accessibleHiddenText, desktop } from '../../theme'


export default {
    container: {
        position: 'relative',
        display: 'block',
        height: 'auto',
        overflow: 'hidden',
        backgroundColor: colors.greyF6,
        color: colors.pureWhite,
        '& button': {
            background: 'transparent',
            outline: '0 none',
            userSelect: 'none',
            WebkitTapHighlightColor: 'transparent',
            border: '0 none',
            cursor: 'pointer',
        },
        ...tablet({
            '&.is-notinpush': {
                width: '80%',
                margin: '0 auto',
            },
        }),
        '&.is-inpush': {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
    },
    wrapper: {
        '$container.is-fullscreen &': {
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
        },
        '$container.is-inpush.is-notfullscreen &': {
            height: '100%',
        },
    },
    logo: {
        position: 'absolute',
        top: '0.5rem',
        left: '0.5rem',
        zIndex: 10,
        width: '4rem',
        height: 'auto',
        '&, & *': {
            pointerEvents: 'none',
        },
        // starting screen
        '$container.is-inpush:not(.is-touched) &': {
            opacity: 0,
        },
    },
    tooltip: {
        position: 'absolute',
        top: '-4rem',
        left: '-0.5rem',
        zIndex: 10,
        backgroundColor: Color('#333333').alpha(0.85).toString(),
        color: colors.pureWhite,
        padding: '0.5rem 1rem',
        borderRadius: '0.5rem',
        width: 'max-content',
        fontSize: '1.4rem',
    },
    tooltipPrevious: {
        left: '-0.5rem',
        top: '-4rem',
    },
    tooltipPlay: {
        left: '-2.5rem',
        top: '-3rem',
    },
    tooltipPause: {
        left: '-2rem',
        top: '-3rem',
    },
    tooltipNext: {
        left: '-4rem',
        top: '-4rem',
    },
    tooltipSettings: {
        left: '-3rem',
        top: '-3rem',
    },
    tooltipFullscreen: {
        left: '-5rem',
        top: '-3rem',
    },
    tooltipExitFullscreen: {
        left: '-15rem',
        top: '-4rem',
    },
    tooltipShare: {
        left: '-2.5rem',
        top: '-2.5rem',
    },
    player: {
        position: 'relative',
        zIndex: 5,
        width: '100%',
        '$container.is-notfullscreen.is-inpush & .plyr__captions': {
            display: 'none',
        },
        // plyr
        '& .plyr': {
            ...fonts.MaaxRegular,
            '--plyr-control-radius': colors.blue,
            '--plyr-range-thumb-background': colors.blue,
            '--plyr-range-thumb-height': '2rem',
            '--plyr-range-track-height': '0.2rem',
            '--plyr-range-thumb-shadow': '0 none',
            '--plyr-video-background': '#fff',
            '--plyr-progress-loading-background': 'transparent',
            '--plyr-video-progress-buffered-background': 'transparent',
            '--plyr-video-range-track-background': Color(colors.pureWhite)
                .alpha(0.85)
                .toString(),
        },
        '$container.is-big.is-fullscreen & .plyr': {
            '--plyr-video-background': '#000',
        },
        '& .plyr-color-main': {
            color: colors.blue,
        },
        '& .plyr__captions': {
            bottom: '-3rem',
        },
        '& .plyr__poster': {
            backgroundSize: 'cover',
        },
        '& .plyr__tooltip': {
            color: colors.blue,
        },
        '& .plyr__controls': {
            paddingBottom: '6.5rem',
            paddingTop: '4.5rem',
            // transition: 'opacity 200ms ease-in-out',
        },
        '$container.is-big.is-notinpush & .plyr__controls': {
            paddingBottom: '4.5rem',
            paddingTop: '4.5rem',
        },
        '& .plyr__control--overlaid[data-plyr="play"]': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
        },
        '& .plyr--full-ui input[type=range]': {
            color: colors.blue,
        },
        '& .plyr__progress input[type=range]': {
            borderRadius: 0,
        },
        '& .plyr__progress input[type=range]::-webkit-slider-runnable-track': {
            borderRadius: 0,
        },
        '& .plyr__progress input[type=range]::-moz-range-track': {
            borderRadius: '0',
        },
        '& .plyr__progress input[type=range]::-moz-range-progress': {
            borderRadius: '0',
        },
        '& .plyr__progress input[type=range]::-ms-track': {
            borderRadius: '0',
        },
        '& .plyr__progress input[type=range]::-ms-fill-upper': {
            borderRadius: '0',
        },
        '& .plyr__progress input[type=range]::-ms-fill-lower': {
            borderRadius: '0',
        },
        '& .plyr__progress input[type=range]::-ms-thumb': {
            opacity: 0,
        },
        // hidden when in push and not touched or when when controls are hidden
        '$container:not(.is-touched) & .plyr__controls, $container.is-playing.is-withoutcontrols & .plyr__controls, $container.is-inpush.is-fullscreen.is-playing.is-withoutcontrols & .plyr__controls': {
            opacity: 0,
            visibility: 'hidden',
        },
        // fullscreen etc
        '$container.is-fullscreen &': {
            maxHeight: '100%',
        },
        '$container.is-fullscreen & .plyr': {
            height: '100%',
        },
        // in push
        '$container.is-inpush.is-notfullscreen &, $container.is-inpush.is-notfullscreen  & .plyr': {
            height: '100%',
        },
        // poster alternative dos not work on safari unless we change the following (zIndex problems)
        '& .plyr__video-wrapper video': {
            position: 'relative',
        },
    },
    poster: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    playscreen: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 15,
        background: 'transparent',
        outline: 'none',
        cursor: 'pointer',
        '& i': {
            ...accessibleHiddenText,
        },
        '$container.is-touched &': {
            opacity: 0,
            visibility: 'hidden',
        },
        '& svg': {
            filter: 'drop-shadow(0 0 0.2rem rgba(0, 0, 0, .5))',
        },
    },
    playscreenControl: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '5.5rem',
        height: '5.5rem',
        border: '1px solid currentColor',
        borderRadius: '50%',
        backgroundColor: Color(colors.pureWhite)
            .alpha(0.3)
            .toString(),
        transition: 'all 200ms ease-out',
        transform: 'translate3D(-50%, -50%, 0)',
        boxShadow: '0 0 0.5rem rgba(0, 0, 0, .2)',
        '$container:hover &': {
            backgroundColor: Color(colors.pureWhite)
                .alpha(0.2)
                .toString(),
        },
        '$container.is-notinpush &': {
            width: '8rem',
            height: '8rem',
        },
    },
    playscreenIcon: {
        width: '1.5rem',
        height: '1.5rem',
        '$container.is-notinpush &': {
            width: '2rem',
            height: '2rem',
        },
    },
    playerUI: {
        width: '100%',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'flex-end',
        alignItems: 'stretch',
        zIndex: 10,
        pointerEvents: 'none',
        // transition: 'opacity 200ms ease-in-out',
        '& *': {
            pointerEvents: 'all',
        },
        '$container:not(.is-touched) &, $container.is-playing.is-withoutcontrols &, $container.is-inpush.is-fullscreen.is-playing.is-withoutcontrols &': {
            opacity: 0,
            visibility: 'hidden',
        },
    },
    bottomUI: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0.5rem',
        // fullscreen
        '$container.is-big &, $container.is-small.is-fullscreen &': {
            justifyContent: 'flex-start',
            padding: '1.5rem 1.5rem 3rem',
        },
        '$container.is-big.is-notinpush &': {
            justifyContent: 'flex-start',
            padding: '1.5rem',
        },
    },
    controls: {
        position: 'absolute',
        top: 'calc(50% - 2rem)',
        left: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 'max(30%, 12rem)',
        transform: 'translate(-50%)',
        fallbacks: {
            minWidth: '30%',
        },
        '& > *': {
            flex: '0 0 auto',
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
        },
        '& svg': {
            filter: 'drop-shadow(0 0 0.2rem rgba(0, 0, 0, .5))',
        },
    },
    controlButton: {
        display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    controlMenu: {
        position: 'relative',
        display: 'inline-flex',
    },
    controlButtonPlay: {
        position: 'relative',
        width: '2.4rem',
        height: '2.8rem',
        '$controls > &': {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    controlButtonNav: {
        position: 'relative',
        width: '1.5rem',
        height: '1.7rem',
    },
    controlButtonShare: {
        position: 'relative',
        width: '3.5rem',
        height: '3rem',
    },
    controlButtonMute: {
        width: '2.8rem',
        height: '2.3rem',
        '$controls &': {
            display: 'none',
        },
    },
    controlButtonSettings: {
        position: 'relative',
        width: '2.6rem',
        height: '2.6rem',
    },
    controlButtonFullscreen: {
        position: 'relative',
        width: '3.2rem',
        height: '2.4rem',
    },
    controlIcon: {
        height: '100%',
    },
    controlIconPause: {
        '.plyr--paused ~ $playerUI &': {
            display: 'none',
        },
    },
    controlIconPlay: {
        '.plyr--playing ~ $playerUI &': {
            display: 'none',
        },
    },
    controlIconShare: {
        marginTop: '0.5rem',
    },
    controlVolume: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '12rem',
        padding: '1.5rem 0.5rem',
        background: Color('#333333')
            .alpha(0.85)
            .toString(),
        transform: 'rotate(-90deg) translateX(-1rem) translateY(calc(-50% + 1rem))',
        transformOrigin: 'left top',
        opacity: 0,
        visibility: 'hidden',
        '$controls &': {
            display: 'none',
        },
        '& input': {
            padding: 0,
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            right: '100%',
            top: 0,
            bottom: 0,
            width: '2rem',
            background: 'transparent',
        },
        '$controlMenu:hover &': {
            opacity: 1,
            visibility: 'visible',
        },
        '& input[type=range]': {
            WebkitAppearance: 'none',
            background: '0 0',
            border: '0',
            borderRadius: 0,
            color: colors.blue,
            display: 'block',
            height: '1rem',
            margin: '0',
            minWidth: '0',
            padding: '0',
            transition: 'box-shadow .3s ease',
            width: '100%',
            overflow: 'hidden',
        },
        '& input[type=range]::-webkit-slider-runnable-track': {
            background: colors.pureWhite,
            border: '0',
            borderRadius: 0,
            height: '1rem',
            WebkitTransition: 'box-shadow .3s ease',
            transition: 'box-shadow .3s ease',
            WebkitUserSelect: 'none',
            userSelect: 'none',
        },
        '& input[type=range]::-webkit-slider-thumb': {
            background: '#fff',
            border: '0',
            borderRadius: 0,
            boxShadow: `-200px 0 0 200px ${colors.blue}`,
            height: '0.5rem',
            position: 'relative',
            WebkitTransition: 'all .2s ease',
            transition: 'all .2s ease',
            width: '0',
            WebkitAppearance: 'none',
            marginTop: 'calc(((13px - 5px)/ 2) * -1)',
        },
        '& input[type=range]::-moz-range-track': {
            background: colors.pureWhite,
            border: '0',
            borderRadius: '0',
            height: '1rem',
            transition: 'box-shadow .3s ease',
            userSelect: 'none',
        },
        '& input[type=range]::-moz-range-thumb': {
            opacity: 0,
        },
        '& input[type=range]::-moz-range-progress': {
            background: 'currentColor',
            borderRadius: '0',
            height: '1rem',
        },
        '& input[type=range]::-ms-track': {
            background: '0 0',
            border: '0',
            borderRadius: '0',
            height: '1rem',
            msTransition: 'box-shadow .3s ease',
            transition: 'box-shadow .3s ease',
            msUserSelect: 'none',
            userSelect: 'none',
            color: 'transparent',
        },
        '& input[type=range]::-ms-fill-upper': {
            background: '0 0',
            border: '0',
            borderRadius: '0',
            height: '1rem',
            msTransition: 'box-shadow .3s ease',
            transition: 'box-shadow .3s ease',
            msUserSelect: 'none',
            userSelect: 'none',
        },
        '& input[type=range]::-ms-fill-lower': {
            background: '0 0',
            border: '0',
            borderRadius: '0',
            height: '1rem',
            msTransition: 'box-shadow .3s ease',
            transition: 'box-shadow .3s ease',
            msUserSelect: 'none',
            userSelect: 'none',
        },
        '& input[type=range]::-ms-thumb': {
            opacity: 0,
        },
        '& input[type=range]::-ms-tooltip': { display: 'none' },
        '& input[type=range]:focus': { outline: '0' },
        '& input[type=range]::-moz-focus-outer': { border: '0' },
    },
    controlShare: {
        // position: 'absolute',
        // bottom: 0,
        // left: 0,
    },
    titles: {
        flex: '0 1 auto',
        display: 'none',
        fontSize: '1.4rem',
        overflow: 'hidden',
        '& > *': {
            flex: '0 1 auto',
            lineHeight: 1.2,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '$container.is-big &, $container.is-small.is-fullscreen &': {
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
        },
    },
    title: {
        ...fonts.MaaxRegular,
        whiteSpace: 'nowrap',
    },
    settings: {
        display: 'flex',
        alignItems: 'center',
        '& > *:not(:first-child)': {
            marginLeft: '1rem',
        },
        // fullscreen
        '$container.is-big &, $container.is-small.is-fullscreen &': {
            marginLeft: 'auto !important',
            paddingLeft: '2rem',
        },
        '$container.is-big & > *:not(:first-child), $container.is-small.is-fullscreen & > *:not(:first-child)': {
            marginLeft: '2.5rem',
        },
    },
    advanced: {
        position: 'absolute',
        bottom: '6rem',
        right: 0,
        '$container.is-notfullscreen &': {
            bottom: '5rem',
        },
        minWidth: '25rem',
        borderRadius: '0.4rem',
        fontSize: '1.2rem',
        background: Color('#333333')
            .alpha(0.85)
            .toString(),
        '&:not(.is-open)': {
            opacity: 0,
            visibility: 'hidden',
        },
        '& ul': {
            display: 'none',
            padding: '2rem',
        },
        '& ul.is-visible': {
            display: 'block',
        },
        '& li': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& li:not(:first-child)': {
            marginTop: '0.8rem',
        },
        '& b': {
            ...fonts.MaaxMedium,
            fontSize: '1.3rem',
        },
        '& a': {
            color: 'inherit',
        },
        '& li > span, & li > b': {
            position: 'relative',
        },
        '& span[class*=icon]': {
            width: '0.7rem',
            height: '1rem',
            transform: 'translateY(0.1rem)',
        },
        '& span[class*=icon]:first-child': {
            marginRight: '0.6rem',
        },
        '& span[class*=icon]:last-child': {
            marginLeft: '0.6rem',
        },
        '& input[type=checkbox]': {
            position: 'absolute',
            visibility: 'hidden',
        },
        '& input[type=checkbox] + span': {
            position: 'relative',
            display: 'block',
            height: '1.6rem',
            width: '3.8rem',
            borderRadius: '1.6rem',
            cursor: 'pointer',
            textIndent: '-9999px',
            background: colors.greyD9,
            transition: 'all 200ms ease-out',
        },
        '& input[type=checkbox] + span:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '0',
            width: '2rem',
            height: '2rem',
            borderRadius: '50%',
            background: colors.pureWhite,
            transform: 'translate(0, -50%)',
            transition: 'all 200ms ease-out',
        },
        '& input:checked + span': {
            background: colors.turquoiseDark,
        },
        '& input:checked + span:after': {
            left: 'calc(100%)',
            transform: 'translate(-100%, -50%)',
        },
    },
    advancedMain: {
        '& li': {
            minHeight: '2rem',
        },
        '& li:not(:first-child)': {
            marginTop: '1rem',
        },
    },
    next: {
        display: 'none',
        // basic styles
        position: 'absolute',
        zIndex: 12,
        borderRadius: '0.4rem',
        overflow: 'hidden',
        opacity: 0,
        visibility: 'hidden',
        '&.is-visible': {
            opacity: 1,
            visibility: 'visible',
        },
    },
    nextImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    nextTitles: {
        position: 'absolute',
        top: '0.5rem',
        left: '0.5rem',
        right: 0,
        paddingRight: '2rem',
        '& > *': {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '2',
            overflow: 'hidden',
            fontSize: '1.1rem',
            textAlign: 'left',
        },
        '& > span': {
            ...fonts.MaaxMedium,
        },
    },
    nextDuration: {
        position: 'absolute',
        right: '0.5rem',
        bottom: '0.5rem',
        fontSize: '0.9rem',
        padding: '0.2rem',
        borderRadius: '0.4rem',
        background: Color('#333333')
            .alpha(0.85)
            .toString(),
    },
    '@media (hover: hover)': {
        player: {
            '$container.is-playing.is-withoutcontrols &:hover .plyr__controls, $container.is-inpush.is-fullscreen.is-playing. &:hover .plyr__controls': {
                opacity: 1,
                visibility: 'visible',
            },
        },
        playerUI: {
            '$container.is-playing.is-withoutcontrols $player:hover &, $container.is-inpush.is-fullscreen.is-playing.is-withoutcontrols $player:hover &': {
                opacity: 1,
                visibility: 'visible',
            },
        },
    },
    ...small({
        logo: {
            '$container.is-big &': {
                top: '1.5rem',
                left: '1.5rem',
                width: '7.5rem',
            },
        },
        next: {
            '$container.is-big &, $container.is-small.is-fullscreen &': {
                display: 'block',
                bottom: '8.5rem',
                right: '1.5rem',
                width: '20rem',
                height: '10rem',
                background: Color(colors.pureBlack)
                    .alpha(0.85)
                    .toString(),
            },
        },
    }),
    ...tablet({
        logo: {
            '$container.is-big.is-fullscreen &, $container.is-small.is-fullscreen &': {
                top: '2rem',
                left: '2rem',
                width: '12rem',
            },
        },
        player: {
            // plyr
            '& .plyr': {
                '--plyr-range-track-height': '0.5rem',
            },
            '& .plyr__controls': {
                paddingTop: '5.5rem',
                paddingBottom: '5.5rem',
            },
            '$container.is-big.is-fullscreen & .plyr__captions': {
                bottom: '10rem',
            },
            '$container.is-notfullscreen.is-inpush & .plyr__captions': {
                display: 'none',
            },
            '$container.is-big.is-fullscreen & .plyr__controls, $container.is-small.is-fullscreen & .plyr__controls': {
                paddingBottom: '10.5rem',
                paddingTop: '10.5rem',
                paddingLeft: '2rem',
                paddingRight: '2rem',
            },
        },
        advanced: {
            '$container.is-big.is-fullscreen &': {
                bottom: '12rem',
            },
            '$container.is-notfullscreen &': {
                bottom: '7rem',
            },
        },
        advancedShare: {
            '$container.is-big.is-fullscreen &': {
                bottom: '12rem',
                right: '-15rem',
                ...desktop({
                    right: '-20rem',
                }),
            },
            '$container.is-notfullscreen &': {
                right: '-12.5rem',
                bottom: '7rem',
                ...desktop({
                    right: '-11.5rem',
                }),
            },
        },
        bottomUI: {
            '$container.is-big.is-fullscreen &, $container.is-small.is-fullscreen &': {
                justifyContent: 'flex-start',
                padding: '2rem',
            },
            '$container & > *:not(:first-child)': {
                marginLeft: '2rem',
            },
            '$container.is-big.is-fullscreen & > *:not(:first-child), $container.is-small.is-fullscreen & > *:not(:first-child)': {
                marginLeft: '5rem',
            },
        },
        controls: {
            // fullscreen
            '$container.is-big &, $container.is-small.is-fullscreen &': {
                position: 'relative',
                top: 'auto',
                left: 'auto',
                transform: 'translate(0)',
                flex: '0 0 auto',
                minWidth: 0,
                '& svg': {
                    filter: 'none',
                },
            },
            '$container.is-big & > *, $container.is-small.is-fullscreen & > *': {
                marginLeft: 0,
                marginRight: 0,
            },
            '$container & > *:not(:first-child)': {
                marginLeft: '2rem',
            },
            '$container.is-big.is-fullscreen & > *:not(:first-child), $container.is-small.is-fullscreen & > *:not(:first-child)': {
                marginLeft: '5rem',
            },
        },
        controlButton: {},
        controlMenu: {},
        controlButtonPlay: {
            '$container.is-big.is-fullscreen &, $container.is-small.is-fullscreen &': {
                width: '4rem',
                height: '4.6rem',
            },
        },
        controlButtonNav: {
            '$container.is-big.is-fullscreen &, $container.is-small.is-fullscreen &': {
                width: '3.2rem',
                height: '2.9rem',
            },
        },
        controlButtonShare: {
            '$container.is-big.is-fullscreen &, $container.is-small.is-fullscreen &': {
                width: '5.1rem',
                height: '5.1rem',
                marginRight: '-5rem',
            },
        },
        controlButtonMute: {
            // fullscreen
            '$container.is-big.is-fullscreen $controls &, $container.is-small.is-fullscreen $controls &': {
                display: 'flex',
                width: '4.7rem',
                height: '3.8rem',
            },
            '$container.is-big.is-fullscreen $settings &, $container.is-small.is-fullscreen $settings &': {
                display: 'none',
            },
        },
        controlVolume: {
            // fullscreen
            '$container.is-big.is-fullscreen $controls &, $container.is-small.is-fullscreen $controls &': {
                display: 'block',
            },
            '$container.is-big.is-fullscreen $settings &, $container.is-small.is-fullscreen $settings &': {
                display: 'none',
            },
        },
        controlButtonSettings: {
            '$container.is-big.is-fullscreen &, $container.is-small.is-fullscreen &': {
                width: '5.1rem',
                height: '5.1rem',
            },
        },
        controlButtonFullscreen: {
            '$container.is-big.is-fullscreen &, $container.is-small.is-fullscreen &': {
                width: '5.3rem',
                height: '4rem',
            },
        },
        titles: {
            '$container.is-big.is-fullscreen &, $container.is-small.is-fullscreen &': {
                fontSize: '2.8rem',
            },
        },
        settings: {
            '$container.is-big.is-fullscreen &, $container.is-small.is-fullscreen &': {
                marginLeft: 'auto !important',
                paddingLeft: '5rem',
            },
            '$container.is-big.is-fullscreen & > *:not(:first-child), $container.is-small.is-fullscreen & > *:not(:first-child)': {
                marginLeft: '5rem',
            },
        },
        next: {
            '$container.is-big.is-fullscreen &, $container.is-small.is-fullscreen &': {
                bottom: '10rem',
                right: '2rem',
            },
        },
        nextTitles: {
            top: '1rem',
            left: '1rem',
            '& > *': {
                fontSize: '1.2rem',
            },
        },
        nextDuration: {
            position: 'absolute',
            right: '0.5rem',
            bottom: '0.5rem',
            fontSize: '1.2rem',
        },
    }),
    advancedShare: {
        zIndex: '9999',
        minWidth: '17rem',
        height: '10rem',
        padding: '1rem 1rem',
        bottom: '7rem',
        right: '-12.5rem',
        '&:not(.is-open)': {
            opacity: 0,
            visibility: 'hidden',
            display: 'none',
        },
        ...desktop({
            bottom: '3rem',
            right: '-20rem',
        }),
    },
    shareTitle: {
        ...fonts.MaaxMedium,
        fontSize: '1.6rem',
        textAlign: 'center',
        margin: 0,
    },
    shareButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '1rem',
    },
    shareLayerButton: {
        width: '3rem',
        height: '3rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    shareLayerIcon: {
        width: '3rem !important',
        height: '3rem !important',
        marginBottom: '0.8rem',
    },
}
