import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Icon from '../Icon'
import icons from '../Icon/assets'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockQuestion = (props) => {
  const {
    id,
    classes: classesProp,
    className,
    children,
    TagTitle,
    textAuthor,
    textDate,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <article
      id={id}
      className={cx(classes.container, className)}
    >
      <div className={classes.wrapper}>
        <div className={classes.titleZone}>
          <TagTitle className={classes.title}>
            <MarkdownText
              text={textDate}
              className={classes.date}
            />
            {' '}
            <MarkdownText
              text={textAuthor}
              className={classes.author}
            />
          </TagTitle>
          <Icon
            icon={icons.Calendar}
            className={classes.icon}
          />
        </div>
        {children}
      </div>
    </article>
  )
}

BlockQuestion.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  TagTitle: PropTypes.string,
  textAuthor: PropTypes.string.isRequired,
  textDate: PropTypes.string.isRequired,
}

BlockQuestion.defaultProps = {
  id: null,
  className: null,
  classes: null,
  children: null,
  TagTitle: 'h2',
}

export default withMemo()(BlockQuestion)
