import gql from 'graphql-tag'

import { ArticleFragment } from '../Fragments/ArticleFragment'
import { SatelliteFragment } from '../Fragments/SatelliteFragment'
import { RubriqueFragment } from '../Fragments/RubriqueFragment'
import { FaqFragment } from '../Fragments/FaqFragment'
import { OutilFragment } from '../Fragments/OutilFragment'
import { PageLandingFragment } from '../Fragments/PageLandingFragment'


export const PageByFullSlug = gql`
    query PageByFullSlug($slug: String!) {
        route(path: $slug) {
            __typename
            ... on DefaultInternalUrl {
                pathInternal
                metatags {
                    key
                    value
                }
                breadcrumb {
                    text
                    url {
                        path
                    }
                }
            }
            ... on RedirectUrl {
                target {
                    path
                    ... on EntityCanonicalUrl {
                        breadcrumb {
                            text
                            url {
                                path
                            }
                        }
                        nodeContext {
                            __typename
                            title
                            entityChanged
                            ... on NodeOutil {
                                ...OutilFragment
                            }
                            ... on NodeArticle {
                                ...ArticleFragment
                            }
                            ... on NodeFaq {
                                ...FaqFragment
                            }
                            ... on NodeRubrique {
                                ...RubriqueFragment
                            }
                            ... on NodeSatellite {
                                ...SatelliteFragment
                            }
                            ... on NodePageLanding {
                                ...PageLandingFragment
                            }
                            entityTranslations {
                                entityLanguage {
                                    id
                                }
                                entityUrl {
                                    path
                                }
                            }
                            entityMetatags {
                                key
                                value
                            }
                        }
                    }
                }
            }
            ... on EntityCanonicalUrl {
                breadcrumb {
                    text
                    url {
                        path
                    }
                }
                nodeContext {
                    __typename
                    title
                    entityChanged
                    ... on NodeOutil {
                        ...OutilFragment
                    }
                    ... on NodeArticle {
                        ...ArticleFragment
                    }
                    ... on NodeFaq {
                        ...FaqFragment
                    }
                    ... on NodeRubrique {
                        ...RubriqueFragment
                    }
                    ... on NodeSatellite {
                        ...SatelliteFragment
                    }
                    ... on NodePageLanding {
                        ...PageLandingFragment
                    }
                    entityTranslations {
                        entityLanguage {
                            id
                        }
                        entityUrl {
                            path
                        }
                    }
                    entityMetatags {
                        key
                        value
                    }
                }
            }
        }
    }

    # Fragments
    ${OutilFragment.fragment}
    ${ArticleFragment.fragment}
    ${SatelliteFragment.fragment}
    ${RubriqueFragment.fragment}
    ${FaqFragment.fragment}
    ${PageLandingFragment.fragment}
`
