import { colors, smallest, tablet } from '../../theme'


export default {
  container: {
    height: 0,
    transition: 'opacity 200ms ease-out',
    '&.is-hidden': {
      opacity: '0',
      '&, & *': {
        pointerEvents: 'none !important',
      },
    },
  },
  // styles react-tooltip
  tooltip: {
    // overriding some of the original options with important
    padding: '0 !important',
    border: '0 none !important',
    borderRadius: '0.4rem',
    overflow: 'hidden',
    fontSize: '1.4rem',
    color: `${colors.pureWhite} !important`,
    pointerEvents: 'all',
    maxWidth: '36rem',
    boxShadow: '0 0 2rem rgba(0,0,0,0.2)',
    transition: 'opacity 200ms ease-out !important',
    '&.show': {
      opacity: '1 !important',
    },
    '&::before, &::after': {
      display: 'none', // hidden because of the size of the tooltips
    },
    '&.place-top::after': {
      borderTopColor: `${colors.blue} !important`,
    },
    '&.place-bottom::after': {
      borderBottomColor: `${colors.blue} !important`,
    },
    '&.place-right::after': {
      borderRightColor: `${colors.blue} !important`,
    },
    '&.place-right': {
      marginLeft: '0!important',
    },
    '&.place-left::after': {
      borderLeftColor: `${colors.blue} !important`,
    },
  },
  // styles the definitions inside
  definition: {
    margin: 0,
  },
  link: {
    display: 'block',
    padding: '1rem 3.5rem 1rem 1rem',
    color: 'inherit',
    fontSize: '1.4rem',
    backgroundColor: colors.blueDiscover,
  },
  // not displayed for now
  term: {},
  text: {
    display: '-webkit-box',
    maxHeight: '5.5rem', // added for incompatible browser with the line clamps
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    margin: 0,
    overflow: 'hidden',
    fontSize: 'inherit',
  },
  icon: {
    position: 'absolute',
    right: '0.75rem',
    bottom: '1em',
    width: '2rem',
    height: '0.6rem',
    transition: 'transform 200ms ease-out',
    '$link:hover &': {
      transform: 'translateX(0.25rem)',
    },
  },
  ...tablet({
    link: {
      fontSize: '1.4rem',
    },
  }),
  ...smallest({
    link: {
      '&.noRedirect': {
        padding: '1rem',
      },
    },
  }),
}
