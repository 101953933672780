export default {
  image: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    // for ie11 to make sure images do not expand
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      '& img': {
        flex: '0 0 auto',
      },
    },
    '&.covered img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      fontFamily: "'object-fit: cover;'",
    },
    '&.storybook-cover': {
      width: '300px',
      height: '300px',
      border: '2px red solid',
    },
  },
  picture: {
    width: '100%',
    height: '100%',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}
