import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'
import Image from '../Image'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockLandingWhat = (props) => {
  const {
    classes: classesProp,
    className,
    text,
    title,
    TagTitle,
    lexicon,
    transformLexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <section className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {title && (
            <TagTitle className={classes.title}>{title}</TagTitle>
          )}
          {text && (
            <MarkdownStyled
              className={classes.text}
              text={text}
              lexicon={lexicon}
              transformLexicon={transformLexicon}
              markHover={markHover}
            />
          )}
        </div>
        <div className={classes.bubble}>
          <Image
            src="/images/Landing/landing-bg-what.png"
            className={classes.bubbleImage}
            alt=" "
            aria-hidden="true"
          />
        </div>
      </div>
    </section>
  )
}

BlockLandingWhat.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
  title: PropTypes.string,
  TagTitle: PropTypes.string,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  transformLexicon: PropTypes.bool,
  markHover: PropTypes.func,
}

BlockLandingWhat.defaultProps = {
  className: null,
  classes: null,
  text: '',
  title: '',
  TagTitle: 'h2',
  lexicon: null,
  transformLexicon: true,
  markHover: () => null,
}

export default withMemo()(BlockLandingWhat)
