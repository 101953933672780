import { all, call, put, select, takeEvery, putResolve } from 'redux-saga/effects'

import ApiSagas from '../api/sagas'
import Services from '../../graphql/query/Vocabulary'

import { actions, selectors } from './redux'
import { lexicalsTransformer } from './transformers'



export default class LexiconSagas {


  static* loadInitialData() {
    return yield call(LexiconSagas.requestInitialData)
  }

  static* requestInitialData() {
    const result = yield call(ApiSagas.persistQuery, Services.taxonomyTermsByVocabulary, { vocabulary: 'glossaire' })

    if (result && !result.errors) {
      const lexicals = yield call(lexicalsTransformer, result?.data?.taxonomyTermQuery?.entities ?? [])

      yield put(actions.setLexicals(lexicals))
    }
  }

  static* addSkip({ payload }) {
    const { skip } = payload
    const skips = yield select(selectors.skips)

    if (skips.indexOf(skip) < 0) {
      skips.push(skip)
    }

    yield put(putResolve(actions.setSkips(skips)))
  }


  static* removeSkip({ payload }) {
    const { skip } = payload
    const skips = yield select(selectors.skips)

    const filtered = skips.filter((value) => value === skip)

    yield put(putResolve(actions.setSkips(filtered)))
  }


  static* loop() {
    yield all([
      takeEvery(actions.addSkip.getType(), LexiconSagas.addSkip),
      takeEvery(actions.removeSkip.getType(), LexiconSagas.removeSkip),
    ])
  }

}
