import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import { app as AppConfig } from '../../configuration'


export const key = 'app'

export const pageType = {}

export const resourceType = {}

//
// Initial state
//
const initialState = {
  route: null,
  preview: AppConfig.IS_PREVIEW,
  resource: { type: null, id: null },
  pages: {},
  breadcrumb: null,
  seo: null,
  jsonld: null,
  isMobileMenuOpen: false,
  isCurrentSearch: false,
}

//
// Actions
//
export const actions = {
  setRoute: createAction('App: setRoute', (route) => route),
  setPreview: createAction('App: setPreview', (preview) => preview),
  setPages: createAction('App: setPages', (pages) => pages),
  setBreadcrumb: createAction('App: setBreadcrumb', (breadcrumb) => breadcrumb),
  scrollTop: createAction('App: scrollTop'),
  setSeo: createAction('App: setSeo', (seo) => seo),
  setJsonld: createAction('App: setJsonld', (jsonld) => jsonld),
  setIsMobileMenuOpen: createAction('App: setIsMobileMenuOpen', (isMobileMenuOpen) => isMobileMenuOpen),
  setIsCurrentSearch: createAction('App: setIsCurrentSearch', (isCurrentSearch) => isCurrentSearch),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setRoute]: (state, route) => Immutable.merge(state, { route }),
    [actions.setPreview]: (state, preview) => Immutable.merge(state, { preview }),
    [actions.setResource]: (state, resource) => Immutable.merge(state, { resource }),
    [actions.setPages]: (state, pages) => Immutable.merge(state, { pages }),
    [actions.setBreadcrumb]: (state, breadcrumb) => Immutable.merge(state, { breadcrumb }),
    [actions.scrollTop]: (state) => {
      window.scrollTo(0, 0)
      return state
    },
    [actions.setSeo]: (state, seo) => Immutable.merge(state, { seo }),
    [actions.setJsonld]: (state, jsonld) => Immutable.merge(state, { jsonld }),
    [actions.setIsMobileMenuOpen]: (state, isMobileMenuOpen) => Immutable.merge(state, { isMobileMenuOpen }),
    [actions.setIsCurrentSearch]: (state, isCurrentSearch) => Immutable.merge(state, { isCurrentSearch }),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state[key]
const route = (state) => root(state).route
const preview = (state) => root(state).preview
const pages = (state) => root(state).pages
const page = (state) => (id) => pages(state)[id] || {}
const breadcrumb = (state) => root(state).breadcrumb
const seo = (state) => root(state).seo
const jsonld = (state) => root(state).jsonld
const isMobileMenuOpen = (state) => root(state).isMobileMenuOpen
const isCurrentSearch = (state) => root(state).isCurrentSearch

export const selectors = {
  route,
  preview,
  page,
  breadcrumb,
  seo,
  jsonld,
  isMobileMenuOpen,
  isCurrentSearch,
}
