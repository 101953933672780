import Color from 'color'

import { accessibleHiddenText, colors, grids } from '../../theme'


export default {
  container: {
    position: 'relative',
    display: 'block',
    width: '48.8rem',
    height: '40rem',
    borderRadius: '1rem',
    overflow: 'hidden',
    backgroundColor: colors.greyF6,
    color: colors.pureWhite,
  },
  figure: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 0,
    height: '100%',
    width: '100%',
  },
  video: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    borderRadius: '1rem',
    overflow: 'hidden',
  },
  play: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    background: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    '& i': {
      ...accessibleHiddenText,
    },
    '&.is-touched': {
      opacity: 0,
      pointerEvents: 'none',
    },
  },
  playControl: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '5.5rem',
    height: '5.5rem',
    border: '1px solid currentColor',
    borderRadius: '50%',
    backgroundColor: Color(colors.pureWhite).alpha(0.3).toString(),
    transition: 'all 200ms ease-out',
    transform: 'translate3D(-50%, -50%, 0)',
    '$container:hover &': {
      backgroundColor: Color(colors.pureWhite).alpha(0.2).toString(),
    },
  },
  playIcon: {
    width: '0.8rem',
    height: '0.9rem',
  },
  [`@media (max-width: ${grids.desktop.boxingVideo}px)`]: {
    container: {
        width: '24.4rem',
        height: '20rem',
    },
  },
}
