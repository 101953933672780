import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'
import A from '../A'
import Image from '../Image'
import Icon from '../Icon'
import icons from '../Icon/assets'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockFocus = (props) => {
  const {
    classes: classesProp,
    className,
    text,
    image,
    linkProps,
    title,
    TagTitle,
    // eslint-disable-next-line no-unused-vars
    variant,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return text && (
    <section className={cx(classes.container, className)}>
      <A
        {...linkProps}
        className={classes.wrapper}
      >
        {image && (
          <figure className={classes.figure}>
            <Image
              {...image}
              covered
              className={classes.image}
            />
          </figure>
        )}
        <div className={classes.content}>
          {title && (
            <MarkdownText
              tag={TagTitle}
              className={classes.title}
              text={title}
            />
          )}
          <MarkdownStyled
            className={classes.text}
            text={text}
            variant={variant}
          />
          {linkProps && (
            <Icon
              className={classes.linkIcon}
              icon={icons.ArrowRight}
              aria-hidden="true"
            />
          )}
        </div>
      </A>
    </section>
  )
}

BlockFocus.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
  image: PropTypes.shape(Image.propTypes),
  linkProps: PropTypes.shape(A.propTypes),
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
  title: PropTypes.string,
  TagTitle: PropTypes.string,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  transformLexicon: PropTypes.bool,
  markHover: PropTypes.func,
}

BlockFocus.defaultProps = {
  className: null,
  classes: null,
  text: '',
  image: null,
  linkProps: null,
  title: '',
  TagTitle: 'h2',
  lexicon: null,
  transformLexicon: true,
  markHover: () => null,
}

export default withMemo()(BlockFocus)
