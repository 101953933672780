import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    ...fonts.MaaxMedium,
    display: 'block',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '1.9rem',
    color: colors.grey95,
    transition: 'color 200ms ease-out',
    background: colors.greyF6,
    cursor: 'pointer',
    '&:hover': {
      color: colors.blueSteel,
      textDecoration: 'underline',
    },
    '&.is-spacecoloronhover:hover': {
      color: (props) => (
        props.variant === 'care' ? colors.redCare
          : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
            : props.variant === 'listen' ? colors.orangeDarkListen
              : props.variant === 'discover' ? colors.blueDiscover
                : colors.blueSteel),
    },
    '&.is-active': {
      color: (props) => (
        props.variant === 'care' ? colors.redCare
          : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
            : props.variant === 'listen' ? colors.orangeDarkListen
              : props.variant === 'discover' ? colors.blueDiscover
                : colors.blue),
    },
    ...tablet({
      ...fonts.MaaxBold,
      display: 'inline',
      textAlign: 'left',
      fontSize: '1.6rem',
      backgroundColor: 'transparent',
    }),
  },
}
