import { colors, desktop, grids } from '../../theme'


export default {
  container: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    background: colors.pureWhite,

  },
  wrapper: {
    maxWidth: `${grids.desktop.boxingContent / 10}rem`,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  list: {
    textAlign: 'center',
    ...desktop({
      textAlign: 'left',
    }),
  },
  item: {
    position: 'relative',
    display: 'inline',
    '&:not(:first-child)': {
      '&::before': {
        marginLeft: '1rem',
        marginRight: '1rem',
        content: '"•"',
      },
    },
  },
  link: {
    fontSize: '1.4rem',
    color: colors.pureBlack,
    '&:hover': {
      color: colors.blue,
    },
    '&.is-current': {
      color: colors.blue,
      fontWeight: 'bold',
    },
  },
  '@media print': {
    container: {
      display: 'none',
    },
  },
}
