/* eslint-disable max-lines */
import { all, call, put } from 'redux-saga/effects'

import ApiSagas from '../../../redux/api/sagas'
import { actions as AppActions } from '../../../redux/app/redux'
import Service from '../../../graphql/query/Video/index'
import { api as ApiConfig, app as AppConfig } from '../../../configuration'
import { generateAkamaiUrl } from '../../../helpers/Akamai'
import { breadcrumbTransformer } from '../../Pages/Page/transformers'
import { actions as PageActions } from '../../Pages/Page/redux'

import { actions as VideoActions } from './redux'
import { videoBySlugTransformer } from './transformers'


export default class ShareVideoSagas {

  static* init(ctx) {
    const { asPath } = ctx

    const result = yield call(ApiSagas.persistQuery, Service.bySlug, {
      path: asPath,
    })

    if (result && !result.errors && result.data && result.data.route && result.data.route.entity) {
      yield put(VideoActions.setVideo(videoBySlugTransformer(result.data.route.entity)))
      const seo = yield call(
          ShareVideoSagas.seo,
          (result.data.route.nodeContext?.entityMetatags ?? (result.data.route?.metatags ?? [])),
          result.data.route.entity?.fieldCoverShare ?? null,
          result.data.route.entity?.fieldDescription ?? ''
      )

      yield put(AppActions.setSeo({
        metas: seo,
        image: generateAkamaiUrl(result.data.route.entity?.fieldCoverShare?.url ?? result.data.route.entity?.fieldImage?.url ?? ''),
      }))

      const breadcrumbTransformed = yield call(breadcrumbTransformer, result.data.route.breadcrumb)

      yield all([
        put(PageActions.setBreadcrumb(breadcrumbTransformed)),
      ])
    } else {
      return { error: 404 }
    }
  }


  static seo(metas, shareCover, description) {
    const tMetas = metas?.map((k) => {
      if (k.key === 'canonical' || k.key === 'shortlink' || k.key === 'og:url' || k.key === 'twitter:url') {
        const protocol = ApiConfig.BACKEND_URL.startsWith('http://') ? {
          search: 'http',
          replace: 'https',
        } : { search: 'https', replace: 'http' }
        const search = [
          ApiConfig.BACKEND_URL,
          ApiConfig.BACKEND_URL.replace(protocol.search, protocol.replace),
        ]

        let value = k.value

        search.forEach((url) => {
          value = value.replace(url, AppConfig.APP_URL)
        })

        return {
          ...k,
          value,
        }
      }
      if (k.key === 'title' || k.key === 'og:title' || k.key === 'twitter:title') {
        let cleanValue = k.value

        if (cleanValue.indexOf('- ') === 0) {
          cleanValue = cleanValue.substr(2)
        }
        return {
          ...k,
          value: cleanValue,
        }
      }
      if (k.key === 'image_src' || k.key === 'og:image' || k.key === 'og:image:url' || k.key === 'twitter:image') {
        return {
          ...k,
          value: generateAkamaiUrl(shareCover?.url || k.value).slice(0, -1),
        }
      }

      return k
    }) ?? []

    tMetas.push({
      key: 'description',
      value: description,
      __typename: 'MetaProperty',
    })

    tMetas.push({
      key: 'og:description',
      value: description,
      __typename: 'MetaProperty',
    })

    // Remove canonical? (seems buggy)
    const noCanonicalMetas = []

    for (let i = 0; i < tMetas.length; i++) {
      if (tMetas[i].key !== 'canonical') {
        noCanonicalMetas.push(tMetas[i])
      }
    }

    return noCanonicalMetas
  }

  static* loop() {
    yield all([])
  }

}
