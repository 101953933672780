import { variantTransformer } from '../../../redux/menu/transformers'
import { generateAkamaiUrl } from '../../../helpers/Akamai'


export const questionTransformer = (item) => ({
  question: item?.fieldQuestion ?? '',
  answer: item?.body?.value ?? '',
})

export const faqTransformer = (item) => ({
  id: item?.uuid ?? '',
  title: item?.fieldTitreAncre ?? '',
  variant: variantTransformer(item?.fieldFaqBlocRubriqueColor?.entity?.fieldRubriqueClass ?? ''),
  questions: item?.fieldFaqBlocQuestionReponse?.map(({ entity }) => questionTransformer(entity)) ?? [],
  image: item?.fieldImageAncre ? {
    src: generateAkamaiUrl(item?.fieldImageAncre?.url ?? ''),
    alt: item?.fieldImageAncre?.alt ?? '',
  } : null,
})
