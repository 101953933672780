import { grids } from '../../theme'


export default {
  container: {},
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingMax / 10}rem)`,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  figure: {
    margin: 0,
    width: '100%',
  },
  image: {},
}
