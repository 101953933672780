import { grids } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexFlow: 'row-reverse nowrap',
    alignItems: 'flex-start',
    marginLeft: '-2rem',
    marginRight: '-2rem',
    // display: 'none',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
    // override plyr style to fill entire h/w
    '& .is-notfullscreen .plyr video': {
      height: '200%',
      width: '200%',
      top: '-50%',
      left: '-50%',
    },
  },
  avatar: {
    display: 'none',
  },
  scroller: {
    flex: '1',
  },
  content: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
    width: 'max-content',
    '& > *': {
      flex: '0 0 auto',
      marginRight: '1rem',
    },
    '& > *:first-child': {
      marginLeft: 'max(calc(100vw - 24.4rem - 9rem), 1rem)', // viewport - size of the visible first item - space on the left OR 1rem if it's too small
      fallbacks: {
        marginLeft: 'calc(100vw - 24.4rem - 9rem)',
      },
    },
  },
  [`@media (min-width: ${grids.desktop.boxingQuestion}px)`]: {
    container: {
      marginLeft: 0,
      marginRight: 0,
    },
    content: {
      width: '100%',
      '& > *': {
        marginRight: '2rem',
      },
      '& > *:first-child': {
        marginLeft: '0',
      },
      '& > *:last-child': {
        marginRight: '0',
      },
    },
    avatar: {
      flex: '0 0 auto',
      display: 'block',
      minHeight: '1rem',
      width: '5rem',
      marginLeft: '2rem',
      overflow: 'hidden',
    },
  },
  '@media print': {
    content: {
      '& > *:first-child': {
        marginLeft: '2rem',
      },
    },
  },
}
