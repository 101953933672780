import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles, useTheme } from 'react-jss'

import withMemo from '../../decorators/withMemo'
import Header from '../../components/Header'
import Breadcrumb from '../../components/Breadcrumb'
import Footer from '../../components/Footer'
import FooterMenu from '../../components/FooterMenu'
import FooterUnder from '../../components/FooterUnder'
import NavMobile from '../../components/NavMobile'
import BackToTop from '../../components/BackToTop'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PageContainer = (props) => {
  // eslint-disable-next-line react/prop-types
  const {
    classes: classesProp,
    className,
    children,
    tools,
    headerProps,
    breadcrumbProps,
    footerProps,
    footerMenuProps,
    footerUnderProps,
    navMobileProps,
    backToTopProps,
    isMobileMenuOpen,
    setMobileMenuOpen,
    ...otherProps
  } = props

  const { APP_THEME } = useTheme()
  const classesComp = useStyles({ ...props, APP_THEME })
  const classes = useMemo(() => ({ ...classesComp, ...classesProp }), [classesComp, classesProp])

  const handleOpenMobileMenu = useCallback(() => {
    setMobileMenuOpen(true)
  }, [setMobileMenuOpen])

  const handleCloseMobileMenu = useCallback(() => {
    setMobileMenuOpen(false)
  }, [setMobileMenuOpen])

  return (
    <div
      className={cx(classes.pageContainer, className)}
      {...otherProps}
    >
      <Header
        {...headerProps}
        onOpenMenu={handleOpenMobileMenu}
      />
      <Breadcrumb {...breadcrumbProps} />
      <main className={classes.pageContainerMain}>
        {children}
      </main>
      {tools && (
        <div>
          {tools}
        </div>
      )}
      <Footer
        {...footerProps}
        className={classes.pageContainerFooter}
      >
        <FooterMenu {...footerMenuProps} />
        <FooterUnder {...footerUnderProps} />
      </Footer>
      <NavMobile
        {...navMobileProps}
        isOpen={isMobileMenuOpen}
        onCloseMenu={handleCloseMobileMenu}
      />
      <BackToTop {...backToTopProps} />
    </div>
  )
}

PageContainer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  headerProps: PropTypes.shape(Header.propTypes),
  footerProps: PropTypes.shape(Footer.propTypes),
  breadcrumbProps: PropTypes.shape(Breadcrumb.propTypes),
  footerMenuProps: PropTypes.shape(FooterMenu.propTypes),
  footerUnderProps: PropTypes.shape(FooterUnder.propTypes),
  navMobileProps: PropTypes.shape(NavMobile.propTypes),
  children: PropTypes.node,
  tools: PropTypes.node,
  backToTopProps: PropTypes.shape(BackToTop.propTypes),
  isMobileMenuOpen: PropTypes.bool,
  setMobileMenuOpen: PropTypes.func,
}

PageContainer.defaultProps = {
  className: '',
  classes: {},
  headerProps: null,
  footerProps: null,
  breadcrumbProps: null,
  footerMenuProps: null,
  footerUnderProps: null,
  navMobileProps: null,
  children: null,
  tools: null,
  backToTopProps: null,
  isMobileMenuOpen: false,
  setMobileMenuOpen: () => null,
}

export default withMemo()(PageContainer)
