const sizes = {
  smallest: 325,
  small: 500,
  mobile: 750,
  tablet: 980,
  desktop: 1180,
  biggerDesktop: 1280,
  biggestDesktop: 1400,
  ultraDesktop: 1600,
}

export default sizes

// Media Queries

export const smallestMedia = `(min-width: ${sizes.smallest}px)`
export const smallestMediaQuery = `@media ${smallestMedia}`

export const smallMedia = `(min-width: ${sizes.small}px)`
export const smallMediaQuery = `@media ${smallMedia}`

export const mobileMedia = `(min-width: ${sizes.mobile}px)`
export const mobileMediaQuery = `@media ${mobileMedia}`

export const tabletMedia = `(min-width: ${sizes.tablet}px)`
export const tabletMediaQuery = `@media ${tabletMedia}`

export const desktopMedia = `(min-width: ${sizes.desktop}px)`
export const desktopMediaQuery = `@media ${desktopMedia}`

export const biggerDesktopMedia = `(min-width: ${sizes.biggerDesktop}px)`
export const biggerDesktopMediaQuery = `@media ${biggerDesktopMedia}`

export const biggestDesktopMedia = `(min-width: ${sizes.biggestDesktop}px)`
export const biggestDesktopMediaQuery = `@media ${biggestDesktopMedia}`

export const ultraDesktopMedia = `(min-width: ${sizes.ultraDesktop}px)`
export const ultraDesktopMediaQuery = `@media ${ultraDesktopMedia}`
