import { colors, fonts, grids, mobile, tablet } from '../../theme'


export default {
  container: {
    marginTop: '3rem',
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '3rem',
    },
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  titleZone: {
    display: 'flex',
    justifyContent: 'center',
    background: 'transparent',
    color: colors.blue,
    '$container.is-practicehome &': {
      alignItems: 'center',
      width: 'calc(73.5% + 4rem)', // added to fit the client's demand that it stays the same as before
      minHeight: '26rem',
      color: colors.pureWhite,
      background: colors.blue,
      paddingLeft: '2rem',
      paddingRight: '2rem',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  title: {
    ...fonts.MaaxBold,
    position: 'relative',
    margin: 0,
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    textTransform: 'uppercase',
    fontSize: '2.8rem',
    '$container.is-practicehome &': {
      fontSize: '3.4rem',
    },
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      height: '0.5rem',
      width: '8rem',
      backgroundColor: 'currentColor',
    },
    '&::before': {
      top: 0,
      left: 0,
    },
    '&::after': {
      bottom: 0,
      right: 0,
      width: '8rem',
    },
    '$container.is-practicehome &::after': {
      width: '100%',
    },
  },
  contentZone: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginTop: '2rem',
    '$container.is-practicehome &': {
      flexFlow: 'column-reverse nowrap',
      marginTop: '0',
    },
    '& > *': {
      marginTop: '2rem',
    },
  },
  toolsZone: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    fontSize: '1.6rem',
  },
  ...mobile({
    container: {
      marginTop: '4.5rem',
      // penultimate child has a margin bottom because last is lexicon container
      '&:nth-last-child(2)': {
        marginBottom: '4.5rem',
      },
    },
    wrapper: {
      flexFlow: 'row nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    titleZone: {
      minHeight: '0',
      width: 'calc(100% / 12 * 6 - 4rem)',
      '$container.is-practicehome &': {
        minHeight: '21rem',
        width: 'calc(100% / 12 * 5)',
      },
    },
    title: {
      fontSize: '3.4rem',
    },
    contentZone: {
      marginTop: 0,
      '&, $container.is-practicehome &': {
        flexFlow: 'column nowrap',
        width: 'calc(100% / 12 * 6)',
      },
      '& > *': {
        marginTop: 0,
      },
      '& > *:not(:first-child)': {
        marginTop: '1.5rem',
      },
      '$container.is-practicehome &': {
        minHeight: '21rem',
      },
      '$container.is-practicehome & > *': {
        marginTop: '1.5rem',
      },
    },
    toolsZone: {
      marginTop: '4rem', // overrides generic styles above
    },
  }),
  ...tablet({
    titleZone: {
      '&, $container.is-practicehome &': {
        width: 'calc(100% / 12 * 4)',
      },
    },
    contentZone: {
      '&, $container.is-practicehome &': {
        width: 'calc(100% / 12 * 7)',
      },
    },
  }),
  '@media print': {
    toolsZone: {
      display: 'none',
    },
    titleZone: {
      '&, $container.is-practicehome &': {
        minHeight: 0,
      },
    },
  },
}
