import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import withMemo from '../../../decorators/withMemo'
import BlockAnchor from '../../../components/BlockAnchor'
import { satelliteAncreTransformer } from '../../Templates/SatelliteWrapper/transformers'
import { selectors as PageSelectors } from '../../Pages/Page/redux'


const SatelliteAncre = (props) => {
  const { lexicon, markHover } = props
  const variant = useSelector(PageSelectors.variant)
  const { blockIndex, renderMenu } = props
  const block = useMemo(() => satelliteAncreTransformer(props), [props])

  return (
    <BlockAnchor
      key={`simplepage-blockanchor-${block.id}`}
      {...block}
      variant={variant}
      zoneMenu={blockIndex === 0 ? renderMenu : null}
      lexicon={lexicon}
      markHover={markHover}
    />
  )
}

SatelliteAncre.propTypes = {
  blockIndex: PropTypes.number,
  renderMenu: PropTypes.node,
  lexicon: PropTypes.array,
  markHover: PropTypes.func,
}

SatelliteAncre.defaultProps = {
  blockIndex: 0,
  renderMenu: null,
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(SatelliteAncre)
