import { variantMapperTransformer } from '../../Pages/Page/transformers'
import { generateAkamaiUrl } from '../../../helpers/Akamai'


export const relatedTransformer = (item, t, variant = '') => ({
  title: t('textTitleRelated'),
  links: item?.fieldArticleBlocsSuggestions?.map(({ entity }) => ({
    text: entity?.title ?? '',
    variant: variantMapperTransformer(entity),
    linkProps: {
      href: entity?.entityUrl?.path,
    },
    image: entity?.fieldImage?.fieldImageArticle ? {
      src: generateAkamaiUrl(entity?.fieldImage?.fieldImageArticle?.url ?? ''),
      alt: entity?.fieldImage?.alt ?? '',
    } : null,
    summary: entity?.body?.value ?? null,
  })) ?? [],
})
