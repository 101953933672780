import hoistNonReactStatics from 'hoist-non-react-statics'
import i18n from 'i18next'
import moment from 'moment'
import numeral from 'numeral'
import 'numeral/locales'
import { initReactI18next, withTranslation } from 'react-i18next'

import locales from './locales'


i18n.fallbackLng = 'FR'
i18n.locales = locales

i18n.use(initReactI18next) // bind react-i18next to the instance

i18n.reset = () => i18n.init({
  fallbackLng: i18n.fallbackLng,
  debug: false,
  react: {
    useSuspense: false,
  },

  interpolation: {
    escapeValue: false, // not needed for react!!
    format: (value, format, lng) => {
      if (format === 'uppercase') {
        return value.toUpperCase()
      }
      if (value instanceof Date) {
        return moment(value).format(format)
      }
      if (!Number.isNaN(value) && format) {
        return numeral(value).format(format)
      }
      return value
    },
  },
})

i18n.on('languageChanged', (lng) => {
  moment.locale(lng)
  numeral.locale(lng)
})

i18n.reset()

export default i18n

export const localize = () => (Component) => hoistNonReactStatics(withTranslation()(Component), Component)
