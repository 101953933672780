import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FooterMenuSub = (props) => {
  const {
    classes: classesProp,
    className,
    links,
    isOpen,
    isLarge,
    // eslint-disable-next-line no-unused-vars
    rowsNumber,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <ul
      className={cx(classes.container, className, isLarge && 'is-large', isOpen && 'is-open')}
    >
      {links.map((link, index) => (
        <li
          key={`footermenusub-${index}`}
          className={classes.levelTwoItem}
        >
          <A
            className={cx(classes.levelTwoLink, link.isCurrent === true && 'is-current')}
            {...link?.linkProps}
            dangerouslySetInnerHTML={{ __html: link?.text }}
            preventDefaultOnClick={false}
          />
        </li>
      ))}
    </ul>
  )
}

FooterMenuSub.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    linkProps: PropTypes.shape(A.propTypes),
    isCurrent: PropTypes.bool,
  })),
  isOpen: PropTypes.bool,
  isLarge: PropTypes.bool,
  rowsNumber: PropTypes.number,
}

FooterMenuSub.defaultProps = {
  className: null,
  classes: null,
  links: null,
  isOpen: false,
  isLarge: false,
  rowsNumber: 0,
}

export default withMemo()(FooterMenuSub)
