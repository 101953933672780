import { all, call, put, select } from 'redux-saga/effects'

import ApiSagas from '../api/sagas'
import Menu from '../../graphql/query/Menu'
import { selectors as AppSelectors } from '../app/redux'

import { actions as MenuActions, selectors as MenuSelectors } from './redux'
import { footerTransformer, menuTransformer } from './transformers'


export default class MenuSagas {

  static* loadMenuWithCache(variables) {
    const menusCache = yield select(MenuSelectors.menusCache)
    const { languageId } = variables

    if (!menusCache[languageId]) {
      const result = yield call(ApiSagas.persistQuery, Menu.menuByName, variables)

      if (!result.errors) {
        const tmp = {}

        tmp[languageId] = result
        yield put(MenuActions.setMenusCache(Object.assign(tmp, menusCache)))
      }

      return result
    }

    return menusCache[languageId] ?? null
  }

  static* purgeCache() {
    yield all([
      put(MenuActions.setMenusCache({})),
    ])
  }


  static* loadMenu(languageId) {
    const result = yield call(MenuSagas.loadMenuWithCache, {
      footerTop: 'footer-top',
      footer: 'footer',
      menu: 'main',
      headerRight: 'header-right',
      search: 'search',
      languageId,
    })

    if (!result.errors) {
      const footerTop = yield call(footerTransformer, result?.data?.footerTop?.links ?? {})
      const footer = yield call(footerTransformer, result?.data?.footer?.links ?? {})
      const menu = yield call(menuTransformer, result?.data?.menu?.links ?? [])
      const search = yield call(menuTransformer, result?.data?.search?.links ?? [])
      const headerRight = yield call(menuTransformer, result?.data?.headerRight?.links ?? [])

      yield all([
        put(MenuActions.setFooterTop(footerTop)),
        put(MenuActions.setFooter(footer)),
        put(MenuActions.setMenu(menu)),
        put(MenuActions.setHeaderRight(headerRight)),
        put(MenuActions.setSearch(search)),
      ])
    }

    return null
  }

  static* addAnchors({ payload }) {
    const { links } = payload

    yield put(MenuActions.setHeader(links))
  }

  static* addAnchor({ payload }) {
    const { link } = payload
    const header = yield select(MenuSelectors.header)
    const route = yield select(AppSelectors.route)
    const item = {
      label: link?.title ?? '',
      url: `${route?.asPath ?? '/'}#${link?.id ?? ''}`,
    }

    let inArray = false

    header.forEach((headerItem) => {
      if (headerItem.url === item.url) {
        inArray = true
      }
    })
    if (!inArray) {
      const newHeader = [].concat(header, [item])

      yield put(MenuActions.setHeader(newHeader))
    }
  }

  static* loop() {
    yield all([
    ])
  }

}
