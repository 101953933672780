import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockError = (props) => {
  const {
    classes: classesProp,
    className,
    text,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <section className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {text && (
          <MarkdownStyled
            className={classes.text}
            text={text}
          />
        )}
      </div>
    </section>
  )
}

BlockError.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
}

BlockError.defaultProps = {
  className: null,
  classes: null,
  text: '',
}

export default withMemo()(BlockError)
