export const isTypeObject = (o) => Object.prototype.toString.call(o) === '[object Object]'
export const isTypeArray = (o) => Object.prototype.toString.call(o) === '[object Array]'
export const isTypeString = (o) => Object.prototype.toString.call(o) === '[object String]'
export const isUndefined = (o) => Object.prototype.toString.call(o) === '[object Undefined]'
export const isNull = (o) => Object.prototype.toString.call(o) === '[object Null]'
export const isDefined = (o) => !isNull(o) && !isUndefined(o)

export const strContains = (src, search) => {
    const areAllParamsOfTypeString = [src, search].every(isTypeString)
    if (!areAllParamsOfTypeString) {
        console.info('strContains: param type error: all parameters should be string')
        return false
    }
    return src.indexOf(search) >= 0
}

export const isEmptyString = (o) => {
    if (!isDefined(o) || !isTypeString(o)) {
        console.info('isEmptyString: error: param is not a string and should be', JSON.stringify(o))
        return false
    }
    return o.length === 0
}


export const isEmptyObject = (o) => {
    if (!isTypeObject(o)) {
        throw new Error('isEmptyObject: error: param is not an object and should be', JSON.stringify(o))
    }
    return isTypeObject(o) && Object.keys(o).length === 0
}

export const strRemoveAllSpaces = (s) => {
    if (!isDefined(s) || !isTypeString(s)) {
        console.info('strRemoveAllSpaces: error: param is not a string and should be', JSON.stringify(s))
        return s
    }
    return s.trim().replaceAll(' ', '')
}
