import { colors, desktop, fonts } from '../../theme'


export default {
  container: {
    ...fonts.MaaxBold,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '3.5rem',
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: '1.25',
    textTransform: 'uppercase',
    color: (props) => (props.variant === 'questions' ? colors.blueQuestions : colors.pureWhite),
    backgroundColor: (props) => (props.variant === 'questions' ? colors.turquoiseQuestions : colors.blue),
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      height: '0.3rem',
      marginTop: '0.5rem',
      backgroundColor: (props) => (props.variant === 'questions' ? colors.turquoiseQuestions : colors.blue),
      opacity: (props) => ((props.isActive || props.isOpen) ? 1 : 0),
      transition: 'opacity 100ms ease-out',
      pointerEvents: 'none',
    },
    '&:hover::before': {
      opacity: 1,
    },
    ...desktop({
      height: '4rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      fontSize: '1.2rem',
      '&::before': {
        marginTop: '1rem',
      },
    }),
  },
}
