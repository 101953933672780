import gql from 'graphql-tag'

import { TaxonomyTermRubriqueFragment } from './TaxonomyTermRubriqueFragment'


export const RubriqueFragment = {
  fragment: gql`
    fragment RubriqueFragment on NodeRubrique {
      entityUrl {
        path
      }
      entityMetatags{
        key
        value
      }
      fieldImageReseauxSociaux{
          url
      }
      title
      body{value}
      fieldRubriqueImageOutil{
          alt
          derivative(style:IMAGEBLOCFAQ){
              url
          }
      }
      fieldImage{
          url
          alt
          fieldImageRub: derivative(style:IMAGERUB){
              url
          }
      }
      fieldSousRubrique{
        entity{
          ... on TaxonomyTermRubrique{
              ...TaxonomyTermRubriqueFragment
          }
        }
      }
    }
    ${TaxonomyTermRubriqueFragment.fragment}
  `,
}
