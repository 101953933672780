import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'


const https = require('https')
const fetch = require('cross-fetch')

const config = require('../configuration')


const fetchOptions = {}

if (config.app.IGNORE_API_SSL_ERRORS) {
  fetchOptions.agent = new https.Agent({
    rejectUnauthorized: false,
  })
}

const httpLink = createHttpLink({
  uri: config.api.GRAPH_API_URL,
  fetch,
  fetchOptions,
})

const errorLink = onError(({ networkError, graphQLErrors, response, operation }) => {
  const bugsnagClient = require('../helpers/BugsnagHelper')

  if (bugsnagClient) {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, ...additional }) => {
        const messageToSend = `[GraphQL error]: \
            Message: ${JSON.stringify(message)}, \
            Location: ${JSON.stringify(locations)}, \
            Path: ${JSON.stringify(path)}, \
            Additional: ${JSON.stringify(additional)}`

        bugsnagClient.notify(new Error(messageToSend), {
          severity: 'error',
          context: 'graphQLErrors',
          metaData: {
            Message: message,
            Location: locations,
            Path: path,
            Operation: operation,
            Response: response,
            Additional: additional,
          },
        })
      })
    }
    if (networkError) {
      bugsnagClient.notify(new Error(`[Network error]: ${networkError}`), {
        severity: 'error',
        context: 'graphQLNetworkError',
        metaData: {
          Operation: operation,
          Response: response,
        },
      })
    }
  }
})

const authBasic = config.api.API_BASIC_AUTH

const getLink = (isBasicAuth, isPersisted) => {
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `Basic ${authBasic}`,
      },
    })

    return forward(operation)
  })

  const persistedQueryLink = createPersistedQueryLink({ useGETForHashedQueries: true })

  return ApolloLink.from([
    ...isBasicAuth ? [authLink] : [],
    ...isPersisted ? [persistedQueryLink] : [],
    errorLink,
    httpLink,
  ])
}

export const defaultConfig = {
  fetchPolicy: 'no-cache',
  errorPolicy: 'all',
}

const GraphQLClient = new ApolloClient({
  link: getLink(authBasic, false),
  cache: new InMemoryCache(),
  defaultOptions: defaultConfig,
})

export const persistedClient = new ApolloClient({
  link: getLink(authBasic, true),
  cache: new InMemoryCache(),
  errorLink,
})

export default GraphQLClient
