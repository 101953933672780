import { colors, fonts } from '../../theme'


export default {
  container: {
    ...fonts.MaaxBold,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    textAlign: 'left',
    fontSize: '1.2rem',
    lineHeight: '1.25',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    color: colors.blue,
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '0.3rem',
      backgroundColor: (props) => (props.variant === 'environment' ? colors.turquoiseEnvironment
        : props.variant === 'listen' ? colors.orangeListen
          : props.variant === 'discover' ? colors.blueDiscover
            : props.variant === 'care' ? colors.redCare
            : colors.turquoiseQuestions),
      pointerEvents: 'none',
      opacity: (props) => ((props.isActive || props.isOpen) ? 1 : 0),
      transition: 'opacity 100ms ease-out',
    },
    '&:hover::before': {
      opacity: 1,
    },
  },
}
