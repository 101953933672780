/* eslint-disable max-lines */
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import ApiSagas from '../../../redux/api/sagas'
import Service from '../../../graphql/query/View/index'
import ServiceNode from '../../../graphql/query/Node/index'
import { selectors as TranslationSelectors } from '../../../redux/i18n/redux'
import { selectors as AppSelectors } from '../../../redux/app/redux'

import {
  questionsDuMomentTransformer,
  questionsTransformer
} from './transformers'
import { actions as QuestionsDuMomentActions, selectors as QuestionsDuMomentSelectors } from './redux'


export default class QuestionsDuMomentSagas {

  static* init({ languageId, data }) {
    const periodes = yield call(ApiSagas.persistQuery, Service.questionsDuMomentView, {}, questionsDuMomentTransformer)

    yield all([
      put(QuestionsDuMomentActions.setPeriodes(periodes ?? null)),
    ])

    const { query } = yield select(AppSelectors.route)

    const current = query?.slug?.substr(query?.slug?.indexOf('/') + 1)

    if (current) {
      const currentPeriodContent = periodes.find((periode) => periode.path === current)

      if (currentPeriodContent) {
        yield put(QuestionsDuMomentActions.setCurrentPeriode(current))
        yield call(QuestionsDuMomentSagas.loadQuestionsByPeriode, QuestionsDuMomentActions.loadQuestionsByPeriode({ periode: currentPeriodContent }))
      }
    }
  }

  static* loadQuestionsByPeriode() {
    const languageId = yield select(TranslationSelectors.lang)
    const periodes = yield select(QuestionsDuMomentSelectors.periodes)
    const currentPeriode = yield select(QuestionsDuMomentSelectors.currentPeriode)

    const periodeTID = periodes.find((periode) => periode.path === currentPeriode)?.tid ?? null

    if (periodeTID) {
      const questions = yield call(ApiSagas.persistQuery, ServiceNode.questionsByPeriode, {
        types: ['question_moment'],
        periodes: [`${periodeTID}`],
        languageId,
      }, questionsTransformer)

      yield put(QuestionsDuMomentActions.setQuestions(questions))
    }
  }

  static* loop() {
    yield all([
      takeLatest(QuestionsDuMomentActions.loadQuestionsByPeriode.getType(), QuestionsDuMomentSagas.loadQuestionsByPeriode),
    ])
  }

}
