import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import slugify from 'slugify'

import withMemo from '../../../decorators/withMemo'
import HeaderSimple from '../../../components/HeaderSimple'
import BlockLexicon from '../../../components/BlockLexicon'
import { selectors as LexiqueSelectors } from '../../../redux/lexicon/redux'
import { selectors as AppSelectors } from '../../../redux/app/redux'

import { headerSimpleLexiqueTransformer, lexiqueViewTransformer } from './transformers'


function RechercheWrapper() {
  const { t } = useTranslation()
  const view = useSelector(LexiqueSelectors.view)
  const term = useSelector(LexiqueSelectors.term)
  const route = useSelector(AppSelectors.route)

  // function to return initial
  const getInitial = (initialTerm) => initialTerm?.charAt(0)
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace('Œ', 'OE')
    .replace('œ', 'oe')
    .toLowerCase()

  // all the open terms
  const [openTerms, setOpenTerms] = useState([''])
  // the last open term
  const [openTerm, setOpenTerm] = useState('')
  // the current letter
  const [currentLetter, setCurrentLetter] = useState('')

  // set current parameter term as open
  useEffect(() => {
    setOpenTerms(term ? [slugify(term ?? '')] : [])
    setOpenTerm(term ? slugify(term) : '')
    setCurrentLetter(term ? getInitial(slugify(term))?.toUpperCase() : 'A')
  }, [term, view])

  // set all first words open by default but term
  useEffect(() => {
    let currentInitial
    const newOpenTerms = [...view].sort((a, b) => {
      if (a.id.toLowerCase() < b.id.toLowerCase()) {
        return -1
      }
      if (a.id.toLowerCase() > b.id.toLowerCase()) {
        return 1
      }
      return 0
    }).filter((thisTerm) => {
      const isDifferentFromPrevious = currentInitial !== getInitial(thisTerm.id)

      currentInitial = getInitial(thisTerm.id)

      const isDifferentFromTerm = currentInitial !== getInitial(term ?? '')

      // return only the first word for each letter OR the current term
      return (isDifferentFromPrevious && isDifferentFromTerm) || thisTerm.id === term === true
    }).map((thisTerm) => thisTerm.id)

    setOpenTerms([...newOpenTerms, term && slugify(term)])
  }, [term, view])

  // toggle handler
  const handleToggle = useCallback((toggleTerm, forceOpen) => {
    const newOpenTerms = [...openTerms]
    const thisTerm = slugify(toggleTerm?.id)
    const thisTermLetter = getInitial(thisTerm)
    // conditions
    const isInTerms = openTerms.includes(thisTerm)
    const indexSameFirstLetter = openTerms.findIndex((firstLetterTerm) => getInitial(firstLetterTerm) === thisTermLetter) ?? -1

    // set new state
    if (isInTerms && forceOpen !== true) {
      // console.log('xxxxxxxxxxx - case 1')
      const indexTerm = newOpenTerms.indexOf(thisTerm)

      newOpenTerms.splice(indexTerm, 1)
      setOpenTerm()
    } else if (indexSameFirstLetter !== -1) {
      // console.log('xxxxxxxxxxx - case 2')
      newOpenTerms.splice(indexSameFirstLetter, 1)
      newOpenTerms.push(thisTerm)
      setOpenTerm(thisTerm)
    } else {
      // console.log('xxxxxxxxxxx - case 3')
      newOpenTerms.push(thisTerm)
      setOpenTerm(thisTerm)
    }
    setOpenTerms(newOpenTerms)
  }, [openTerms])

  // build new terms
  const terms = useMemo(() => view.map((thisTerm) => ({
    ...thisTerm,
    isOpen: openTerms.includes(slugify(thisTerm?.id)) ?? false,
    onToggleHandler: () => handleToggle(thisTerm),
  })), [handleToggle, openTerms, view])

  // pass new terms to the transformer
  const blockLexiconProps = useMemo(() => lexiqueViewTransformer(terms, openTerm, currentLetter, t), [terms, openTerm, currentLetter, t])
  const headerSimpleLexiconProps = useMemo(() => headerSimpleLexiqueTransformer({ url: route?.asPath }, t), [route.asPath, t])

  // on click on the nav
  const handleChangeLetter = useCallback((letter) => {
    setCurrentLetter(letter)
    if (window) {
      const target = document.getElementById(`lexiconletter-${letter}`)

      if (target) {
        const y = target?.getBoundingClientRect().top + window.scrollY - 100

        window.scroll({
          top: y,
          behavior: 'smooth',
        })
      }
    }
  }, [])

  // on page load if term is defined
  useLayoutEffect(() => {
    setTimeout(() => {
      if (window && term) {
        const target = document.getElementById(`lexiconterm-${slugify(term)}`)

        if (target) {
          const y = target?.getBoundingClientRect().top + window.scrollY - 120

          window.scroll({
            top: y,
            behavior: 'smooth',
          })
        }
      }
    }, 300)
  }, [term])

  return (
    <>
      <HeaderSimple {...headerSimpleLexiconProps} />
      <BlockLexicon
        {...blockLexiconProps}
        onNavClickHandler={(letter) => handleChangeLetter(letter)}
      />
    </>
  )
}

RechercheWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
}

RechercheWrapper.defaultProps = {
}

export default withMemo()(RechercheWrapper)
