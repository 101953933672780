import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'

import styles from './styles'


const useStyles = createUseStyles(styles)

const ButtonColored = (props) => {
  const {
    classes: classesProp,
    className,
    linkProps,
    text,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <A
      {...linkProps}
      className={cx(className, classes.container)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}

ButtonColored.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  linkProps: PropTypes.shape(A.propTypes),
  text: PropTypes.string,
}

ButtonColored.defaultProps = {
  className: null,
  classes: null,
  linkProps: null,
  text: null,
}

export default withMemo()(ButtonColored)
