import { colors, fonts, grids, biggerDesktop, desktop } from '../../theme'


export default {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'none',
    color: colors.pureWhite,
    backgroundColor: (props) => (props.variant === 'environment' ? colors.turquoiseEnvironment
      : props.variant === 'listen' ? colors.orangeListen
        : props.variant === 'discover' ? colors.blueDiscover
          : colors.redCare),
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',
      bottom: 0,
      zIndex: 1,
      width: '50%',
      backgroundColor: (props) => (props.variant === 'environment' ? colors.turquoiseDarkEnvironment
        : props.variant === 'listen' ? colors.orangeDarkListen
          : props.variant === 'discover' ? colors.blueDarkDiscover
            : colors.redDarkestCare),
    },
    '& button, & a, & em': {
      ...fonts.MaaxBold,
      fontStyle: 'normal',
      color: (props) => (props.variant === 'environment' ? colors.blueDarkEnvironment
        : props.variant === 'listen' ? colors.blueListen
          : props.variant === 'discover' ? colors.yellowDiscover
            : colors.yellowCare),
      transition: 'all 100ms ease-out',
    },
    '& button:hover, & a:hover, & em': {
      color: colors.pureWhite,
    },
  },
  wrapper: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem)`,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  content: {
    width: 'calc(100% / 12 * 5)',
    padding: '5rem 12rem 5rem 5rem',
    backgroundColor: (props) => (props.variant === 'environment' ? colors.turquoiseEnvironment
      : props.variant === 'listen' ? colors.orangeListen
        : props.variant === 'discover' ? colors.blueDiscover
          : colors.redCare),
    ...biggerDesktop({
      paddingRight: '7rem',
    }),
  },
  title: {
    ...fonts.MaaxBold,
    position: 'relative',
    fontSize: '3.5rem',
    textTransform: 'uppercase',
    color: (props) => (props.variant === 'environment' ? colors.blueDarkEnvironment
      : props.variant === 'listen' ? colors.blueListen
        : props.variant === 'discover' ? colors.yellowDiscover
          : colors.yellowCare),
    lineHeight: 1.1,
    '&::before, &::after': {
      content: '""',
      height: '0.5rem',
      backgroundColor: 'currentColor',
    },
    '&::before': {
      display: 'block',
      maxWidth: '9rem',
      marginBottom: '2.5rem',
    },
    '&::after': {
      display: 'block',
      maxWidth: '15rem',
      marginTop: '2.5rem',
    },
  },
  text: {
    ...fonts.MaaxMedium,
    fontSize: '1.4rem',
    color: (props) => (props.variant === 'environment' ? colors.blueEnvironment
      : props.variant === 'listen' ? colors.blueDarkListen
        : props.variant === 'discover' ? colors.pureWhite
          : colors.pureWhite),
    lineHeight: 1.3,
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  navigation: {
    width: 'calc(100% / 12 * 7)',
    display: 'flex',
    flexFlow: 'row nowrap',
    padding: '5rem',
    backgroundColor: (props) => (props.variant === 'environment' ? colors.turquoiseDarkEnvironment
      : props.variant === 'listen' ? colors.orangeDarkListen
        : props.variant === 'discover' ? colors.blueDarkDiscover
          : colors.redDarkestCare),
    '& > *': {
      width: '50%',
    },
    '& > *:first-child': {
      paddingRight: '2rem',
    },
    '& > *:not(:first-child)': {
      paddingLeft: '2rem',
    },
  },
  twoList: {
  },
  twoItem: {
    '&:not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  twoTrigger: {
    position: 'relative',
    display: 'block',
    maxWidth: '27rem',
    padding: '2rem 0 2rem 0',
    textTransform: 'uppercase',
    textAlign: 'left',
    fontSize: '1.4rem',
    wordSpacing: 'normal',
    wordBreak: 'break-word',
    cursor: 'pointer',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
    },
    '&.is-active, &:hover': {
      color: colors.pureWhite,
    },
    '&::before': {
      top: 0,
      left: 0,
      display: 'block',
      width: '2.8rem',
      height: '0.3rem',
      background: 'currentColor',
    },
    '&::after': {
      right: '-2rem',
      top: '-7rem',
      bottom: '-7rem',
      width: '30%',
      pointerEvents: 'none',
    },
    '&.is-active::after, &:hover::after': {
      zIndex: 5,
    },
    'li:hover &.is-active::after, &:hover::after': {
      pointerEvents: 'all',
    },
  },
  level3: {
    position: 'relative',
  },
  threeList: {
    position: 'absolute',
    top: 0,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 200ms ease-out 50ms',
    paddingTop: '1.8rem',
    '&.is-active': {
      position: 'static',
      opacity: 1,
    },
    'li:hover &.is-active': {
      pointerEvents: 'all',
    },
  },
  threeItem: {
    minHeight: '2.8rem',
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  threeLink: {
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: '30rem',
    fontSize: '1.4rem',
    '&:hover': {
      transform: 'translateX(0.5rem)',
    },
  },
  threeIcon: {
    flex: '0 0 auto',
    width: '1rem',
    height: '1.4rem',
    marginRight: '1rem',
    color: colors.pureWhite,
  },
  ...desktop({
    container: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      zIndex: 1,
      opacity: 0,
      display: 'block',
      pointerEvents: 'none',
      transition: 'opacity 200ms ease-out 300ms',
      '&.is-open': {
        zIndex: 5,
        opacity: 1,
        pointerEvents: 'all',
        transition: 'opacity 200ms ease-out 0ms',
      },
    },
  }),
}
