import { small, tablet } from '../../theme'


export default {
  container: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    '&:not(:first-child)': {
      marginTop: '5rem',
    },
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '7rem',
    },
  },
  wrapper: {
    maxWidth: '123rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    margin: 0,
    fontSize: '2.6rem',
    lineHeight: 1.3,
    textTransform: 'uppercase',
    fontWeight: 900,
  },
  content: {
    position: 'relative',
    zIndex: 2,
  },
  text: {
    fontSize: '1.7rem',
    lineHeight: 1.6,
    '&, & > *': {
      lineHeight: '1.6 !important',
    },
    '&:not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  figure: {
    position: 'relative',
    margin: '0 auto',
    width: '70%',
    maxWidth: '36rem',
    '&:not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  image: {
    position: 'relative',
    zIndex: 2,
  },
  bubble: {
    position: 'absolute',
    zIndex: 1,
    bottom: '-2rem',
    right: '-25%',
    width: '52.2rem',
    height: '55rem',
  },
  buttons: {
    position: 'relative',
    zIndex: 2,
    '&:not(:first-child)': {
      marginTop: '1.5rem',
    },
    '& > *': {
      width: '100%',
    },
    '& > *:not(:first-child)': {
      marginTop: '2.5rem',
    },
  },
  ...small({
    container: {
      '&:not(:first-child)': {
        marginTop: '10rem',
      },
      '&:nth-last-child(2)': {
        marginBottom: '10rem',
      },
    },
    buttons: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      '& > *': {
        flex: '1 1 auto',
        width: 'auto',
        maxWidth: '25rem',
      },
      '& > *:not(:first-child)': {
        marginTop: '0',
        marginLeft: '2.5rem',
      },
    },
  }),
  ...tablet({
    container: {},
    wrapper: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      minHeight: '52rem',
      paddingLeft: '45rem',
    },
    title: {
      fontSize: '3rem',
      '& br': {
        display: 'none',
      },
    },
    figure: {
      position: 'absolute',
      left: 0,
      top: '50%',
      margin: '0 auto',
      transform: 'translateY(-45%)',
      '&:not(:first-child)': {
        marginTop: '0',
      },
    },
    bubble: {
      top: '50%',
      left: '50%',
      right: 'auto',
      width: '58.5rem',
      height: '62.2rem',
      transform: 'translate(-45%, -55%)',
    },
    content: {
      maxWidth: '77.5rem',
    },
    text: {
      fontSize: '1.5rem',
      '&, & > *': {
        lineHeight: '1.8 !important',
      },
      '&:not(:first-child)': {
        marginTop: '3rem',
      },
    },
    buttons: {
      '&:not(:first-child)': {
        marginTop: '3.5rem',
      },
      '& > *': {
        minWidth: '25rem',
      },
    },
  }),
  '@media print': {
    container: {
      '&:not(:first-child)': {
        marginTop: '1cm',
      },
    },
    figure: {
      width: '4cm',
      breakInside: 'avoid-page',
      display: 'block',
      '&:not(:first-child)': {
        marginTop: '2cm',
      },
      // all images hidden, code above was from before and kept in case
      // display: 'none',
    },
    text: {
      breakInside: 'avoid-page',
      display: 'block',
    },
    image: {
      display: 'block',
      breakInside: 'avoid-page',
    },
    buttons: {
      '&:not(:first-child)': {
        marginTop: '1cm',
      },
    },
    bubble: {
      width: '7cm',
      height: '7cm',
      // all images hidden, code above was from before and kept in case
      display: 'none',
    },
  },
}
