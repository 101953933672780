import gql from 'graphql-tag'


export const ParagraphVideoSpfFragment = {
  fragment: gql`
      fragment ParagraphVideoSpfFragment on ParagraphVideoSpf {
          fieldImage {
              url
              alt
          }
          fieldUrlVideo
      }
  `,
}
