import React from 'react'

import withMemo from '../../../decorators/withMemo'
import BlockLandingWhat from '../../../components/BlockLandingWhat'
import { useLexiconHandler } from '../../../utils/hooks/useLexicon'

import { pageLandingRichTextTransformer } from './transformer'


const PageLandingRichText = (props) => {
  const blockProps = pageLandingRichTextTransformer(props)
  const [lexicals, handleLexicon] = useLexiconHandler()

  return (
    <BlockLandingWhat
      {...blockProps}
      lexicon={lexicals}
      markHover={handleLexicon}
    />
  )
}

PageLandingRichText.propTypes = {}

PageLandingRichText.defaultProps = {}

export default withMemo()(PageLandingRichText)
