import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useFormik } from 'formik'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import { safeCall } from '../../helpers/React'
import Icon from '../Icon'
import icons from '../Icon/assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const HeaderSearchResults = (props) => {
  const {
    classes: classesProp,
    className,
    textSearchPlaceholder,
    textSearchedTerm,
    textSubmit,
    textCountResults,
    onSearch,
    zoneTools,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const formik = useFormik({
    initialValues: {
      search: textSearchedTerm ?? '',
    },
    enableReinitialize: true,
    onSubmit: useCallback(
      (values) => {
        safeCall(onSearch, values.search)
      },
      [onSearch]
    ),
  })

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.main}>
          <form
            className={classes.form}
            onSubmit={formik.handleSubmit}
          >
            <div className={classes.field}>
              <span
                aria-hidden="true"
                className={classes.fieldDecoration}
              >
                <Icon icon={icons.Search} />
              </span>
              <input
                id="search-input"
                className={classes.input}
                type="text"
                name="search"
                aria-label="search"
                placeholder={textSearchPlaceholder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.search}
              />
              <button
                type="submit"
                className={classes.submit}
              >
                <i>{textSubmit}</i>
                <Icon icon={icons.ArrowRight} />
              </button>
            </div>
          </form>
          <div
            className={classes.count}
          >
            <h1 className={classes.countResults}>
              {textCountResults}
              {textSearchedTerm}
            </h1>
          </div>
        </div>
        {zoneTools && (
          <div className={classes.toolsZone}>{zoneTools}</div>
        )}
      </div>
    </div>
  )
}

HeaderSearchResults.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  textSearchPlaceholder: PropTypes.string.isRequired,
  textSearchedTerm: PropTypes.string.isRequired,
  textSubmit: PropTypes.string.isRequired,
  textCountResults: PropTypes.string.isRequired,
  onSearch: PropTypes.func,
  zoneTools: PropTypes.node,
}

HeaderSearchResults.defaultProps = {
  className: null,
  classes: null,
  onSearch: PropTypes.func,
  zoneTools: null,
}

export default withMemo()(HeaderSearchResults)
