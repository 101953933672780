import { useState, useEffect } from 'react'

import { sizes } from '../../theme'


export function useWindowSize() {
  const [size, setSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  })

  useEffect(() => {
    const handleResize = () => setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })

    window.addEventListener('resize', handleResize)
    return () => {
      try {
        window.removeEventListener('resize', handleResize)
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    }
  }, [])
  return size
}

export const useIsDesktop = () => {
  const { width } = useWindowSize()

  return width > sizes.desktop
}

export const useIsTablet = () => {
  const { width } = useWindowSize()

  return width > sizes.tablet && width < sizes.desktop
}

export const useIsMobile = () => {
  const { width } = useWindowSize()

  return width < sizes.tablet
}

// mobile + tablet
export const useIsSmallDevice = () => {
  const { width } = useWindowSize()

  return width < sizes.desktop
}
