/* eslint-disable jsx-a11y/media-has-caption */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'

import styles from './styles'


const useStyles = createUseStyles(styles)

const QuestionPushEmpty = (props) => {
  const {
    classes: classesProp,
    className,
    children,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])


  return (
    <div
      className={cx(classes.container, className)}
    >
      {children}
    </div>
  )
}

QuestionPushEmpty.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
}

QuestionPushEmpty.defaultProps = {
  className: null,
  classes: null,
  children: null,
}

export default withMemo()(QuestionPushEmpty)
