import { replaceAndReturnMatch } from '../../../utils/hooks/useLexicon'


export const articleEncartContenuTransformer = (item, variant = '') => ({
  variant,
  focus: item?.fieldDescriptionAncre ? {
    variant,
    text: item?.fieldDescriptionAncre ? item?.fieldDescriptionAncre?.value : '',
  } : null,
  text: item?.fieldParagrapheContenu ? item?.fieldParagrapheContenu?.value : '',
})

export const blockArticleTransformer = (item, variant = '', lexicals, defaultSkip = []) => {
  const skip = defaultSkip ? [].concat(defaultSkip) : []
  const [replace, tmpSkip] = replaceAndReturnMatch(item?.body?.value ?? '', lexicals, skip)

  const tmp = {
    variant,
    text: replace,
  }

  return [tmp, skip.concat(tmpSkip)]
}


export const fieldBlocsArticleTransformer = (fieldBlocsArticle, lexicals, defaultSkip = []) => {
  let skip = defaultSkip ? [].concat(defaultSkip) : []

  const blocks = fieldBlocsArticle.map((block) => {
    const tmp = {
      ...block,
      entity: {
        ...block.entity,
      },
    }

    if (block?.entity?.fieldDescriptionAncre?.value) {
      tmp.entity.fieldDescriptionAncre = { ...block?.entity?.fieldDescriptionAncre }
      const [replace, tmpSkip] = replaceAndReturnMatch(block?.entity?.fieldDescriptionAncre.value, lexicals, skip)

      tmp.entity.fieldDescriptionAncre.value = replace
      skip = tmpSkip
    }

    if (block?.entity?.fieldParagrapheContenuResume?.value) {
      tmp.entity.fieldParagrapheContenuResume = { ...block?.entity?.fieldParagrapheContenuResume }
      const [replace, tmpSkip] = replaceAndReturnMatch(block?.entity?.fieldParagrapheContenuResume.value, lexicals, skip)

      tmp.entity.fieldParagrapheContenuResume.value = replace
      skip = tmpSkip
    }

    if (block?.entity?.fieldParagrapheContenu?.value) {
      tmp.entity.fieldParagrapheContenu = { ...block?.entity?.fieldParagrapheContenu }
      const [replace, tmpSkip] = replaceAndReturnMatch(block?.entity?.fieldParagrapheContenu.value, lexicals, skip)

      tmp.entity.fieldParagrapheContenu.value = replace
      skip = tmpSkip
    }


    return tmp
  })

  return [blocks, skip]
}
