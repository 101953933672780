import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useFormik } from 'formik'
import debounce from 'lodash/debounce'

import { useOutsideClick } from '../../utils/hooks/useOutsideClick'
import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import Icon from '../Icon'
import icons from '../Icon/assets'
import { safeCall } from '../../helpers/React'
import { tcEventEnum, useTagCommander } from '../../utils/hooks/useTagCommander'
import { isEmptyString } from '../../helpers/TypeHelpers'

import styles from './styles'


const useStyles = createUseStyles(styles)

const HeaderSearch = (props) => {
    const {
        classes: classesProp,
        className,
        links,
        isOpen,
        isCurrent,
        textSearch,
        textSubmit,
        textSuggestionsTitle,
        textSearchPlaceholder,
        textInitialValue,
        onSearch,
    } = props
    const { handleEventTC } = useTagCommander()
    const classesComp = useStyles(props)
    const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

    const refDialog = useRef()
    const [stateIsOpen, setStateIsOpen] = useState(false)
    const [y, setY] = useState(0)

    useEffect(() => {
        setStateIsOpen(isOpen)
    }, [isOpen, setStateIsOpen])

    useEffect(() => {
        setY(document.documentElement.scrollTop || document.body.scrollTop)
    }, [])

    const handleToggle = useCallback(() => {
        setStateIsOpen(!stateIsOpen)
    }, [stateIsOpen])

    const handleClose = useCallback(() => {
        if (stateIsOpen === true) {
            setStateIsOpen(false)
        }
    }, [stateIsOpen])

    useOutsideClick(refDialog, handleClose)

    const formik = useFormik({
        initialValues: {
            search: textInitialValue,
        },
        enableReinitialize: true,
        onSubmit: useCallback(
            (values) => {
                safeCall(onSearch, values.search)
                handleClose()
            },
            [onSearch, handleClose]
        ),
    })


    const handleCloseOnScroll = useCallback(
        debounce(() => {
            const scrollTop = (document.documentElement.scrollTop || document.body.scrollTop)

            if (stateIsOpen === true && (scrollTop > y + 100) && (scrollTop > y - 100)) {
                setStateIsOpen(false)
            }

            setY(scrollTop)
        }, 50), [stateIsOpen]
    )

    // effects
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleCloseOnScroll)
            window.addEventListener('scroll', handleCloseOnScroll)
        }
        return () => {
            try {
                if (typeof window !== 'undefined') {
                    window.removeEventListener('resize', handleCloseOnScroll)
                    window.removeEventListener('scroll', handleCloseOnScroll)
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
            }
        }
    }, [handleCloseOnScroll, stateIsOpen])

    const handleOnClickSuggestionItem = useCallback((dataSearchItem, index) => {
        const term = dataSearchItem.text
        handleEventTC(
            tcEventEnum.ACTION_SEARCH,
            {
                data: {
                    searchTerm: term,
                },
            }
        )
    }, [handleEventTC])

    const renderSuggestions = useMemo(() => (links ? (
      <div className={classes.suggestions}>
        <p className={classes.suggestionsTitle}>{textSuggestionsTitle}</p>
        <ul
          className={classes.suggestionsList}
        >
          {links.map((link, index) => (
            <li
              className={classes.suggestionsItem}
              key={`headersearch-link-${index}`}
              onClick={() => handleOnClickSuggestionItem(link, index)}
            >
              <A
                {...link?.linkProps}
                onClick={() => handleClose()}
                className={classes.suggestionsLink}
              >
                {link?.text}
              </A>
            </li>
                ))}
        </ul>
      </div>
        // eslint-disable-next-line max-len
    ) : null), [classes.suggestions, classes.suggestionsItem, classes.suggestionsLink, classes.suggestionsList, classes.suggestionsTitle, handleClose, handleOnClickSuggestionItem, links, textSuggestionsTitle])

    const handleSubmitSearch = (e) => {
        if (!isEmptyString(formik.values.search)) {
            handleEventTC(
                tcEventEnum.ACTION_SEARCH,
                {
                    data: {
                        searchTerm: formik.values.search,
                    },
                }
            )
            formik.handleSubmit(e)
        }
    }

    return (
      <div
        className={cx(classes.container, className)}
      >
        <button
          type="button"
          className={cx(classes.trigger, isCurrent === true && 'is-current')}
          onClick={handleToggle}
        >
          <Icon icon={icons.Search} />
          <i>{textSearch}</i>
        </button>
        <div
          className={cx(classes.dialog, stateIsOpen && 'is-open')}
          ref={refDialog}
        >
          <form
            className={classes.form}
            onSubmit={handleSubmitSearch}
          >
            <label
              className={classes.title}
              htmlFor="search-input"
            >
              {textSearch}
            </label>
            <div className={classes.field}>
              <span
                aria-hidden="true"
                className={classes.fieldDecoration}
              >
                <Icon icon={icons.Search} />
              </span>
              <input
                id="search-input"
                className={classes.input}
                type="text"
                name="search"
                aria-label="search"
                placeholder={textSearchPlaceholder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.search}
              />
              <button
                type="submit"
                className={classes.submit}
              >
                <i>{textSubmit}</i>
                <Icon icon={icons.ArrowRight} />
              </button>
            </div>
          </form>
          {renderSuggestions}
        </div>
      </div>
    )
}

HeaderSearch.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.string),
    links: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        linkProps: PropTypes.shape(A.propTypes),
    })),
    isOpen: PropTypes.bool,
    isCurrent: PropTypes.bool,
    textSearch: PropTypes.string,
    textSubmit: PropTypes.string,
    textSuggestionsTitle: PropTypes.string,
    textSearchPlaceholder: PropTypes.string,
    textInitialValue: PropTypes.string,
    onSearch: PropTypes.func,
}

HeaderSearch.defaultProps = {
    className: null,
    classes: null,
    links: null,
    isOpen: false,
    isCurrent: false,
    textSearch: '',
    textSubmit: '',
    textSuggestionsTitle: '',
    textSearchPlaceholder: '',
    textInitialValue: '',
    onSearch: () => null,
}

export default withMemo()(HeaderSearch)
