import { generateAkamaiUrl } from '../../../helpers/Akamai'


export const videoSpfTransformer = (props, t) => {
  const videoUrl = props?.fieldUrlVideo ?? ''
  const videoType = videoUrl.indexOf('.mp4') !== -1 ? 'video/mp4' : (videoUrl.indexOf('.webm') !== -1 ? 'video/webm' : null)

  return {
    textPlay: t('playVideo'),
    image: {
      src: generateAkamaiUrl(props?.fieldImage?.url ?? ''),
      alt: props?.fieldImage?.alt ?? '',
    },
    videos: [
      {
        src: videoUrl,
        type: videoType,
      },
    ],
    tracks: [],
  }
}
