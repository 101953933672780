import slugify from 'slugify'

import routes, { Router } from '../../../routes'
import { variantTransformer } from '../../../redux/menu/transformers'


export const pageByFullSlugTransformer = (data) => {
  if (data?.route?.nodeContext) {
    return data?.route?.nodeContext ?? null
  } if (data?.route?.target?.nodeContext) {
    return data?.route?.target?.nodeContext ?? null
  } if (data.route.__typename) {
    return data.route
  }

  return null
}
export const pageAlternateTransformer = (data) => {
  const entityTranslations = data?.route?.nodeContext?.entityTranslations ?? data?.route?.target?.nodeContext?.entityTranslations ?? null
  const result = {}

  if (entityTranslations) {
    entityTranslations.forEach((item) => {
      result[item.entityLanguage.id] = item?.entityUrl?.path
        ?? Router.getRouteUrl(routes.page, { slug: '/', locale: item.entityLanguage.id })
    })
  }

  return result
}

export const variantMapperTransformer = (data) => {
  const fieldSousRubrique = data?.fieldSousRubrique ?? (data?.fieldArticleSousRubrique ?? null)

  if (fieldSousRubrique && fieldSousRubrique.length > 0) {
    return variantTransformer(fieldSousRubrique[0].entity?.fieldRubriqueClass ?? null)
  } if (fieldSousRubrique && typeof fieldSousRubrique === 'object') {
    return variantTransformer(fieldSousRubrique.entity?.fieldRubriqueClass ?? null)
  }

  return 'default'
}



export const breadcrumbItemTransformer = (data) => ({
  text: data?.text.replace('|', '') ?? '',
  slug: slugify(data?.text.replace('|', '').toLowerCase() ?? ''),
  linkProps: {
    href: data?.url?.path ?? '',
  },
})

export const breadcrumbTransformer = (data) => (data && data?.map((item) => breadcrumbItemTransformer(item))) ?? []

