import { colors, desktop, fonts, grids, tablet, biggerDesktop } from '../../theme'


export default {
  container: {
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '6rem',
    },
  },
  // title and infos
  main: {
    position: 'relative',
    maxWidth: `${grids.desktop.boxingMax / 10}rem`, // another exception
    marginLeft: 'auto',
    marginRight: 'auto',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '0.5rem',
      backgroundColor: (props) => (
        props.variant === 'care' ? colors.redCare
          : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
            : props.variant === 'listen' ? colors.orangeDarkestListen
              : props.variant === 'discover' ? colors.blueDiscover
                : colors.blue),
    },
  },
  mainWrapper: {
    display: 'flex',
    flexFlow: 'column-reverse nowrap',
    maxWidth: `calc(${(grids.desktop.boxingContent - 20) / 10}rem + 4rem)`, // another exception
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  titleZone: {
    flex: '1 1 auto',
    padding: '3rem 2rem 1rem',
  },
  title: {
    ...fonts.MaaxBold,
    position: 'relative',
    margin: '0 auto',
    padding: '3rem 0',
    lineHeight: '1.1',
    fontSize: '2.5rem',
    textTransform: 'uppercase',
    color: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
          : props.variant === 'listen' ? colors.orangeDarkestListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      height: '0.5rem',
      width: '6rem',
      backgroundColor: 'currentColor',
    },
    '&::before': {
      top: 0,
      left: 0,
    },
    '&::after': {
      bottom: 0,
      left: 0,
    },
  },
  toolsZone: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  infos: {
    display: 'none',
    color: colors.grey67,
    '& > *': {
      display: 'block',
      margin: 0,
    },
  },
  infosTiming: {
    ...fonts.MaaxBold,
  },
  // image
  figure: {
    margin: 0,
    width: '100%',
  },
  image: {},
  // summary and sharing
  summary: {
    marginTop: '2rem',
  },
  summaryWrapper: {
    display: 'flex',
    flexFlow: 'column-reverse nowrap',
    maxWidth: `calc(${(grids.desktop.boxingContent - 20) / 10}rem + 4rem)`, // another exception
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  sharingZone: {
    display: 'none',
  },
  text: {
    ...fonts.MaaxBold,
    fontSize: '1.6rem',
    '& b, & strong': {
      fontWeight: 'inherit',
    },
    '& > :not(:first-child)': {
      marginTop: '1rem',
    },
  },
  ...tablet({
    main: {
      '&::before': {
        width: '1rem',
      },
    },
    mainWrapper: {
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
    },
    titleZone: {
      padding: '6rem 3rem 2rem 3rem',
      '&:not(last-child)': {
        paddingRight: '5rem',
      },
    },
    title: {
      width: 'auto',
      fontSize: '3.5rem',
      marginLeft: 0,
      '&::before, &::after': {
        width: '11.5rem',
      },
    },
    toolsZone: {
      '&:not(:first-child)': {
        marginTop: '5rem',
      },
      '& > *:not(:first-child)': {
        display: 'none',
      },
    },
    infos: {
      display: 'block',
      '&:not(:first-child)': {
        marginTop: '3rem',
      },
    },
    figure: {
      flex: '0 0 auto',
      width: 'min(calc(100% / 12 * 6), 68rem)',
      fallbacks: {
        width: '50%',
      },
    },
    summary: {
      marginTop: '6rem',
    },
    summaryWrapper: {
      paddingLeft: '3rem', // another exception
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
    },
    sharingZone: {
      flex: '0 0 auto',
      display: 'block',
      width: 'calc(25% - 2.5rem)',
    },
    text: {
      flex: '0 0 auto',
      width: '75%',
      fontSize: '1.5rem',
      '&:first-child': {
        marginLeft: 'auto',
      },
    },
  }),
  ...desktop({
    main: {
      '&::before': {
        width: '1.5rem',
      },
    },
    titleZone: {
      paddingLeft: '5rem',
    },
    figure: {
      width: 'min(calc(100% / 12 * 6), 68rem)',
      fallbacks: {
        width: 'calc(100% / 12 * 6)',
      },
    },
    summaryWrapper: {
      paddingLeft: '5rem', // another exception
    },
  }),
  ...biggerDesktop({
    figure: {
      width: 'min(calc(100% / 12 * 7), 68rem)',
      fallbacks: {
        width: 'calc(100% / 12 * 7)',
      },
    },
  }),

  '@media print': {
    main: {
      '&::before, &::after': {
        display: 'none',
      },
    },
    toolsZone: {
      display: 'none',
    },
    figure: {
      display: 'none',
    },
    titleZone: {
      paddingBottom: 0,
    },
    title: {
      paddingTop: 0,
      paddingBottom: 0,
      '&::before, &::after': {
        display: 'none',
      },
    },
  },
}
