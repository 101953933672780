import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useKeenSlider } from 'keen-slider/react'
import padStart from 'lodash/padStart'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'
import Icon from '../Icon'
import icons from '../Icon/assets'
import LandingSlide from '../LandingSlide'

import styles from './styles'


const useStyles = createUseStyles(styles)

const LandingSlider = (props) => {
  const {
    classes: classesProp,
    className,
    slides,
    textPrevious,
    textNext,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  // to guarantee that `loop: true` won't break, we pad the slide with "fake slides" if there's not a least visibleSlides + 1
  const arrPaddedSlides = useMemo(() => {
    const numberPaddingSlides = Math.ceil((3 + 1) / slides.length)

    const tmpArrPaddedSlides = []

    for (let i = 0; i < numberPaddingSlides; i++) {
      for (let x = 0; x < slides.length; x++) {
        tmpArrPaddedSlides.push({
          originalIndex: x,
          ...slides[x],
        })
      }
    }

    return tmpArrPaddedSlides
  }, [slides])

  // slider settings
  const [sliderRef, slider] = useKeenSlider()

  const optionsSlider = useMemo(() => ({
    slidesPerView: 3,
    loop: true,
    centered: true,
    spacing: 18,
    mounted(s) {
      s.resize()
      if (sliderRef.current) {
        sliderRef.current.style.visibility = 'visible'
      }
    },
    slideChanged(s) {
    },
    breakpoints: {
      '(min-width: 750px)': {
        slidesPerView: 1,
        spacing: 0,
      },
    },
  }), [sliderRef])

  // init
  useEffect(() => {
    if (slider) {
      slider.refresh(optionsSlider)
      slider.moveToSlide(0)
    }
  }, [slider, optionsSlider])

  // slider handlers
  const handlePrev = useCallback(() => {
    if (slider) {
      slider.prev()
    }
  }, [slider])

  const handleNext = useCallback(() => {
    if (slider) {
      slider.next()
    }
  }, [slider])

  const handleResize = useCallback(() => {
    if (slider) {
      slider.resize()
    }
  }, [slider])

  // effects
  // - slider resize on change fonts size
  // - slider resize on window resize
  useEffect(() => {
    const o = ((typeof window !== 'undefined')) ? new MutationObserver(() => {
      handleResize()
    }) : null

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      if (o !== null) {
        o.observe(document.querySelector('html'), { attributes: true, attributeFilter: ['style'] })
      }
    }
    return () => {
      try {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleResize)
          if (o !== null) {
            o.disconnect()
          }
        }
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [handleResize, slider])

  // renderers
  const renderSlider = useMemo(() => (
    <div
      ref={sliderRef}
      className={cx(classes.slider, 'keen-slider')}
    >
      {arrPaddedSlides.map((slide, index) => {
        const textNumber = `${padStart((slide.originalIndex + 1).toString(), 2, '0')}/${padStart(slides.length.toString(), 2, '0')}`

        return (
          <div
            key={`navpractice-column-${index}`}
            className={cx(classes.slide, 'keen-slider__slide')}
          >
            <LandingSlide
              {...slide}
              textNumber={textNumber}
            />
          </div>
        )
      })}
    </div>

  ),
  // eslint-disable-next-line max-len
  [arrPaddedSlides, classes.slide, classes.slider, sliderRef, slides.length])

  return (
    <div
      className={cx(classes.container, className)}
    >
      <h3 className={classes.sliderTitle}>{arrPaddedSlides[0].sliderTitle}</h3>
      {renderSlider}
      {slider && (
        <>
          <button
            type="button"
            className={cx(classes.previous, classes.nav)}
            onClick={handlePrev}
          >
            <Icon
              icon={icons.ChevronThinLeft}
              className={classes.navIcon}
            />
            <i>{textPrevious}</i>
          </button>
          <button
            type="button"
            className={cx(classes.next, classes.nav)}
            onClick={handleNext}
          >
            <Icon
              icon={icons.ChevronThinRight}
              className={classes.navIcon}
            />
            <i>{textNext}</i>
          </button>
        </>
      )}
    </div>
  )
}

LandingSlider.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  id: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape(Image.propTypes),
    })
  ),
  textPrevious: PropTypes.string.isRequired,
  textNext: PropTypes.string.isRequired,
  visibleSlides: PropTypes.number,
  fromOriginalIndex: PropTypes.number,
  onProductChange: PropTypes.func,
}

LandingSlider.defaultProps = {
  className: null,
  classes: null,
  id: null,
  slides: null,
  fromOriginalIndex: null,
  visibleSlides: 3,
  onProductChange: () => null,
}

export default withMemo()(LandingSlider)
