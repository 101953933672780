import gql from 'graphql-tag'


export const ParagraphSatelliteAncreFragment = {
  fragment: gql`
    fragment ParagraphSatelliteAncreFragment on ParagraphSatelliteAncre {
        uuid
        fieldTitreAncre
        fieldImageAncre {
            alt
            title
            url
        }
        fieldDescriptionAncre {
            value
        }
        fieldFiles {
            entity {
                ... on File {
                    url
                    filename
                }
            }
            description
        }
    }
  `,
}
