import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    fontSize: '1.7rem',
    '& h2, & h4, & h5, & h6': {
      margin: 0,
      color: (props) => (
        props.variant === 'care' ? colors.redCare
          : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
            : props.variant === 'listen' ? colors.orangeDarkestListen
              : props.variant === 'discover' ? colors.blueDiscover
                : colors.blue),
    },
    '& h4': {
      fontSize: '1.8rem',
    },
    '& h5': {
      fontSize: '1.7rem',
    },
    '& h6': {
      fontSize: '1.6rem',
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'underline',
      transition: 'color 200ms ease-out',
      '&:hover': {
        color: colors.blueSteel,
      },
    },
    '& .red-link': {
      ...fonts.MaaxBold,
      textDecoration: 'none',
      color: (props) => (
        props.variant === 'care' ? colors.redCare
          : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
            : props.variant === 'listen' ? colors.orangeDarkestListen
              : props.variant === 'discover' ? colors.blueDiscover
                : colors.blue),
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    '& p, & li, & dt, & dt, & dd, & th, & td': {
      lineHeight: 1.4,
    },
    '& ul': {
      paddingLeft: '3rem',
    },
    '& ul li': {
      listStyle: 'disc outside',
    },
    '& ul > li + li': {
      marginTop: '0.3rem',
    },
    '& ol': {
      paddingLeft: '3rem',
    },
    '& ol li': {
      listStyle: 'decimal outside',
    },
    '& ol > li + li': {
      marginTop: '0.3rem',
    },
    '& blockquote': {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      padding: '1rem 2rem',
      fontSize: '1.1em',
      borderLeft: `0.5rem solid ${colors.greyEE}`,
    },
    '& table': {
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
      width: '100%',
      border: `1px solid ${colors.greyE7}`,
    },
    '& table th, & table td ': {
      border: `1px solid ${colors.greyE7}`,
      padding: '1rem',
      textAlign: 'left',
      fontSize: '1.2rem',
    },
    // vertical spacing
    '& > * + *': {
      marginTop: '1rem',
    },
    '& h4 + *': {
      marginTop: '3rem',
    },
    '& h5 + *': {
      marginTop: '2rem',
    },
    '& h6 + *': {
      marginTop: '1rem',
    },
    '& blockquote + *': {
      marginTop: '2rem',
    },
    '& table + *': {
      marginTop: '2rem',
    },
    '& h4:not(:first-child)': {
      marginTop: '3.5rem',
    },
    '& h5:not(:first-child)': {
      marginTop: '2.5rem',
    },
    '& h6:not(:first-child)': {
      marginTop: '1.5rem',
    },
    '& blockquote:not(:first-child)': {
      marginTop: '2rem',
    },
    '& table:not(:first-child)': {
      marginTop: '2rem',
    },
    ...tablet({
      fontSize: '1.5rem',
      '& table th, & table td ': {
        padding: '2rem',
        fontSize: 'inherit',
      },
    }),
  },
  '@media print': {
    container: {
      '& table': {
        breakInside: 'avoid',
        pageBreakInside: 'avoid',
      },
      '& table th, & table td ': {
        border: `1pt solid ${colors.greyE7}`,
        pageBreakInside: 'avoid',
        pageBreakAfter: 'auto',
      },
    },
  },
}
