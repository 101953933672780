/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'
import Icon from '../Icon'
import icons from '../Icon/assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const QuestionPushVideo = (props) => {
  const {
    classes: classesProp,
    className,
    image,
    videos,
    textPlay,
    tracks,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const videoRef = useRef(null)
  const [isTouched, setTouched] = useState(false)

  const handlePlay = useCallback(() => {
    setTouched(true)
    if (videoRef?.current) {
      const el = videoRef.current

      if (el.requestFullscreen) {
        el.requestFullscreen()
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen()
      } else if (el.msRequestFullScreen) {
        el.msRequestFullScreen()
      }
      el.play()
    }
  }, [])

  const renderSources = useMemo(() => videos?.map((source, index) => (
    <source
      {...source}
      key={`questionpushvideo-${index}`}
    />
  )), [videos])

  const renderTracks = useMemo(() => tracks?.map((track, index) => (
    <track
      {...track}
      key={`questionpushvideo-track-${index}`}
    />
  )), [tracks])

  return (
    <div
      className={cx(classes.container, className)}
    >
      {(videos?.length > 0) && (
        <>
          <video
            className={classes.video}
            poster={image.src ?? ''}
            ref={videoRef}
            controls={!!isTouched}
          >
            {renderSources}
            {renderTracks}
          </video>
          <button
            type="button"
            className={cx(classes.play, isTouched && 'is-touched')}
            onClick={handlePlay}
          >
            <i>{textPlay}</i>
            <span className={classes.playControl}>
              <Icon
                icon={icons.Play}
                className={classes.playIcon}
              />
            </span>
          </button>
        </>
      )}
    </div>
  )
}

QuestionPushVideo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  image: PropTypes.shape(Image.propTypes),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      srcLang: PropTypes.string,
      kind: PropTypes.oneOf(['subtitles', 'captions', 'descriptions', 'chapters', 'metadata']),
      label: PropTypes.string,
    })
  ),
  textPlay: PropTypes.string.isRequired,
}

QuestionPushVideo.defaultProps = {
  className: null,
  classes: null,
  image: null,
  videos: null,
  tracks: null,
}

export default withMemo()(QuestionPushVideo)
