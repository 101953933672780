import { colors } from '../../theme'


export default {
  container: {
    padding: '2.5rem 1.5rem 0.5rem',
    '& > *:not(:first-child)': {
      marginTop: '1rem',
    },
  },
  figure: {
    margin: 0,
    maxHeight: '14rem',
    maxWidth: '14rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    margin: 0,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '1.8rem',
    color: colors.blueQuestions,
  },
}
