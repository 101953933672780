import gql from 'graphql-tag'


export const Search = gql`
    query search($q: String!, $offset: Int!, $limit: Int!) {
        all: searchAPISearch(index_id: "apb_search_index", fulltext: { keys: [$q]}, range: { offset: $offset, limit: $limit }) {
            result_count
            documents {
                ... on ApbSearchIndexDoc {
                    relevance
                    type
                    field_image_url
                    field_image_alt
                    field_rubrique_class
                    field_rubrique_name
                    field_master_rubrique_name
                    field_url
                    field_title
                    field_body
                    field_paragraphe_contenu
                    field_description_ancre
                    field_paragraphe_contenu_resume
                }
            }
        }
        tools: searchAPISearch(index_id: "apb_search_outils_index", fulltext: { keys: [$q] }) {
            result_count
            documents {
                ... on ApbSearchOutilsIndexDoc {
                    relevance
                    title
                    field_icone_espace_url
                    field_icone_espace_alt
                    url
                    summary
                    field_zones_body
                    field_zones_title
                }
            }
        }
    }
`
