import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'
import MarkdownText from '../MarkdownText'
import Icon from '../Icon'
import icons from '../Icon/assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const QuestionMessage = (props) => {
  const {
    classes: classesProp,
    className,
    text,
    image,
    variantType,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <div className={cx(classes.container, className, `is-${variantType}`)}>
      {image && (
        <div className={classes.avatar}>
          <Image
            {...image}
            className={classes.image}
          />
        </div>
      )}
      <div className={classes.content}>
        <MarkdownText
          className={classes.text}
          text={text}
          lexicon={lexicon}
          markHover={markHover}
        />
        <Icon
          className={classes.icon}
          icon={variantType === 'question' ? icons.BubbleLeft : icons.BubbleRight}
          aria-hidden="true"
        />
      </div>
    </div>
  )
}

QuestionMessage.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
  image: PropTypes.shape(Image.propTypes),
  variantType: PropTypes.oneOf(['question', 'answer']),
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

QuestionMessage.defaultProps = {
  className: null,
  classes: null,
  text: '',
  image: null,
  variantType: 'question',
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(QuestionMessage)
