// import { cookiesTool } from 'react-gdpr'
import { all, call, put, select } from 'redux-saga/effects'

import QueryString from '../../helpers/QueryString'
import MenuSagas from '../menu/sagas'
import ApiSagas from '../api/sagas'
import I18nSagas from '../i18n/sagas'
import { parseUriToLanguageID, redirect } from '../../helpers/UrlHelpers'
import routes, { Router } from '../../routes'
import PageSagas from '../../Wrappers/Pages/Page/sagas'
import { selectors as TranslationsSelectors } from '../i18n/redux'
import LexiconSagas from '../lexicon/sagas'
import { EnPratiqueSagas } from '../../Wrappers/Templates/sagas'

import { actions } from './redux'


const queryStringConfig = { arrayFormat: 'comma' }

export default class AppSagas {

  static* init(ctx, pageSaga) {
    const { pathname, query, asPath, req } = ctx

    yield call(AppSagas.updateRoute, {
      pathname,
      query,
      asPath,
    })

    if (req) {
      yield call(AppSagas.setPreview, req.IS_PREVIEW)
    }

    const isLocaleAvailable = yield call(AppSagas.isLocaleAvailable, ctx)

    if (!isLocaleAvailable) {
      const route = Router.getRouteUrl(routes.page, { slug: '/', locale: 'fr' })

      return redirect(ctx.res, route)
    }

    // Fix Page param /p1 => ''
    const match = ctx.query.slug && ctx.query.slug.match(/\/p([0-9]+)/)

    if (!ctx.query.page && match && ctx.pathname === '/Page') {
      ctx.query.slug = ctx.query.slug.replace(/\/p([0-9]+)/, '')
      ctx.query.page = match[1]
    }

    if (ctx.query.page && (ctx.query.page === '1' || ctx.query.page === '0')) {
      // Add get params if exists
      const params = { ...ctx.query }

      Object.keys(params).forEach((key) => (key === 'slug' || key === 'page' || key === 'locale') && delete params[key])
      const qs = QueryString.stringify(params, queryStringConfig)
      const route = Router.getRouteUrl(routes.page, { slug: ctx.query.slug, locale: ctx.query.locale })

      return redirect(ctx.res, `${route}${qs ? `?${qs}` : ''}`)
    }

    yield call(AppSagas.appStart, ctx)
    const initialProps = pageSaga ? yield call(pageSaga, ctx) : {}

    if (ctx.res && ctx.res.statusCode > 400) {
      // eslint-disable-next-line no-console
      console.log('error', ctx.res.statusCode, ctx.res.statusMessage, {
        asPath: ctx.asPath,
        query: ctx.query,
        pathname: ctx.pathname,
        err: ctx.err,
      })
      ctx.res.statusCode = 404
    }

    if (ctx.res && initialProps && initialProps.error) {
      ctx.res.statusCode = initialProps.error
    }

    return initialProps || { loaded: true }
  }

  static* setPreview(preview) {
    const isPreview = (parseInt(preview, 10) === 1)

    yield put(actions.setPreview(isPreview))
  }


  static* appStart(ctx) {
    yield call(AppSagas.purgeCache)
    yield all([
      call(ApiSagas.initHeaders),
      call(AppSagas.initCommons, ctx),
      call(AppSagas.initTracking),
    ])
  }

  static* initCommons({ query }) {
    if (query?.locale) {
      const lang = parseUriToLanguageID(query.locale)

      yield all([
        call(I18nSagas.init, { lang, locale: query.locale }),
        call(MenuSagas.loadMenu, lang),
        call(LexiconSagas.loadInitialData),
        call(EnPratiqueSagas.init),
      ])
    } else {
      yield all([
        call(I18nSagas.init, { lang: 'FR', locale: 'fr' }),
      ])
    }
  }

  static initTracking() {
  // if (cookiesTool.isActive('cookiesEdited') && !cookiesTool.isActive('analytics')) {
  //   cookiesTool.setActive('analytics', false)
  // }
  }

  static* isLocaleAvailable(context) {
    const requestedLocale = context?.query?.locale

    yield call(I18nSagas.getLocales)
    const locales = yield select(TranslationsSelectors.locales)

    return !!locales[requestedLocale]
  }

  static* updateRoute(route) {
    yield put(actions.setRoute(route))
  }

  static* purgeCache() {
    if (!process.browser) {
      yield all([
        call(PageSagas.purgeCache),
        call(MenuSagas.purgeCache),
      ])
    }
  }

  static* loop() {
    yield all([
      //
    ])
  }

}
