import Color from 'color'

import { accessibleHiddenText, colors, desktop, tablet } from '../../theme'


export default {
  container: {
    position: 'fixed',
    bottom: '1rem',
    right: '1rem',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '3.5rem',
    height: '3.5rem',
    padding: '0.8rem',
    borderRadius: '50%',
    backgroundColor: Color(colors.pureBlack).alpha(0.8).toString(),
    margin: 0,
    color: colors.pureWhite,
    opacity: 0,
    transition: 'opacity 200ms ease-out',
    cursor: 'pointer',
    pointerEvents: 'none',
    '&.is-visible': {
      opacity: 1,
      pointerEvents: 'all',
    },
    '& i': {
      ...accessibleHiddenText,
    },
  },
  ...tablet({
    container: {
      bottom: '2.5rem',
      right: '2.5rem',
      width: '4.8rem',
      height: '4.8rem',
    },
  }),
  ...desktop({
    container: {
      bottom: '5rem',
      right: '5rem',
    },
  }),
  '@media print': {
    container: {
      display: 'none',
    },
  },
}
