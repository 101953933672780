import React, { useMemo, Children, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import ScrollContainer from 'react-indiana-drag-scroll'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'

import styles from './styles'


const useStyles = createUseStyles(styles)

const QuestionPush = (props) => {
  const {
    classes: classesProp,
    className,
    children,
    image,

  } = props
  // eslint-disable-next-line no-unused-vars
  const [numberChildren] = useState(Children.toArray(children).length)

  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])


  return (
    <aside className={cx(classes.container, className)}>
      <div className={classes.avatar}>
        {image && (
        <Image
          {...image}
          className={classes.image}
        />
        )}
      </div>
      <ScrollContainer className={classes.scroller}>
        <div className={cx(classes.content, `is-containing__${numberChildren}`)}>
          {children}
        </div>
      </ScrollContainer>
    </aside>
  )
}

QuestionPush.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  image: PropTypes.shape(Image.propTypes),
}

QuestionPush.defaultProps = {
  className: null,
  classes: null,
  children: null,
  image: null,
}

export default withMemo()(QuestionPush)
