import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'page'

//
// Initial state
//
const initialState = {
  slug: null,
  data: null,
  alternates: null,
  breadcrumb: [],
  loaded: false,
  isLoading: false,
  variant: 'default',
  pages: {},
}

//
// Actions
//
export const actions = {
  setBreadcrumb: createAction('Page: setBreadcrumb', (breadcrumb) => ({ breadcrumb })),
  setVariant: createAction('Page: setVariant', (variant) => ({ variant })),
  setPages: createAction('Page: setPages', (pages) => ({ pages })),
  setSlug: createAction('Page: setSlug', (slug) => ({ slug })),
  setData: createAction('Page: setData', (data) => ({ data })),
  setAlternates: createAction('Page: setAlternates', (alternates) => ({ alternates })),
  setLoaded: createAction('Page: setLoaded', (loaded) => ({ loaded })),
  setIsLoading: createAction('Page: setIsLoading', (isLoading) => ({ isLoading })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setBreadcrumb]: (state, { breadcrumb }) => Immutable.static.merge(state, { breadcrumb }),
    [actions.setVariant]: (state, { variant }) => Immutable.static.merge(state, { variant }),
    [actions.setPages]: (state, { pages }) => Immutable.static.merge(state, { pages }),
    [actions.setSlug]: (state, { slug }) => Immutable.static.merge(state, { slug }),
    [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
    [actions.setAlternates]: (state, { alternates }) => Immutable.static.merge(state, { alternates }),
    [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
    [actions.setIsLoading]: (state, { isLoading }) => Immutable.static.merge(state, { isLoading }),
  },
  initialState
)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const isLoading = (state) => root(state).isLoading
const pages = (state) => root(state).pages
const slug = (state) => root(state).slug
const data = (state) => root(state).data
const breadcrumb = (state) => root(state).breadcrumb
const variant = (state) => root(state).variant
const alternates = (state) => root(state).alternates

export const selectors = {
  isLoaded,
  isLoading,
  pages,
  breadcrumb,
  variant,
  slug,
  data,
  alternates,
}
