import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownText from '../MarkdownText'
import Image from '../Image'

import styles from './styles'


const useStyles = createUseStyles(styles)

const HeaderHomeSpecial = (props) => {
  const {
    classes: classesProp,
    className,
    title,
    TagTitle,
    text,
    image,
    zoneTools,
    zoneSharing,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <header
      className={cx(classes.container, className)}
      role="heading"
      aria-level="2"
    >
      <div className={classes.wrapper}>
        <div className={classes.main}>
          <div className={classes.content}>
            {title && (
              <TagTitle
                className={classes.title}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {text && (
              <MarkdownText
                className={classes.text}
                text={text}
                lexicon={lexicon}
                markHover={markHover}
              />
            )}
          </div>
          {(zoneTools || zoneSharing) && (
            <div className={classes.toolsZone}>
              {zoneTools}
              {zoneSharing}
            </div>
          )}
        </div>
        {image && (
          <figure className={classes.figure}>
            <Image
              {...image}
              className={classes.figure}
            />
            <div
              className={classes.overlay}
              aria-hidden="true"
            />
          </figure>
        )}
        <div
          className={classes.bubbleLeft}
          aria-hidden="true"
        >
          <Image
            src="/images/Landing/landing-header-home-bg-1.png"
            className={classes.bubbleLeftImage}
          />
        </div>
        <div
          className={classes.bubbleRight}
          aria-hidden="true"
        >
          <Image
            src="/images/Landing/landing-header-home-bg-2.png"
            className={classes.bubbleRightImage}
          />
        </div>
        <div
          className={classes.gradient}
          aria-hidden="true"
        />
      </div>
    </header>
  )
}

HeaderHomeSpecial.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  TagTitle: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.shape(Image.propTypes),
  zoneTools: PropTypes.node,
  zoneSharing: PropTypes.node,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

HeaderHomeSpecial.defaultProps = {
  className: null,
  classes: null,
  title: '',
  TagTitle: 'h1',
  text: '',
  image: null,
  zoneTools: null,
  zoneSharing: null,
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(HeaderHomeSpecial)
