import Color from 'color'

import { colors, grids, tablet } from '../../theme'


export default {
  container: {
    '&:not(:first-child)': {
      marginTop: '7rem',
    },
  },
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingQuestion / 10}rem + 4rem)`, // another exception
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  titleZone: {
    position: 'relative',
    paddingTop: '1.5rem',
  },
  title: {
    margin: 0,
    paddingTop: '2rem',
    paddingBottom: '1.5rem',
    borderTop: `2px solid ${Color(colors.blueQuestions).alpha(0.1).toString()}`,
    borderBottom: `2px solid ${Color(colors.blueQuestions).alpha(0.1).toString()}`,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  date: {
    display: 'block',
    textTransform: 'uppercase',
    color: colors.turquoiseDark,
    '& > *': {
      margin: 0,
    },
  },
  author: {
    display: 'block',
    color: colors.blue,
    '& > *': {
      margin: 0,
    },
  },
  icon: {
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '5rem',
    height: '2.5rem',
    transform: 'translateX(-50%)',
    borderRight: `1rem solid ${colors.pureWhite}`,
    borderLeft: `1rem solid ${colors.pureWhite}`,
  },
  ...tablet({
    title: {
      paddingTop: '1.5rem',
    },
    date: {
      display: 'inline',
    },
    author: {
      position: 'relative',
      display: 'inline',
      marginLeft: '1rem',
      paddingLeft: '1.2rem',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: '50%',
        height: '2rem',
        width: '1px',
        background: colors.greyE7,
        transform: 'translateY(-50%)',
      },
    },
  }),
}
