import { all, fork } from 'redux-saga/effects'


const entities = [
  // Pages
  require('../Wrappers/Pages/Page/sagas').default,
  // Templates
  require('../Wrappers/Templates/PageWrapper/sagas').default,
  require('../Wrappers/Templates/RechercheWrapper/sagas').default,
  require('../Wrappers/Templates/QuestionsDuMomentWrapper/sagas').default,
  require('../Wrappers/Templates/ShareVideoWrapper/sagas').default,

  // Global
  require('./i18n/sagas').default,
  require('./menu/sagas').default,
  require('./api/sagas').default,
  require('./app/sagas').default,
  require('./lexicon/sagas').default,
]


function* loop() {
  yield all(entities.map((entity) => entity.loop()))
}

export default function* rootSaga() {
  yield fork(loop)
}
