import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
// import uniq from 'lodash/uniq'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import NavLexicon from '../NavLexicon'
import LexiconLetter from '../LexiconLetter'
import LexiconEntry from '../LexiconEntry'
import { safeCall } from '../../helpers/React'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockLexicon = (props) => {
  const {
    classes: classesProp,
    className,
    terms,
    currentLetter,
    onNavClickHandler,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  // state, refs and starting data
  // - terms ordered to build the sections
  const termsOrdered = useMemo(() => [...terms.map((term) => ({
      ...term,
      normalizedTerm: term.term.normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .replace('Œ', 'OE').replace('œ', 'oe'),
    }))]
    .sort((a, b) => ((a.normalizedTerm.toUpperCase() < b.normalizedTerm.toUpperCase())
      ? -1
      : (a.normalizedTerm.toUpperCase() > b.normalizedTerm.toUpperCase()) ? 1 : 0)), [terms])
  // - sections built from the whole ordered terms
  const sections = useMemo(() => Object.values(termsOrdered?.reduce((acc, term) => {
    const firstLetter = term?.normalizedTerm?.charAt(0).toUpperCase() ?? null

    if (firstLetter && !acc[firstLetter]) {
      acc[firstLetter] = {
        text: firstLetter,
        isCurrent: firstLetter === currentLetter,
        terms: [term],
      }
    } else {
      acc[firstLetter].terms.push(term)
    }

    return acc
  }, {})), [currentLetter, termsOrdered])
  // - alphabet and navigation
  const nav = useMemo(() => Array.from(Array(26))
    .map((_e, i) => i + 65)
    .map((letter) => String.fromCharCode(letter))
    .map((letter) => ({
      text: letter,
      isCurrent: letter === currentLetter,
      isLink: !!sections.find((x) => x.text === letter),
    })), [currentLetter, sections])

  // handlers and effects
  // - handle click on nav
  const handleClick = useCallback((index, letter) => {
    safeCall(onNavClickHandler, letter)
  }, [onNavClickHandler])

  // renders
  // - nav
  const renderNavigation = useMemo(() => (
    <NavLexicon
      className={classes.navigation}
      letters={nav}
      clickHandler={handleClick}
    />
  ), [classes.navigation, nav, handleClick])
  // - sections
  const renderSections = useMemo(() => (
    <div className={classes.content}>
      {sections.map((section, index) => (
        <LexiconLetter
          key={index}
          letter={section.text}
          terms={section.terms}
        />
        ))}
    </div>
  ), [classes.content, sections])

  return terms ? (
    <section className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {renderNavigation}
        {renderSections}
      </div>
    </section>
  ) : null
}

BlockLexicon.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  terms: PropTypes.arrayOf(PropTypes.shape(LexiconEntry.propTypes)).isRequired,
  currentLetter: PropTypes.string,
  onNavClickHandler: PropTypes.func,
}

BlockLexicon.defaultProps = {
  className: null,
  classes: null,
  currentLetter: 'A',
  onNavClickHandler: (letter) => null,
}

export default withMemo()(BlockLexicon)
