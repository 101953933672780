import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Head from 'next/head'

import withMemo from '../../decorators/withMemo'
import routes, { Router } from '../../routes'
import BlockError from '../../components/BlockError'
import NavMain from '../../components/NavMain'
import NavSecondary from '../../components/NavSecondary'
import HeaderSearch from '../../components/HeaderSearch'
import { selectors as MenuSelectors } from '../../redux/menu/redux'
import { selectors as PageSelectors } from '../../Wrappers/Pages/Page/redux'
import { selectors as AppSelectors } from '../../redux/app/redux'
import { selectors as TranslationSelector } from '../../redux/i18n/redux'
import { selectors as SearchSelectors } from '../../Wrappers/Templates/RechercheWrapper/redux'
import PageContainer from '../../containers/PageContainer'


const Error = () => {
  const { t } = useTranslation()
  const menu = useSelector(MenuSelectors.menu)
  const terms = useSelector(SearchSelectors.terms)
  const locale = useSelector(TranslationSelector.locale)
  const breadcrumb = useSelector(PageSelectors.breadcrumb)
  const headerRight = useSelector(MenuSelectors.headerRight)
  const footer = useSelector(MenuSelectors.footer)
  const footerTop = useSelector(MenuSelectors.footerTop)
  const isCurrentSearch = useSelector(AppSelectors.isCurrentSearch)
  const search = useSelector(MenuSelectors.search)
  const searchIsOpen = useSelector(MenuSelectors.searchIsOpen)

  const menuProps = useMemo(() => (menu?.map((link) => ({
    ...link,
    isActive: !!(breadcrumb && breadcrumb.find((item) => (item?.slug.indexOf(link.slug) >= 0))),
  })) ?? []), [breadcrumb, menu])

  const navSecondaryProps = useMemo(() => (headerRight?.map((link) => ({
    ...link,
    isActive: false,
  })) ?? []), [headerRight])

  const searchProps = useMemo(() => ({
    links: search,
    textSearch: t('textSearch'),
    textSubmit: t('textSubmit'),
    textSuggestionsTitle: t('textSuggestionsTitle'),
    textSearchPlaceholder: t('textSearchPlaceholder'),
    isOpen: searchIsOpen,
    textInitialValue: terms ?? '',
    isCurrent: isCurrentSearch,
    onSearch: (values) => Router.pushRoute(Router.getRouteUrl(routes.page, { slug: 'recherche', locale, search_api_fulltext: values })),
  }), [search, t, searchIsOpen, terms, locale, isCurrentSearch])

  const headerProps = useMemo(() => ({
    urlHome: `/${locale}`,
    logoMain: {
      src: '/images/Common/logo-1000-jours.png',
      alt: t('logoMain'),
    },
    logoSPF: {
      src: '/images/Common/logo-spf.png',
      alt: t('logoSPF'),
    },
    logoRF: {
      src: '/images/Common/logo-republique-francaise.png',
      alt: t('logoRF'),
    },
    urlSPF: 'https://www.santepubliquefrance.fr/',
    urlRF: 'https://solidarites-sante.gouv.fr/',
    textOpenMenu: t('textOpenMenu'),
    navMain: <NavMain links={menuProps} />,
    navSecondary: <NavSecondary links={navSecondaryProps} />,
    search: <HeaderSearch {...searchProps} />,
  }), [locale, t, menuProps, navSecondaryProps, searchProps])

  const footerMenuProps = useMemo(() => ({
    urlHome: `/${locale}`,
    logo: {
      src: '/images/Common/logo-1000-jours.png',
      alt: t('logoMain'),
      width: 72,
      height: 65,
    },
    textOpenMenu: t('textOpenMenu'),
    links: footerTop,
  }), [footerTop, t, locale])

  const navMobileProps = useMemo(() => ({
    linksMain: menuProps.map((item) => item.sub),
    linksSecondary: headerRight,
    textClose: t('textClose'),
    textBack: t('textBack'),
  }), [menuProps, headerRight, t])

  const blockErrorProps = useMemo(() => ({
    text: t('404PageSubTitle'),
  }), [t])

  return (
    <>
      <Head>
        <title>{t('404PageTitle')}</title>
      </Head>
      <PageContainer
        headerProps={headerProps}
        navMobileProps={navMobileProps}
        breadcrumbProps={{ links: breadcrumb }}
        footerMenuProps={footerMenuProps}
        footerUnderProps={{ links: footer }}
      >
        <BlockError {...blockErrorProps} />
      </PageContainer>
    </>
  )
}

Error.propTypes = {}

Error.defaultProps = {}

export default withMemo()(Error)
