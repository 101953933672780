import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import slugify from 'slugify'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownText from '../MarkdownText'
import Icon from '../Icon'
import icons from '../Icon/assets'
import { safeCall } from '../../helpers/React'

import styles from './styles'


const useStyles = createUseStyles(styles)

const LexiconEntry = (props) => {
  const {
    classes: classesProp,
    className,
    term,
    id,
    text,
    isOpen,
    lexicon,
    markHover,
    onToggleHandler,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const handleToggle = useCallback(() => {
    safeCall(onToggleHandler)
  }, [onToggleHandler])

  return (
    <div
      id={`lexiconentry-${id}`}
      className={cx(classes.container, className, isOpen && 'is-open')}
    >
      <dt className={classes.term}>
        <button
          type="button"
          aria-expanded={isOpen}
          id={`lexiconterm-${slugify(id)}`}
          aria-controls={`lexicondefinition-${slugify(id)}`}
          className={classes.button}
          onClick={handleToggle}
        >
          {term}
          <Icon
            icon={icons.ChevronSquareDown}
            className={classes.icon}
          />
        </button>
      </dt>
      <dd
        className={classes.definition}
        aria-labelledby={`lexiconterm-${slugify(id)}`}
        id={`lexicondefinition-${slugify(id)}`}
      >
        <MarkdownText
          className={classes.text}
          text={text}
          lexicon={lexicon}
          markHover={markHover}
        />
      </dd>
    </div>
  )
}

LexiconEntry.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  term: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
  onToggleHandler: PropTypes.func,
}

LexiconEntry.defaultProps = {
  className: null,
  classes: null,
  isOpen: false,
  lexicon: null,
  markHover: () => null,
  onToggleHandler: () => null,
}

export default withMemo()(LexiconEntry)
