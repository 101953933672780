/* eslint-disable max-lines */
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import ApiSagas from '../../../redux/api/sagas'
import Service from '../../../graphql/query/Search'
import { actions as AppActions, selectors as AppSelectors } from '../../../redux/app/redux'
import { actions as MenuActions } from '../../../redux/menu/redux'

import { actions as SearchActions, selectors as SearchSelectors } from './redux'


export default class RechercheSagas {

  static* init() {
    yield all([
      put(showLoading()),
      put(AppActions.setIsCurrentSearch(true)),
      put(MenuActions.setSearchIsOpen(false)),
    ])

    const route = yield select(AppSelectors.route)
    const page = yield select(SearchSelectors.page)
    const perPage = yield select(SearchSelectors.perPage)

    if (route && route.query && route.query.search_api_fulltext) {
      const search = yield call(ApiSagas.persistQuery, Service.search, {
        q: route?.query?.search_api_fulltext ?? '',
        offset: (page - 1) * perPage,
        limit: perPage,
      })

      yield all([
        put(SearchActions.setTools(search?.data?.tools ?? null)),
        put(SearchActions.setAll(search?.data?.all ?? null)),
        put(SearchActions.setTerms(route?.query?.search_api_fulltext ?? null)),
        put(SearchActions.setPage(1)),
      ])
    } else {
      yield all([
        put(SearchActions.setTools(null)),
        put(SearchActions.setTools(null)),
        put(SearchActions.setTerms(null)),
        put(SearchActions.setPage(1)),
      ])
    }
    yield put(hideLoading())
  }

  static* search({ payload }) {
    yield all([
      put(showLoading()),
      put(MenuActions.setSearchIsOpen(false)),
    ])

    const allItems = { ...yield select(SearchSelectors.all) }
    const perPage = yield select(SearchSelectors.perPage)

    const page = payload?.page ?? 1

    const search = yield call(ApiSagas.persistQuery, Service.search, {
      q: payload.q,
      offset: (page - 1) * perPage,
      limit: perPage,
    })

    if (allItems && allItems.documents) {
      allItems.documents = allItems?.documents?.concat((search?.data?.all?.documents ?? []))
    }

    yield all([
      put(SearchActions.setAll(allItems ?? null)),
      put(SearchActions.setTerms(payload?.q ?? null)),
      put(SearchActions.setPage(payload?.page ?? null)),
    ])

    yield put(hideLoading())
  }

  static* loop() {
    yield all([takeLatest(SearchActions.search.getType(), RechercheSagas.search)])
  }

}
