import { colors, grids, mobile, tablet } from '../../theme'


export default {
  container: {
    marginTop: '4rem',
    marginBottom: '4rem',
  },
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  navigation: {
  },
  category: {
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  categoryTitle: {
    position: 'relative',
    width: '80%',
    maxWidth: 'max-content',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    margin: '0 auto',
    fontSize: '3rem',
    textTransform: 'uppercase',
    color: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
          : props.variant === 'listen' ? colors.orangeDarkestListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
    // '&::before, &::after': {
    //   content: '""',
    //   position: 'absolute',
    //   height: '0.5rem',
    //   width: '11.5rem',
    //   backgroundColor: 'currentColor',
    // },
    '&::before': {
      top: 0,
      left: 0,
    },
    '&::after': {
      bottom: 0,
      right: 0,
    },
  },
  list: {
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
  },
  item: {
    marginTop: '2rem',
  },
  link: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '100%',
    color: (props) => (
      props.variant === 'care' ? colors.yellowCare
        : props.variant === 'environment' ? colors.blueDarkEnvironment
          : props.variant === 'listen' ? colors.blueDarkListen
            : props.variant === 'discover' ? colors.yellowDiscover
              : colors.pureWhite),
    backgroundColor: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseEnvironment
          : props.variant === 'listen' ? colors.orangeListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
      zIndex: 5,
      border: '1px solid',
      borderColor: (props) => (
        props.variant === 'care' ? colors.redDarkestCare
          : props.variant === 'environment' ? colors.turquoiseDarkEnvironment
            : props.variant === 'listen' ? colors.orangeDarkListen
              : props.variant === 'discover' ? colors.blueDarkDiscover
                : colors.blue),
      opacity: 0,
      transition: 'opacity 200ms ease-out',
    },
    '&:hover::after': {
      opacity: 1,
    },
  },
  figure: {
    flex: '0 0 auto',
    width: 'calc(100% / 12 * 4)',
    margin: 0,
  },
  content: {
    padding: '1rem 1.5rem',
    alignSelf: 'center',
  },
  text: {
    display: 'none',
    lineHeight: 1.5,
    color: (props) => (
      props.variant === 'care' ? colors.pureWhite
        : props.variant === 'environment' ? colors.pureBlack
          : props.variant === 'listen' ? colors.pureBlack
            : props.variant === 'discover' ? colors.pureWhite
              : colors.pureWhite),
    '$link:hover $content:first-child &': {
      textDecoration: 'underline',
    },
  },
  title: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: '1.5rem',
    '$link:hover $content:first-child &': {
      textDecoration: 'underline',
    },
  },
  ...mobile({
    list: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'stretch',
      marginLeft: '-1rem',
      marginRight: '-1rem',
      '&:not(:first-child)': {
        marginTop: '4rem',
      },
    },
    item: {
      flex: '0 0 auto',
      width: 'calc(100% / 3 - 2rem)',
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    link: {
      flexFlow: 'column',
    },
    content: {
      alignSelf: 'unset',
    },
    figure: {
      width: '100%',
    },
    title: {
      fontSize: '1.4rem',
    },
    text: {
      display: 'block',
      fontSize: '1.4rem',
      '&:not(:first-child)': {
        marginTop: '1rem',
      },
    },
  }),
  ...tablet({
    container: {
      marginTop: '5rem',
      marginBottom: '5rem',
    },
    wrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
    navigation: {
      flex: '0 0 auto',
      width: 'calc(100% / 12 * 3)',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
    category: {
      flex: '0 0 auto',
      width: 'calc(100% / 12 * 9)',
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem',
      '&:not(:first-child)': {
        margin: 0,
      },
    },
    categoryTitle: {
      minWidth: 0,
      maxWidth: 'max-content',
      margin: 0,
    },
    title: {
      fontSize: '1.6rem',
    },
    text: {
      fontSize: '1.3rem',
    },
  }),
  '@media print': {
    navigation: {
      display: 'none',
    },
    categoryTitle: {
      width: 'auto',
      maxWidth: 'none',
      paddingTop: 0,
      '&::after, &::before': {
        display: 'none',
      },
    },
  },
}
