import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import HomeTopicCard from '../HomeTopicCard'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockHomeTopic = (props) => {
  const {
    classes: classesProp,
    className,
    topics,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return topics && (
    <section className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {topics?.map((topic, index) => (
          <HomeTopicCard
            key={`blockhometopic-${index}`}
            {...topic}
          />
        ))}
      </div>
    </section>
  )
}

BlockHomeTopic.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  topics: PropTypes.arrayOf(
    PropTypes.shape(HomeTopicCard.propTypes)
  ),
}

BlockHomeTopic.defaultProps = {
  className: null,
  classes: null,
  topics: null,
}

export default withMemo()(BlockHomeTopic)
