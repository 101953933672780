import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import Icon from '../Icon'
import icons from '../Icon/assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavPaginate = (props) => {
  const {
    classes: classesProp,
    className,
    next,
    previous,
    // eslint-disable-next-line no-unused-vars
    variant,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <nav className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {previous && (
          <A
            className={cx(classes.link, classes.prevLink)}
            {...previous.linkProps}
          >
            <span>{previous.text}</span>
            <Icon
              className={classes.icon}
              icon={icons.ChevronSquareLeft}
              aria-hidden="true"
            />
          </A>
        )}
        {next && (
          <A
            className={cx(classes.link, classes.nextLink)}
            {...next.linkProps}
          >
            <span>{next.text}</span>
            <Icon
              className={classes.icon}
              icon={icons.ChevronSquareRight}
              aria-hidden="true"
            />
          </A>
        )}
      </div>
    </nav>
  )
}

NavPaginate.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  next: PropTypes.shape({
    linkProps: PropTypes.shape(A.propTypes),
    text: PropTypes.string,
  }),
  previous: PropTypes.shape({
    linkProps: PropTypes.shape(A.propTypes),
    text: PropTypes.string,
  }),
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
}

NavPaginate.defaultProps = {
  className: null,
  classes: null,
  next: null,
  previous: null,
}

export default withMemo()(NavPaginate)
