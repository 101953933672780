import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import NavPractice from '../NavPractice'
import MagicTitle from '../MagicTitle'
import MarkdownStyled from '../MarkdownStyled'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockHomePractice = (props) => {
  const {
    classes: classesProp,
    className,
    title,
    text,
    navigationProps,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <section className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.titleZone}>
            <MagicTitle
              text={title}
              TagTitle="h2"
              className={classes.title}
            />
          </div>
          <MarkdownStyled
            text={text}
            className={classes.text}
            lexicon={lexicon}
            markHover={markHover}
          />
        </div>
        {navigationProps && (
          <NavPractice
            {...navigationProps}
            className={classes.navigation}
          />
        )}
      </div>
    </section>
  )
}

BlockHomePractice.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  text: PropTypes.string,
  navigationProps: PropTypes.shape(NavPractice.propTypes),
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

BlockHomePractice.defaultProps = {
  className: null,
  classes: null,
  title: '',
  text: '',
  navigationProps: null,
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(BlockHomePractice)
