import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavSecondaryLink = (props) => {
  const {
    classes: classesProp,
    className,
    linkProps,
    text,
    isActive,
    isOpen,
    // eslint-disable-next-line no-unused-vars
    variant,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <A
      {...linkProps}
      className={cx(className,
        classes.container,
        isActive === true && 'is-active',
        isOpen === true && 'is-open')}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}

NavSecondaryLink.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  linkProps: PropTypes.shape(A.propTypes),
  text: PropTypes.string,
  isActive: PropTypes.bool,
  isOpen: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'questions']),
}

NavSecondaryLink.defaultProps = {
  className: null,
  classes: null,
  linkProps: null,
  text: null,
  isActive: false,
  isOpen: false,
  variant: 'default',
}

export default withMemo()(NavSecondaryLink)
