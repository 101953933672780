/* eslint-disable max-lines */
import { all, call, put, select } from 'redux-saga/effects'

import ApiSagas from '../../../redux/api/sagas'
import Service from '../../../graphql/query/Node/index'
import { selectors as PageSelectors } from '../../Pages/Page/redux'
import { OutilSagas } from '../sagas'

import { actions as ArticleActions } from './redux'
import { navPaginateTransformer } from './transformers'


export default class ArticleSagas {

  static* init({ languageId, data }) {
    const variant = yield select(PageSelectors.variant)

    const navPaginateProps = yield call(ApiSagas.persistQuery, Service.prevNext, {
      languageId,
      types: ['article'],
      nid: data?.nid,
      rubrique: [data?.fieldArticleSousRubrique?.targetId ?? 0],
      order: data.fieldArticleOrdre,
    }, navPaginateTransformer)

    navPaginateProps.variant = variant

    yield all([
      call(OutilSagas.loadSmallTools, languageId),
      put(ArticleActions.setNextPrev(navPaginateProps ?? null)),
    ])
  }


  static* loop() {
    yield all([
    ])
  }

}
