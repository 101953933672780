import { mobile } from '../../theme'


export default {
  container: {},
  wrapper: {
    maxWidth: '150rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  ...mobile({
    wrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'stretch',
      '& > *': {
        flex: '0 0 auto',
        width: '25%',
      },
    },
  }),
}
