import { colors, grids } from '../../theme'


export default {
  container: {
    position: 'relative',
    display: 'block',
    width: '24.4rem',
    height: '20rem',
    borderRadius: '1rem',
    overflow: 'hidden',
    backgroundColor: colors.greyF6,
    color: colors.pureWhite,
  },
  [`@media (min-width: ${grids.desktop.boxingQuestion}px)`]: {
    container: {
      '.is-containing__1 > &': {
        width: '50.9rem',
        height: '32.5rem',
      },
    },
  },
}
