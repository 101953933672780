import { colors, fonts, mobile } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    backgroundColor: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseEnvironment
          : props.variant === 'listen' ? colors.orangeListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
    ...mobile({
      flexFlow: 'column nowrap',
    }),
  },
  figure: {
    flex: '0 0 auto',
    width: 'calc(100% / 12 * 4)',
    margin: 0,
    ...mobile({
      width: '100%',
    }),
  },
  image: {
    '& img': {
      objectFit: 'cover',
    },
  },
  text: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    padding: '2.5rem',
    ...mobile({
      '$container.is-text &': {
        justifyContent: 'flex-start',
        flex: 1,
      },
    }),
  },
  title: {
    ...fonts.MaaxMedium,
    fontSize: '1.7rem',
    color: (props) => (
      props.variant === 'care' ? colors.yellowCare
        : props.variant === 'environment' ? colors.blueDarkEnvironment
          : props.variant === 'listen' ? colors.blueDarkListen
            : props.variant === 'discover' ? colors.yellowDiscover
              : colors.pureWhite),
    ...mobile({
      fontSize: '1.6rem',
      lineHeight: 1.4,
    }),
  },
  summary: {
    display: 'none',
    color: (props) => (
      props.variant === 'care' ? colors.pureWhite
        : props.variant === 'environment' ? colors.pureBlack
          : props.variant === 'listen' ? colors.pureBlack
            : props.variant === 'discover' ? colors.pureWhite
              : colors.pureWhite),
    ...mobile({
      display: 'block',
      fontSize: '1.4rem',
      lineHeight: 1.4,
      '&:not(first-child)': {
        marginTop: '2rem',
      },
    }),
  },
}
