export const linkTypeEnum = {
    internal: 'Interne',
    external: 'Externe',
}

export const tcEventEnum = {
    /* Global actions */
    LINK_CLICK: Symbol('LINK_CLICK'),
    ACTION_SHARE_ARTICLE: Symbol('ACTION_SHARE_ARTICLE'),
    ACTION_FONT_RESIZE: Symbol('ACTION_FONT_RESIZE'),
    ACTION_SEARCH: Symbol('ACTION_SEARCH'),
    ACTION_VIDEO_PLAY: Symbol('ACTION_VIDEO_PLAY'),
    ACTION_VIDEO_PAUSE: Symbol('ACTION_VIDEO_PAUSE'),
    ACTION_VIDEO_SPEED_CHANGE: Symbol('ACTION_VIDEO_SPEED_CHANGE'),
    ACTION_VIDEO_SHARE: Symbol('ACTION_VIDEO_SHARE'),

    /* Questions du moment actions */
    ACTION_CLICK_CARD_QUESTION_DU_MOMENT: Symbol('ACTION_CLICK_CARD_QUESTION_DU_MOMENT'),

    /* En pratique actions */
    ACTION_CLICK_LOCATION_RUBRIQUE_EN_PRATIQUE: Symbol('ACTION_CLICK_LOCATION_RUBRIQUE_EN_PRATIQUE'),
    ACTION_CLICK_OBJECT_RUBRIQUE_EN_PRATIQUE: Symbol('ACTION_CLICK_OBJECT_RUBRIQUE_EN_PRATIQUE'),
    ACTION_CLICK_CATEGORY_RUBRIQUE_EN_PRATIQUE: Symbol('ACTION_CLICK_CATEGORY_RUBRIQUE_EN_PRATIQUE'),
    ACTION_CLICK_SECTION_RUBRIQUE_EN_PRATIQUE: Symbol('ACTION_CLICK_SECTION_RUBRIQUE_EN_PRATIQUE'),

    /* Search */
    ACTION_CLICK_ON_SEARCH_RESULT_ITEM: Symbol('ACTION_CLICK_ON_SEARCH_RESULT_ITEM'),
}

export const CategoryTypenamesEnum = {
    ARTICLE: 'NodeArticle',
}

export const CategoryTitlesEnum = {
    ARTICLE: 'Articles',
}

export const SectionTitleEnum = {
    QUESTION_DU_MOMENT: 'Questions du moment',
    EN_PRATIQUE: 'En pratique',
}

