import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import withMemo from '../../../decorators/withMemo'
import BlockFaq from '../../../components/BlockFaq'
import { faqTransformer } from '../../Templates/FaqWrapper/transformers'


const Faq = (props) => {
  const { blockIndex, renderMenu } = props
  const block = useMemo(() => faqTransformer(props), [props])

  return (
    <BlockFaq
      key={`faq-blockfaq-${block.id}`}
      {...block}
      zoneMenu={blockIndex === 0 ? renderMenu : null}
    />
  )
}

Faq.propTypes = {
  blockIndex: PropTypes.number,
  renderMenu: PropTypes.node,
}

Faq.defaultProps = {
  blockIndex: 0,
  renderMenu: null,
}

export default withMemo()(Faq)
