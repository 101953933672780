import { colors, fonts, grids, tablet } from '../../theme'


export default {
  container: {
    marginTop: '3rem',
    // penultimate child has a margin bottom because last is lexicon container
    '&:nth-last-child(2)': {
      marginBottom: '6rem',
    },
  },
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  titleZone: {},
  toolsZone: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '3rem',
    marginLeft: '-1rem',
    marginRight: '-1rem',
    '& > *': {
      margin: 0,
      marginLeft: '1rem',
      marginRight: '1rem',
    },
  },
  title: {
    ...fonts.MaaxBold,
    position: 'relative',
    margin: '0 auto',
    minWidth: 'min-content',
    maxWidth: '20rem',
    padding: '2.5rem 0',
    lineHeight: '1.1',
    fontSize: '2.8rem',
    textTransform: 'uppercase',
    color: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
          : props.variant === 'listen' ? colors.orangeListen
            : props.variant === 'discover' ? colors.blueDiscover
              : colors.blue),
    '&.is-small': {
      maxWidth: 'min-content',
    },
    '&.is_special': {
      maxWidth: '17.5rem',
      ...tablet({
        maxWidth: 'none',
      }),
    },
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      height: '0.5rem',
      width: '6rem',
      backgroundColor: 'currentColor',
    },
    '&::before': {
      top: 0,
      left: 0,
    },
    '&::after': {
      bottom: 0,
      right: 0,
    },
  },
  text: {
    ...fonts.MaaxBold,
    fontSize: '2rem',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
    '& > :not(:first-child)': {
      marginTop: '1rem',
    },
  },
  ...tablet({
    container: {
      marginTop: '5rem',
    },
    wrapper: {
      display: 'flex',
      flexFlow: 'row-reverse nowrap',
      justifyContent: 'space-between',
    },
    titleZone: {
      flex: '0 0 auto',
      width: '75%',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-start',
    },
    toolsZone: {
      display: 'block',
      marginTop: '0',
      marginLeft: '0',
      marginRight: '0',
      width: 'calc(25% - 2.5rem)',
      '& > *': {
        marginLeft: 0,
        marginRight: 0,
      },
      '& > *:not(:first-child)': {
        marginTop: '2rem',
      },
    },
    title: {
      width: 'auto',
      maxWidth: 'none',
      minWidth: 0,
      fontSize: '3.5rem',
      marginLeft: 0,
      '&.is-small': {
        maxWidth: 'none',
      },
      '&::before, &::after': {
        width: 'min(11.5rem, 55%)',
        fallbacks: {
          width: '11.5rem',
        },
      },
    },
    text: {
      fontSize: '1.5rem',
    },
  }),
  '@media print': {
    toolsZone: {
      display: 'none',
    },
    title: {
      width: 'auto',
      maxWidth: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      '&::after, &::before': {
        display: 'none',
      },
    },
  },
}
