import { accessibleHiddenText, colors, desktop, fonts, grids } from '../../theme'


export default {
  // this animation exists only to prevent the current state to be visible before the route/hash change
  '@keyframes isCurrent': {
    '0%': {
      color: colors.pureBlack,
      fontWeight: 'normal',
    },
    '100%': {
      color: colors.blue,
      fontWeight: 'bold',
    },
  },
  container: {
    paddingTop: '3rem',
    paddingBottom: '2.5rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    background: colors.greyF6,
    ...desktop({
      paddingBottom: '4.5rem',
    }),
  },
  wrapper: {
    maxWidth: `${grids.desktop.boxingContent / 10}rem`,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  logo: {
    display: 'flex',
    height: '5rem',
    width: 'auto',
    '& img': {
      width: 'auto',
    },
  },
  list: {
    display: 'flex',
    flexFlow: 'row wrap',
    '&:not(:first-child)': {
      marginTop: '3rem',
    },
    ...desktop({
      '&:not(:first-child)': {
        marginTop: '1rem',
      },
    }),
  },
  levelOne: {
    width: '100%',
    borderBottom: `1px solid ${colors.greyDD}`,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '&.is-small + &:not(.is-small), &:first-child:not(.is-small)': {
      borderTop: `1px solid ${colors.greyDD}`,
    },
    '&.is-small': {
      width: '100%',
      borderBottom: '0 none',
      paddingBottom: '0',
    },
    ...desktop({
      flex: '0 0 auto',
      borderBottom: '0 none',
      '&.is-small + &:not(.is-small), &:first-child:not(.is-small)': {
        borderTop: '0 none',
      },
      '&, &.is-small': {
        width: 'calc(100% / 4)',
        flex: '0 0 auto',
      },
      '&.is-large': {
        width: 'calc(100% / 2)',
        flex: '0 0 auto',
      },
    }),
  },
  levelOneTitle: {
    display: 'flex',
    lineHeight: 1.1,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    ...desktop({
      paddingRight: '4rem',
    }),
  },
  levelOneLink: {
    ...fonts.MaaxBold,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    color: colors.pureBlack,
    fontSize: '1.3rem',
    textTransform: 'uppercase',
    transition: 'color 200ms ease-out',
    '& br ': {
      display: 'none',
    },
    '&:hover': {
      color: colors.blue,
    },
    '&.is-current': {
      animation: '$isCurrent 200ms 1 forwards ease-out 2000ms',
    },
    ...desktop({
      '& br ': {
        display: 'block',
      },
    }),
  },
  levelOneOpen: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    width: '2.1rem',
    marginLeft: '1rem',
    paddingLeft: '1rem',
    cursor: 'pointer',
    '& > i': {
      ...accessibleHiddenText,
    },
    ...desktop({
      display: 'none',
    }),
  },
  levelOneOpenIcon: {
    transition: 'transform 100ms',
    '$levelOne.is-open &': {
      transform: 'rotate(180deg)',
    },
  },
  levelTwo: {
    position: 'absolute',
    opacity: 0,
    pointerEvents: 'none',
    paddingBottom: '1rem',
    transition: 'opacity 0ms ease-out',
    '$levelOne.is-open &': {
      position: 'static',
      opacity: 1,
      pointerEvents: 'all',
      transition: 'opacity 200ms ease-out',
    },
    ...desktop({
      position: 'static',
      opacity: 1,
      pointerEvents: 'all',
      '$levelOne.is-large &': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(4, auto)',
        gridAutoFlow: 'column',
      },
    }),
  },
  '@media print': {
    list: {
      display: 'none',
    },
  },
}
