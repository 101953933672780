

export const paginateTransformer = (item) => ({
  linkProps: {
    href: item?.entityUrl?.path ?? '',
  },
  text: item?.title ?? '',
})



export const navPaginateTransformer = (item) => {
  const debouncePrev = item?.previous?.entities.find((e) => e !== null) ?? null
  const debounceNext = item?.next?.entities.find((e) => e !== null) ?? null

  const debounceFirst = item?.first?.entities.find((e) => e !== null && e.title !== debounceNext?.title) ?? null
  const debounceLast = item?.last?.entities.find((e) => (e !== null && e.title !== debouncePrev?.title && e.title !== debounceFirst?.title)) ?? null

  return {
    previous: debouncePrev ? paginateTransformer(debouncePrev) : (debounceFirst ? paginateTransformer(debounceFirst) : null),
    next: debounceNext ? paginateTransformer(debounceNext) : (debounceLast ? paginateTransformer(debounceLast) : null),
  }
}
