import { app as AppConfig } from '../configuration'


export const generateAkamaiUrl = (url) => {
  if (AppConfig.APP_URL_ASSETS && AppConfig.APP_URL_ASSETS_REPLACE) {
    const regexHttp = new RegExp(`http:${AppConfig.APP_URL_ASSETS}`, 'g')
    const regexHttps = new RegExp(`https:${AppConfig.APP_URL_ASSETS}`, 'g')

    if (regexHttps.test(url)) {
      return `${url.replace(regexHttps, AppConfig.APP_URL_ASSETS_REPLACE)}?`
    }
    if (regexHttp.test(url)) {
      return `${url.replace(regexHttp, AppConfig.APP_URL_ASSETS_REPLACE)}?`
    }
  }

  return url
}
