import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import debounce from 'lodash/debounce'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import icons from '../Icon/assets'
import Icon from '../Icon'
import A from '../A'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BackToTop = (props) => {
  const {
    classes: classesProp,
    className,
    text,
    isVisible,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const [isStateVisible, setStateVisible] = useState(isVisible)
  const [viewportHeight, setViewportHeight] = useState(500)

  const handleClick = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [])

  const handleOnScroll = useCallback(
    debounce(() => {
      const scrollTop = (document.documentElement.scrollTop || document.body.scrollTop)

      if (isStateVisible === false && scrollTop > viewportHeight * 0.5) {
        setStateVisible(true)
      }
      if (isStateVisible === true && scrollTop < viewportHeight * 0.5) {
        setStateVisible(false)
      }
    }, 50), [isStateVisible, viewportHeight]
  )

  // effects
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setViewportHeight(window.innerHeight)

      handleOnScroll()
      window.addEventListener('resize', handleOnScroll)
      window.addEventListener('scroll', handleOnScroll)
    }
    return () => {
      try {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleOnScroll)
          window.removeEventListener('scroll', handleOnScroll)
        }
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [handleOnScroll])

  return (
    <A
      className={cx(classes.container, className, isStateVisible === true && 'is-visible')}
      onClick={handleClick}
    >
      <Icon
        className={classes.icon}
        icon={icons.ChevronSquareUp}
      />
      <i>{text}</i>
    </A>
  )
}

BackToTop.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
  isVisible: PropTypes.bool,
}

BackToTop.defaultProps = {
  className: null,
  classes: null,
  text: '',
  isVisible: false,
}

export default withMemo()(BackToTop)
