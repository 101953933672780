import { query } from '../../../helpers/GraphqlHelpers'

import { Configuration } from './Configuration'
import { Locales } from './Locales'


export default {
  configuration: (variables, headers = {}) => query(Configuration, variables, headers),
  locales: (variables, headers = {}) => query(Locales, variables, headers),
}
