import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PracticeSection = (props) => {
  const { classes: classesProp, className, id, map, content, textNoContent } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  // if()

  return (
    <section className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {map}
        <TransitionGroup
          appear
          className={classes.contentWrapper}
        >
          <CSSTransition
            in
            timeout={200}
            classNames="animate"
            key={id}
          >
            <div className={classes.content}>
              {content ?? (
              <MarkdownStyled
                className={classes.noContent}
                text={textNoContent}
              />
)}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </section>
  )
}

PracticeSection.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  id: PropTypes.number || PropTypes.string,
  map: PropTypes.node,
  content: PropTypes.node,
  textNoContent: PropTypes.string.isRequired,
}

PracticeSection.defaultProps = {
  className: null,
  classes: null,
  id: null,
  map: null,
  content: null,
}

export default withMemo()(PracticeSection)
