import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/withMemo'
import { useSafeCall } from '../../helpers/React'
import { useLexicon } from '../../utils/hooks/useLexicon'

import { fixSpaces, cleanTitleInA } from './utils'
import styles from './styles'


const useStyles = createUseStyles(styles)

const MarkdownText = (props) => {
  const classes = useStyles(props)
  const {
    className,
    text,
    tag,
    inline,
    autoBlankExternalLinks,
    onLinkClick,
    lexicon,
    markHover,
    transformLexicon,
    ...otherProps
  } = props

  const [attachLexicon, handleMarkHover] = useLexicon({
    markHover,
  })

  const correctedText = useMemo(() => fixSpaces(text), [text])
  const textWithLexicon = useMemo(() => (transformLexicon
    ? attachLexicon(correctedText, lexicon)
    : correctedText), [attachLexicon, correctedText, lexicon, transformLexicon])

  const html = cleanTitleInA(textWithLexicon)
  const Tag = tag || 'div'
  const ref = useRef(null)
  const handleClick = useSafeCall(onLinkClick)

  useEffect(() => {
    if (ref.current) {
      Array.from(ref.current.querySelectorAll('a')).forEach((m) => {
        // eslint-disable-next-line no-param-reassign
        m.onclick = handleClick
      })
    }
  }, [ref, html, handleClick])

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('mouseover', handleMarkHover, false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      return () => ref.current.removeEventListener('mouseover', handleMarkHover)
    }
  }, [ref, html, handleMarkHover])

  return (
    <Tag
      {...otherProps}
      ref={ref}
      className={cx(classes.container, className)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

MarkdownText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  tag: PropTypes.elementType,
  inline: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  lexicon: PropTypes.array,
  markHover: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  autoBlankExternalLinks: PropTypes.bool,
  onLinkClick: PropTypes.func,
  transformLexicon: PropTypes.bool,
}

MarkdownText.defaultProps = {
  className: null,
  text: '',
  tag: 'div',
  inline: false,
  lexicon: null,
  markHover: () => null,
  autoBlankExternalLinks: true,
  onLinkClick: () => null,
  transformLexicon: true,
}

export default withMemo()(MarkdownText)
