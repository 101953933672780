/* eslint-disable max-lines */
import { all, call, put } from 'redux-saga/effects'

import { OutilSagas } from '../sagas'
import ApiSagas from '../../../redux/api/sagas'
import Service from '../../../graphql/query/View'

import { actions as HomeActions } from './redux'
import { homepageViewTransformer } from './transformers'


export default class HomeSagas {

  static* init({ languageId }) {
    const view = yield call(ApiSagas.persistQuery, Service.homepageView, {}, homepageViewTransformer)


    yield all([
      call(OutilSagas.loadSmallTools, languageId),
      put(HomeActions.setRubriques(view?.rubriques ?? null)),
      put(HomeActions.setQuestions(view?.questions ?? null)),
    ])
  }

  static* loop() {
    yield all([
    ])
  }

}
