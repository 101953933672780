import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import ArrowDown from './icons/arrow-down.svg'
import ArrowLeft from './icons/arrow-left.svg'
import ArrowRight from './icons/arrow-right.svg'
import ArrowUp from './icons/arrow-up.svg'
import Burger from './icons/burger.svg'
import BubbleLeft from './icons/bubble-left.svg'
import BubbleRight from './icons/bubble-right.svg'
import Calendar from './icons/calendar.svg'
import CalendarEyelet from './icons/calendar-eyelet.svg'
import ChevronRoundedDown from './icons/chevron-rounded-down.svg'
import ChevronSliderLeft from './icons/chevron-slider-left.svg'
import ChevronSliderRight from './icons/chevron-slider-right.svg'
import ChevronSquareLeft from './icons/chevron-square-left.svg'
import ChevronSquareRight from './icons/chevron-square-right.svg'
import ChevronSquareDown from './icons/chevron-square-down.svg'
import ChevronSquareUp from './icons/chevron-square-up.svg'
import ChevronThinLeft from './icons/chevron-thin-left.svg'
import ChevronThinRight from './icons/chevron-thin-right.svg'
import Close from './icons/close.svg'
import CircleClose from './icons/circle-close.svg'
import Download from './icons/download.svg'
import Facebook from './icons/facebook.svg'
import Home from './icons/home.svg'
import Mail from './icons/mail2.svg'
import Play from './icons/play.svg'
import PlayerFullscreen from './icons/player-fullscreen.svg'
import PlayerMute from './icons/player-mute.svg'
import PlayerNext from './icons/player-next.svg'
import PlayerNormalScreen from './icons/player-normalscreen.svg'
import PlayerPause from './icons/player-pause.svg'
import PlayerPlay from './icons/player-play.svg'
import PlayerPrevious from './icons/player-previous.svg'
import PlayerSettings from './icons/player-settings.svg'
import PlayerSound from './icons/player-sound.svg'
import Print from './icons/print.svg'
import Search from './icons/search.svg'
import Share from './icons/share.svg'
import ShareReverse from './icons/share-outlined.svg'
import Twitter from './icons/twitter.svg'


const IconWrapper = (Component, width, height) => {
  const IconWrapperStyles = {
    asset: {
      display: 'block',
      width: '100%',
      height: '100%',
    },
  }

  const useStyles = createUseStyles(IconWrapperStyles)

  const IconAsset = (props) => {
    const classes = useStyles(props)
    const { className } = props

    return <Component className={cx(classes.asset, className)} />
  }

  IconAsset.propTypes = {
    className: PropTypes.string,
  }
  IconAsset.defaultProps = {
    className: '',
  }

  return IconAsset
}

const icons = {
  ArrowDown: IconWrapper(ArrowDown, 14, 39),
  ArrowLeft: IconWrapper(ArrowLeft, 39, 14),
  ArrowRight: IconWrapper(ArrowRight, 39, 14),
  ArrowUp: IconWrapper(ArrowUp, 14, 39),
  Burger: IconWrapper(Burger, 25, 17),
  BubbleLeft: IconWrapper(BubbleLeft, 15, 15),
  BubbleRight: IconWrapper(BubbleRight, 15, 15),
  Calendar: IconWrapper(Calendar, 68, 67),
  CalendarEyelet: IconWrapper(CalendarEyelet, 10, 17),
  ChevronRoundedDown: IconWrapper(ChevronRoundedDown, 13, 8),
  ChevronSliderLeft: IconWrapper(ChevronSliderLeft, 14, 31),
  ChevronSliderRight: IconWrapper(ChevronSliderRight, 14, 31),
  ChevronSquareLeft: IconWrapper(ChevronSquareLeft, 14, 21),
  ChevronSquareRight: IconWrapper(ChevronSquareRight, 14, 21),
  ChevronSquareDown: IconWrapper(ChevronSquareDown, 21, 14),
  ChevronSquareUp: IconWrapper(ChevronSquareUp, 21, 14),
  ChevronThinLeft: IconWrapper(ChevronThinLeft, 9, 17),
  ChevronThinRight: IconWrapper(ChevronThinRight, 9, 17),
  Close: IconWrapper(Close, 20, 20),
  CircleClose: IconWrapper(CircleClose, 20, 20),
  Download: IconWrapper(Download, 13, 17),
  Facebook: IconWrapper(Facebook, 9, 16),
  Home: IconWrapper(Home, 20, 20),
  Mail: IconWrapper(Mail, 20, 13),
  Play: IconWrapper(Play, 9, 10),
  PlayerFullscreen: IconWrapper(PlayerFullscreen, 53, 50),
  PlayerMute: IconWrapper(PlayerMute, 48, 40),
  PlayerNext: IconWrapper(PlayerNext, 32, 29),
  PlayerNormalScreen: IconWrapper(PlayerNormalScreen, 53, 50),
  PlayerPause: IconWrapper(PlayerPause, 30, 45),
  PlayerPlay: IconWrapper(PlayerPlay, 40, 45),
  PlayerPrevious: IconWrapper(PlayerPrevious, 32, 29),
  PlayerSettings: IconWrapper(PlayerSettings, 50, 50),
  PlayerSound: IconWrapper(PlayerSound, 47, 38),
  Print: IconWrapper(Print, 18, 15),
  Search: IconWrapper(Search, 28, 20),
  Share: IconWrapper(Share, 24, 24),
  ShareReverse: IconWrapper(ShareReverse, 35, 24),
  Twitter: IconWrapper(Twitter, 18, 15),
}

export default icons
