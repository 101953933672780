import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'
import Image from '../Image'
import ButtonColored from '../ButtonColored'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockLandingApps = (props) => {
  const {
    classes: classesProp,
    className,
    title,
    TagTitle,
    text,
    image,
    buttons,
    lexicon,
    transformLexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const renderButtons = useMemo(() => buttons && buttons.length > 1 && (
    <div className={classes.buttons}>
      {buttons.map((button, index) => (
        <ButtonColored
          key={`blocklandingapps-button-${index}`}
          {...button}
        />
      ))}
    </div>
    ), [buttons, classes.buttons])

  return (
    <section className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {title && (
            <TagTitle
              className={classes.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {text && (
            <MarkdownStyled
              className={classes.text}
              text={text}
              lexicon={lexicon}
              transformLexicon={transformLexicon}
              markHover={markHover}
            />
          )}
        </div>
        {image && (
          <figure className={classes.figure}>
            <Image
              {...image}
              className={classes.image}
            />
            <div className={classes.bubble}>
              <Image
                src="/images/Landing/landing-bg-apps.png"
                className={classes.bubbleImage}
                alt=" "
                aria-hidden="true"
              />
            </div>
          </figure>
        )}
        {renderButtons}
      </div>
    </section>
  )
}

BlockLandingApps.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  TagTitle: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.shape(Image.propTypes),
  buttons: PropTypes.arrayOf(PropTypes.shape(ButtonColored.propTypes)),
  lexicon: PropTypes.arrayOf(PropTypes.object),
  transformLexicon: PropTypes.bool,
  markHover: PropTypes.func,
}

BlockLandingApps.defaultProps = {
  className: null,
  classes: null,
  title: '',
  TagTitle: 'h2',
  text: '',
  image: null,
  buttons: [],
  lexicon: null,
  transformLexicon: true,
  markHover: () => null,
}

export default withMemo()(BlockLandingApps)
