import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'
import Image from '../Image'
import MagicTitle from '../MagicTitle'

import styles from './styles'


const useStyles = createUseStyles(styles)

const HeaderLanding = (props) => {
  const {
    classes: classesProp,
    className,
    title,
    text,
    textPublished,
    textUpdated,
    image,
    imageMobile,
    zoneTools,
    zoneSharing,
    TagTitle,
    lexicon,
    transformLexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <header
      className={cx(classes.container, className)}
      role="heading"
      aria-level="2"
    >
      <div className={classes.main}>
        <div className={classes.mainWrapper}>
          <div className={classes.titleZone}>
            <MagicTitle
              text={title}
              className={classes.title}
              TagTitle={TagTitle}
            />
            <div className={classes.infos}>
              {(textPublished || textUpdated) && (
              <time className={classes.infosDate}>
                {textPublished}
                {textPublished && textUpdated && (
                  <>
                    <br />
                    {' - '}
                  </>
                )}
                {textUpdated}
              </time>
            )}
            </div>
            {(zoneTools && zoneSharing) && (
              <div className={classes.toolsZone}>
                {zoneTools}
              </div>
            )}
          </div>
          <div className={classes.figures}>
            {image?.src && (
              <figure className={cx(classes.figure, classes.figureDesktop)}>
                <Image
                  {...image}
                  covered
                  className={classes.imageDesktop}
                />
              </figure>
            )}
            {imageMobile?.src && (
              <figure className={cx(classes.figure, classes.figureMobile)}>
                <Image
                  {...imageMobile}
                  covered
                  className={classes.imageMobile}
                />
              </figure>
            )}
          </div>
          <div className={classes.bubble}>
            <Image
              src="/images/Landing/landing-bg-header.png"
              alt=" "
              className={classes.bubbleImage}
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
      <div className={classes.summary}>
        <div className={classes.summaryWrapper}>
          {(zoneSharing) && (
            <div className={classes.sharingZone}>
              {zoneSharing}
            </div>
          )}
          {text && (
            <MarkdownStyled
              className={classes.text}
              text={text}
              lexicon={lexicon}
              markHover={markHover}
              transformLexicon={transformLexicon}
            />
          )}
        </div>
      </div>
    </header>
  )
}

HeaderLanding.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  zoneTools: PropTypes.node,
  zoneSharing: PropTypes.node,
  TagTitle: PropTypes.string,
  textPublished: PropTypes.string,
  textUpdated: PropTypes.string,
  image: PropTypes.shape(Image.propTypes),
  imageMobile: PropTypes.shape(Image.propTypes),
  lexicon: PropTypes.arrayOf(PropTypes.object),
  transformLexicon: PropTypes.bool,
  markHover: PropTypes.func,
}

HeaderLanding.defaultProps = {
  className: null,
  classes: null,
  text: '',
  zoneTools: null,
  zoneSharing: null,
  TagTitle: 'h1',
  textPublished: '',
  textUpdated: '',
  image: null,
  imageMobile: null,
  lexicon: null,
  transformLexicon: true,
  markHover: () => null,
}

export default withMemo()(HeaderLanding)
