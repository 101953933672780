import { biggerDesktop, ultraDesktop, colors, desktop, grids } from '../../theme'


export default {
  container: {
    display: 'none',
    flexFlow: 'row nowrap',
    overflow: 'hidden',
    color: colors.grey67,
    background: colors.greyEF,
  },
  list: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'stretch',
    // another exception to guarantee the icon looks "outside" the breadcrumb on fullscreen, but displays correctly on inferior resolutions
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 3rem + 6rem)`,
    marginLeft: '5rem',
    height: '4rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  item: {
    position: 'relative',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    '&:not(:first-child)': {
      paddingLeft: '2.5rem',
      marginLeft: '2.5rem',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: '0',
        top: '50%',
        height: '0.8em',
        width: '1px',
        background: 'currentColor',
        transform: 'translateY(-50%)',
      },
    },
  },
  link: {
    position: 'relative',
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '100%',
    fontSize: '1.4rem',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    transition: 'color 200ms ease-out',
    '&[href], &:is(a)': {
      color: colors.blueTropaz,
      '&:hover': {
        color: colors.blue,
      },
    },
  },
  homeIcon: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '1.5rem',
    color: colors.grey67,
    transform: 'translateY(-0.1rem)',
  },
  ...desktop({
    container: {
      display: 'block',
      maxWidth: '160rem',
      width: '100%',
      margin: '0 auto',
    },
  }),
  ...biggerDesktop({
    list: {
      paddingRight: '4.5rem',
    },
  }),
  ...ultraDesktop({
    list: {
      marginLeft: '2rem',
    },
  }),
}
