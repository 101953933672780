/* eslint-disable */
// Cover all standardized ES6 APIs.
import 'core-js/es'

// Standard now
import 'core-js/features/array/includes'
import 'core-js/features/array/flat-map'
import 'core-js/features/string/pad-start'
import 'core-js/features/string/pad-end'
import 'core-js/features/string/trim-start'
import 'core-js/features/string/trim-end'
import 'core-js/features/symbol/async-iterator'
import 'core-js/features/object/get-own-property-descriptors'
import 'core-js/features/object/values'
import 'core-js/features/object/entries'
import 'core-js/features/promise/finally'

// Ensure that we polyfill ES6 compat for anything web-related, if it exists.
import 'core-js/web'

import 'regenerator-runtime/runtime'
import 'intersection-observer'
import 'custom-event-polyfill'

if (typeof window !== 'undefined') {

  // see : https://github.com/vercel/next.js/issues/13231
  require('@webcomponents/shadydom')

  require('element-scroll-polyfill')

  if (window.NodeList && !window.NodeList.prototype.forEach) {
    window.NodeList.prototype.forEach = function forEach(callback, thisArg) {
      const arg = thisArg || window

      for (let i = 0; i < this.length; i++) {
        callback.call(arg, this[i], i, this)
      }
    }
  }

  (function(arr) {
    arr.forEach(function(item) {
      if (item.hasOwnProperty('prepend')) {
        return
      }
      Object.defineProperty(item, 'prepend', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function prepend() {
          const argArr = Array.prototype.slice.call(arguments)
          const docFrag = document.createDocumentFragment()

          argArr.forEach(function(argItem) {
            const isNode = argItem instanceof Node

            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)))
          })

          this.insertBefore(docFrag, this.firstChild)
        },
      })
    })
  }([Element.prototype, Document.prototype, DocumentFragment.prototype]))
}

String.prototype.toCamelCase = function() {
  const camel = this.toString()
      .replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })
      .replace(/\s/g, '')
      .replace(/^(.)/, function($1) { return $1.toLowerCase(); });

 return  camel.charAt(0).toUpperCase()+ camel.slice(1)
}

String.prototype.sanitize = function() {
  const sanitized = this.toString()
      .replace('\r', '')
      .replace('\n', '')
      .replace('\t', '')
      .replace(/<[^>]*>?/gm, '')

  return  sanitized
}

Array.prototype.rotate = function(n) {
  while (this.length && n < 0) n += this.length;
  this.push.apply(this, this.splice(0, n));
  return this;
}