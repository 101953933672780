/* eslint-disable max-lines */
import { all, call, put } from 'redux-saga/effects'

import { variantMapperTransformer } from '../../Pages/Page/transformers'
import { actions as PageActions } from '../../Pages/Page/redux'
import { OutilSagas } from '../sagas'

import { actions as RubriqueActions } from './redux'


export default class RubriqueSagas {

  static* init({ languageId, data }) {
    const variant = yield call(variantMapperTransformer, data)


    yield all([
      put(RubriqueActions.setCurrentCategory(0)),
      put(PageActions.setVariant(variant)),
      call(OutilSagas.loadSmallTools, languageId),
    ])
  }


  static* loop() {
    yield all([
    ])
  }

}
