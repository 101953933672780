import { accessibleHiddenText, colors } from '../../theme'


export default {
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(1.1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  container: {
    position: 'relative',
  },
  wrapper: {
    position: 'relative',
    height: 0,
    paddingTop: '100%',
  },
  dots: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },
  dot: {
    position: 'absolute',
    width: '18px',
    height: '18px',
    background: colors.redInfo,
    border: `4px solid ${colors.pureWhite}`,
    borderRadius: '50%',
    boxShadow: `0 0 0 4px ${colors.redInfo}`,
    cursor: 'pointer',
    marginTop: '-9px',
    marginLeft: '-9px',
    animation: '$pulsate 0.6s ease-out infinite alternate both',
    '&:hover': {
      animation: 'paused',
      transform: 'scale(1.3)',
    },
    '& i': {
      ...accessibleHiddenText,
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  image: {
    position: 'relative',
    display: 'block',
    zIndex: 2,
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
}
