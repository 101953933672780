import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'questionsDuMoment'

//
// Initial state
//
const initialState = {
  periodes: null,
  questions: null,
  currentPeriode: null,
}

//
// Actions
//
export const actions = {
  setPeriodes: createAction('QuestionsDuMoment: setPeriodes', (periodes) => ({ periodes })),
  setQuestions: createAction('QuestionsDuMoment: setQuestions', (questions) => ({ questions })),
  setCurrentPeriode: createAction('QuestionsDuMoment: setCurrentPeriode', (currentPeriode) => ({ currentPeriode })),
  loadQuestionsByPeriode: createAction('QuestionsDuMoment: loadQuestionsByPeriode'),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setPeriodes]: (state, { periodes }) => Immutable.static.merge(state, { periodes }),
    [actions.setQuestions]: (state, { questions }) => Immutable.static.merge(state, { questions }),
    [actions.setCurrentPeriode]: (state, { currentPeriode }) => Immutable.static.merge(state, { currentPeriode }),
  },
  initialState
)

//
// Selectors
//

const root = (state) => state[key]
const periodes = (state) => root(state).periodes
const questions = (state) => root(state).questions
const currentPeriode = (state) => root(state).currentPeriode

export const selectors = {
  periodes,
  questions,
  currentPeriode,
}
