import { desktop } from '../../theme'


export default {
  container: {
    display: 'none',
    flexFlow: 'row nowrap',
    ...desktop({
      display: 'flex',
    }),
  },
  list: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  item: {
    '&:not(:first-child)': {
      marginLeft: '4.5rem',
    },
  },
  navmainlink: {
    // pseudo element to prevent the loss of hover
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: '50%',
      height: '1rem',
      width: '100%',
      transform: 'translateX(-50%)',
      // background: 'red',
    },
    '$item:hover &::after, &:hover::after': {
      height: '7rem',
      width: '300%',
      zIndex: 50,
      marginTop: '-2rem',
    },
  },
}
