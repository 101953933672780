import React from 'react'
import PropTypes from 'prop-types'

import withMemo from '../../decorators/withMemo'
import PageContainer from '../../containers/PageContainer'


const PageTemplate = (props) => {
  const {
    children,
    pageContainerProps,
  } = props

  return (
    <PageContainer {...pageContainerProps}>{children}</PageContainer>
  )
}

PageTemplate.propTypes = {
  children: PropTypes.node,
  pageContainerProps: PropTypes.shape(PageContainer.propTypes),
}

PageTemplate.defaultProps = {
  children: null,
  pageContainerProps: null,
}

export default withMemo()(PageTemplate)
