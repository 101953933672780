import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'
import Image from '../Image'

import styles from './styles'


const useStyles = createUseStyles(styles)

const HeaderTopic = (props) => {
  const {
    classes: classesProp,
    className,
    variant,
    title,
    text,
    image,
    zoneTools,
    zoneSharing,
    TagTitle,
    lexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <header
      className={cx(classes.container, className, `is-${variant}`)}
      role="heading"
      aria-level="2"
    >
      <div className={classes.main}>
        <div className={classes.mainWrapper}>
          <div className={classes.titleZone}>
            <div className={classes.titleWrapper}>
              <TagTitle
                className={classes.title}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
            {(zoneTools && zoneSharing) && (
              <div className={classes.toolsZone}>
                {zoneTools}
                {zoneSharing}
              </div>
            )}
          </div>
          <figure className={classes.figure}>
            <Image
              {...image}
              covered
              className={classes.image}
            />
          </figure>
        </div>
      </div>
      <div className={classes.summary}>
        <div className={classes.summaryWrapper}>
          {(zoneSharing) && (
            <div className={classes.sharingZone}>
              {zoneSharing}
            </div>
          )}
          {text && (
            <MarkdownStyled
              className={classes.text}
              text={text}
              variant={variant}
              lexicon={lexicon}
              markHover={markHover}
            />
          )}
        </div>
      </div>
    </header>
  )
}

HeaderTopic.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  zoneTools: PropTypes.node,
  zoneSharing: PropTypes.node,
  TagTitle: PropTypes.string,
  image: PropTypes.shape(Image.propTypes),
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

HeaderTopic.defaultProps = {
  className: null,
  classes: null,
  text: '',
  zoneTools: null,
  zoneSharing: null,
  TagTitle: 'h1',
  image: null,
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(HeaderTopic)
