import { desktop, tablet, biggerDesktop, mobile } from '../../theme'


export default {
  container: {
    position: 'relative',
  },
  main: {
    maxWidth: '144rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  mainWrapper: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column-reverse',
  },
  titleZone: {
    position: 'relative',
    zIndex: 2,
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    marginTop: '2.5rem',
  },
  title: {
    position: 'relative',
    fontSize: '3.2rem',
    maxWidth: '20rem',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    fontWeight: 900,
    '& span:first-child::before': {
      content: '""',
      position: 'absolute',
      top: '-1.5rem',
      left: 0,
      height: '0.5rem',
      width: '100%',
      background: 'currentColor',
    },
    '& span:last-child::after': {
      display: 'none',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: '0.5rem',
      width: '100%',
      background: 'currentColor',
    },
  },
  infos: {
    fontSize: '1.7rem',
    '&:not(:first-child)': {
      marginTop: '2.5rem',
    },
  },
  toolsZone: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:first-child)': {
      marginTop: '2.5rem',
    },
  },
  figures: {
    position: 'relative',
    zIndex: 2,
  },
  figure: {
    margin: 0,
  },
  figureMobile: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
  },
  imageMobile: {
    flex: '0 0 auto',
    maxWidth: 'min(87%, 40rem)',
  },
  figureDesktop: {
    display: 'none',
  },
  imageDesktop: {},
  summary: {
    maxWidth: '144rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '3rem',
  },
  summaryWrapper: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
  },
  text: {
    fontSize: '1.7rem',
  },
  sharingZone: {},
  bubble: {
    position: 'absolute',
    top: '5rem',
    left: '-9rem',
    width: '45rem',
    height: '53rem',
    zIndex: 1,
    pointerEvents: 'none',
  },
  ...mobile({
    mainWrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
    titleZone: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-end',
      alignItems: 'stretch',
    },
    title: {
      width: '25rem',
    },
    infos: {
      fontSize: '1.6rem',
    },
    toolsZone: {
      '&:not(:first-child)': {
        marginTop: '2.5rem',
      },
      '& .is-mobile-only': {
        display: 'none',
      },
      '& .is-desktop-only': {
        display: 'flex',
      },
    },
    imageMobile: {
      maxWidth: 'none',
    },
    summary: {
      marginTop: '6rem',
    },
    summaryWrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
      '& > *': {
        flex: '1 1 auto',
      },
    },
    sharingZone: {
      width: '27.5rem',
      '$summaryWrapper > &': {
        flex: '0 0 auto',
      },
      '& .is-mobile-only': {
        display: 'none',
      },
      '& .is-desktop-only': {
        display: 'flex',
      },
    },
    text: {
      fontSize: '1.5rem',
    },
  }),
  ...tablet({
    main: {
      display: 'flex',
      flexFlow: 'row',
    },
    titleZone: {
      width: '45.5rem',
      paddingLeft: '10.5rem',
    },
    title: {
      width: '32rem',
      maxWidth: '32rem',
      fontSize: '4.5rem',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: 0,
        height: '0.5rem',
        width: '4.3em',
        background: 'currentColor',
      },
      '&::after': {
        display: 'block',
        width: '30rem',
        left: 0,
        right: 'auto',
        bottom: 0,
      },
      '& span:first-child::before': {
        display: 'none',
      },
      '& span:last-child::after': {
        display: 'none',
      },
    },
    figureMobile: {
      display: 'none',
    },
    figureDesktop: {
      display: 'flex',
    },
    summary: {

    },
    sharingZone: {
      width: '42.5rem',
      paddingLeft: '8rem',
    },
    bubble: {
      position: 'absolute',
      top: '2rem',
      left: '-2rem',
      width: '53rem',
      height: '67.9rem',
      zIndex: 1,
    },
  }),
  ...desktop({
    summaryWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    text: {
      maxWidth: '105rem',
      paddingRight: '8rem',
    },
    title: {
      '&::after': {
        width: '27rem',
      },
    },
  }),
  ...biggerDesktop({}),
  '@media print': {
    main: {
    },
    mainWrapper: {
      flexFlow: 'column-reverse nowrap',
    },
    bubble: {
      display: 'none',
    },
    figureMobile: {
      display: 'none',
    },
    figureDesktop: {
      display: 'none',
    },
  },
}
