import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownText from '../MarkdownText'
import A from '../A'
import Image from '../Image'
import QuestionPushCard from '../QuestionPushCard'
import { useTagCommander } from '../../utils/hooks'
import { tcEventEnum } from '../../utils/hooks/useTagCommander'

import styles from './styles'


const useStyles = createUseStyles(styles)

const QuestionPushPractice = (props) => {
    const {
        classes: classesProp,
        className,
        title,
        image,
        TagTitle,
        linkProps,
        // eslint-disable-next-line no-unused-vars
        variant,
        lexicon,
        markHover,
    } = props
    const { handleEventTC } = useTagCommander()
    const classesComp = useStyles(props)
    const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

    const handleOnClick = () =>
        handleEventTC(
            tcEventEnum.ACTION_CLICK_CARD_QUESTION_DU_MOMENT,
            {
                data: {
                    ...linkProps,
                    markHover,
                    title,
                },
            }
        )

    return (
      <QuestionPushCard
        onClick={handleOnClick}
        linkProps={linkProps}
        variant={variant}
        hasIcon
        className={cx(classes.container, className)}
      >
        <MarkdownText
          tag={TagTitle}
          className={classes.title}
          text={title}
        />
        {image && (
        <figure className={classes.figure}>
          <Image
            {...image}
            covered
            className={classes.image}
            lexicon={lexicon}
            markHover={markHover}
          />
        </figure>
            )}
      </QuestionPushCard>
    )
}

QuestionPushPractice.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.string),
    title: PropTypes.string,
    image: PropTypes.shape(Image.propTypes),
    TagTitle: PropTypes.string,
    linkProps: PropTypes.shape(A.propTypes),
    variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
    lexicon: PropTypes.arrayOf(PropTypes.object),
    markHover: PropTypes.func,
}

QuestionPushPractice.defaultProps = {
    className: null,
    classes: null,
    title: '',
    image: null,
    TagTitle: 'h3',
    linkProps: null,
    lexicon: null,
    markHover: () => null,
}

export default withMemo()(QuestionPushPractice)
