import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import Icon from '../Icon'
import icons from '../Icon/assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const QuestionPushCard = (props) => {
  const {
    classes: classesProp,
    className,
    linkProps,
    children,
    hasIcon,
    // eslint-disable-next-line no-unused-vars
    variant,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <A
      {...linkProps}
      className={cx(classes.container, className)}
    >
      {children}
      {linkProps && hasIcon && (
        <Icon
          className={classes.icon}
          icon={icons.ArrowRight}
        />
      )}
    </A>
  )
}

QuestionPushCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  linkProps: PropTypes.shape(A.propTypes),
  hasIcon: PropTypes.bool,
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
}

QuestionPushCard.defaultProps = {
  className: null,
  classes: null,
  children: null,
  linkProps: null,
  hasIcon: true,
}

export default withMemo()(QuestionPushCard)
