import { useCallback, useEffect, useMemo, useState } from 'react'

import useCookie from '../../utils/hooks/useCookie'

import { COOKIE_NAME, CONTENT_URI, LONG_CLOSE_DURATION_DAYS, MS_TIMEOUT_BEFORE_SHOW } from './advertPopinConstants'


let timeoutID = null

const fetchContent = (onResponseFn) => {
  fetch(CONTENT_URI)
  .then((res) => res.json())
  .then((data) => {
    onResponseFn(data)
  })
}

export default function useAdvertPopinData() {
  const [content, setContent] = useState(null)
  const [cookieValue, setCookieValue] = useCookie(COOKIE_NAME)

  const shouldFetchContent = useMemo(() => {
    return !cookieValue || cookieValue === 'undefined'
  }, [cookieValue])

  useEffect(() => {
    if (shouldFetchContent && !content) {
      setTimeout(() => {
          fetchContent(setContent)
      }, MS_TIMEOUT_BEFORE_SHOW)
    }
  }, [shouldFetchContent, content])

  const doClose = useCallback(() => {
    setContent(null)
    clearTimeout(timeoutID)
    timeoutID = null
  }, [])

  const saveSessionCloseCookie = useCallback(() => {
    setCookieValue('closed')
  }, [setCookieValue])

  const saveLongCloseCookie = useCallback(() => {
    setCookieValue('closed', { expires: LONG_CLOSE_DURATION_DAYS })
  }, [setCookieValue])

  const onClose = useCallback(() => {
    saveSessionCloseCookie()
    doClose()
  }, [doClose, saveSessionCloseCookie])

  const onDontAskAgain = useCallback(() => {
    saveLongCloseCookie()
    doClose()
  }, [doClose, saveLongCloseCookie])

  const onNavigate = useCallback(() => {
    onClose()
  }, [onClose])

  return {
    content,
    onClose,
    onDontAskAgain,
    onNavigate,
  }
}
