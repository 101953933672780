import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'
import FocusSmall from '../FocusSmall'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockArticle = (props) => {
  const {
    classes: classesProp,
    className,
    text,
    focus,
    // eslint-disable-next-line no-unused-vars
    variant,
    lexicon,
    transformLexicon,
    markHover,
    children,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <section className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {text && (
            <MarkdownStyled
              className={classes.text}
              text={text}
              variant={variant}
              lexicon={lexicon}
              transformLexicon={transformLexicon}
              markHover={markHover}
            />
          )}
          {children && (
            <div>{children}</div>
          )}
        </div>
        {focus && (
          <FocusSmall
            className={classes.focus}
            {...focus}
            variant={variant}
            lexicon={lexicon}
            transformLexicon={transformLexicon}
            markHover={markHover}
          />
        )}
      </div>
    </section>
  )
}

BlockArticle.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
  focus: PropTypes.shape(FocusSmall.propTypes),
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  transformLexicon: PropTypes.bool,
  markHover: PropTypes.func,
  children: PropTypes.node,
}

BlockArticle.defaultProps = {
  className: null,
  classes: null,
  text: '',
  focus: null,
  lexicon: null,
  transformLexicon: true,
  markHover: () => null,
  children: null,
}

export default withMemo()(BlockArticle)
