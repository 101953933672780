import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'tool'

//
// Initial state
//
const initialState = {
  tools: null,
  view: null,
  current: null,
}

//
// Actions
//
export const actions = {
  setTools: createAction('Tool: setTools', (tools) => ({ tools })),
  setView: createAction('Tool: setView', (view) => ({ view })),
  setCurrent: createAction('Tool: setCurrent', (current) => ({ current })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setTools]: (state, { tools }) => Immutable.static.merge(state, { tools }),
    [actions.setView]: (state, { view }) => Immutable.static.merge(state, { view }),
    [actions.setCurrent]: (state, { current }) => Immutable.static.merge(state, { current }),
  },
  initialState
)

//
// Selectors
//

const root = (state) => state[key]
const tools = (state) => root(state).tools
const view = (state) => root(state).view
const current = (state) => root(state).current

export const selectors = {
  tools,
  view,
  current,
}
