import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'rubrique'

//
// Initial state
//
const initialState = {
  currentCategory: 0,
}

//
// Actions
//
export const actions = {
  setCurrentCategory: createAction('Rubrique: setCurrentCategory', (currentCategory) => ({ currentCategory })),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setCurrentCategory]: (state, { currentCategory }) => Immutable.static.merge(state, { currentCategory }),
  },
  initialState
)

//
// Selectors
//

const root = (state) => state[key]
const currentCategory = (state) => root(state).currentCategory

export const selectors = {
  currentCategory,
}
