import { colors, desktop, tablet } from '../../theme'


export default {
  container: {
    background: colors.pureWhite,
    border: `1px solid ${colors.greya8}`,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
    '&.is-inpractice': {
      paddingTop: '2rem',
      paddingBottom: '1rem',
      borderColor: colors.greyEF,
      backgroundColor: colors.greyEF,
    },
  },
  main: {
    display: 'flex',
    alignItems: 'flex-start',
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
    '&::after': {
      content: '""',
      clear: 'both',
      display: 'block',
    },
  },
  category: {},
  topic: {
    position: 'relative',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '2.2rem',
    margin: 0,
    color: (props) => (
      props.variant === 'care' ? colors.redCare
        : props.variant === 'environment' ? colors.turquoiseDarkestEnvironment
          : props.variant === 'listen' ? colors.orangeDarkestListen
            : props.variant === 'discover' ? colors.blueDiscover
              : props.variant === 'inpractice' ? colors.blue
                : colors.pureBlack),
    '& a': {
      color: 'inherit',
    },
  },
  topicText: {
    position: 'relative',
    display: 'inline-block',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '$container.is-inpractice &:first-child::before, $container.is-inpractice &:last-child::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      background: 'currentColor',
      height: '0.3rem',
    },
    '$container.is-inpractice &:first-child::before': {
      top: 0,
      left: 0,
    },
    '$container.is-inpractice &:last-child::after': {
      bottom: 0,
      right: 0,
    },
  },
  section: {
    textTransform: 'uppercase',
    fontSize: '2rem',
    margin: 0,
    fontWeight: (props) => (props.variant === 'inpractice' ? 'bold' : 'normal'),
    color: (props) => (
      props.variant === 'inpractice' ? colors.turquoiseDark
        : colors.pureBlack),
    '& a': {
      color: 'inherit',
    },
    '$container.is-inpractice &:not(first-child)': {
      marginTop: '2rem',
    },
  },
  figure: {
    flex: '0 0 auto',
    maxWidth: (props) => (props.variant === 'inpractice' ? 'min(30%, 13.8rem)' : 'min(30%, 18.5rem)'),
    margin: '0 2.5rem 0 0',
  },
  content: {
  },
  title: {
    margin: 0,
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    '& a': {
      color: 'inherit',
    },
    '& a:hover': {
      color: colors.grey95,
    },
  },
  summary: {
    flex: '1 1 auto',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '5',
    overflow: 'hidden',
    fontSize: '1.6rem',
    lineHeight: 1.2,
    '& .search-result-hightlight': {
      fontWeight: 'bold',
    },
  },
  buttons: {
    '&:not(:first-child)': {
      marginTop: '1rem',
    },
  },
  button: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '4rem',
    border: `1px solid ${colors.blue}`,
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: '1.25',
    textTransform: 'uppercase',
    color: colors.pureWhite,
    backgroundColor: colors.blue,
    transition: 'all 200ms ease-out',
    '&:hover': {
      color: colors.blue,
      backgroundColor: colors.pureWhite,
      borderColor: colors.blue,
    },
  },
  ...tablet({
    container: {
      display: 'flex',
      flexFlow: 'row nowrap',
      '&, &.is-inpractice': {
        paddingTop: '2rem',
        paddingBottom: '2rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
      },
    },
    category: {
      flex: '0 0 auto',
      width: 'calc(100% / 12 * 3)',
    },
    section: {
      '&:not(first-child)': {
        marginTop: '0.5rem',
      },
      '$container.is-inpractice &:not(first-child)': {
        marginTop: '3rem',
      },
    },
    main: {
      flex: '1 1 auto',
      '&:not(:only-child)': {
        width: 'calc(100% / 12 * 9)',
      },
      '&:not(:first-child)': {
        marginTop: '0',
        paddingLeft: '4rem',
      },
    },
    title: {
      fontSize: '2rem',
    },
    summary: {
      fontSize: '2rem',
      WebkitLineClamp: '5',
      '& b, & strong': {
        fontWeight: 'bold',
      },
    },
    buttons: {
      '&:not(:first-child)': {
        marginTop: '2.5rem',
      },
    },
  }),
  ...desktop({
    container: {
      '&, &.is-inpractice': {
        paddingTop: '3rem',
        paddingBottom: '3rem',
        paddingLeft: '4.5rem',
        paddingRight: '4.5rem',
      },
    },
  }),
  '@media print': {
    buttons: {
      display: 'none',
    },
    container: {
      borderColor: colors.greyD9,
    },
  },
}
