import React from 'react'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { removePTags } from '../../utils/StringUtils'
import Image from '../Image'
import icons from '../Icon/assets'
import Icon from '../Icon'

import styles from './styles'
import useAdvertPopinData from './useAdvertPopinData'


const useStyles = createUseStyles(styles)

export default function AdvertPopin() {
  const objStyles = useStyles(styles)
  const { t } = useTranslation()
  const {
    content,
    onClose,
    onDontAskAgain,
    onNavigate,
  } = useAdvertPopinData()

  return (content?.enable === 1) ? (
    <div className={objStyles.overlay}>
      <article className={objStyles.popin}>
        <header className={objStyles.popinHeader}>
          <h2 className={objStyles.popinHeaderTitle}>{t('modalWeNeedYou')}</h2>
          <Button
            onClick={onClose}
            className={objStyles.btnClose}
          >
            <Icon
              icon={icons.CircleClose}
              className={objStyles.iconClose}
            />
          </Button>
        </header>

        <div className={objStyles.popinBody}>
          <div className="img-container">
            <Image src="/images/family.jpg" />
          </div>
          <LinkExternal
            className={objStyles.popinText}
            href={content.link_cta}
            onClick={onNavigate}
          >
            {removePTags(content.text_cta)}
          </LinkExternal>
          <LinkExternal
            className={objStyles.btnPopinLink}
            href={content.link_cta}
            onClick={onNavigate}
          >
            {t('modalSeeMore')}
          </LinkExternal>
        </div>
        <div className={objStyles.popinFooter}>
          <Button
            type="button"
            className={objStyles.btnDoNotAskAgain}
            onClick={onDontAskAgain}
          >
            {t('modalDontAskAgain')}
          </Button>
        </div>
      </article>
    </div>
  ) : null
}

const Button = ({ children, ...props }) => {
  return (
    <button
      type="button"
      {...props}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
}

const LinkExternal = ({ children, ...props }) => {
  return (
    <a
      {...props}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )
}

LinkExternal.propTypes = {
  children: PropTypes.node.isRequired,
}

