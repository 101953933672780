/* eslint-disable max-lines */
import { all, call, put, select } from 'redux-saga/effects'
import slugify from 'slugify'

import ApiSagas from '../../../redux/api/sagas'
import Service from '../../../graphql/query/Node/index'
import { selectors as AppSelectors } from '../../../redux/app/redux'

import { actions as ToolActions, selectors as ToolSelectors } from './redux'
import { praticesTransformer } from './transformers'


export default class OutilSagas {

  static* init({ languageId, data }) {
    yield all([
      call(OutilSagas.loadSmallTools, languageId),
    ])

    const currentInStore = yield select(ToolSelectors.current)

    if (currentInStore === null) {
      const { query } = yield select(AppSelectors.route)

      const current = query?.slug?.substr(query?.slug?.indexOf('/') + 1)

      if (current) {
        yield put(ToolActions.setCurrent(slugify(current, {
          lower: true,
          strict: true,
        })))
      }
    }
  }

  static* loadSmallTools(languageId) {
    const tools = yield call(ApiSagas.persistQuery, Service.toolsSmall, {
      languageId,
      types: ['outil'],
    }, praticesTransformer)

    yield all([
      put(ToolActions.setTools(tools ?? null)),
    ])
  }

  static* loop() {
    yield all([])
  }

}
