import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import MarkdownText from '../MarkdownText'
import Icon from '../Icon'
import icons from '../Icon/assets'
import { safeCall } from '../../helpers/React'
import { Router } from '../../routes'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavQuestionsLink = (props) => {
  const {
    classes: classesProp,
    className,
    linkProps,
    text,
    isCurrent,
    changeCurrentHandler,
    scrollCurrentHandler,
    animationTime,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])
  const itemRef = useRef()

  const handlePosition = useCallback(() => {
    // we scroll to the current new entry only if it's not in the viewport
    if (window && itemRef.current) {
      setTimeout(() => {
        const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        const elWidth = itemRef?.current?.clientWidth ?? null

        if (elWidth) {
          const boundingClientRect = itemRef?.current?.getBoundingClientRect() ?? null

          if (boundingClientRect) {
            const isInViewPort = (boundingClientRect.left > 0) && (boundingClientRect.right < viewportWidth)

            if (!isInViewPort) {
              const newScroll = itemRef.current.offsetLeft - viewportWidth / 2 + elWidth / 2

              scrollCurrentHandler(newScroll > 0 ? newScroll : 0)
            }
          }
        }
      }, animationTime)
    }
  }, [animationTime, scrollCurrentHandler])

  const handleClick = useCallback((event) => {
    if (linkProps) {
      // change status current in parent
      safeCall(changeCurrentHandler)
      // scroll laterally to newcurrent stuff on click
      if (isCurrent === false) {
        handlePosition()
      }
    }

    Router.pushRoute(`/${props?.linkProps?.route}`)
  }, [changeCurrentHandler, handlePosition, isCurrent, linkProps, props.linkProps.route])

  useEffect(() => {
    // scroll to current stuff on load
    if (isCurrent === true) {
      handlePosition()
    }
  }, [handlePosition, isCurrent])

  return (
    <li
      className={cx(classes.container, className, isCurrent === true && 'is-current')}
      ref={itemRef}
    >
      <A
        {...linkProps}
        scroll={false}
        className={classes.link}
        onClick={(e) => handleClick(e)}
      >
        <h2 className={classes.textBox}>
          <MarkdownText
            text={text}
            className={classes.text}
          />
        </h2>
        <Icon
          icon={icons.CalendarEyelet}
          className={classes.eyelet}
        />
        <Icon
          icon={icons.CalendarEyelet}
          className={classes.eyelet}
        />
      </A>
    </li>
  )
}

NavQuestionsLink.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  linkProps: PropTypes.shape(A.propTypes),
  text: PropTypes.string,
  isCurrent: PropTypes.bool,
  changeCurrentHandler: PropTypes.func,
  scrollCurrentHandler: PropTypes.func,
  animationTime: PropTypes.number,
}

NavQuestionsLink.defaultProps = {
  className: null,
  classes: null,
  linkProps: null,
  text: '',
  isCurrent: false,
  changeCurrentHandler: () => null,
  scrollCurrentHandler: () => null,
  animationTime: 300,
}

export default withMemo()(NavQuestionsLink)
