function configureLinks(md, { autoBlankExternalLinks }) {
  const defaultRender = md.renderer.rules.link_open
    || ((tokens, idx, options, env, self) => self.renderToken(tokens, idx, options))

  // eslint-disable-next-line no-param-reassign
  md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    const targetIndex = tokens[idx].attrIndex('target')
    const href = tokens[idx].attrGet('href')

    if (autoBlankExternalLinks && targetIndex < 0 && href && href.indexOf('http') === 0) {
      tokens[idx].attrPush(['target', '_blank'])
      tokens[idx].attrPush(['rel', 'nofollow noreferrer'])
    }

    if (!href) {
      // eslint-disable-next-line no-script-url
      tokens[idx].attrSet('href', 'javascript:void(0)')
    }

    return defaultRender(tokens, idx, options, env, self)
  }
}

export function configure(md, config) {
  const { autoBlankExternalLinks } = config

  configureLinks(md, { autoBlankExternalLinks })

  return md
}

export function fixSpaces(text) {
  return text?.replace(/(\*\*|__|_)( *)(.*?)( *)\1/gim, '$2$1$3$1$4')
}

export function cleanTitleInA(text) {
  const regex = /<a[^>]*title="(.*?)"[^>]*>(.*?)<\/a>/gim

  return text?.replace(regex, (match) => match.replace('<mark data-tip>', '').replace('</mark>', ''))
}
