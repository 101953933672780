import gql from 'graphql-tag'


export const QuestionsDuMomentView = gql`
    query questionsDuMomentView {
        questionsDuMomentView {
            results {
                ... on TaxonomyTermPeriodes {
                    tid
                    name
                    fieldPeriodesSubtitle
                    entityUrl {
                        path
                    }
                }
            }
        }
    }
    # Fragments
`

export const EnPratiqueView = gql`
    query EnPratiqueView {
        enPratiqueView {
            meta
            results {
                ... on NodeOutil {
                    title
                    entityUrl {
                        path
                    }
                    fieldIconeEspace {
                        url
                        alt
                    }
                }
            }
        }
    }
    # Fragments
`

export const LexiqueView = gql`
    query LexiqueView {
        lexiqueView {
            results {
                ... on TaxonomyTermGlossaire {
                    name
                    description{
                        value
                    }
                }
            }
        }
    }
    # Fragments
`

export const HomepageView = gql`
    query HomepageView {
        blocListeRubriquesView {
            results {
                ... on NodeRubrique {
                    title
                    body {
                        value
                    }
                    entityUrl {
                        path
                    }
                    fieldImage {
                        alt
                        fieldImageRubHome: derivative(style: IMAGERUBHOME) {
                            url
                        }
                    }
                    fieldSousRubrique {
                        entity {
                            ... on TaxonomyTermRubrique {
                                fieldRubriqueClass
                            }
                        }
                    }
                }
            }
        }
        blocListeQuestionsDuMomentView {
            results {
                ... on NodeQuestionMoment {
                    nid
                    fieldPeriode {
                        entity {
                            ... on TaxonomyTermPeriodes {
                                tid
                                path {
                                    alias
                                }
                                entityLabel
                            }
                        }
                    }
                    fieldDate {
                        value
                    }
                    fieldAuthor {
                        processed
                    }
                    fieldQuestion
                    fieldAnswer {
                        processed
                    }
                }
            }
        }
    }
    # Fragments
`
