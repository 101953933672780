import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import NavSide from '../../../components/NavSide'
import HeaderSimple from '../../../components/HeaderSimple'
import withMemo from '../../../decorators/withMemo'
import { selectors as PageSelectors } from '../../Pages/Page/redux'
import { useMenuAnchor } from '../../../utils/hooks/useMenuAnchor'
import { headerSimpleTransformer } from '../../Paragraphs/HeaderSimple/transformers'
import { BlocksParser } from '../../../helpers/BlocksHelpers'
import { useLexiconHandler } from '../../../utils/hooks/useLexicon'
import { selectors as AppSelectors } from '../../../redux/app/redux'

import { faqTransformer } from './transformers'


function FaqWrapper(props) {
  const { t } = useTranslation()
  const route = useSelector(AppSelectors.route)
  const { fieldFaqBlocsQuestionReponse } = props
  const [lexicals, handleLexicon] = useLexiconHandler()
  const variant = useSelector(PageSelectors.variant)
  const blocks = useMemo(() => fieldFaqBlocsQuestionReponse.map((block) => faqTransformer(block.entity)), [fieldFaqBlocsQuestionReponse])
  const headerSimpleProps = useMemo(() => headerSimpleTransformer(props, route, t), [props, route, t])

  const { links } = useMenuAnchor(blocks)
  const renderMenu = useMemo(() => (
    <NavSide
      variant={variant}
      links={links}
    />
  ), [variant, links])

  return (
    <>
      <HeaderSimple
        {...headerSimpleProps}
        variant={variant}
        lexicon={lexicals}
        markHover={handleLexicon}
      />
      {fieldFaqBlocsQuestionReponse && (
        <BlocksParser
          options={{
            renderMenu,
            lexicon: lexicals,
            markHover: handleLexicon,
          }}
          blocks={fieldFaqBlocsQuestionReponse}
        />
      )}
    </>
  )
}

FaqWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fieldFaqBlocsQuestionReponse: PropTypes.array,
}

FaqWrapper.defaultProps = {
  fieldFaqBlocsQuestionReponse: null,
}

export default withMemo()(FaqWrapper)
