import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import NavSecondaryLink from '../NavSecondaryLink'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavSecondary = (props) => {
  const { classes: classesProp, className, links } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return links ? (
    <nav className={cx(classes.container, className)}>
      <ul className={classes.list}>
        {links.map((link, index) => (
          <li
            key={`nav-${index}`}
            className={cx(classes.item, 'is-active', `is-${link.variant}`)}
          >
            <NavSecondaryLink {...link} />
          </li>
        ))}
      </ul>
    </nav>
  ) : null
}

NavSecondary.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(
    PropTypes.shape(NavSecondaryLink.propTypes)
  ),
}

NavSecondary.defaultProps = {
  className: null,
  classes: null,
  links: null,
}

export default withMemo()(NavSecondary)
