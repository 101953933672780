import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import MarkdownStyled from '../MarkdownStyled'
import Image from '../Image'
import ButtonColored from '../ButtonColored'

import styles from './styles'


const useStyles = createUseStyles(styles)

const LandingSlide = (props) => {
  const {
    classes: classesProp,
    className,
    text,
    title,
    TagTitle,
    sliderTitle,
    TagSliderTitle,
    image,
    textNumber,
    button,
    lexicon,
    transformLexicon,
    markHover,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <div className={cx(classes.container, className)}>
      {sliderTitle && (
        <TagSliderTitle className={classes.sliderTitle}>{sliderTitle}</TagSliderTitle>
      )}
      {image && (
        <div className={classes.figure}>
          <Image
            {...image}
            className={classes.image}
          />
        </div>
      )}
      <div className={classes.content}>
        {title && (
          <TagTitle className={classes.title}>{title}</TagTitle>
        )}
        {text && (
          <MarkdownStyled
            className={classes.text}
            text={text}
            lexicon={lexicon}
            transformLexicon={transformLexicon}
            markHover={markHover}
          />
        )}
        {button && (
          <ButtonColored
            {...button}
            className={classes.button}
          />
        )}
        {textNumber && (
          <p className={classes.number}>{textNumber}</p>
        )}
      </div>
    </div>
  )
}

LandingSlide.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string,
  title: PropTypes.string,
  TagTitle: PropTypes.string,
  sliderTitle: PropTypes.string,
  TagSliderTitle: PropTypes.string,
  image: PropTypes.shape(Image.propTypes),
  textNumber: PropTypes.string,
  button: PropTypes.shape(ButtonColored.propTypes),
  lexicon: PropTypes.arrayOf(PropTypes.object),
  transformLexicon: PropTypes.bool,
  markHover: PropTypes.func,
}

LandingSlide.defaultProps = {
  className: null,
  classes: null,
  text: '',
  title: '',
  TagTitle: 'h4',
  sliderTitle: '',
  TagSliderTitle: 'h3',
  image: null,
  textNumber: null,
  button: null,
  lexicon: null,
  transformLexicon: true,
  markHover: () => null,
}

export default withMemo()(LandingSlide)
