import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import withMemo from '../../../decorators/withMemo'
import { selectors as PageSelectors } from '../../Pages/Page/redux'
import BlockFocus from '../../../components/BlockFocus'

import { blockFocusTransformer } from './transformers'


const ArticleBlocFocusBienEtre = (props) => {
  const { lexicon, markHover, transformLexicon } = props
  const { t } = useTranslation()
  const variant = useSelector(PageSelectors.variant)
  const blockFocusProps = useMemo(() => blockFocusTransformer(props, t, variant), [props, t, variant])

  return (
    <BlockFocus
      {...blockFocusProps}
      variant={variant}
      lexicon={lexicon}
      markHover={markHover}
      transformLexicon={transformLexicon}
    />
  )
}

ArticleBlocFocusBienEtre.propTypes = {
  lexicon: PropTypes.array,
  markHover: PropTypes.func,
  transformLexicon: PropTypes.bool,

}

ArticleBlocFocusBienEtre.defaultProps = {
  lexicon: null,
  markHover: () => null,
  transformLexicon: true,
}

export default withMemo()(ArticleBlocFocusBienEtre)
