import { accessibleHiddenText, colors, fonts } from '../../theme'


export default {
  container: {
    ...fonts.MaaxBold,
    position: 'fixed',
    zIndex: 60,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: colors.pureWhite,
    textTransform: 'uppercase',
    overflow: 'hidden',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 200ms ease-out 100ms',
    '&.is-open': {
      opacity: 1,
      pointerEvents: 'all',
      transition: 'opacity 200ms ease-out 0ms',
    },
  },
  start: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowX: 'hidden',
    overflowY: 'scroll',
    padding: '3rem 3rem 3rem 7.5rem',
    color: colors.blue,
    WebkitOverflowScrolling: 'touch',
  },
  close: {
    position: 'absolute',
    top: '3rem',
    left: '2rem',
    display: 'block',
    width: '3rem',
    height: '3rem',
    borderRadius: '50%',
    padding: '0.5rem',
    border: '0.3rem solid currentColor',
    cursor: 'pointer',
    '& span[class*=icon-]': {
      display: 'block',
      width: '100%',
      height: '100%',
    },
  },
  menuTitle: {
    position: 'relative',
    display: 'block',
    fontSize: '2.4rem',
  },
  levelOne: {
    '&:not(:first-child)': {
      marginTop: '2.5rem',
    },
  },
  levelOneItem: {
    '&:not(:first-child)': {
      marginTop: '2.5rem',
    },
  },
  levelOneButton: {
    position: 'relative',
    display: 'block',
    width: '100%',
    paddingTop: '3rem',
    paddingBottom: '1rem',
    paddingRight: '5rem',
    fontSize: '1.5rem',
    lineHeight: '1.4',
    textAlign: 'left',
    textTransform: 'uppercase',
    cursor: 'pointer',
    outline: '0 none',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'block',
      width: '6rem',
      height: '0.5rem',
      background: 'currentColor',
      pointerEvents: 'none',
    },
    // variant
    '&.is-care::before': { backgroundColor: colors.redCare },
    '&.is-environment::before': { backgroundColor: colors.turquoiseEnvironment },
    '&.is-listen::before': { backgroundColor: colors.orangeListen },
    '&.is-discover::before': { backgroundColor: colors.blueDiscover },
    '&.is-default::before': { backgroundColor: colors.turquoiseEnvironment },
  },
  levelOneLink: {
    color: colors.blue,
  },
  levelOneText: {
  },
  levelOneIcon: {
    position: 'absolute',
    top: 'calc(50% + 1rem)',
    right: 0,
    width: '1.4rem',
    height: '2rem',
    transform: 'translateY(-50%)',
  },
  // secondary
  secondary: {
    '&:not(:first-child)': {
      marginTop: '4rem',
    },
  },
  secondaryItem: {
    paddingRight: '4rem',
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  secondaryLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '40rem',
    height: '4rem',
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
    textAlign: 'center',
    fontSize: '1.4rem',
    color: colors.pureWhite,
    backgroundColor: colors.blue,
    // variant
    '&.is-questions': { backgroundColor: colors.turquoiseQuestions, color: colors.blueQuestions },
  },
  // levelTwo
  sub: {},
  levelTwo: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowX: 'hidden',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 200ms ease-out 100ms',
    // default
    backgroundColor: colors.redDarkestCare,
    color: colors.yellowCare,
    // active
    '&.is-active': {
      opacity: 1,
      transition: 'opacity 200ms ease-out 0ms',
    },
    '$container.is-open &.is-active': {
      pointerEvents: 'all',
    },
    // variant
    '&.is-environment': { backgroundColor: colors.turquoiseDarkEnvironment, color: colors.blueDarkEnvironment },
    '&.is-listen': { backgroundColor: colors.orangeDarkListen, color: colors.blueListen },
    '&.is-discover': { backgroundColor: colors.blueDarkDiscover, color: colors.yellowDiscover },
    //
    '& a, & button': {
      color: 'inherit',
    },
    '& a:hover': {
      color: colors.pureWhite,
    },
  },
  backButton: {
    position: 'absolute',
    top: '3rem',
    left: '2rem',
    display: 'block',
    width: '3rem',
    height: '3rem',
    borderRadius: '50%',
    padding: '0.5rem',
    border: '0.3rem solid currentColor',
    cursor: 'pointer',
    '& span[class*=icon-]': {
      display: 'block',
      width: '100%',
      height: '100%',
      transform: 'translateX(-0.1rem)',
    },
  },
  levelTwoHeader: {
    padding: '3rem 3rem 3rem 7.5rem',
    // default
    backgroundColor: colors.redCare,
    // variant
    '.is-environment &': { backgroundColor: colors.turquoiseEnvironment },
    '.is-listen &': { backgroundColor: colors.orangeListen },
    '.is-discover &': { backgroundColor: colors.blueDiscover },
  },
  levelTwoTitle: {
    position: 'relative',
    display: 'block',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
    fontSize: '2.4rem',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      display: 'block',
      width: '6rem',
      height: '0.5rem',
      background: 'currentColor',
      pointerEvents: 'none',
    },
    '&::before': {
      top: 0,
    },
    '&::after': {
      bottom: '0',
    },
  },
  levelTwoContent: {
    padding: '3rem 3rem 3rem 7.5rem',
  },
  levelTwoItem: {
    '&:not(:first-child)': {
      marginTop: '2rem',
    },
  },
  levelTwoLink: {
    position: 'relative',
    display: 'block',
    padding: '2rem 0 1rem',
    fontSize: '1.8rem',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'block',
      width: '6rem',
      height: '0.5rem',
      background: 'currentColor',
      pointerEvents: 'none',
    },
  },
  //
  accessiblehiddentext: {
    ...accessibleHiddenText,
  },
}
