import { accessibleHiddenText, colors, fonts, mobile, tablet } from '../../theme'


export default {
  container: {
    position: 'relative',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    paddingBottom: '2rem',
  },
  // slider
  slider: {
    '& > .keen-slider': {
      visibility: 'hidden',
    },
  },
  nav: {
    position: 'absolute',
    top: '50%',
    zIndex: 5,
    cursor: 'pointer',
    color: colors.blue,
    padding: '0.5rem',
    transform: 'translateY(-40%)',
    '&.is-disabled': {
      opacity: '50%',
      pointerEvents: 'none',
    },
    '& i': {
      ...accessibleHiddenText,
    },
  },
  previous: {
    left: '0.5rem',
  },
  next: {
    right: '0.5rem',
  },
  navIcon: {
    width: '1.5rem',
    height: '2rem',
  },
  // content
  containerSlderPart: {

  },
  slide: {
    paddingTop: '2rem',
    textAlign: 'center',
  },
  figure: {
    width: '6rem',
    margin: '0 auto',
    transformOrigin: 'bottom center',
    opacity: 0.4,
    transition: 'transform 200ms ease-out, opacity 250ms ease-out',
    '$slide.is-current &': {
      opacity: 1,
      transform: 'scale(1.2) translateY(-0.5rem)',
    },
  },
  text: {
    ...fonts.MaaxBold,
    display: 'block',
    marginTop: '2rem',
    textAlign: 'center',
    color: colors.blue,
    textTransform: 'uppercase',
    fontSize: '1.6rem',
    marginBottom: 0,
  },
  ...mobile({
    slide: {
      paddingTop: '4rem',
    },
    figure: {
      width: '10rem',
    },
  }),
  ...tablet({
    container: {
      paddingLeft: '6rem',
      paddingRight: '6rem',
      paddingBottom: '3rem',
      background: colors.greyF6,
      maxWidth: '100%',
    },
    previous: {
      left: '2rem',
    },
    next: {
      right: '2rem',
    },
  }),
  '@media print': {
    nav: {
      display: 'none',
    },
    slide: {
      opacity: 0,
      '&.is-current': {
        opacity: 1,
      },
    },
  },
}
