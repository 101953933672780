import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockImage = (props) => {
  const {
    classes: classesProp,
    className,
    image,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return image ? (
    <section
      className={cx(classes.container, className)}
    >
      <div className={classes.wrapper}>
        <figure className={classes.figure}>
          <Image
            {...image}
            className={classes.image}
          />
        </figure>
      </div>
    </section>
  ) : null
}

BlockImage.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  image: PropTypes.shape(Image.propTypes),
}

BlockImage.defaultProps = {
  className: null,
  classes: null,
  image: null,
}

export default withMemo()(BlockImage)
