import { colors } from '../../theme'


export default {
  pageContainer: {
    overflow: 'hidden',
    background: colors.pureWhite,
    display: 'flex',
    flexFlow: 'column nowrap',
    minHeight: 'calc(var(--vh, 1vh) * 100)',
  },
  pageContainerMain: {
    position: 'relative',
    flex: '1',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
  },
  pageContainerGooter: {
    marginTop: 'auto',
  },
}
