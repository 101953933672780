import { colors, fonts, mobile } from '../../theme'


export default {
  container: {
    ...fonts.MaaxBold,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '4.6rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    textAlign: 'center',
    fontSize: '1.8rem',
    lineHeight: '1',
    whiteSpace: 'nowrap',
    color: colors.pureWhite,
    background: colors.blue,
  },
  ...mobile({
    container: {
      fontSize: '2rem',
      minHeight: '3.8rem',
    },
  }),
}
