import { colors } from '../../theme'


export default {
  container: {
    '& mark': {
      fontWeight: 'bold',
      color: colors.blueDiscover,
      background: 'inherit',
      cursor: 'context-menu',
      transition: 'color 200ms ease-out',
    },
    '& mark:hover': {
      color: colors.blueSteel,
      textDecoration: 'underline',
    },
    '& a mark, & a mark:hover': {
      fontWeight: 'inherit',
      color: 'inherit',
      cursor: 'inherit',
      textDecoration: 'none',
    },
    '& h4 mark, & h4 mark:hover, & h5 mark, & h5 mark:hover, & h6 mark, & h6 mark:hover': {
      fontWeight: 'inherit',
      color: 'inherit',
      cursor: 'inherit',
      textDecoration: 'none',
    },
  },
}
