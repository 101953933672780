import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'api'

//
// Initial state
//
const initialState = {
  headers: {},
}

//
// Actions
//
export const actions = {
  setHeaders: createAction('App: setHeaders', (headers) => headers),
}

export const reducer = createReducer(
  {
    [actions.setHeaders]: (state, headers) => Immutable.static.merge(state, { headers }),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state.api
const headers = (state) => ({
  ...root(state).headers,
})

export const selectors = {
  headers,
}
