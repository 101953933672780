import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
// eslint-disable-next-line
import { default as Loader } from 'react-redux-loading-bar'

import { mergeStyles } from '../../utils/StylesUtils'

import styles from './styles'


const useStyles = createUseStyles(styles)

function LoadingBar(props) {
  const {
    classes: classesProp,
    className,
    // eslint-disable-next-line no-unused-vars
    variant,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <div className={cx(classes.container, className)}>
      <Loader
        updateTime={100}
        progressIncrease={8}
        maxProgress={100}
        className={classes.loader}
      />
    </div>
  )
}

LoadingBar.styles = styles

LoadingBar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
}

LoadingBar.defaultProps = {
  className: null,
  classes: {},
}

export default LoadingBar
