import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import withMemo from '../../../decorators/withMemo'
import HeaderArticle from '../../../components/HeaderArticle'
import NavPaginate from '../../../components/NavPaginate'
import BlockRelated from '../../../components/BlockRelated'
import BlockToPractice from '../../../components/BlockToPractice'
import { headerArticleTransformer } from '../../Paragraphs/HeaderArticle/transformers'
import { selectors as PageSelectors } from '../../Pages/Page/redux'
import { selectors as ToolSelectors } from '../OutilWrapper/redux'
import {
  blockArticleTransformer,
  fieldBlocsArticleTransformer
} from '../../Paragraphs/ArticleEncartContenu/transformers'
import { BlocksParser } from '../../../helpers/BlocksHelpers'
import { relatedTransformer } from '../../Paragraphs/BlockRelated/transformers'
import { blockToPracticeTransformer } from '../OutilWrapper/transformers'
import { useLexiconHandler } from '../../../utils/hooks/useLexicon'
import { selectors as AppSelectors } from '../../../redux/app/redux'

import { selectors as ArticleSelectors } from './redux'


function ArticleWrapper(props) {
  const { fieldBlocsArticle } = props
  const { t } = useTranslation()
  const variant = useSelector(PageSelectors.variant)
  const route = useSelector(AppSelectors.route)
  const nextPrevProps = useSelector(ArticleSelectors.nextPrev)
  const tools = useSelector(ToolSelectors.tools)
  const [lexicals, handleLexicon] = useLexiconHandler()


  const headerArticleProps = useMemo(() => headerArticleTransformer({ url: route.asPath, ...props }, t, variant), [route.asPath, props, t, variant])
  const [blockArticleProps, skip] = useMemo(() => blockArticleTransformer(props, variant, lexicals), [lexicals, props, variant])
  const blockRelatedProps = useMemo(() => relatedTransformer(props, t, variant), [props, t, variant])
  const blockToPracticeProps = useMemo(() => blockToPracticeTransformer(t, tools), [t, tools])

  const [fieldBlocsArticleProps] = useMemo(() => fieldBlocsArticleTransformer(fieldBlocsArticle, lexicals, skip), [fieldBlocsArticle, lexicals, skip])

  return (
    <>
      <HeaderArticle
        {...headerArticleProps}
        text={blockArticleProps?.text ?? null}
        lexicon={lexicals}
        markHover={handleLexicon}
        transformLexicon={false}
      />
      {fieldBlocsArticleProps && (
        <BlocksParser
          blocks={fieldBlocsArticleProps}
          options={{
            lexicon: lexicals,
            markHover: handleLexicon,
            transformLexicon: false,
          }}
        />
      )}
      <NavPaginate
        {...nextPrevProps}
        variant={variant}
      />
      <BlockRelated {...blockRelatedProps} />
      <BlockToPractice {...blockToPracticeProps} />
    </>
  )
}

ArticleWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fieldBlocsArticle: PropTypes.array,

}

ArticleWrapper.defaultProps = {
  fieldBlocsArticle: null,
}

export default withMemo()(ArticleWrapper)
