import { grids, tablet } from '../../theme'


export default {
  container: {
    marginTop: (props) => (props.isHidden === true ? '0' : '4rem'),
    opacity: (props) => (props.isHidden === true ? '0' : '1'),
    transition: 'max-height 200ms ease-out 50ms, opacity 150ms ease-out',
    '&:last-child': {
      marginBottom: (props) => (props.isHidden === true ? '0' : '4rem'),
    },
  },
  wrapper: {
    maxWidth: `calc(${grids.desktop.boxingContent / 10}rem + 4rem)`,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > * + *': {
      marginTop: '5rem',
    },
  },
  text: {
    fontSize: '1.8rem',
  },
  textOther: {
    textAlign: 'center',
    fontSize: '2rem',
    '& h3, & h4, & h5, & h6': {
      fontSize: '1.8rem !important',
      textTransform: 'uppercase',
    },
    '& h3': {
      margin: 0,
    },
  },
  ...tablet({
    container: {
      marginTop: '5rem',
    },
    text: {
      maxWidth: 'calc(100% / 12 * 9)',
      marginLeft: 'auto',
      fontSize: '1.6rem',
    },
    textOther: {
      fontSize: '2.4rem',
    },
  }),
  '@media print': {
    container: {
      maxHeight: 'none !important',
      marginTop: '4rem !important',
      opacity: '1 !important',
    },
  },
}
