import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import Image from '../Image'
import NavSide from '../NavSide'
import MarkdownText from '../MarkdownText'
import { safeCall } from '../../helpers/React'
import MagicTitle from '../MagicTitle'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockTopic = (props) => {
  const {
    classes: classesProp,
    className,
    categories,
    currentCategory,
    onMenuClick,
    variant,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const refTitle = useRef(null)

  const handleClick = useCallback(
    (index) => {
      safeCall(onMenuClick, index)
      // scroll to content
      if (refTitle.current && typeof window !== 'undefined') {
        const target = refTitle.current.getBoundingClientRect().top
          + (window.pageYOffset || document.documentElement.scrollTop)
          - 100

        window.scrollTo({
          top: target,
          behavior: 'smooth',
        })
      }
    },
    [onMenuClick]
  )

  const renderMenu = useMemo(() => {
    const navsideLinks = categories?.map((category, index) => ({
      linkProps: {
        onClick: () => handleClick(index),
      },
      text: category.text,
      variant,
      isActive: index === currentCategory,
    }))

    return (
      <NavSide
        links={navsideLinks}
        className={classes.navigation}
        variant={variant}
      />
)
  }, [categories, classes.navigation, currentCategory, handleClick, variant])

  const renderLinks = useMemo(
    () => categories[currentCategory]?.links && (
    <ul className={classes.list}>
      {categories[currentCategory].links?.map((link, index) => {
            const truncatedText = link && link.text ? `${link.text.substr(0, 200)}\u2026` : ''

      return (
      // eslint-disable-next-line react/jsx-indent
      <li
        className={classes.item}
        key={`blocktopic-link-${index}`}
      >
        <A
          {...link.linkProps}
          className={classes.link}
        >
          {link.image && (
            <figure className={classes.figure}>
              <Image
                className={classes.image}
                {...link.image}
              />
            </figure>
          )}
          <div className={classes.content}>
            <h3 className={classes.title}>{link.title}</h3>
            {!link.image && link.text && (
            <MarkdownText
              className={classes.text}
              text={truncatedText}
            />
            )}
          </div>
        </A>
      </li>
    )
})}
    </ul>
  // eslint-disable-next-line max-len
  ), [categories, classes.content, classes.figure, classes.image, classes.item, classes.link, classes.list, classes.text, classes.title, currentCategory]
)

  return (
    <section className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {renderMenu}
        <div className={classes.category}>
          <MagicTitle
            text={categories[currentCategory]?.text}
            className={classes.categoryTitle}
          />
          {renderLinks}
        </div>
      </div>
    </section>
  )
}

BlockTopic.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  onMenuClick: PropTypes.func,
  currentCategory: PropTypes.number,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          linkProps: PropTypes.shape(A.propTypes),
          image: PropTypes.shape(Image.propTypes),
          title: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    })
  ),
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
  lexicon: PropTypes.arrayOf(PropTypes.object),
  markHover: PropTypes.func,
}

BlockTopic.defaultProps = {
  className: null,
  classes: null,
  categories: null,
  onMenuClick: () => null,
  currentCategory: 0,
  lexicon: null,
  markHover: () => null,
}

export default withMemo()(BlockTopic)
