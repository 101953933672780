import { isDefined, isTypeArray, strRemoveAllSpaces } from '../../../helpers/TypeHelpers'

import { CategoryTitlesEnum, CategoryTypenamesEnum, SectionTitleEnum } from './consts'


const getBreadcrumbAsStringList = (breadcrumb) => {
    return breadcrumb.reduce((resultBreadcrumbStringList, breadcrumbItem) => {
        return [
            ...resultBreadcrumbStringList,
            breadcrumbItem.text,
        ]
    }, [])
}
const extractTitleFromMetatags = (metatags = []) => {
    if (!isTypeArray(metatags)) {
        return ''
    }

    const TARGET_KEY_ENTITY_TITLE = 'title'
    let i = 0
    while (i < metatags?.length) {
        if (metatags[i]?.key === TARGET_KEY_ENTITY_TITLE) {
            return metatags[i]?.value
        }
        i++
    }
    return ''
}
export const getPageTitleFromPageData = (pageData) => extractTitleFromMetatags(pageData?.entityMetatags || pageData?.metatags)


const createTcPayload = (tagName, section = '/', subSection = '/') => ({
    page_cat1_name: tagName,
    page_cat2_name: section,
    page_cat3_name: subSection,
})


// determines period and detail from route slug
export const getRubriqueQuestionDuMomentPeriodNameWithRoute = (route) => {
    return {
        questions_du_moment: ['/', '/'],
        'questions_du_moment/avant-la-grossesse': ['Avant la grossesse', '/'],
        'questions_du_moment/grossesse-1er-trimestre': ['Grossesse', '1er trimestre'],
        'questions_du_moment/grossesse-2eme-trimestre': ['Grossesse', '2ème trimestre'],
        'questions_du_moment/grossesse-3eme-trimestre': ['Grossesse', '3ème trimestre'],
        'questions_du_moment/apres-la-grossesse-1eres-semaines': ['Après la naissance', '1ères semaines'],
        'questions_du_moment/bebe-grandit-3-a-9-mois': ['Bébé grandit', '3 à 9 mois'],
        'questions_du_moment/bebe-grandit-9-mois-a-2-ans': ['Bébé grandit', '9 mois à 2 ans'],
    }[route.query.slug ?? '']
}

export const matchers = [
    /*
     *  Detects Articles pages
    */
    {
        // eslint-disable-next-line
        testFn: function isArticle(pageData, pageInfos = null, route = null, breadcrumb) {
            return CategoryTypenamesEnum.ARTICLE === pageData?.__typename
        },
        // eslint-disable-next-line
        prepareTcPayload: function handleArticleTc(pageData, pageInfos, route = null, breadcrumb) {
            const breadcrumbStringList = getBreadcrumbAsStringList(breadcrumb)
            const [_, articleCategory] = breadcrumbStringList
            const articleName = pageData.title
            const tagName = CategoryTitlesEnum.ARTICLE
            return createTcPayload(tagName, articleCategory, articleName)
        },
    },

    /*
     *  Detects rubrique "En pratique"
    */
    {
        // eslint-disable-next-line
        testFn: function isRubriqueEnPratique(pageData, pageInfos, route = null, breadcrumb) {
            const breadcrumbArray = getBreadcrumbAsStringList(breadcrumb).map((path) => strRemoveAllSpaces(path).toLowerCase())
            const [_, rubriqueName] = breadcrumbArray
            return rubriqueName === 'enpratique'
        },
        prepareTcPayload: function handleRubriqueEnPratique(pageData, pageInfos, route = null, breadcrumb) {
            const tagName = SectionTitleEnum.EN_PRATIQUE
            const urlPath = route?.query?.slug
            const isRubriqueRootPage = urlPath === 'en_pratique'
            if (isRubriqueRootPage) {
                return createTcPayload(tagName)
            }
            const [locationArea, selectedElementInArea] = urlPath.split('/')
            return createTcPayload(tagName, locationArea, selectedElementInArea?.replaceAll('-', ' '))
        },
    },

    /*
     *  Detects rubrique "Questions du moment"
    */
    {
        testFn: function isRubriqueQuestionsDuMoment(pageData, pageInfos, route, breadcrumb) {
            return SectionTitleEnum.QUESTION_DU_MOMENT === getPageTitleFromPageData(pageData)
        },
        prepareTcPayload: function handleRubriqueQuestionsDuMoment(pageData, pageInfos, route, breadcrumb) {
            const tagName = SectionTitleEnum.QUESTION_DU_MOMENT
            const arrayCategoryInfos = getRubriqueQuestionDuMomentPeriodNameWithRoute(route)
            if (!isDefined(arrayCategoryInfos)) {
                return
            }
            try {
                const [categoryPeriod, categoryDetail] = arrayCategoryInfos
                return createTcPayload(tagName, categoryPeriod, categoryDetail)
            } catch (e) {
                return {}
            }
        },
    },
    /*
     *  For specific pages
     */
    {
        testFn: function machtSpecificPages(pageData, pageInfos, route, breadcrumb) {
            const pathname = route.query.slug ?? ''
            const pageName = {
                'les-1000-premiers-jours': 'Pourquoi 1000 jours ?',
                accessibilite: 'Accessibilité',
                'mentions-legales': 'Mentions légales',
                'politique-de-confidentialite': 'Politique de confidentialité',
                'plan-du-site': 'Plan du site',
                aide: 'Aide',
                lexique: 'Lexique',
                'appli-et-autres-outils': 'Appli et autres outils',
                'a-propos-du-site': 'A propos du site',
            }[pathname] ?? null
            return isDefined(pageName)
        },
        prepareTcPayload: function handleSpecificPages(pageData, pageInfos, route, breadcrumb) {
            const pageName = route.query.slug
            const tagName = 'Autres pages'
            return createTcPayload(tagName, pageName)
        },
    },
    /*
     *  Apply on all pages: NOTE: this is already handled somewhere in the app
    */
    {
        testFn: (pageData, pageInfos, route) => false,
        prepareTcPayload: (pageData, pageInfos, route, breadcrumb) => {
            // This should never be called as |testFn| returns false
            const page_name = getPageTitleFromPageData(pageData)
            const env_language = ''
            const env_device = ''
            const env_country = ''
            const cookie_consent = ''
            return ({
                page_name,
                env_language,
                env_device,
                env_country,
                cookie_consent,
            })
        },
    },
]
