/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import debounce from 'lodash/debounce'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import { safeCall } from '../../helpers/React'
import MarkdownText from '../MarkdownText'
import NavPractice from '../NavPractice'
import Icon from '../Icon'
import icons from '../Icon/assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockToPractice = (props) => {
  const {
    classes: classesProp,
    className,
    title,
    text,
    onClickButton,
    navigationProps,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const refMarker = useRef(null)
  const refWrapper = useRef(null)

  const [isOpen, setOpen] = useState(false)
  const [isInViewport, setInViewport] = useState(false)
  const [y, setY] = useState(0)
  const [maxHeight, setMaxHeight] = useState(0)
  const refContent = useRef()

  useEffect(() => {
    setY(document.documentElement.scrollTop || document.body.scrollTop)
  }, [])

  const handleClickButton = useCallback(() => {
    safeCall(onClickButton)
    if (isInViewport === true && isOpen === true) {
      setOpen(false)
    } else {
      setOpen(!isOpen)
    }
  }, [isInViewport, isOpen, onClickButton])


  const handleMaxHeight = useCallback(
    debounce(() => {
      if (refContent.current && isOpen) {
        setMaxHeight(refContent.current.offsetHeight)
      } else {
        setMaxHeight(0)
      }
    }, 50),
    [isOpen]
  )

  const handleMenuThreshold = useCallback(
    debounce(() => {
      const targetShow = refMarker?.current?.getBoundingClientRect().top
        + (window?.pageYOffset || document?.documentElement.scrollTop)
        - (window?.innerHeight * 0.9) ?? null
      const targetHide = refMarker?.current?.getBoundingClientRect().top
        + (window?.pageYOffset || document?.documentElement.scrollTop)
        - (window?.innerHeight * 1) - maxHeight ?? null
      const targetPin = refWrapper?.current?.getBoundingClientRect().top
        + (window?.pageYOffset || document?.documentElement.scrollTop)
        - (window?.innerHeight * 1) ?? null
      const scrollTop = (document?.documentElement?.scrollTop || document?.body?.scrollTop)
        ?? null

      if (scrollTop) {
        if (y > scrollTop) {
          // console.log('scrolling up')
          if (targetHide && scrollTop < targetHide) {
            setOpen(false)
          }
          if (targetPin && scrollTop < targetPin) {
            setInViewport(false)
          }
        } else if (y < scrollTop) {
          // console.log('scrolling down')
          if (targetShow && scrollTop > targetShow) {
            setInViewport(true)
            if (isOpen === false) {
              setOpen(true)
            }
          }
        }
        setY(scrollTop)
      }
    }, 25), [y, maxHeight]
  )

  // effects
  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleMaxHeight()
      handleMenuThreshold()
      window.addEventListener('resize', handleMenuThreshold)
      window.addEventListener('scroll', handleMenuThreshold)
      window.addEventListener('resize', handleMaxHeight)
    }
    return () => {
      try {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleMenuThreshold)
          window.removeEventListener('scroll', handleMenuThreshold)
          window.removeEventListener('resize', handleMaxHeight)
        }
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [handleMaxHeight, handleMenuThreshold])

  return (
    <>
      <aside
        className={cx(classes.container,
          className,
          isOpen === true && 'is-open',
          isInViewport === true ? 'is-inviewport' : 'is-fixed')}
      >
        <button
          onClick={handleClickButton}
          type="button"
          className={classes.button}
        >
          <span>{title}</span>
          <Icon
            icon={icons.Close}
            className={classes.buttonIcon}
          />
        </button>
        <div
          className={classes.wrapper}
          ref={refWrapper}
          style={{ maxHeight }}
        >
          <div
            className={classes.content}
            ref={refContent}
          >
            {text && (
              <MarkdownText
                text={text}
                className={classes.text}
              />
            )}
            {navigationProps && (
              <NavPractice
                {...navigationProps}
                isSmallerOnMobile
                className={classes.navigation}
              />
            )}
          </div>
        </div>
      </aside>
      <div
        className={classes.refMarker}
        aria-hidden="true"
        ref={refMarker}
      />
    </>
  )
}

BlockToPractice.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  navigationProps: PropTypes.shape(NavPractice.propTypes),
  title: PropTypes.string,
  text: PropTypes.string,
  onClickButton: PropTypes.func,
}

BlockToPractice.defaultProps = {
  className: null,
  classes: null,
  navigationProps: null,
  title: '',
  text: '',
  onClickButton: () => null,
}

export default withMemo()(BlockToPractice)
