import React from 'react'

import { variantTransformer } from '../../../redux/menu/transformers'
import FontsResizer from '../../../components/FontsResizer'
import ShareLinks from '../../../components/ShareLinks'
import { shareLinksTransformer } from '../../../transformers'


export const headerSimpleTransformer = (item, route, t) => ({
  title: item?.title ?? '',
  text: item?.body?.value ?? '',
  variant: variantTransformer(''),
  zoneTools:
  <>
    <FontsResizer />
    <ShareLinks {...shareLinksTransformer({ title: item?.title ?? '', url: route?.asPath ?? null }, t)} />
  </>,
})
