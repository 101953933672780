import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import Immutable from 'seamless-immutable'
import { I18nextProvider } from 'react-i18next'


export default class I18nProvider extends PureComponent {

  constructor(props) {
    super(props)

    if (props.store && props.store.getState() && props.store.getState().i18n) {
      const { lang, store } = props.store.getState().i18n
      const i18nInstance = this.props.i18n || i18n

      if (store && !i18nInstance.initializedStoreOnce) {
        i18nInstance.services.resourceStore.data = Immutable.isImmutable(store)
          ? Immutable.asMutable(store, { deep: true })
          : store
        i18nInstance.initializedStoreOnce = true
      }

      if (lang && !i18nInstance.initializedLanguageOnce) {
        i18nInstance.changeLanguage(lang)
        i18nInstance.initializedLanguageOnce = true
      }
    }
  }

  render() {
    const { children } = this.props
    const i18nInstance = this.props.i18n || i18n

    return <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
  }

}

I18nProvider.propTypes = {
  i18n: PropTypes.shape({
    services: PropTypes.object,
  }),
  store: PropTypes.shape({
    getState: PropTypes.func,
  }),
  children: PropTypes.node,
}

I18nProvider.defaultProps = {
  i18n: null,
  store: {
    getState: () => ({}),
  },
  children: null,
}
