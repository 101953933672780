import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'lexicon'


//
// Initial state
//
const initialState = {
  lexicals: null,
  lexicon: null,
  view: null,
  term: null,
  skips: [],
}

//
// Actions
//
export const actions = {
  setLexicals: createAction('Lexicon: setLexicals', (lexicals) => lexicals),
  setLexicon: createAction('Lexicon: setLexicon', (lexicon) => lexicon),
  setView: createAction('Lexicon: setView', (view) => view),
  setTerm: createAction('Lexicon: setTerm', (term) => term),
  setSkips: createAction('Lexicon: setSkips', (skips) => skips),
  addSkip: createAction('Lexicon: addSkip', (skip) => skip),
  removeSkip: createAction('Lexicon: removeSkip', (skip) => skip),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setLexicals]: (state, lexicals) => Immutable.merge(state, { lexicals }),
    [actions.setLexicon]: (state, lexicon) => Immutable.merge(state, { lexicon }),
    [actions.setView]: (state, view) => Immutable.merge(state, { view }),
    [actions.setTerm]: (state, term) => Immutable.merge(state, { term }),
    [actions.setSkips]: (state, skips) => Immutable.merge(state, { skips }),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state[key]
const lexicals = (state) => root(state).lexicals
const lexicon = (state) => root(state).lexicon
const view = (state) => root(state).view
const term = (state) => root(state).term
const skips = (state) => root(state).skips

export const selectors = {
  lexicals,
  lexicon,
  view,
  term,
  skips,
}
