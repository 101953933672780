import Color from 'color'

import colors from './colors'
import { desktop } from './mixins'


const textStyles = {
  lightOnDark: {
    color: colors.pureWhite,
  },
  darkOnLight: {
    color: colors.pureBlack,
  },
  accentOnLight: {
    color: colors.orange,
  },
  lightOnAccent: {
    color: colors.pureWhite,
  },
  sectionKeyword: {
    fontSize: '1.4rem',
    color: Color(colors.grey13).alpha(0.5).string(),
    ...desktop({
      fontSize: '1.6rem',
    }),
  },
}

export default textStyles
