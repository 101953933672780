import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'
import A from '../A'
import MagicTitle from '../MagicTitle'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const HomeTopicCard = (props) => {
  const {
    classes: classesProp,
    className,
    title,
    TagTitle,
    image,
    text,
    linkProps,
    // eslint-disable-next-line no-unused-vars
    variant,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <article className={cx(classes.container, className)}>
      <A
        {...linkProps}
        className={classes.link}
      >
        <div className={classes.hook}>
          {image && (
            <figure className={classes.figure}>
              <Image
                className={classes.image}
                covered
                {...image}
              />
            </figure>
          )}
          <div className={classes.titleZone}>
            <MagicTitle
              className={classes.title}
              TagTitle={TagTitle}
              text={title}
              sizeDecoration={4}
            />
          </div>
        </div>
        <div
          className={classes.text}
        >
          <MarkdownText
            text={text}
          />
        </div>
      </A>
    </article>
  )
}

HomeTopicCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  TagTitle: PropTypes.string,
  image: PropTypes.shape(Image.propType),
  text: PropTypes.string,
  linkProps: PropTypes.shape(A.propTypes),
  lexicon: MarkdownText.propTypes.lexicon,
  markHover: MarkdownText.propTypes.markHover,
  variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']),
}

HomeTopicCard.defaultProps = {
  className: null,
  classes: null,
  TagTitle: 'h2',
  image: null,
  text: '',
  linkProps: null,
  lexicon: null,
  markHover: () => null,
  variant: 'default',
}

export default withMemo()(HomeTopicCard)
