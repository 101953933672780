import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import Image from '../Image'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockLandingSliders = (props) => {
  const {
    classes: classesProp,
    className,
    children,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <section className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {children}
        </div>
        <div className={classes.bubbleTop}>
          <Image
            src="/images/Landing/landing-bg-sliders-1.png"
            className={classes.bubbleImage}
            alt=" "
            aria-hidden="true"
          />
        </div>
        <div className={classes.bubbleBottom}>
          <Image
            src="/images/Landing/landing-bg-sliders-3.png"
            className={classes.bubbleImage}
            alt=" "
            aria-hidden="true"
          />
        </div>
        <div className={classes.bubbleBottomMobile}>
          <Image
            src="/images/Landing/landing-bg-sliders-2.png"
            className={classes.bubbleImage}
            alt=" "
            aria-hidden="true"
          />
        </div>
      </div>
    </section>
  )
}

BlockLandingSliders.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
}

BlockLandingSliders.defaultProps = {
  className: null,
  classes: null,
  children: null,
}

export default withMemo()(BlockLandingSliders)
