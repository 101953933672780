import { colors } from '../../theme'


export default {
  container: {},
  '@media print': {
    container: {
      marginTop: '6rem',
      borderTop: `1pt solid ${colors.greyDE}`,
      breakInside: 'avoid',
      pageBreakInside: 'avoid',
    },
  },
}
