import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import { safeCall } from '../../helpers/React'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NavLexicon = (props) => {
  const {
    classes: classesProp,
    className,
    letters,
    clickHandler,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const handleClick = useCallback((index, letter) => {
    safeCall(clickHandler, index, letter)
  }, [clickHandler])

  const renderLetters = useMemo(() => letters && (
    <ul className={classes.list}>
      {letters.map((letter, index) => (
        <li
          className={classes.item}
          key={`navlexicon-${index}`}
        >
          {letter.isLink === true ? (
            <A
              className={cx(classes.letter, letter.isCurrent === true && 'is-current')}
              onClick={() => handleClick(index, letter.text)}
            >
              {letter.text}
            </A>
          ) : (
            <span className={cx(classes.letter, 'is-notlink')}>
              {letter.text}
            </span>
          )}
        </li>
      ))}
    </ul>
  ), [classes.item, classes.letter, classes.list, handleClick, letters])

  return letters ? (
    <nav className={cx(classes.container, className)}>
      {renderLetters}
    </nav>
  ) : null
}

NavLexicon.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  letters: PropTypes.arrayOf(
    PropTypes.shape(
      {
        text: PropTypes.string,
        isCurrent: PropTypes.bool,
        isLink: PropTypes.bool,
      }
    )
  ),
  clickHandler: PropTypes.func,
}

NavLexicon.defaultProps = {
  className: null,
  classes: null,
  letters: null,
  clickHandler: () => null,
}

export default withMemo()(NavLexicon)
