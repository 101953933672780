import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'tracking'

const initialState = {
  enabled: false,
  codeEmbeded: false,
}

//
// Actions
//
export const actions = {
  enableTracking: createAction('Tracking: enableTracking'),
  disabledTracking: createAction('Tracking: disabledTrackking'),
  setCodeEmbeded: createAction('Tracking: set code embeded'),
  trackPage: createAction('Tracking: trackPage', (path) => path),
  trackEvent: createAction('Tracking: trackEvent', (event) => event),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.enableTracking]: (state) => Immutable.merge(state, { enabled: true }),
    [actions.disabledTracking]: (state) => Immutable.merge(state, { enabled: false }),
    [actions.setCodeEmbeded]: (state) => Immutable.merge(state, { codeEmbeded: true }),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state[key]
const enabled = (state) => root(state).enabled

export const selectors = {
  enabled,
}
