import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/withMemo'
import { mergeStyles } from '../../utils/StylesUtils'
import A from '../A'
import Image from '../Image'
import RelatedCard from '../RelatedCard'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BlockRelated = (props) => {
  const {
    classes: classesProp,
    className,
    title,
    links,
    TagTitle,
  } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  const renderLinks = useMemo(() => links && links.map((link, index) => (
    <li
      className={classes.item}
      key={`blockrelated-link-${index}`}
    >
      <RelatedCard
        className={classes.card}
        {...link}
      />
    </li>
  )), [classes.card, classes.item, links])

  return links && (
    <aside className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {title && <TagTitle className={classes.title}>{title}</TagTitle>}
        <ul className={classes.list}>
          {renderLinks}
        </ul>
      </div>
    </aside>
  )
}

BlockRelated.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    linkProps: PropTypes.shape(A.propTypes),
    text: PropTypes.string,
    image: PropTypes.shape(Image.propTypes),
    variant: PropTypes.oneOf(['care', 'environment', 'listen', 'discover', 'default']).isRequired,
  })),
  TagTitle: PropTypes.string,
}

BlockRelated.defaultProps = {
  className: null,
  classes: null,
  title: '',
  links: null,
  TagTitle: 'h3',
}

export default withMemo()(BlockRelated)
